import React from "react";
import { Form, useFormik } from "formik";
import { signupFields } from "../../helper/form/fields";
import { FormikProvider } from "formik";
import FormikFieldController from "../../uikits/form";
import { FormButton } from "../../uikits/buttons";
import { SignupSchema } from "../../helper/form/validations";
import { errorToast, successToast } from "../../helper/others";

import axios from "axios";
import { useStripe } from "@stripe/react-stripe-js";
import { SimpleImage } from "../../GlobalComponents/img/global";

function saveUserToDatabase(userData) {
  axios
    .post("/user/register", userData)
    .then((res) => {
      successToast("Compte créé avec succes");
      window.setTimeout(() => {
        window.location = "/Connexion";
      }, 5000);
    })
    .catch((err) =>
      errorToast("Une erreur s'est produite,lors de la création de compte ")
    );
}

function checkPaiement(stripe, userData, apiResponse) {
  const { latest_invoice } = apiResponse.data.results[0];
  const { payment_intent } = latest_invoice;
  if (payment_intent) {
    const { client_secret, status } = payment_intent;

    if (
      status === "requires_action"
      // || status === "requires_payment_method"
    ) {
      stripe
        .confirmCardPayment(client_secret)
        .then((res) => {
          if (res.paymentIntent) {
            saveUserToDatabase(userData);
          } else {
            errorToast("Erreur de confimation de carte");
          }
        })
        .catch((scErr) =>
          errorToast("Erreur de confimation de carte :" + scErr.error.message)
        );
    } else if (status == "succeeded") {
      saveUserToDatabase(userData);
    } else {
      errorToast("Une erreur est survenue, veuillez réessayer");
    }
  } else {
    errorToast("Une erreur est survenue, veuillez réessayer");
  }
}

function Signup({ props }) {
  const stripe = useStripe();

  const subscriptionPrice = "29,90";
  const formik = useFormik({
    initialValues: {
      nom: "",
      prenom: "",
      codepostal: "",
      telephone: "",
      email: "",
      carte_expiration_mois: "",
      carte_expiration_année: "",
      number: "",
      code_derriere_carte: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: SignupSchema,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });
  const { isValid, setSubmitting, isSubmitting } = formik;

  function handleSubmit(formValues) {
    let bodyToSet = {
      nom: formValues.nom,
      prenom: formValues.prenom,
      telephone: formValues.telephone,
      email: formValues.email,
      codepostal: formValues.codepostal,
      number: formValues.number,
      exp_month: formValues.carte_expiration_mois,
      exp_year: formValues.carte_expiration_année,
      cvc: formValues.code_derriere_carte,
      password: formValues.password,
    };
    axios
      .post("/user/pay", bodyToSet)
      .then((res) => {
        checkPaiement(stripe, bodyToSet, res);
      })
      .catch((err) => {
        errorToast("Une erreur s'est produite : " + err.response.data.message);
      })
      .finally(() => setSubmitting(false));
  }
  return (
    <FormikProvider value={formik}>
      <div className="checkOutTitle">
        <b>Paiement 100% sécurisé</b>
        <SimpleImage
          props={{ src: "paiement.webp", alt: "Piament pic", className: "" }}
        />
      </div>
      <Form>
        {signupFields.map((item, i) => (
          <FormikFieldController key={"item nb" + i} {...item} />
        ))}
        <div className="formTotalPrice">
          <p>Total à payer :</p>
          <b>{subscriptionPrice} €</b>
        </div>
        <FormButton
          isValid={isSubmitting ? false : isValid}
          text={isSubmitting ? "" : "Valider"}
        />
      </Form>
    </FormikProvider>
  );
}
export default Signup;
