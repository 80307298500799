
export function conjoint_aAuMoins_55ans(etatLegal, age) {
    if (etatLegal == "Veuf(ve)" && age > 55)
        return true;
    return false
}

export function veuvage_auMoins_55ans_et_enZoneUE(nationalite, age, mesRevenusVal, etatLegal) {
    // UE + Norvège + Islande + Suisse
    if (["UE + Norvège + Islande + Suisse", "Français(e)"].includes(nationalite) && etatLegal === "Veuf(ve)") {
        if (age <= 55 && mesRevenusVal <= 790) {
            return true
        }
    }
    return false
}