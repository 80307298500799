import React from 'react'
import "../style/loadingBorder.css"
function LoadingBorder({loading}) {
    return (
        <>
        {loading?<div className="loaderBorderContainer">
            <h1 data-text="Nous calculons vos aides">
            Nous calculons vos aides
            </h1>
            <div>
            </div>
        </div>:null}
    </>
    )
}

export default LoadingBorder
