export const showMenuOptionAnimation = (baseClass) => {
    document.querySelector('.' + baseClass).classList.toggle(baseClass + '-Visible')
}

export function showMenuAndMoveMainAnimation() {
    document.querySelector('.mobileGeneralSidebar').classList.toggle('mobileGeneralSidebarVisible')
    toggleMenuAndScrollBar()
}

export function toggleMenuAndScrollBar() {
    document.querySelector('main').classList.toggle('mainMoveRight')
    // document.querySelector('html').classList.toggle('bonyNotScrollable');
    // document.querySelector('body').classList.toggle('bonyNotScrollable');
}