import { withoutAuth } from "../hocs/withoutAuth"

const Politique_de_confidentialité = () => {
    return <div className="legality">
        <section className='pdc'>
            <h1>
                Politique de confidentialité et de protection des données à caractère personnel des clients du site « france-assist.fr»
            </h1>
            <h3>Identification du responsable de traitement</h3>
            <strong>France-Assist est un site privé et indépendant</strong>

            Le Prestataire apporte un intérêt tout particulier sur la protection des données personnelles collectées. Le Prestataire s’engage à respecter les textes applicables et notamment le Règlement Général sur la Protection des Données (ci-après, le « RGPD ») et la loi Informatique et Libertés modifiées du 6 janvier 1978 modifiée.
            Le Prestataire est responsable de traitement pour les traitements indiqués ci-dessous, sauf clause contraire.
            <h3>Définitions</h3>

            <b>Données personnelles : </b> désigne toute information se rapportant à une personne physique identifiée ou identifiable

            <b>Données de santé :</b> désigne les données relatives à la santé physique ou mentale, passée, présente ou future, d’une personne physique (y compris la prestation de services de soins de santé) qui révèlent des informations sur l’état de santé de cette personne

            <b>Site :</b> désigne le site internet accessible à l’URL <a href="https://france-assist.fr">www.france-assist.fr</a>

            <b>Utilisateur(s) :</b> désigne l’internaute qui accède au Site afin de le consulter, de demander une simulation gratuite ou un accompagnement


            <h3>Pourquoi vos données sont-elles collectées ?</h3>

            <b>Plusieurs raisons peuvent amener le Prestataire à collecter vos données :</b>
            <br />
            1- Pour la réalisation de la simulation gratuite et de l’accompagnement.
            Vos Données personnelles nous permettent alors de remplir et
            d’étudier votre dossier afin de calculer les aides auxquelles vous pourriez être éligible.
            Vos Données personnelles nous permettront par ailleurs de réaliser le suivi de la relation contractuelle.
            <br />
            2- Lorsque vous procédez à une demande de contact sur le Site, les données transmises nous permettent de répondre à votre demande.
            <br />
            3- Lorsque vous procédez à une demande d’aide ou de rappel sur le site, vos données transmises nous permettent de vous rappeler.
            <br />
            4- Collecte et transmission de données à destination de nos partenaires notamment de renégociation de crédit.


            <h3>Quelles sont les données collectées par le responsable de traitement ?</h3>

            Les données collectées varient selon les traitements.
            1. Simulation gratuite / Accompagnement
            Afin de pouvoir calculer le montant d’aides auquel vous pouvez prétendre, nous avons besoin d’une situation complète et à jour (tant personnelle que familiale et professionnelle). Les informations collectées varient selon votre situation. Les informations demandées sont obligatoires pour pouvoir répondre au mieux à votre demande d’accompagnement. Les informations pouvant être collectées sont :
            <br />- Age
            <br />- Nationalité
            <br />- Résidence légale en France
            <br />- Détention d’un titre de séjour depuis plus de 5 ans ou non
            <br />- Statut (étudiant, retraité, demandeur d’emploi, enceinte, en situation de handicap, inapte au travail, actif)
            <br />- Etudiant boursier ou non, et l’échelon de bourse le cas échéant
            <br />- Date de fin du dernier contrat de travail
            <br />- Taux de handicap
            <br />- Enfants à charge (handicapés ou non)
            <br />- Prénom, âge, statut (garde alternée, étudiant, demandeur d’emploi, en situation de handicap et taux du handicap, inapte au travail, enceinte, actif, autre) des enfants à charge
            <br />- Si l’enfant à charge étudiant est boursier, et quel échelon de bourse le cas échéant
            <br />- Scolarisation de l’enfant à charge à la rentrée prochaine (collège, lycée/CAP, aucun des deux)
            <br />- Situation maritale (seul, marié, pacsé, en concubinage)
            <br />- Age du conjoint et statut (étudiant, retraité, demandeur d’emploi, enceinte, en situation de handicap, inapte au travail, actif…) et complément d’information si le conjoint est boursier, handicapé,…
            <br />- Information concernant le logement (locataire, propriétaire, hébergé,…)
            <br />- Information concernant le type de logement
            <br />- Information concernant le loyer
            <br />- Information concernant la valeur du bien immobilier
            <br />- Information sur le crédit le cas échéant
            <br />- Information sur les besoins éventuels et les montants (crédit, prêt étudiant,…)
            <br />- Information sur les crédits à la consommation en cours
            <br />- Information sur le type de travail et de contrat
            <br />- Information sur les revenus (personnels et ceux du conjoint et des enfants)
            D’autres informations pourront être demandées en cours de réalisation du dossier (impôts,…). Ces informations ne sont demandées par le Prestataire que si elles s’avèrent nécessaires en fonction de votre situation personnelle.
            A l’issue du formulaire et afin de pouvoir vous communiquer votre simulation, il vous sera demandé : vos nom et prénom, votre téléphone, votre adresse mail. Ces informations nous permettent par ailleurs de vous contacter le cas échéant afin d’approfondir notre accompagnement et vous proposer nos services.

            <br />2. Page contact
            Lorsque vous utilisez la page contact sur le Site, les Données personnelles que nous collectons sont votre nom et votre adresse électronique. Ces Données nous sont nécessaires afin de vous apporter une réponse.

            <br />3. Besoin d’aide
            Lorsque vous effectuez une demande d’aide pendant que vous remplissez votre formulaire ou sur la page « Demande de rappel », seul votre numéro de téléphone est demandé afin que nous puissions vous rappeler.

            <br />4. Collecte de données pour nos partenaires
            Ces données sont la situation de crédits de l’utilisateur afin de calculer l’éligibilité l’Utilisateur à des dispositifs de renégociation de crédit.


            <h3>Quelle sont les bases légales de ces traitements ?</h3>

            <br />1. Simulation gratuite et accompagnement
            Une fois le formulaire complété, il vous est demandé de prendre connaissance des conditions générales de vente et d’utilisation et de la présente politique de confidentialité. En cliquant sur « Accéder à mon résultat » vous consentez au traitement de vos données. La base légale du traitement est donc le consentement. Les Données remplies dans le formulaire en ligne ne nous sont en aucun cas transmises tant que l’Utilisateur n’a pas cliqué sur « Accéder à mon résultat ».

            Conformément à la loi, il vous est demandé de donner votre consentement avant le traitement de vos Données de santé (enceinte, handicap,…).
            Les données concernant les tiers (conjoint, enfant) sont traitées sur la base de l’intérêt légitime. Les Données personnelles des tiers ne font l’objet d’un traitement qu’afin de répondre à votre demande et ne sont en aucun cas transmises à des tiers.

            <br />2. Pages « Contact », « Besoin d’aide » et « Demande de rappel »
            Lorsque vous nous transmettez un message via la page « Contact » ou que vous réalisez une demande d’aide ou de rappel, la base légale du traitement est notre intérêt légitime.

            <br />3. Transmission d’information aux Partenaires
            La transmission d’information est effectuée sur la base du consentement.


            <h3>Combien de temps conservons-nous les données ?</h3>

            Lorsque vous procédez simplement à une demande de simulation gratuite, vos données de contact sont conservées trois (3) ans pour une finalité de prospection pour un service équivalent. Les autres données transmises sont supprimées dans un délai de trente (30) jours après la fin de la mission.
            Dans le cadre d’un accompagnement, le Prestataire conserve vos données pendant la durée de réalisation de la mission. Une fois l’accompagnement terminé, vos Données personnelles sont supprimées. Le contrat est conservé pendant la durée de conservation légale des contrats. Les Données pouvant servir d’éléments de preuve en cas de contentieux seront conservées dans une base archive jusqu’à la fin de la durée de prescription.
            Lorsque vous nous contactez via la page « Contact » ou « Besoin d’aide » ou « Demande de rappel » vos données sont effacées à l’issue d’un délai de six (6) mois après la transmission de votre demande. Vos données sont sécurisées selon les règles de l’art. Les salariés sont soumis à une clause de confidentialité.

            <h3>Où les données sont-elles hébergées ?</h3>

            Les données sont hébergées par o2switch hébergeur 100% français
            <b> Téléphone :</b> {process.env.REACT_APP_CONTACT_PHONE}
            <b>Adresse :</b> Clermont-Ferrand
            qui intervient donc en tant que sous-traitant du Prestataire pour l’hébergement.
            Comment vos données sont-elles protégées ?

            Afin de protéger vos données, nous avons mis en place les mesures de sécurité suivantes :
            <br />• Sensibilisation des utilisateurs ;
            <br />• Authentification des utilisateurs ;
            <br />• Gestion des habilitations ;
            <br />• Traçage des accès ;
            <br />• Sécurisation des postes de travail (antivirus) ;
            <br />• Sécurisation de l’informatique mobile ;
            <br />• Protection du réseau informatique interne (pare-feu) ;
            <br />• Sécurisation des serveurs ;
            <br />• Sécuriser du site internet ;
            <br />• Sauvegardes régulières afin de garantir la continuité d’activité ;
            <br />• Sécurisation des archives ;
            <br />• Encadrement de la maintenance et de la destruction des données ;
            <br />• Protection des locaux ;
            <br />• Signature d’accords de confidentialité dans les contrats de travail et de prestation de services.

            <h3>A qui les données sont-elles transmises ?</h3>

            Destinataires externes : l’hébergeur.
            Le Prestataire peut également communiquer vos Données personnelles à des tiers si vous avez demandé à être mis en relation avec des prestataires pour les prêts à la consommation, la restructuration de crédits,… La base légale de cette transmission est donc votre consentement.

            <h3>Les données sont-elles transférées hors de l’UE ?</h3>

            Les données sont transférées à un prestataire sous-traitant hors de l’UE. Le Prestataire a signé avec ce sous-traitant les clauses contractuelles types de la Commission européenne permettant le transfert de données d’un responsable de traitement à un sous-traitant basé hors de l’Union Européenne.


            <h3>Quels sont vos droits ?</h3>

            <b>Droit de retirer son consentement à tout moment</b>
            Lorsque la base légale du traitement est le consentement, vous disposez du droit de le retirer à tout moment en utilisant le formulaire de désinscription ci-dessous.
            Vous pouvez par ailleurs, à tout moment, vous opposer au traitement à des fins de prospection en envoyant un mail à contact@france-assist.fr .

            <b>Droit d’accès</b>
            L’Utilisateur dispose du droit d’obtenir la confirmation que ses données personnelles sont ou ne sont pas traitées par le Prestataire ainsi que, lorsqu’elles le sont, l’accès à ces données ainsi que les informations suivantes :
            - les finalités du traitement ;
            - les catégories de données à caractère personnel concernées ;
            - les destinataires ou catégories de destinataires auxquels les données à caractère personnel ont été ou seront communiquées, en particulier les destinataires qui sont établis dans des pays tiers ou les organisations internationales ;
            - lorsque cela est possible, la durée de conservation des données à caractère personnel envisagée ou, lorsque ce n'est pas possible, les critères utilisés pour déterminer cette durée ;
            - l'existence du droit de demander au Prestataire la rectification ou l'effacement de données à caractère personnel ;
            - le droit d'introduire une réclamation auprès d'une autorité de contrôle ;
            - l'existence d'une prise de décision automatisée, y compris un profilage, et, au moins en pareils cas, des informations utiles concernant la logique sous-jacente, ainsi que l'importance et les conséquences prévues de ce traitement pour la personne concernée.

            <b>Droit de demander la limitation du traitement</b>
            L’Utilisateur peut demander au Prestataire la limitation des traitements de ses données personnelles, notamment lorsqu’il conteste l’exactitude des données, pendant la durée permettant au responsable de traitement de vérifier l’exactitude des données ; si le traitement est illicite mais que l’Utilisateur ne souhaite pas que ses données soient effacées ; si le Prestataire n’a plus besoin des données mais que l’Utilisateur en a besoin dans le cadre de la constatation, l’exercice ou la défense de droits en justice.

            <b>Droit d’effacement</b>
            L’Utilisateur peut demander au Prestataire l’effacement de ses données personnelles, notamment lorsqu’il a retiré son consentement au traitement de ses données ou lorsque les données ne sont plus nécessaires pour la finalité pour laquelle le Prestataire avait besoin de les traiter.

            <b>Droit de rectification</b>
            L’Utilisateur peut demander au Prestataire de rectifier les données personnelles collectées.

            <b>Droit de demander la portabilité des données</b>
            Le Prestataire s'engage à vous offrir la possibilité de vous faire restituer l'ensemble des données vous concernant sur simple demande. L'Utilisateur se voit ainsi garantir une meilleure maîtrise de ses données, et garde la possibilité de les réutiliser. Ces données seront fournies à l’Utilisateur ou au tiers qu’il aura désigné dans un format ouvert et aisément réutilisable.

            Chaque Utilisateur a le droit d’exercer les droits mentionnés, en écrivant :
            <br />- par e-mail accompagné d’une photocopie d’une pièce d’identité à l’adresse suivante : contact@france-assist.fr ;
            <br />- par courrier accompagné d’une photocopie d’une pièce d’identité signée à l’adresse postale <strong>France-Assist est un site privé et indépendant</strong> Le Prestataire fera ses meilleurs efforts pour répondre à la demande de l’Utilisateur dans le délai d’un mois à compter de la réception de celle-ci. Si la réponse fournie par le Prestataire ne satisfait pas l’Utilisateur ou s’il souhaite la contester, il peut introduire une réclamation auprès de la CNIL.

            <b>Cookies</b>
            Les cookies sont de petits fichiers transférés sur votre ordinateur par un site web, qui collectent des données sur votre navigation.
            C’est grâce aux cookies qu’une boutique en ligne conserve le contenu de votre panier alors que vous continuez à parcourir le site, par exemple. C’est aussi grâce à eux que vous n’avez pas besoin de redonner votre identifiant et votre mot de passe sans arrêt lorsque vous naviguez sur certains sites. La durée de vie des cookies peut être très courte ou durer plusieurs mois, jusqu’à 13 mois maximum. Certains cookies peuvent apparaître intrusifs aux yeux des internautes, c’est pourquoi la loi encadre strictement leur usage. Lorsque les cookies utilisés correspondent à ceux visés par la réglementation, le site web qui les utilise doit en informer ses utilisateurs en précisant leur finalité. Il doit également recueillir leur consentement pour utiliser ces cookies.

            <b>Finalité des cookies</b>
            Les cookies peuvent être utilisés :
            <br />• pour des fins statistiques et analytiques notamment pour optimiser les services rendus à l'Utilisateur, à partir du traitement des informations concernant la fréquence d'accès, la personnalisation des pages ainsi que les opérations réalisées et les informations consultées ;
            <br />• pour des fins de ciblages publicitaires ;
            <br />• pour des publications sur les réseaux sociaux ;
            <br />• pour des raisons techniques (améliorations du Site).

            <b>Durée de conservation des cookies</b>
            Conformément aux recommandations de la CNIL, la durée maximale de conservation des cookies est de 13 mois au maximum après leur premier dépôt dans le terminal de l'Utilisateur, tout comme la durée de la validité du consentement de l’Utilisateur à l’utilisation de ces cookies. La durée de vie des cookies n’est pas prolongée à chaque visite. Le consentement de l’Utilisateur devra donc être renouvelé à l'issue de ce délai.

            Liste des cookies présents sur le Site, par finalités :
            <b>Finalités d’analyse du trafic :</b>
            Google Analytics nous permet de collecter des informations sur votre navigation sur France Assist : votre parcours sur le site, le temps passé sur nos pages, ce sur quoi vous cliquez etc. Ces données nous permettent de mieux comprendre le comportement et les attentes de nos utilisateurs afin d’améliorer nos services.
            Aucune donnée personnelle directement identifiante (comme votre nom) n’est collectée par Google Analytics.
            Vous pouvez désactiver le traçage effectué par Google Analytics.
            HotJar
            Ce cookie permet d’analyser le comportement des visiteurs du site France Assist dans le but d'améliorer votre expérience sur le site.

            <b>Finalités de ciblage publicitaire :</b>
            Ces cookies enregistrent votre visite sur le site, ainsi que les pages que vous avez consultées et les liens que vous avez suivis. Ces informations permettent d’afficher des publicités pertinentes en fonction de vos centres d'intérêts. Ces cookies sont également utilisés pour limiter le nombre d'affichages d'une même publicité et pour aider à mesurer l'efficacité des campagnes publicitaires :
            - Send In Blue
            - DoubleClick (Google)
            - Facebook

            <b>Finalités de publications sur les réseaux sociaux</b>
            Afin de rendre l’expérience France Assist plus conviviale, nous avons installé sur notre site de petits modules fournis par les entreprises de réseaux sociaux (Facebook, Twitter, Linkedin, Instagram, Youtube) qui sont susceptibles d’installer des cookies sur votre terminal.
            Ces modules vous permettent notamment d’« aimer » France Assist directement depuis notre site ou de partager une de nos publications. Nous vous invitons à consulter la politique de confidentialité des entreprises concernées.

            <b>Finalité technique</b>
            Google Optimize
            Dans le cadre de tests de différentes versions du site France Assist, nous pouvons être amenés à rediriger les visiteurs vers différentes versions des pages du Site afin de tirer des conclusions sur la performance de chaque version.

            <b>Droit de l'Utilisateur de refuser les cookies</b>
            Pour la gestion des cookies, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d’aide de votre navigateur, qui permettra de savoir de quelle manière modifier l’enregistrement de cookies.
            france assist
        </section>
    </div>
}

export default withoutAuth(Politique_de_confidentialité);