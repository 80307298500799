import React, { useEffect, useLayoutEffect, useState } from "react";
import "./profil.css";
import { OneChoice } from "../../../GlobalComponents/simulation/choices";
import { NextSimulationStepButton, PrevSimulationStep } from "../../../GlobalComponents/simulation/nav";
import { addSelectedClassToSelectedOptionForSimulator } from "../../../helper/simulation/others";
function Profil({ setSection, setProgress, setData }) {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;
  const [page, setPage] = useState(1);
  const [profil, setProfil] = useState({
    age: "",
    nationalite: "",
    statutPersonnel: "",
    statutPersonnel2: [],
    travailEnFrance: "",
    titreDeSejour: "",
    titreDeSejour5Ans: "",
    dateEnFrance: "",
    refuge: "",
    formation: "",
    bacObtention: "",
    bacMention: "",
    partirEtranger: "",
    permisConduite: "",
    bourse: "",
    bourseEchelon: "",
    aideFinanciere: "",
    TravailleFranceDemandeurEmploi: "",
    dernierContratTravail: "",
    TravailleFranceApres2015: "",
    procheAvecMaladieIncurable: "",
    tauxHandicap: "",
    pensionInvalidite: "",
    aideALaPersonne: "",

    inscrisPoleEmplois: "",
    financerPermisParCpf: "",
    besoinSouventDaide: "",
    moniteur_auto_ecole: '',
    signataire_contrat_avec_entreprise_non_agricole_prive: '',
    trajet_de_plus_d1h_ou_70km: '',
    peparation_qualification_RNCP: '',
    formation_alternance_au_moins_1_an: '',

    credit_en_cours: '',
    credit_remboursez_actuellement: '',
    rachat_ou_regroupement_de_credit: '',
    souhaite_beneficier_dun_credit: '',
    type_de_credit_souhaiter: '',
  });

  const normalizeAgeNumber = (value) => {
    return value.replace(/\D/gi, "") || "";
  };

  const normalizeDateNumber = (value) => {
    if (new Date(value) > new Date(today)) return "";
    else return value;
  };
  function singleSelect(e, cl) {
    let doc = document.querySelectorAll(cl);
    if (!profil[e.target.id] || profil[e.target.id] !== e.target.innerText) {
      setProfil({ ...profil, [e.target.id]: e.target.innerText });
      for (let i = 0; i < doc.length; i++) {
        doc[i].classList.remove("selected");
      }
      e.target.classList.add("selected");
    } else {
      setProfil({ ...profil, [e.target.id]: "" });
      e.target.classList.remove("selected");
    }
  }
  function multiSelect(e, cl) {
    if (!profil[e.target.id].includes(e.target.innerText)) {
      setProfil({
        ...profil,
        [e.target.id]: [...profil[e.target.id], e.target.innerText],
      });
      e.target.classList.add("selected");
    } else {
      setProfil({
        ...profil,
        [e.target.id]: profil[e.target.id].filter(
          (el) => el !== e.target.innerText
        ),
      });
      e.target.classList.remove("selected");
    }
  }

  useLayoutEffect(() => {
    let doc = document.querySelectorAll(".nation");
    let statusUnique = document.querySelectorAll(".statut-personnel");
    let statusMulti = document.querySelectorAll(".statut-personnel-multi");
    let travailFra = document.querySelectorAll(".travailEnFrance");
    let titreSejour = document.querySelectorAll(".titreSejour");
    let titreSejourDate = document.querySelectorAll(".titreDeSejour5Ans");
    let dateEnFrance = document.querySelectorAll(".dateEnFrance");
    let refuge = document.querySelectorAll(".refuge");

    if (profil.nationalite && doc) {
      for (let i = 0; i < doc.length; i++) {
        if (doc[i].innerText === profil.nationalite)
          doc[i].classList.add("selected");
      }
    }
    if (profil.statutPersonnel && statusUnique) {
      for (let i = 0; i < statusUnique.length; i++) {
        if (statusUnique[i].innerText === profil.statutPersonnel)
          statusUnique[i].classList.add("selected");
      }
    }
    if (profil.statutPersonnel2.length && statusMulti) {
      for (let i = 0; i < statusMulti.length; i++) {
        if (profil.statutPersonnel2.includes(statusMulti[i].innerText))
          statusMulti[i].classList.add("selected");
      }
    }
    if (profil.travailEnFrance && travailFra) {
      for (let i = 0; i < travailFra.length; i++) {
        if (travailFra[i].innerText === profil.travailEnFrance)
          travailFra[i].classList.add("selected");
      }
    }
    if (profil.titreDeSejour && titreSejour) {
      for (let i = 0; i < titreSejour.length; i++) {
        if (titreSejour[i].innerText === profil.titreDeSejour)
          titreSejour[i].classList.add("selected");
      }
    }
    if (profil.titreDeSejour5Ans && titreSejourDate) {
      for (let i = 0; i < titreSejourDate.length; i++) {
        if (titreSejourDate[i].innerText === profil.titreDeSejour5Ans)
          titreSejourDate[i].classList.add("selected");
      }
    }
    if (profil.dateEnFrance && dateEnFrance) {
      for (let i = 0; i < dateEnFrance.length; i++) {
        if (dateEnFrance[i].innerText === profil.dateEnFrance)
          dateEnFrance[i].classList.add("selected");
      }
    }
    if (profil.refuge && refuge) {
      for (let i = 0; i < refuge.length; i++) {
        if (refuge[i].innerText === profil.refuge)
          refuge[i].classList.add("selected");
      }
    }

    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'formation');
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'bacObtention');
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'partirEtranger');
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'permisConduite');
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'bourse');
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'bourseEchelon');
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'aideFinanciere');
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'TravailleFranceDemandeurEmploi');
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'TravailleFranceApres2015');
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'procheAvecMaladieIncurable');
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'tauxHandicap');
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'pensionInvalidite');

    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'aideALaPersonne');
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'inscrisPoleEmplois');
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'financerPermisParCpf');
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'besoinSouventDaide');
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'moniteur_auto_ecole');

    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'signataire_contrat_avec_entreprise_non_agricole_prive')
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'trajet_de_plus_d1h_ou_70km');
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'formation_alternance_au_moins_1_an');
    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'peparation_qualification_RNCP');

    addSelectedClassToSelectedOptionForSimulator(doc, profil, 'rachat_ou_regroupement_de_credit');

  }, [profil, page]);
  useEffect(() => {
    window.scrollTo(0, 160);
  }, [page]);
  return (
    <>
      {page === 1 && (
        <>
          <div className="simulateur-age">
            <span>J'ai</span>
            <div>
              <input
                // ref={ageRef}
                value={profil.age}
                type="tel"
                placeholder="22"
                autoFocus
                maxLength="2"
                onChange={(e) => {
                  e.target.value = normalizeAgeNumber(e.target.value);
                  setProfil({ ...profil, age: e.target.value });
                }}
              />
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <span>ans</span>
          </div>
          <div className="button-simulateur">
            <NextSimulationStepButton disabledCond={profil.age <= 14} onClick={() => profil.age > 60 ? setPage(1.09) : setPage(2)} />
          </div>
        </>
      )}
      {page === 1.09 && (
        <>
          <div className="simulateur-nationalite">
            <span>Avez regulierement besoin d'assistance ?</span>
            <OneChoice props={{
              choiceArray: ["Non", "Parfois", "Regulierement"],
              target: "besoinSouventDaide", singleSelect
            }} />
          </div>
          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
            <NextSimulationStepButton disabledCond={!profil.besoinSouventDaide}
              onClick={() => setPage(2)} />
          </div>
        </>
      )}
      {page === 2 && (
        <div className="simulateur-nationalite nation">
          <span>Ma nationalité :</span>
          <OneChoice props={{
            choiceArray: [<>
              <img src="/sources/frNation.svg" alt="fr" />Français(e)
            </>, <>
              <img src="/sources/ueNation.svg" alt="ue" />UE + Norvège + Islande + Suisse
            </>, <>
              <img src="/sources/autreNation.svg" alt="autre" />Autre
            </>],
            target: "nationalite", singleSelect
          }} />
          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
            <NextSimulationStepButton disabledCond={!profil.nationalite}
              onClick={() => {
                switch (profil.nationalite) {
                  case "Français(e)":
                    setProfil({
                      ...profil,
                      titreDeSejour: "",
                      titreDeSejour5Ans: "",
                      dateEnFrance: "",
                      refuge: "",
                      travailEnFrance: ""
                    });
                    setPage(3);
                    break;
                  case "UE + Norvège + Islande + Suisse":
                    setProfil({
                      ...profil,
                      titreDeSejour: "",
                      titreDeSejour5Ans: "",
                      dateEnFrance: "",
                      refuge: "",
                    });
                    setPage(2.1);
                    break;
                  default:
                    setProfil({ ...profil, travailEnFrance: "" });
                    setPage(2.2);
                    break;
                }
              }} />
          </div>
        </div>
      )}

      {page === 2.1 && (
        <div className="simulateur-nationalite">
          <span>J’ai déjà travaillé en France (moi ou mes parents)</span>
          <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "travailEnFrance", singleSelect }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 2 }} />
            <NextSimulationStepButton disabledCond={!profil.travailEnFrance} onClick={() => setPage(3)} />
          </div>
        </div>
      )}
      {page === 2.2 && (
        <div className="simulateur-nationalite">
          <span>J’ai un titre de séjour</span>
          <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "titreDeSejour", singleSelect }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 2 }} />
            <button
              disabled={!profil.titreDeSejour}
              style={{ backgroundColor: profil.titreDeSejour ? "var(--colorPrimary)" : "#efefef" }}
              onClick={() => {
                switch (profil.titreDeSejour) {
                  case "Oui":
                    setPage(2.3);
                    break;
                  default:
                    setProfil({ ...profil, titreDeSejour5Ans: "" })
                    setPage(2.4);
                    break;
                }
              }}
            >
              <span>Suivant</span>
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      )}

      {page === 2.3 && (
        <div className="simulateur-nationalite">
          <span>Je détiens un titre de séjour depuis plus de 5 ans</span>
          <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "titreDeSejour5Ans", singleSelect }} />


          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 2 }} />
            <button
              disabled={!profil.titreDeSejour5Ans}
              style={{ backgroundColor: profil.titreDeSejour5Ans ? "var(--colorPrimary)" : "#efefef" }}
              onClick={() => {
                setPage(2.5);
              }}
            >
              <span>Suivant</span>
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      )}

      {page === 2.4 && (
        <div className="simulateur-nationalite">
          <span>Je suis en France depuis au moins 2 ans</span>
          <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "dateEnFrance", singleSelect }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 2 }} />
            <button
              disabled={!profil.dateEnFrance}
              style={{ backgroundColor: profil.dateEnFrance ? "var(--colorPrimary)" : "#efefef" }}
              onClick={() => {
                setPage(2.6);
              }}
            >
              <span>Suivant</span>
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      )}
      {page === 2.5 && (
        <div className="simulateur-nationalite">
          <span>Je suis en France depuis au moins 2 ans</span>
          <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "dateEnFrance", singleSelect }} />
          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 2 }} />
            <button
              disabled={!profil.dateEnFrance}
              style={{ backgroundColor: profil.dateEnFrance ? "var(--colorPrimary)" : "#efefef" }}
              onClick={() => {
                switch (profil.dateEnFrance) {
                  case "Oui":
                    setPage(3);
                    break;

                  default:
                    setPage(2.6)
                    break;
                }
              }}
            >
              <span>Suivant</span>
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      )}
      {page === 2.6 && (
        <div className="simulateur-nationalite">
          <span>Je suis réfugié</span>
          <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "refuge", singleSelect }} />
          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 2 }} />
            <button
              disabled={!profil.refuge}
              style={{ backgroundColor: profil.refuge ? "var(--colorPrimary)" : "#efefef" }}
              onClick={() => {
                setPage(3);
              }}
            >
              <span>Suivant</span>
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      )}

      {page === 3 && (
        <div className="simulateur-nationalite">
          <span>Je suis
            <span style={{ color: "	#3c3c3c", fontSize: 13, display: "block" }}>
              Plusieurs réponses possibles
            </span>
          </span>
          <div>
            <div
              className="statut-personnel"
              onClick={(e) => singleSelect(e, ".statut-personnel")}
              id="statutPersonnel"
            >
              Étudiant(e)
            </div>
            <div
              className="statut-personnel"
              onClick={(e) => singleSelect(e, ".statut-personnel")}
              id="statutPersonnel"
            >
              Apprenti(e)
            </div>
            <div
              className="statut-personnel"
              onClick={(e) => singleSelect(e, ".statut-personnel")}
              id="statutPersonnel"
            >
              Retraité(e)
            </div>
            <div
              className="statut-personnel"
              onClick={(e) => singleSelect(e, ".statut-personnel")}
              id="statutPersonnel"
            >
              Demandeur d’emploi
            </div>
            <div
              className="statut-personnel-multi"
              onClick={(e) => multiSelect(e, ".statut-personnel-multi")}
              id="statutPersonnel2"
            >
              Enceinte
            </div>
            <div
              className="statut-personnel-multi"
              onClick={(e) => multiSelect(e, ".statut-personnel-multi")}
              id="statutPersonnel2"
            >
              En situation de handicap
            </div>
            <div
              className="statut-personnel-multi"
              onClick={(e) => multiSelect(e, ".statut-personnel-multi")}
              id="statutPersonnel2"
            >
              Inapte au travail
            </div>
            <div
              className="statut-personnel-multi"
              onClick={(e) => multiSelect(e, ".statut-personnel-multi")}
              id="statutPersonnel2"
            >
              Actif (salarié / indépendant)
            </div>
          </div>
          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 2 }} />
            <NextSimulationStepButton disabledCond={!profil.statutPersonnel && !profil.statutPersonnel2.length}
              onClick={() => {
                switch (profil.statutPersonnel) {
                  case "Étudiant(e)":
                  case "Apprenti(e)":
                    setProfil({
                      ...profil,
                      permisConduite: "",
                      TravailleFranceDemandeurEmploi: "",
                      dernierContratTravail: "",
                      TravailleFranceApres2015: "",
                      procheAvecMaladieIncurable: "",
                      tauxHandicap: "",
                      pensionInvalidite: "",
                      aideALaPersonne: "",
                    });
                    return setPage(3.001);

                  // break
                  case "Retraité(e)":
                    setProfil({
                      ...profil,
                      formation: "",
                      bacObtention: "",
                      bacMention: "",
                      partirEtranger: "",
                      permisConduite: "",
                      bourse: "",
                      bourseEchelon: "",
                      aideFinanciere: "",
                      TravailleFranceDemandeurEmploi: "",
                      dernierContratTravail: "",
                      TravailleFranceApres2015: "",
                      procheAvecMaladieIncurable: "",
                      tauxHandicap: "",
                      pensionInvalidite: "",
                      aideALaPersonne: "",
                    });
                    if (
                      profil.statutPersonnel2.includes(
                        "En situation de handicap"
                      )
                    ) {
                      return setPage(3.14);
                    }
                    if (profil.statutPersonnel2.includes("Inapte au travail")) {
                      return setPage(3.15);
                    }
                    if (
                      profil.statutPersonnel2.includes(
                        "Actif (salarié / indépendant)"
                      )
                    )
                      return setPage(3.12);
                    return setPage(3.5);
                  // break
                  case "Demandeur d’emploi":
                    setProfil({
                      ...profil,
                      formation: "",
                      bacObtention: "",
                      bacMention: "",
                      partirEtranger: "",
                      permisConduite: "",
                      bourse: "",
                      bourseEchelon: "",
                      aideFinanciere: "",
                      TravailleFranceDemandeurEmploi: "",
                      dernierContratTravail: "",
                      TravailleFranceApres2015: "",
                      procheAvecMaladieIncurable: "",
                      tauxHandicap: "",
                      pensionInvalidite: "",
                      aideALaPersonne: "",
                    });
                    return setPage(3.05);
                  // break
                  default:
                    break;
                }
                if (profil.statutPersonnel2.includes("Enceinte")) {
                  setProfil({
                    ...profil,
                    formation: "",
                    bacObtention: "",
                    bacMention: "",
                    partirEtranger: "",
                    permisConduite: "",
                    bourse: "",
                    bourseEchelon: "",
                    aideFinanciere: "",
                    TravailleFranceDemandeurEmploi: "",
                    dernierContratTravail: "",
                    TravailleFranceApres2015: "",
                    procheAvecMaladieIncurable: "",
                    tauxHandicap: "",
                    pensionInvalidite: "",
                    aideALaPersonne: "",
                  });
                  if (
                    profil.statutPersonnel2.includes("En situation de handicap")
                  )
                    return setPage(3.14);
                  if (profil.statutPersonnel2.includes("Inapte au travail"))
                    return setPage(3.15);
                  if (
                    profil.statutPersonnel2.includes(
                      "Actif (salarié / indépendant)"
                    )
                  )
                    return setPage(3.12);
                  return setPage(3.5);
                }
                if (
                  profil.statutPersonnel2.includes("En situation de handicap")
                ) {
                  setProfil({
                    ...profil,
                    formation: "",
                    bacObtention: "",
                    bacMention: "",
                    partirEtranger: "",
                    permisConduite: "",
                    bourse: "",
                    bourseEchelon: "",
                    aideFinanciere: "",
                    TravailleFranceDemandeurEmploi: "",
                    dernierContratTravail: "",
                    TravailleFranceApres2015: "",
                    procheAvecMaladieIncurable: "",
                    tauxHandicap: "",
                    pensionInvalidite: "",
                    aideALaPersonne: "",
                  });
                  return setPage(3.14);
                }
                if (profil.statutPersonnel2.includes("Inapte au travail")) {
                  setProfil({
                    ...profil,
                    formation: "",
                    bacObtention: "",
                    bacMention: "",
                    partirEtranger: "",
                    permisConduite: "",
                    bourse: "",
                    bourseEchelon: "",
                    aideFinanciere: "",
                    TravailleFranceDemandeurEmploi: "",
                    dernierContratTravail: "",
                    TravailleFranceApres2015: "",
                    procheAvecMaladieIncurable: "",
                    tauxHandicap: "",
                    pensionInvalidite: "",
                    aideALaPersonne: "",
                  });
                  return setPage(3.15);
                }
                if (
                  profil.statutPersonnel2.includes(
                    "Actif (salarié / indépendant)"
                  )
                ) {
                  setProfil({
                    ...profil,
                    formation: "",
                    bacObtention: "",
                    bacMention: "",
                    partirEtranger: "",
                    permisConduite: "",
                    bourse: "",
                    bourseEchelon: "",
                    aideFinanciere: "",
                    TravailleFranceDemandeurEmploi: "",
                    dernierContratTravail: "",
                    TravailleFranceApres2015: "",
                    procheAvecMaladieIncurable: "",
                    tauxHandicap: "",
                    pensionInvalidite: "",
                    aideALaPersonne: "",
                  });
                  return setPage(3.12);
                }
              }}
            />
          </div>
        </div>
      )}
      {/* Pole emplois */}
      {page === 3.05 && (
        <div className="simulateur-nationalite">
          <span>Êtes-vous inscrit à pôle Emploi ?</span>
          <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "inscrisPoleEmplois", singleSelect }} />
          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.inscrisPoleEmplois} onClick={() => {
              switch (profil.inscrisPoleEmplois) {
                case "Oui":
                  setPage(3.9);
                  break;
                default:
                  setPage(3.9);
                  break;
              }
            }} />
          </div>
        </div>
      )}

      {page === 3.001 && (
        <div className="simulateur-nationalite">
          <span>
            Etes-vous signataire d’un contrat d’apprentissage ou d’un contrat de professionnalisation avec
            une entreprise de 10 salariés minimum du secteur privé non agricole ?
          </span>
          <OneChoice props={{
            choiceArray: ["Oui", "Non"], target: "signataire_contrat_avec_entreprise_non_agricole_prive", singleSelect
          }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.signataire_contrat_avec_entreprise_non_agricole_prive}
              onClick={() => {
                setPage(3.002);
              }}
            />
          </div>
        </div>
      )}

      {page === 3.002 && (
        <div className="simulateur-nationalite">
          <span>
            Effectuez-vous  plus de 1h15 ou plus de 70 km de trajet jusqu’à votre lieu de travail ou votre lieu de formation?
          </span>
          <OneChoice props={{
            choiceArray: ["Oui", "Non"], target: "trajet_de_plus_d1h_ou_70km", singleSelect
          }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.trajet_de_plus_d1h_ou_70km}
              onClick={() => {
                if (profil.age <= 26) {
                  setPage(3.003)
                } else {
                  setPage(3.1)
                }
              }}
            />
          </div>
        </div>
      )}


      {page === 3.003 && (
        <div className="simulateur-nationalite">
          <span>
            Suivez-vous une formation en alternance d’au moins 1 an ?
          </span>
          <OneChoice props={{
            choiceArray: ["Oui", "Non"], target: "formation_alternance_au_moins_1_an", singleSelect
          }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.formation_alternance_au_moins_1_an}
              onClick={() => {
                setPage(3.004)
              }}
            />
          </div>
        </div>
      )}

      {page === 3.004 && (
        <div className="simulateur-nationalite">
          <span>
            Préparez-vous une qualification enregistrée au RNCP?
          </span>
          <OneChoice props={{
            choiceArray: ["Oui", "Non"], target: "peparation_qualification_RNCP", singleSelect
          }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.peparation_qualification_RNCP}
              onClick={() => {
                setPage(3.1)
              }}
            />
          </div>
        </div>
      )}


      {page === 3.1 && (
        <div className="simulateur-nationalite">
          <span>Je suis une formation en</span>
          <OneChoice props={{
            choiceArray: ["Apprentissage", "Lycée", "Lycée professionnel", "Licence", "Master", "Doctorat",
              "BTS ou DUT", "Sanitaire et sociale", "Stagiaire", "Aucune", "Autre"], target: "formation", singleSelect
          }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.formation}
              onClick={() => {
                switch (profil.formation) {
                  case "Apprentissage":
                    setProfil({
                      ...profil,
                      bourse: "",
                      bourseEchelon: "",
                      aideFinanciere: "",
                    });
                    setPage(3.2);
                    break;
                  default:
                    setProfil({
                      ...profil,
                      bacObtention: "",
                      bacMention: "",
                      partirEtranger: "",
                      permisConduite: "",
                    });
                    setPage(3.6);
                    break;
                }
              }}
            />
          </div>
        </div>
      )}
      {page === 3.2 && (
        <div className="simulateur-nationalite">
          <span>J’ai eu mon bac il y a moins de 2 ans</span>
          <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "bacObtention", singleSelect }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.bacObtention}
              onClick={() => {
                switch (profil.bacObtention) {
                  case "Oui":
                    setPage(3.3);
                    break;
                  default:
                    setPage(3.4);
                    break;
                }
              }}
            />
          </div>
        </div>
      )}

      {page === 3.3 && (
        <div className="simulateur-nationalite">
          <span>Ma mention au bac</span>
          <OneChoice props={{ choiceArray: ["Pas de mention", "Assez bien", "Bien", "Très bien", "Non"], target: "bacMention", singleSelect }} />
          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.bacMention}
              onClick={() => {
                setPage(3.4);
              }}
            />
          </div>
        </div>
      )}

      {page === 3.4 && (
        <div className="simulateur-nationalite">
          <span>
            Je compte partir à l’étranger dans le cadre de mes études :
          </span>
          <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "partirEtranger", singleSelect }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.partirEtranger}
              onClick={() => {
                if (profil.statutPersonnel2.includes("En situation de handicap"))
                  return setPage(3.14);
                if (profil.statutPersonnel2.includes("Inapte au travail"))
                  return setPage(3.15);
                if (profil.statutPersonnel2.includes("Actif (salarié / indépendant)"))
                  return setPage(3.12);
                setPage(3.5);
              }}
            />
          </div>
        </div>
      )}

      {page === 3.5 && (
        <div className="simulateur-nationalite">
          <span>J’ai déjà mon permis de conduire</span>
          <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "permisConduite", singleSelect }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.permisConduite}
              onClick={() => {
                if (profil.TravailleFranceApres2015 === "plus de 2 ans consécutifs" && profil.permisConduite === "Non") {
                  return setPage(3.56)
                } else if (profil.permisConduite === "Non") {
                  return setPage(3.56)
                }
                // return setPage(3.51)
                return setPage(3.56)


              }}
            />
          </div>
        </div>
      )}

      {page === 3.51 && (
        <div className="simulateur-nationalite">
          <span>Etes-vous un moniteur d’auto-école indépendant ?</span>
          <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "moniteur_auto_ecole", singleSelect }} />
          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3.5 }} />
            <NextSimulationStepButton disabledCond={!profil.moniteur_auto_ecole}
              onClick={() => {
                setSection("mon foyer");
                setProgress(40);
                setData((data) => {
                  return { ...data, ...profil, statutPersonnel: [profil.statutPersonnel, ...profil.statutPersonnel2].filter((el) => el !== "") };
                });
              }}
            />
          </div>
        </div>
      )}

      {page === 3.6 && (
        <div className="simulateur-nationalite">
          <span>Je suis boursier :</span>
          <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "bourse", singleSelect }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.bourse}
              onClick={() => {
                switch (profil.bourse) {
                  case "Oui":
                    setPage(3.7);
                    break;
                  default:
                    setPage(3.8);
                    break;
                }
              }}
            />
          </div>
        </div>
      )}

      {page === 3.7 && (
        <div className="simulateur-nationalite">
          <span>Quel est mon échelon de bourse ?</span>
          <OneChoice props={{ choiceArray: [0, 1, 2, 3, 4, 5, 6, 7], target: "bourseEchelon", singleSelect }} />
          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.bourseEchelon}
              onClick={() => {
                setPage(3.2);
              }}
            />
          </div>
        </div>
      )}

      {page === 3.8 && (
        <div className="simulateur-nationalite">
          <span>Mes parents m’aident financièrement :</span>
          <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "aideFinanciere", singleSelect }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.aideFinanciere}
              onClick={() => {
                setPage(3.2);
              }}
            />
          </div>
        </div>
      )}

      {page === 3.9 && (
        <div className="simulateur-nationalite">
          <span>J’ai déjà travaillé en France</span>
          <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "TravailleFranceDemandeurEmploi", singleSelect }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.TravailleFranceDemandeurEmploi}
              onClick={() => {
                switch (profil.TravailleFranceDemandeurEmploi) {
                  case "Oui":
                    setPage(3.12);
                    break;
                  default:
                    if (
                      profil.statutPersonnel2.includes(
                        "En situation de handicap"
                      )
                    )
                      return setPage(3.14);
                    if (profil.statutPersonnel2.includes("Inapte au travail"))
                      return setPage(3.15);
                    if (profil.statutPersonnel2.includes("Actif (salarié / indépendant)"))
                      return setPage(3.12);

                    setPage(3.5);
                    break;
                }
              }}
            />
          </div>
        </div>
      )}



      {page === 3.11 && (
        <>
          <div className="simulateur-date">
            <span>Mon dernier contrat de travail s’est terminé en</span>
            <div>
              <input
                // ref={ageRef}
                value={profil.dernierContratTravail}
                type="date"
                autoFocus
                max={today}
                onChange={(e) => {
                  e.target.value = normalizeDateNumber(e.target.value);
                  setProfil({
                    ...profil,
                    dernierContratTravail: e.target.value,
                  });
                }}
              />
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.dernierContratTravail}
              onClick={() => {
                setPage(3.12);
              }}
            />
          </div>
        </>
      )}

      {page === 3.12 && (
        <div className="simulateur-nationalite">
          <span>Après 2015, j’ai travaillé, en France</span>
          <OneChoice props={{ choiceArray: ["moins de 12 mois consécutifs", "1 à 2 ans consécutifs", "plus de 2 ans consécutifs"], target: "TravailleFranceApres2015", singleSelect }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.TravailleFranceApres2015}
              onClick={() => {
                setPage(3.121);
              }}
            />
          </div>
        </div>
      )}

      {page === 3.121 && (
        <div className="simulateur-nationalite">
          <span> Avez-vous des crédit  en cours ?</span>
          <OneChoice props={{
            choiceArray: ["Oui", "Non"],
            target: "credit_en_cours", singleSelect
          }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3.12 }} />
            <NextSimulationStepButton disabledCond={!profil.credit_en_cours}
              onClick={() => {
                if (profil.credit_en_cours === 'Oui') {
                  return setPage(3.122)
                }
                return setPage(3.124)
              }}
            />
          </div>
        </div>
      )}

      {page === 3.122 && (
        <div className="simulateur-nationalite">
          <span> Les crédits que vous remboursez actuellement ?</span>
          <OneChoice props={{
            choiceArray: ["Crédit immobiliers", "Crédit consommation"],
            target: "credit_remboursez_actuellement", singleSelect
          }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3.121 }} />
            <NextSimulationStepButton disabledCond={!profil.credit_remboursez_actuellement}
              onClick={() => {
                setPage(3.123);
              }}
            />
          </div>
        </div>
      )}

      {page === 3.123 && (
        <div className="simulateur-nationalite">
          <span>Souhaitez-vous bénéficier d’un rachat ou regroupement de crédit ?</span>
          {
            profil.rachat_ou_regroupement_de_credit == 'Oui' && <div className="simulator-subTitle">
              <p style={{
                textAlign: 'center', fontWeight: 'bold',
                color: 'var(--colorPrimary)'
              }}>
                Notre partenaire en rachat ou regroupement de crédit
                vous contactera part téléphone
              </p>
            </div>
          }

          <OneChoice props={{
            choiceArray: ["Oui", "Non"],
            target: "rachat_ou_regroupement_de_credit", singleSelect
          }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3.122 }} />
            <NextSimulationStepButton
              disabledCond={!profil.rachat_ou_regroupement_de_credit}
              onClick={() => {
                setPage(3.5);
              }}
            />
          </div>
        </div>
      )}

      {page === 3.124 && (
        <div className="simulateur-nationalite">
          <span> Souhaitez-vous bénéficier d’un crédit ?</span>
          <OneChoice props={{
            choiceArray: ["Oui", "Non"],
            target: "souhaite_beneficier_dun_credit", singleSelect
          }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3.121 }} />
            <NextSimulationStepButton disabledCond={!profil.souhaite_beneficier_dun_credit}
              onClick={() => {
                if (profil.souhaite_beneficier_dun_credit === 'Oui') {
                  return setPage(3.125)
                }
                return setPage(3.5)
              }}
            />
          </div>
        </div>
      )}

      {page === 3.125 && (
        <div className="simulateur-nationalite">
          <span> Type de crédit souhaiter ?</span>
          <OneChoice props={{
            choiceArray: ["Crédit auto/moto", "Crédit renouvelable",
              "Crédit travaux", "Autres projet"],
            target: "type_de_credit_souhaiter", singleSelect
          }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3.124 }} />
            <NextSimulationStepButton disabledCond={!profil.type_de_credit_souhaiter}
              onClick={() => {
                return setPage(3.5)
              }}
            />
          </div>
        </div>
      )}

      {page === 3.56 && (
        <div className="simulateur-nationalite">
          <span>Voulez-vous financer votre permis grâce au cpf ?</span>
          <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "financerPermisParCpf", singleSelect }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3.5 }} />
            <NextSimulationStepButton disabledCond={!profil.financerPermisParCpf}
              onClick={() => {
                setSection("mon foyer");
                setProgress(40);
                setData((data) => {
                  return { ...data, ...profil, statutPersonnel: [profil.statutPersonnel, ...profil.statutPersonnel2].filter((el) => el !== "") };
                });
              }}
            />
          </div>
        </div>
      )}
      {/*  */}


      {page === 3.13 && (
        <div className="simulateur-nationalite">
          <span>J’ai un proche en phase avancée d'une maladie incurable</span>
          <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "procheAvecMaladieIncurable", singleSelect }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.procheAvecMaladieIncurable}
              onClick={() => {
                setPage(3.5);
              }}
            />
          </div>
        </div>
      )}

      {page === 3.14 && (
        <div className="simulateur-nationalite">
          <span>
            Mon taux de handicap est de
            <span style={{ color: "	#3c3c3c", fontSize: 13, display: "block" }}>
              Évalué par votre MDPH
            </span>
          </span>
          <div>
            <div
              className="tauxHandicap"
              onClick={(e) => singleSelect(e, ".tauxHandicap")}
              id="tauxHandicap"
            >
              Moins de 50%
            </div>
            <div
              className="tauxHandicap"
              onClick={(e) => singleSelect(e, ".tauxHandicap")}
              id="tauxHandicap"
            >
              Entre 50% et 80%
            </div>
            <div
              className="tauxHandicap"
              onClick={(e) => singleSelect(e, ".tauxHandicap")}
              id="tauxHandicap"
            >
              Plus de 80%
            </div>
          </div>
          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabled={!profil.tauxHandicap}
              onClick={() => {
                if (profil.statutPersonnel2.includes("Inapte au travail"))
                  return setPage(3.15);
                if (profil.statutPersonnel2.includes("Actif (salarié / indépendant)"))
                  return setPage(3.12);
                setPage(3.12)
              }}
            />
          </div>
        </div>
      )}

      {page === 3.15 && (
        <div className="simulateur-nationalite">
          <span>Je bénéficie d’une pension d’invalidité</span>
          <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "pensionInvalidite", singleSelect }} />

          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
            <NextSimulationStepButton disabledCond={!profil.pensionInvalidite}
              onClick={() => {
                switch (profil.pensionInvalidite) {
                  case "Oui":
                    setPage(3.16);
                    break;

                  default:
                    if (
                      profil.statutPersonnel2.includes(
                        "Actif (salarié / indépendant)"
                      )
                    )
                      return setPage(3.12);
                    setPage(3.12);
                    break;
                }
              }}
            />
          </div>
        </div>
      )}

      {page === 3.16 && (
        <div className="simulateur-nationalite">
          <span>
            J’ai besoin d'une aide à la personne
            <span style={{ color: "	#3c3c3c", fontSize: 13, display: "block" }}>
              Pour accomplir les actes essentiels de la vie (se nourrir, se
              déplacer, se laver, ...)
            </span>
          </span>
          <OneChoice props={{
            choiceArray: ["Jamais", "Ponctuellement", "Régulièrement"], target: "aideALaPersonne",
            singleSelect
          }} />
          <div className="button-simulateur">
            <PrevSimulationStep props={{ setPage, prevPage: 3.15 }} />
            <NextSimulationStepButton disabledCond={!profil.aideALaPersonne}
              onClick={() => {
                if (
                  profil.statutPersonnel2.includes(
                    "Actif (salarié / indépendant)"
                  )
                )
                  return setPage(3.12);
                setPage(3.5);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Profil;
