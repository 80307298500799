export function g_Af() {
  return (
    <>
      <div className="caf-wrapper">
        <img src="/sources/af.jpg" alt="guides" />
        <p>
          Les allocations familiales sont octroyées à celles et ceux qui ont au minimum deux enfants de moins de 20 ans à charge. Le montant de l'aide accordée est fonction des revenus, du nombre d'enfants et de l'âge de ceux-ci.
        </p>
        <h1>Qui a droit aux allocations familiales ?</h1>
        <span>
          Les conditions suivantes sont prises en compte pour toucher les allocations familiales :
          <ul>
            <li>
              Si vous êtes français, vous devez avoir votre résidence principale, votre foyer permanent en France. Vous devez également séjourner en France plus de 6 mois par an, sans obligation de continuité.
            </li>
            <li>
              Si vous êtes étrangers, vous devez également avoir votre foyer permanent en France, et résider dans l’hexagone au moins 6 mois par an.  Vous devez être en séjour régularisé.
            </li>
          </ul>
        </span>
        <span>
          Si un de vos enfant quitte le territoire plus de 3 mois, même seul, les allocations associées sont supprimées, sauf certaines exceptions :
          <ul>
            <li>
              Si l’enfant se soigne ou étudie dans un pays frontalier, et rentre régulièrement en France auprès des siens
            </li>
            <li>
              Ou si l’enfant est à l’étranger dans le but d’apprendre une langue étrangère ou au sein de son cursus professionnel
            </li>
          </ul>
          L’allocation de base est donnée par famille. En cas de naissances ou d’adoptions multiples, vous avez autant d’allocations de base que d’enfants nés du même accouchement ou adoptés simultanément.
        </span>

      </div>
    </>
  )
}
