import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { SimpleImage } from "../img/global";
import {
    generalNavLinks,
    guidesLinksList, offreTacheLinks, simulateurLinks
} from '../../Raw/menuLinks';
import { IonMdArrowForward } from '../icons';

export function NavLogo() {
    return <section className='gnLogo'>
        <a href="/"><SimpleImage props={{ src: 'logo.webp', alt: 'Logo non Img', classname: '' }} /></a>
    </section>
}


export const AuthLinks = () => {
    return <div className='authLinksSections'>
        <Link to='/Connexion'>Connexion</Link>
        <Link to='/Inscription'>Inscription</Link>
    </div>
}

export const DisconnectOrViewProfilLink = () => {
    const { logout } = useAuth()
    return <div className='authLinksSections'>
        <Link to='/Profil/modification'>Mon Profil</Link>
        <Link to='' onClick={(event) => {
            event.preventDefault();
            logout()
        }}>
            Deconnexion</Link>
    </div>
}



export const SearchBar = () => {
    const [searchRes, setSR] = useState(null)

    function handleSearch(e) {
        let value = e.target.value;

        const filter1 = generalNavLinks.filter((el) => el.label.toLocaleLowerCase().includes(value)
            && el.subLinks.length == 0);
        const filter2 = guidesLinksList.filter((el) => el.label.toLocaleLowerCase().includes(value));
        const filter3 = simulateurLinks.filter((el) => el.label.toLocaleLowerCase().includes(value));
        const filter4 = offreTacheLinks.filter((el) => el.label.toLocaleLowerCase().includes(value));

        setSR(filter1.concat(filter2).concat(filter3).concat(filter4))
    }
    return <div className='searchBar'>
        <form className='sb-input'>
            <label>Rechercher</label>
            <input type="search" placeholder='Recherchez ici....'
                onChange={(event) => handleSearch(event)} />
        </form>

        <section className='sb-results'>
            {
                (() => {
                    if (searchRes == null) {
                        return <p>Aucune recherche effectué</p>
                    } else if (searchRes.length == 0) {
                        return <p>Aucun resultat</p>
                    } else {
                        return <>
                            {
                                searchRes.map((searchResult, index) => <div key={"search re nb" + index}>
                                    <Link to={searchResult.link}>
                                        {searchResult.label} </Link>
                                </div>)
                            }
                        </>
                    }
                })()
            }
        </section>
    </div>
}


export function ShowSubMenuLevel1(props) {
    const { subMenuIndex, subMenu2Visible, subMenusLinks, onSubMenuLinkClick } = props;
    return <div className={'subMenuLevel1 ' + (subMenu2Visible ? ' visibleByHeightAndOpacity ' : 'notVisibleByHeightAndOpacity')}>
        <article>
            {
                subMenusLinks[subMenuIndex] && subMenusLinks[subMenuIndex].subLinks.map((sm2, ind) => <Link to={sm2.link} key={'sub menu 2 index' + ind}
                    onClick={onSubMenuLinkClick}
                    className='withIcon'>
                    <IonMdArrowForward />
                    <b>{sm2.label}</b>
                </Link>)
            }
        </article>
    </div>
}

