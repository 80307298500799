import { IllustrationImage } from "../GlobalComponents/img/global";

const Maintenance = () => {
    return <div className="maintenancePage">
        <IllustrationImage props={{ src: "banners/maintenance.svg", alt: "Page on maint", className: "" }} />
        <h1>Désolé</h1>
        <p>Cette page est en maintenance,veuillez revenir dans quelques jours</p>
    </div>
}

export default Maintenance;