import React, { useEffect, useState } from "react";
import "../../style/simulateurGratuite.css";
import Foyer from "./Foyer";
import Profil from "./Profil";
import { v4 as uuidv4 } from "uuid";
import Logement from "./logement";
import Credits from "./credits";
import Revenus from "./revenus";
import axios from "axios";
import LoadingBorder from "../LoadingBorder";
import { SIMULATION } from "../../store/actions/types";
import { useDispatch } from "react-redux";
import {
  calculCHeque, calculEtudiantDiff, calculLocapass, calculMobiliJeune,
  countChildren, dateEdit, locationMap, maritalMap, nationaliteMap, salairMap, statutMap, tauxCpf, tauxHandi
} from "../../helper/SimulationMath"
import { mapChildren } from "../../helper/SimulationMath"
import cities from "../../zip-codes/cities.json";
import Informations from "./informations";
import { useNavigate } from "react-router-dom";
import { bothAuth } from "../../hocs/bothAuth";


function SimulationGratuit() {
  const [section, setSection] = useState("profil");
  const [progress, setProgress] = useState(20);
  const [data, setData] = useState({});
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [enfant, setEnfant] = useState({
    age: "",
    statut: "",
    statut2: [],
    boursier: "",
    echelonDeBourse: "",
    tauxHandicap: "",
  });

  const [end, setEnd] = useState(false);
  const [loading, setLoading] = useState(false);


  const dispatch = useDispatch()
  // const history = useHistory()
  const navigate = useNavigate();
  useEffect(() => {
    if (end) {

      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }


      let body = {
        familles: {
          _: {
            parents: (data.etatLegal === "Marié(e)" || data.etatLegal === "Pacsé(e)" || data.etatLegal === "En concubinage") ? ["demandeur", "couple"] : ["demandeur"],
            enfants: countChildren(data.enfants).length ? countChildren(data.enfants) : [],
            af: {
              [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("Allocations familiales") ? 0 : null,
            },
            cf: {
              [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("Complément familial") ? 0 : null,
            },
            rsa_non_calculable: {
              [`${yyyy}-${mm}`]: null,
            },
            ppa: {
              [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("Prime d'activité") ? 0 : null,
            },
            aeeh: {
              [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("AEEH") ? 0 : null,
            },
            asf: {
              [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("ASF") ? 0 : null,
            },
            paje: {
              [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("PAJE") ? 0 : null,
            },
            rsa: {
              [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("RSA") ? 0 : null,
            },
            apl: {
              [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("APL") ? 0 : null,
            },
            css_participation_forfaitaire: {
              [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("CSS") ? 0 : null,
            },
            aide_logement: {
              [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("APL") ? 0 : null,
            },
            bourse_college: {
              [`${yyyy}-${mm}`]: null,
            },
            bourse_lycee: {
              [`${yyyy}-${mm}`]: null,
            },
            aspa: {
              [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("ASPA") ? 0 : null,
            },
            prestations_familiales: {
              [`${yyyy}`]: null,
            },
          },
        },
        foyers_fiscaux: {
          _: {
            declarants: (data.etatLegal === "Marié(e)" || data.etatLegal === "Pacsé(e)" || data.etatLegal === "En concubinage") ? ["demandeur", "couple"] : ["demandeur"],
            personnes_a_charge: countChildren(data.enfants).length ? countChildren(data.enfants) : [],
          },
        },
        individus: {
          ...mapChildren(data.enfants, data),
          demandeur: {
            age: {
              [`${yyyy}-${mm}`]: parseInt(data.age),
              [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: parseInt(data.age),
              [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: parseInt(data.age),
              [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: parseInt(data.age),
              [`${dateEdit(mm, -4, yyyy).yyyy}-${dateEdit(mm, -4, yyyy).newMonth}`]: parseInt(data.age),
              [`${dateEdit(mm, -5, yyyy).yyyy}-${dateEdit(mm, -5, yyyy).newMonth}`]: parseInt(data.age)
            },
            allocation_annuelle_etudiant: {
              [`${yyyy}-${mm}`]: null,
            },
            asi: {
              [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("ASI") ? 0 : null,
            },
            ass: {
              [`${yyyy}-${mm}`]: null,
            },
            ass_eligibilite_individu: {
              [`${yyyy}-${mm}`]: null,
            },
            aah: {
              [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("AAH") ? 0 : null,
            },
            mva: {
              [`${yyyy}-${mm}`]: data.statutPersonnel.includes("En situation de handicap") ? (data.aidesSocialesTab.includes("MVA") ? 0 : null) : 0,
            },
            garantie_jeunes: {
              [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("Garantie Jeunes") ? 0 : null,
            },
            apa_domicile: {
              [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("APA") ? 0 : null,
            },
            nationalite: {
              [`${yyyy}-${mm}`]: nationaliteMap(data.nationalite),
              [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: nationaliteMap(data.nationalite),
              [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: nationaliteMap(data.nationalite),
              [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: nationaliteMap(data.nationalite),
              [`${dateEdit(mm, -4, yyyy).yyyy}-${dateEdit(mm, -4, yyyy).newMonth}`]: nationaliteMap(data.nationalite),
              [`${dateEdit(mm, -5, yyyy).yyyy}-${dateEdit(mm, -5, yyyy).newMonth}`]: nationaliteMap(data.nationalite)
            },
            ...((!!data.titreDeSejour5Ans) || data.nationalite === "UE + Norvège + Islande + Suisse") && {
              duree_possession_titre_sejour: {
                [`${yyyy}-${mm}`]: data.titreDeSejour5Ans ? ((data.titreDeSejour5Ans === "Oui") ? 5 : 1) : 1,
                [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: data.titreDeSejour5Ans ? ((data.titreDeSejour5Ans === "Oui") ? 5 : 1) : 1,
                [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: data.titreDeSejour5Ans ? ((data.titreDeSejour5Ans === "Oui") ? 5 : 1) : 1,
                [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: data.titreDeSejour5Ans ? ((data.titreDeSejour5Ans === "Oui") ? 5 : 1) : 1
              }
            },
            activite: {
              [`${yyyy}-${mm}`]: statutMap(data.statutPersonnel),
              [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: statutMap(data.statutPersonnel),
              [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: statutMap(data.statutPersonnel),
              [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: statutMap(data.statutPersonnel),
              [`${dateEdit(mm, -4, yyyy).yyyy}-${dateEdit(mm, -4, yyyy).newMonth}`]: statutMap(data.statutPersonnel),
              [`${dateEdit(mm, -5, yyyy).yyyy}-${dateEdit(mm, -5, yyyy).newMonth}`]: statutMap(data.statutPersonnel),
              [`${dateEdit(mm, -6, yyyy).yyyy}-${dateEdit(mm, -6, yyyy).newMonth}`]: statutMap(data.statutPersonnel),
              [`${dateEdit(mm, -7, yyyy).yyyy}-${dateEdit(mm, -7, yyyy).newMonth}`]: statutMap(data.statutPersonnel),
              [`${dateEdit(mm, -8, yyyy).yyyy}-${dateEdit(mm, -8, yyyy).newMonth}`]: statutMap(data.statutPersonnel),
              [`${dateEdit(mm, -9, yyyy).yyyy}-${dateEdit(mm, -9, yyyy).newMonth}`]: statutMap(data.statutPersonnel),
              [`${dateEdit(mm, -10, yyyy).yyyy}-${dateEdit(mm, -10, yyyy).newMonth}`]: statutMap(data.statutPersonnel),
              [`${dateEdit(mm, -11, yyyy).yyyy}-${dateEdit(mm, -11, yyyy).newMonth}`]: statutMap(data.statutPersonnel),
              [`${dateEdit(mm, -12, yyyy).yyyy}-${dateEdit(mm, -12, yyyy).newMonth}`]: statutMap(data.statutPersonnel)
            },
            ...(!!data.bourseEchelon) && {
              bourse_criteres_sociaux_echelon: {
                [`${yyyy}-${mm}`]: parseInt(data.bourseEchelon),
                [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: parseInt(data.bourseEchelon),
                [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: parseInt(data.bourseEchelon),
                [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: parseInt(data.bourseEchelon)
              }
            }
            ,
            ...(data.statutPersonnel.includes("Enceinte")) && {
              enceinte: {
                [`${yyyy}-${mm}`]: true,
                [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: true,
                [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: true,
                [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: true
              }
            },
            ...(data.statutPersonnel.includes("En situation de handicap")) && {
              handicap: {
                [`${yyyy}-${mm}`]: true,
                [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: true,
                [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: true,
                [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: true
              }
            },
            ...(data.statutPersonnel.includes("En situation de handicap")) && {
              taux_incapacite: {
                [`${yyyy}-${mm}`]: tauxHandi(data.tauxHandicap),
                [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: tauxHandi(data.tauxHandicap),
                [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: tauxHandi(data.tauxHandicap),
                [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: tauxHandi(data.tauxHandicap)
              }
            },
            ...(data.statutPersonnel.includes("Inapte au travail")) && {
              inapte_travail: {
                [`${yyyy}-${mm}`]: true,
                [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: true,
                [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: true,
                [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: true
              }
            },
            [salairMap(data.statutPersonnel)]: {
              [`${yyyy}-${mm}`]: parseInt(data.mesRevenusValMulti1) || parseInt(data.mesRevenusVal),
              [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: parseInt(data.mesRevenusValMulti1) || parseInt(data.mesRevenusVal),
              [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: parseInt(data.mesRevenusValMulti2) || parseInt(data.mesRevenusVal),
              [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: parseInt(data.mesRevenusValMulti3) || parseInt(data.mesRevenusVal),
              [`${dateEdit(mm, -4, yyyy).yyyy}-${dateEdit(mm, -4, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal),
              [`${dateEdit(mm, -5, yyyy).yyyy}-${dateEdit(mm, -5, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal),
              [`${dateEdit(mm, -6, yyyy).yyyy}-${dateEdit(mm, -6, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal),
              [`${dateEdit(mm, -7, yyyy).yyyy}-${dateEdit(mm, -7, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal),
              [`${dateEdit(mm, -8, yyyy).yyyy}-${dateEdit(mm, -8, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal),
              [`${dateEdit(mm, -9, yyyy).yyyy}-${dateEdit(mm, -9, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal),
              [`${dateEdit(mm, -10, yyyy).yyyy}-${dateEdit(mm, -10, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal),
              [`${dateEdit(mm, -11, yyyy).yyyy}-${dateEdit(mm, -11, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal),
              [`${dateEdit(mm, -12, yyyy).yyyy}-${dateEdit(mm, -12, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal)
            },
            ...(salairMap(data.statutPersonnel) === "salaire_net") && {
              salaire_de_base: {
                [`${yyyy}-${mm}`]: parseInt(data.mesRevenusValMulti1) * 1.3 || parseInt(data.mesRevenusVal) * 1.3,
                [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: parseInt(data.mesRevenusValMulti1) * 1.3 || parseInt(data.mesRevenusVal) * 1.3,
                [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: parseInt(data.mesRevenusValMulti2) * 1.3 || parseInt(data.mesRevenusVal) * 1.3,
                [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: parseInt(data.mesRevenusValMulti3) * 1.3 || parseInt(data.mesRevenusVal) * 1.3,
                [`${dateEdit(mm, -4, yyyy).yyyy}-${dateEdit(mm, -4, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) * 1.3 || parseInt(data.mesRevenusVal) * 1.3,
                [`${dateEdit(mm, -5, yyyy).yyyy}-${dateEdit(mm, -5, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) * 1.3 || parseInt(data.mesRevenusVal) * 1.3,
                [`${dateEdit(mm, -6, yyyy).yyyy}-${dateEdit(mm, -6, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) * 1.3 || parseInt(data.mesRevenusVal) * 1.3,
                [`${dateEdit(mm, -7, yyyy).yyyy}-${dateEdit(mm, -7, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) * 1.3 || parseInt(data.mesRevenusVal) * 1.3,
                [`${dateEdit(mm, -8, yyyy).yyyy}-${dateEdit(mm, -8, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) * 1.3 || parseInt(data.mesRevenusVal) * 1.3,
                [`${dateEdit(mm, -9, yyyy).yyyy}-${dateEdit(mm, -9, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) * 1.3 || parseInt(data.mesRevenusVal) * 1.3,
                [`${dateEdit(mm, -10, yyyy).yyyy}-${dateEdit(mm, -10, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) * 1.3 || parseInt(data.mesRevenusVal) * 1.3,
                [`${dateEdit(mm, -11, yyyy).yyyy}-${dateEdit(mm, -11, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) * 1.3 || parseInt(data.mesRevenusVal) * 1.3,
                [`${dateEdit(mm, -12, yyyy).yyyy}-${dateEdit(mm, -12, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) * 1.3 || parseInt(data.mesRevenusVal) * 1.3
              }
            },
            ...(salairMap(data.statutPersonnel) === "chomage_net") && {
              chomage_imposable: {
                [`${yyyy}-${mm}`]: parseInt(data.mesRevenusValMulti1) || parseInt(data.mesRevenusVal),
                [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: parseInt(data.mesRevenusValMulti1) || parseInt(data.mesRevenusVal),
                [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: parseInt(data.mesRevenusValMulti2) || parseInt(data.mesRevenusVal),
                [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: parseInt(data.mesRevenusValMulti3) || parseInt(data.mesRevenusVal),
                [`${dateEdit(mm, -4, yyyy).yyyy}-${dateEdit(mm, -4, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal),
                [`${dateEdit(mm, -5, yyyy).yyyy}-${dateEdit(mm, -5, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal),
                [`${dateEdit(mm, -6, yyyy).yyyy}-${dateEdit(mm, -6, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal),
                [`${dateEdit(mm, -7, yyyy).yyyy}-${dateEdit(mm, -7, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal),
                [`${dateEdit(mm, -8, yyyy).yyyy}-${dateEdit(mm, -8, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal),
                [`${dateEdit(mm, -9, yyyy).yyyy}-${dateEdit(mm, -9, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal),
                [`${dateEdit(mm, -10, yyyy).yyyy}-${dateEdit(mm, -10, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal),
                [`${dateEdit(mm, -11, yyyy).yyyy}-${dateEdit(mm, -11, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal),
                [`${dateEdit(mm, -12, yyyy).yyyy}-${dateEdit(mm, -12, yyyy).newMonth}`]: Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal)
              }
            },
            ...(data.PensionInvaliditeVal) && {
              pensions_invalidite: {
                [`${yyyy}-${mm}`]: parseInt(data.PensionInvaliditeVal),
                [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: parseInt(data.PensionInvaliditeVal),
                [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: parseInt(data.PensionInvaliditeVal),
                [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: parseInt(data.PensionInvaliditeVal)
              }
            },
            ...(data.dernierContratTravail) && {
              date_arret_de_travail: {
                [`${yyyy}-${mm}`]: data.dernierContratTravail,
                [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: data.dernierContratTravail,
                [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: data.dernierContratTravail,
                [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: data.dernierContratTravail
              }
            },
            ...(data.dernierContratTravail) && {
              chomeur_longue_duree: {
                [`${yyyy}`]: (new Date(data.dernierContratTravail) <= new Date((parseInt(yyyy) - 2).toString() + "-" + mm + "-" + dd)) ? true : false,
              }
            },
            statut_marital: {
              [`${yyyy}-${mm}`]: maritalMap(data.etatLegal),
              [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: maritalMap(data.etatLegal),
              [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: maritalMap(data.etatLegal),
              [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: maritalMap(data.etatLegal),
            }
          },
          ...(data.etatLegal === "Marié(e)" || data.etatLegal === "Pacsé(e)" || data.etatLegal === "En concubinage") && {
            couple: {
              garantie_jeunes: {
                [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("Garantie Jeunes") ? 0 : null,
              },
              apa_domicile: {
                [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("APA") ? 0 : null,
              },
              allocation_annuelle_etudiant: {
                [`${yyyy}-${mm}`]: null,
              },
              asi: {
                [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("ASI") ? 0 : null,
              },
              aah: {
                [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("AAH") ? 0 : null,
              },
              mva: {
                [`${yyyy}-${mm}`]: data.conjointStatutPersonnel.includes("En situation de handicap") ? (data.aidesSocialesTab.includes("MVA") ? 0 : null) : 0,
              },
              age: {
                [`${yyyy}-${mm}`]: parseInt(data.conjointAge),
                [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: parseInt(data.conjointAge),
                [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: parseInt(data.conjointAge),
                [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: parseInt(data.conjointAge),
                [`${dateEdit(mm, -4, yyyy).yyyy}-${dateEdit(mm, -4, yyyy).newMonth}`]: parseInt(data.conjointAge),
                [`${dateEdit(mm, -5, yyyy).yyyy}-${dateEdit(mm, -5, yyyy).newMonth}`]: parseInt(data.conjointAge)
              },
              activite: {
                [`${yyyy}-${mm}`]: statutMap(data.conjointStatutPersonnel),
                [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: statutMap(data.conjointStatutPersonnel),
                [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: statutMap(data.conjointStatutPersonnel),
                [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: statutMap(data.conjointStatutPersonnel),
                [`${dateEdit(mm, -4, yyyy).yyyy}-${dateEdit(mm, -4, yyyy).newMonth}`]: statutMap(data.conjointStatutPersonnel),
                [`${dateEdit(mm, -5, yyyy).yyyy}-${dateEdit(mm, -5, yyyy).newMonth}`]: statutMap(data.conjointStatutPersonnel),
                [`${dateEdit(mm, -6, yyyy).yyyy}-${dateEdit(mm, -6, yyyy).newMonth}`]: statutMap(data.conjointStatutPersonnel),
                [`${dateEdit(mm, -7, yyyy).yyyy}-${dateEdit(mm, -7, yyyy).newMonth}`]: statutMap(data.conjointStatutPersonnel),
                [`${dateEdit(mm, -8, yyyy).yyyy}-${dateEdit(mm, -8, yyyy).newMonth}`]: statutMap(data.conjointStatutPersonnel),
                [`${dateEdit(mm, -9, yyyy).yyyy}-${dateEdit(mm, -9, yyyy).newMonth}`]: statutMap(data.conjointStatutPersonnel),
                [`${dateEdit(mm, -10, yyyy).yyyy}-${dateEdit(mm, -10, yyyy).newMonth}`]: statutMap(data.conjointStatutPersonnel),
                [`${dateEdit(mm, -11, yyyy).yyyy}-${dateEdit(mm, -11, yyyy).newMonth}`]: statutMap(data.conjointStatutPersonnel),
                [`${dateEdit(mm, -12, yyyy).yyyy}-${dateEdit(mm, -12, yyyy).newMonth}`]: statutMap(data.conjointStatutPersonnel)
              },
              ...(data.conjointBourseEchelon) && {
                bourse_criteres_sociaux_echelon: {
                  [`${yyyy}-${mm}`]: parseInt(data.conjointBourseEchelon),
                  [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: parseInt(data.conjointBourseEchelon),
                  [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: parseInt(data.conjointBourseEchelon),
                  [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: parseInt(data.conjointBourseEchelon)
                }
              }
              ,
              ...(data.conjointStatutPersonnel.includes("Enceinte")) && {
                enceinte: {
                  [`${yyyy}-${mm}`]: true,
                  [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: true,
                  [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: true,
                  [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: true
                }
              },
              ...(data.conjointStatutPersonnel.includes("En situation de handicap")) && {
                handicap: {
                  [`${yyyy}-${mm}`]: true,
                  [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: true,
                  [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: true,
                  [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: true
                }
              },
              ...(data.conjointStatutPersonnel.includes("En situation de handicap")) && {
                taux_incapacite: {
                  [`${yyyy}-${mm}`]: tauxHandi(data.conjointTauxHandicap),
                  [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: tauxHandi(data.conjointTauxHandicap),
                  [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: tauxHandi(data.conjointTauxHandicap),
                  [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: tauxHandi(data.conjointTauxHandicap)
                }
              },
              ...(data.conjointStatutPersonnel.includes("Inapte au travail")) && {
                inapte_travail: {
                  [`${yyyy}-${mm}`]: true,
                  [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: true,
                  [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: true,
                  [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: true
                }
              },
              [salairMap(data.conjointStatutPersonnel)]: {
                [`${yyyy}-${mm}`]: parseInt(data.conjointRevenusValMulti1) || parseInt(data.conjointRevenusVal),
                [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: parseInt(data.conjointRevenusValMulti1) || parseInt(data.conjointRevenusVal),
                [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: parseInt(data.conjointRevenusValMulti2) || parseInt(data.conjointRevenusVal),
                [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: parseInt(data.conjointRevenusValMulti3) || parseInt(data.conjointRevenusVal),
                [`${dateEdit(mm, -4, yyyy).yyyy}-${dateEdit(mm, -4, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal),
                [`${dateEdit(mm, -5, yyyy).yyyy}-${dateEdit(mm, -5, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal),
                [`${dateEdit(mm, -6, yyyy).yyyy}-${dateEdit(mm, -6, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal),
                [`${dateEdit(mm, -7, yyyy).yyyy}-${dateEdit(mm, -7, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal),
                [`${dateEdit(mm, -8, yyyy).yyyy}-${dateEdit(mm, -8, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal),
                [`${dateEdit(mm, -9, yyyy).yyyy}-${dateEdit(mm, -9, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal),
                [`${dateEdit(mm, -10, yyyy).yyyy}-${dateEdit(mm, -10, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal),
                [`${dateEdit(mm, -11, yyyy).yyyy}-${dateEdit(mm, -11, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal),
                [`${dateEdit(mm, -12, yyyy).yyyy}-${dateEdit(mm, -12, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal)
              },
              ...(salairMap(data.conjointStatutPersonnel) === "salaire_net") && {
                salaire_de_base: {
                  [`${yyyy}-${mm}`]: parseInt(data.conjointRevenusValMulti1) * 1.3 || parseInt(data.conjointRevenusVal) * 1.3,
                  [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: parseInt(data.conjointRevenusValMulti1) * 1.3 || parseInt(data.conjointRevenusVal) * 1.3,
                  [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: parseInt(data.conjointRevenusValMulti2) * 1.3 || parseInt(data.conjointRevenusVal) * 1.3,
                  [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: parseInt(data.conjointRevenusValMulti3) * 1.3 || parseInt(data.conjointRevenusVal) * 1.3,
                  [`${dateEdit(mm, -4, yyyy).yyyy}-${dateEdit(mm, -4, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) * 1.3 || parseInt(data.conjointRevenusVal) * 1.3,
                  [`${dateEdit(mm, -5, yyyy).yyyy}-${dateEdit(mm, -5, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) * 1.3 || parseInt(data.conjointRevenusVal) * 1.3,
                  [`${dateEdit(mm, -6, yyyy).yyyy}-${dateEdit(mm, -6, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) * 1.3 || parseInt(data.conjointRevenusVal) * 1.3,
                  [`${dateEdit(mm, -7, yyyy).yyyy}-${dateEdit(mm, -7, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) * 1.3 || parseInt(data.conjointRevenusVal) * 1.3,
                  [`${dateEdit(mm, -8, yyyy).yyyy}-${dateEdit(mm, -8, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) * 1.3 || parseInt(data.conjointRevenusVal) * 1.3,
                  [`${dateEdit(mm, -9, yyyy).yyyy}-${dateEdit(mm, -9, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) * 1.3 || parseInt(data.conjointRevenusVal) * 1.3,
                  [`${dateEdit(mm, -10, yyyy).yyyy}-${dateEdit(mm, -10, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) * 1.3 || parseInt(data.conjointRevenusVal) * 1.3,
                  [`${dateEdit(mm, -11, yyyy).yyyy}-${dateEdit(mm, -11, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) * 1.3 || parseInt(data.conjointRevenusVal) * 1.3,
                  [`${dateEdit(mm, -12, yyyy).yyyy}-${dateEdit(mm, -12, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) * 1.3 || parseInt(data.conjointRevenusVal) * 1.3
                }
              },
              ...(salairMap(data.conjointStatutPersonnel) === "chomage_net") && {
                chomage_imposable: {
                  [`${yyyy}-${mm}`]: parseInt(data.conjointRevenusValMulti1) || parseInt(data.conjointRevenusVal),
                  [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: parseInt(data.conjointRevenusValMulti1) || parseInt(data.conjointRevenusVal),
                  [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: parseInt(data.conjointRevenusValMulti2) || parseInt(data.conjointRevenusVal),
                  [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: parseInt(data.conjointRevenusValMulti3) || parseInt(data.conjointRevenusVal),
                  [`${dateEdit(mm, -4, yyyy).yyyy}-${dateEdit(mm, -4, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal),
                  [`${dateEdit(mm, -5, yyyy).yyyy}-${dateEdit(mm, -5, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal),
                  [`${dateEdit(mm, -6, yyyy).yyyy}-${dateEdit(mm, -6, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal),
                  [`${dateEdit(mm, -7, yyyy).yyyy}-${dateEdit(mm, -7, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal),
                  [`${dateEdit(mm, -8, yyyy).yyyy}-${dateEdit(mm, -8, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal),
                  [`${dateEdit(mm, -9, yyyy).yyyy}-${dateEdit(mm, -9, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal),
                  [`${dateEdit(mm, -10, yyyy).yyyy}-${dateEdit(mm, -10, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal),
                  [`${dateEdit(mm, -11, yyyy).yyyy}-${dateEdit(mm, -11, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal),
                  [`${dateEdit(mm, -12, yyyy).yyyy}-${dateEdit(mm, -12, yyyy).newMonth}`]: Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal)

                }
              },
              ...(data.conjointPensionInvaliditeVal) && {
                pensions_invalidite: {
                  [`${yyyy}-${mm}`]: parseInt(data.conjointPensionInvaliditeVal),
                  [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: parseInt(data.conjointPensionInvaliditeVal),
                  [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: parseInt(data.conjointPensionInvaliditeVal),
                  [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: parseInt(data.conjointPensionInvaliditeVal)
                }
              },
              ...(data.conjointDernierContratTravail) && {
                date_arret_de_travail: {
                  [`${yyyy}-${mm}`]: data.conjointDernierContratTravail,
                  [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: data.conjointDernierContratTravail,
                  [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: data.conjointDernierContratTravail,
                  [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: data.conjointDernierContratTravail
                }
              },
              ...(data.conjointDernierContratTravail) && {
                chomeur_longue_duree: {
                  [`${yyyy}`]: (new Date(data.conjointDernierContratTravail) <= new Date((parseInt(yyyy) - 2).toString() + "-" + mm + "-" + dd)) ? true : false,
                }
              }
            }
          }
        },
        menages: {
          _: {
            personne_de_reference: ["demandeur"],
            conjoint: (data.etatLegal === "Marié(e)" || data.etatLegal === "Pacsé(e)" || data.etatLegal === "En concubinage") ? ["couple"] : [],
            enfants: countChildren(data.enfants).length ? countChildren(data.enfants) : [],
            cheque_energie: {
              [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("Chèque énergie") ? 0 : 194
            },
            statut_occupation_logement: {
              [`${yyyy}-${mm}`]: locationMap(data.logementUser, data.locationType),
              [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: locationMap(data.logementUser, data.locationType),
              [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: locationMap(data.logementUser, data.locationType),
              [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: locationMap(data.logementUser, data.locationType),
            },
            ...(data.colocation) && {
              coloc: {
                [`${yyyy}-${mm}`]: data.colocation === "Oui" ? true : false,
                [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: data.colocation === "Oui" ? true : false,
                [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: data.colocation === "Oui" ? true : false,
                [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: data.colocation === "Oui" ? true : false,
              }
            },
            ...(data.loyer || data.creditImmoVal) && {
              loyer: {
                [`${yyyy}-${mm}`]: (parseInt(data.loyer) * 0.9 || parseInt(data.creditImmoVal)),
              }
            },
            ...(data.loyer) && {
              charges_locatives: {
                [`${yyyy}-${mm}`]: (parseInt(data.loyer) * 0.1),
              }
            },
            depcom: {
              [`${yyyy}-${mm}`]: cities.find(el => el.zip_code === data.codePostal).insee_code,
              [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]: cities.find(el => el.zip_code === data.codePostal).insee_code,
              [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]: cities.find(el => el.zip_code === data.codePostal).insee_code,
              [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]: cities.find(el => el.zip_code === data.codePostal).insee_code,
            },
          },
        },
      };
      setLoading(true);
      document.body.style.overflowY = "hidden";
      document.body.style.height = "100vh";
      axios
        .post("/simulation/calculate", body)
        .then((res) => {
          setLoading(false);
          document.body.style.overflowY = "auto";
          document.body.style.height = "auto";
          setEnd(false);
          dispatch({
            type: SIMULATION,
            payload: {
              ...res.data,
              cpf: tauxCpf(data.TravailleFranceApres2015),
              cheque_energie: data.aidesSocialesTab.includes("Chèque énergie") ? 0 : calculCHeque(((Math.ceil((parseInt(data.conjointRevenusValMulti1) + parseInt(data.conjointRevenusValMulti3)) / 2) || parseInt(data.conjointRevenusVal)) + (Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal))), data.etatLegal, countChildren(data.enfants)),
              mj: calculMobiliJeune(data.statutPersonnel, data.age),
              lp: calculLocapass(data.demenagement),
              visale: calculLocapass(data.demenagement),
              allocation_annuelle_etudiant: calculEtudiantDiff(data.statutPersonnel, data.age, Math.ceil((parseInt(data.mesRevenusValMulti1) + parseInt(data.mesRevenusValMulti3)) / 2) || parseInt(data.mesRevenusVal)),
              email: data.email,
              prenom: data.prenom,
              data: data
            }
          })
          navigate("/Simulation-resultat", {})
          // window.location = '/'
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setEnd(false);
          document.body.style.overflowY = "auto";
          document.body.style.height = "auto";
        });
    }
  }, [end]);


  function enfantNumber(t) {
    if (t.length === 0) return "1er";
    else return t.length + "e";
  }
  const normalizeAgeNumber = (value) => {
    return value.replace(/\D/gi, "") || "";
  };

  function handleFormSubmit(e) {
    e.preventDefault();
    setModalData([
      ...modalData,
      {
        id: uuidv4(),
        age: enfant.age,
        statut: [enfant.statut, ...enfant.statut2].filter((el) => el !== ""),
        boursier: enfant.boursier,
        echelonDeBourse: enfant.echelonDeBourse,
        tauxHandicap: enfant.tauxHandicap,
        revenu: enfant.revenu,
      },
    ]);
    setModal((modal) => !modal);
    document.querySelector(".App").style.overflowY = "auto";
    document.querySelector(".App").style.height = "auto";
    document.body.style.overflowY = "auto";
    document.body.style.height = "auto";
    setEnfant({
      ...enfant,
      age: "",
      statut: "",
      statut2: [],
      boursier: "",
      echelonDeBourse: "",
      revenu: "",
    });
  }
  function enfantButtonValidate(obj) {
    if (obj.age && (obj.statut || obj.statut2.length)) {
      if (obj.statut === "Étudiant(e)") {
        if (!obj.statut2.includes("En situation de handicap")) {
          if (obj.boursier) {
            if (obj.boursier === "Oui") {
              if (obj.echelonDeBourse) {
                if (obj.statut2.includes("Actif (salarié / indépendant)")) {
                  if (obj.revenu) return true;
                  else return false;
                } else return true;
              } else return false;
            } else {
              if (obj.statut2.includes("Actif (salarié / indépendant)")) {
                if (obj.revenu) return true;
                else return false;
              } else return true;
            }
          } else {
            return false;
          }
        } else {
          if (obj.boursier) {
            if (obj.boursier === "Oui") {
              if (obj.echelonDeBourse) {
                if (obj.tauxHandicap) {
                  if (obj.statut2.includes("Actif (salarié / indépendant)")) {
                    if (obj.revenu) return true;
                    else return false;
                  } else return true;
                } else return false;
              } else return false;
            } else {
              if (obj.tauxHandicap) {
                if (obj.tauxHandicap) {
                  if (obj.statut2.includes("Actif (salarié / indépendant)")) {
                    if (obj.revenu) return true;
                    else return false;
                  } else return true;
                } else return false;
              } else return false;
            }
          } else {
            return false;
          }
        }
      } else if (obj.statut2.includes("En situation de handicap")) {
        if (obj.tauxHandicap) {
          if (obj.statut2.includes("Actif (salarié / indépendant)")) {
            if (obj.revenu) return true;
            else return false;
          } else return true;
        } else return false;
      } else if (obj.statut2.includes("Actif (salarié / indépendant)")) {
        if (obj.revenu) return true;
        else return false;
      } else return true;
    } else {
      return false;
    }
  }
  function singleSelect(e, cl) {
    let doc = document.querySelectorAll(cl);
    if (!enfant[e.target.id] || enfant[e.target.id] !== e.target.innerText) {
      setEnfant({ ...enfant, [e.target.id]: e.target.innerText });
      for (let i = 0; i < doc.length; i++) {
        doc[i].classList.remove("selected");
      }
      e.target.classList.add("selected");
    } else {
      setEnfant({ ...enfant, [e.target.id]: "" });
      e.target.classList.remove("selected");
    }
  }
  function multiSelect(e, cl) {
    // let doc = document.querySelectorAll(cl);
    if (!enfant[e.target.id].includes(e.target.innerText)) {
      setEnfant({
        ...enfant,
        [e.target.id]: [...enfant[e.target.id], e.target.innerText],
      });
      // for (let i = 0; i < doc.length; i++) {
      //   doc[i].classList.remove("selected");
      // }
      e.target.classList.add("selected");
    } else {
      setEnfant({
        ...enfant,
        [e.target.id]: enfant[e.target.id].filter(
          (el) => el !== e.target.innerText
        ),
      });
      // profil[e.target.id].filter(el=>el!==e.target.id)
      e.target.classList.remove("selected");
    }
  }
  useEffect(() => {
    if (enfant.statut !== "Étudiant(e)") {
      setEnfant((enfant) => {
        return { ...enfant, boursier: "", echelonDeBourse: "" };
      });
    }
    if (enfant.boursier !== "Oui") {
      setEnfant((enfant) => {
        return { ...enfant, echelonDeBourse: "" };
      });
    }
    if (!enfant.statut2.includes("En situation de handicap")) {
      setEnfant((enfant) => {
        return { ...enfant, tauxHandicap: "" };
      });
    }
    if (!enfant.statut2.includes("Actif (salarié / indépendant)")) {
      setEnfant((enfant) => {
        return { ...enfant, revenu: "" };
      });
    }
  }, [enfant.statut, enfant.statut2, enfant.boursier]);
  return (
    <>
      <LoadingBorder loading={loading} />
      <div className="simulateur_page">
        <h2>Simulez vos aides en quelques clics</h2>
        <>
          <div className="top_simulateur">
            <span>
              Étape {section === "profil" && "1"}
              {section === "mon foyer" && "2"}{" "}
              {section === "mon logement" && "3"}{" "}
              {section === "mes credits" && "4"}{" "}
              {section === "mes revenus" && "4"}{section === "mes informations" && "5"} sur 5
            </span>
          </div>
          <div className="simulateur_content">
            <div className="simulateur_tag">
              <h3>
                .{section === "profil" && "Mon profil"}
                {section === "mon foyer" && "Mon foyer"}
                {section === "mon logement" && "Mon logement"}
                {section === "mes credits" && "Mes crédits"}
                {section === "mes revenus" && "Mes revenus"}
                {section === "mes informations" && "Mes informations"}
              </h3>
              <div>
                {section === "profil" && "1"}
                {section === "mon foyer" && "2"}
                {section === "mon logement" && "3"}
                {section === "mes credits" && "4"}
                {section === "mes revenus" && "4"}
                {section === "mes informations" && "5"}
              </div>
            </div>
            <div className="simulateur_wrap">
              {section === "profil" && (
                <Profil
                  setSection={setSection}
                  setProgress={setProgress}
                  setData={setData}
                />
              )}
              {section === "mon foyer" && (
                <Foyer
                  setSection={setSection}
                  setProgress={setProgress}
                  setData={setData}
                  setModal={setModal}
                  modalData={modalData}
                  setModalData={setModalData}
                />
              )}
              {section === "mon logement" && (
                <Logement
                  setSection={setSection}
                  setProgress={setProgress}
                  setData={setData}
                />
              )}
              {section === "mes credits" && (
                <Credits
                  setSection={setSection}
                  setProgress={setProgress}
                  setData={setData}
                />
              )}
              {section === "mes revenus" && (
                <Revenus
                  setSection={setSection}
                  setProgress={setProgress}
                  setData={setData}
                  data={data}
                />
              )}
              {section === "mes informations" && (
                <Informations
                  setSection={setSection}
                  setProgress={setProgress}
                  setData={setData}
                  setEnd={setEnd}
                />
              )}
            </div>
          </div>
        </>
        {modal && (

          <>
            <div
              onClick={() => {
                setModal((modal) => !modal);
                setEnfant({
                  ...enfant,
                  age: "",
                  statut: "",
                  statut2: [],
                  boursier: "",
                  echelonDeBourse: "",
                });
                document.querySelector(".App").style.overflowY = "auto";
                document.querySelector(".App").style.height = "auto";
                document.body.style.overflowY = "auto";
                document.body.style.height = "auto";
              }}
            >
              <i className="fas fa-times-circle"></i>
            </div>
            <div>Informations du {enfantNumber(modalData)} enfant</div>
            <div>
              <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <label className="form-label">Âge</label>
                  <input
                    value={enfant.age}
                    type="tel"
                    id="age"
                    className="form-control"
                    placeholder="0"
                    required
                    maxLength="2"
                    autoFocus
                    onChange={(e) => {
                      e.target.value = normalizeAgeNumber(e.target.value);
                      setEnfant({ ...enfant, age: e.target.value });
                    }}
                  />
                </div>

                <div className="simulateur-nationalite modal-enfant">
                  <span>Statut</span>
                  <div>
                    <div
                      className="statut"
                      onClick={(e) => {
                        singleSelect(e, ".statut");
                      }}
                      id="statut"
                    >
                      Étudiant(e)
                    </div>
                    <div
                      className="statut"
                      onClick={(e) => {
                        singleSelect(e, ".statut");
                      }}
                      id="statut"
                    >
                      Apprenti(e)
                    </div>
                    <div
                      className="statut"
                      onClick={(e) => {
                        singleSelect(e, ".statut");
                      }}
                      id="statut"
                    >
                      Demandeur d’emploi
                    </div>
                    <div
                      className="statut2"
                      onClick={(e) => multiSelect(e, ".statut2")}
                      id="statut2"
                    >
                      Enceinte
                    </div>
                    <div
                      className="statu2"
                      onClick={(e) => multiSelect(e, ".statut2")}
                      id="statut2"
                    >
                      En situation de handicap
                    </div>
                    <div
                      className="statut2"
                      onClick={(e) => multiSelect(e, ".statut2")}
                      id="statut2"
                    >
                      Inapte au travail
                    </div>
                    <div
                      className="statut2"
                      onClick={(e) => multiSelect(e, ".statut2")}
                      id="statut2"
                    >
                      Actif (salarié / indépendant)
                    </div>
                    <div
                      className="statut2"
                      onClick={(e) => multiSelect(e, ".statut2")}
                      id="statut2"
                    >
                      Autre
                    </div>
                  </div>
                </div>
                {enfant.statut === "Étudiant(e)" && (
                  <div className="enfant-modal-selection">
                    <span>Est-il boursier ?</span>
                    <div>
                      <div
                        id="boursier"
                        className="boursier"
                        onClick={(e) => singleSelect(e, ".boursier")}
                      >
                        Oui
                      </div>
                      <div
                        id="boursier"
                        className="boursier"
                        onClick={(e) => singleSelect(e, ".boursier")}
                      >
                        Non
                      </div>
                    </div>
                  </div>
                )}
                {enfant.boursier === "Oui" &&
                  enfant.statut === "Étudiant(e)" && (
                    <div className="enfant-modal-selection">
                      <span>Echelon de bourse</span>
                      <div className="echelon-bourse-enfant">
                        <div
                          id="echelonDeBourse"
                          className="echelonDeBourse"
                          onClick={(e) => singleSelect(e, ".echelonDeBourse")}
                        >
                          0
                        </div>
                        <div
                          id="echelonDeBourse"
                          className="echelonDeBourse"
                          onClick={(e) => singleSelect(e, ".echelonDeBourse")}
                        >
                          1
                        </div>
                        <div
                          id="echelonDeBourse"
                          className="echelonDeBourse"
                          onClick={(e) => singleSelect(e, ".echelonDeBourse")}
                        >
                          2
                        </div>
                        <div
                          id="echelonDeBourse"
                          className="echelonDeBourse"
                          onClick={(e) => singleSelect(e, ".echelonDeBourse")}
                        >
                          3
                        </div>
                        <div
                          id="echelonDeBourse"
                          className="echelonDeBourse"
                          onClick={(e) => singleSelect(e, ".echelonDeBourse")}
                        >
                          4
                        </div>
                        <div
                          id="echelonDeBourse"
                          className="echelonDeBourse"
                          onClick={(e) => singleSelect(e, ".echelonDeBourse")}
                        >
                          5
                        </div>
                        <div
                          id="echelonDeBourse"
                          className="echelonDeBourse"
                          onClick={(e) => singleSelect(e, ".echelonDeBourse")}
                        >
                          6
                        </div>
                        <div
                          id="echelonDeBourse"
                          className="echelonDeBourse"
                          onClick={(e) => singleSelect(e, ".echelonDeBourse")}
                        >
                          7
                        </div>
                      </div>
                    </div>
                  )}
                {enfant.statut2.includes("En situation de handicap") && (
                  <div className="enfant-modal-selection handicap-wrap">
                    <span>Quel est son taux de handicap ?</span>
                    <div>
                      <div
                        className="tauxHandicap"
                        onClick={(e) => singleSelect(e, ".tauxHandicap")}
                        id="tauxHandicap"
                      >
                        Moins de 50%
                      </div>
                      <div
                        className="tauxHandicap"
                        onClick={(e) => singleSelect(e, ".tauxHandicap")}
                        id="tauxHandicap"
                      >
                        Entre 50% et 80%
                      </div>
                      <div
                        className="tauxHandicap"
                        onClick={(e) => singleSelect(e, ".tauxHandicap")}
                        id="tauxHandicap"
                      >
                        Plus de 80%
                      </div>
                    </div>
                  </div>
                )}
                {enfant.statut2.includes("Actif (salarié / indépendant)") && (
                  <div className="revenu-modal">
                    <span>Ses revenus mensuels nets</span>
                    <div className="simulateur-age revenu">
                      <div>
                        <input
                          type="tel"
                          autoFocus
                          maxLength="4"
                          value={enfant.revenu}
                          placeholder="0"
                          required
                          onChange={(e) => {
                            e.target.value = normalizeAgeNumber(
                              e.target.value
                            );
                            setEnfant({ ...enfant, revenu: e.target.value });
                          }}
                        />
                      </div>
                      <span>€</span>
                    </div>
                  </div>
                )}
                <div>
                  <button
                    type="submit"
                    disabled={!enfantButtonValidate(enfant)}
                    style={{
                      backgroundColor:
                        enfantButtonValidate(enfant) ? "var(--colorPrimary)" : "#efefef",
                    }}
                  >
                    Ajouter
                  </button>
                </div>
              </form>
            </div>
          </>
        )
        }
      </div >
    </>
  );
}

export default bothAuth(SimulationGratuit);
