import axios from 'axios';
import { createContext, useState, useEffect, useContext } from 'react';

export const AuthContext = createContext();

function getConnectedUser(successCb, erroCb, finalyCb) {
    return axios.get('/user')
        .then(res => successCb(res.data))
        .catch(err => erroCb())
        .finally(() => {
            finalyCb()
        })
}

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userToken, setUserToken] = useState(localStorage.getItem('fa-authToken'));
    const [userLoading, setUserLoading] = useState(true);
    const [refetchUser, setRefetchUser] = useState(false);
    const [userError, setUserError] = useState(null);

    useEffect(() => {
        if (userToken) {
            if (!axios.defaults.headers['Authorization']) {
                axios.defaults.headers = {
                    'Authorization': 'Bearer ' + userToken
                }
            }
            getConnectedUser((userData) => {
                setUser(userData);
            }, () => {
                logout()
            }, () => {
                setUserLoading(false)
            });
        } else {
            setUserLoading(false)
        }
    }, [userToken, refetchUser]);

    const login = (token) => {
        setUserLoading(true);
        setUserToken(token);

        localStorage.setItem('fa-authToken', token);

        axios.defaults.headers = {
            'Authorization': 'Bearer ' + token
        }
    };

    const updateLogin = (token) => {
        localStorage.setItem('fa-authToken', token)
        axios.defaults.headers = {
            'Authorization': 'Bearer ' + token
        }
        refetchUserLoggedData()
    };

    const refetchUserLoggedData = () => {
        setRefetchUser(prev => !prev)
    };

    const logout = () => {
        setUserLoading(true);
        setUser(null);
        setUserToken(null);
        localStorage.removeItem('fa-authToken');
        axios.defaults.headers = null
    };

    return (
        <AuthContext.Provider value={{ user, userLoading, login, updateLogin, logout, refetchUserLoggedData }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
