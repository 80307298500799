export function Redaction() {
  return (
    <>
      <div className="caf-wrapper">
        <p>
          Vous allez rédiger votre CV,lettre de motivation, ou même des courriers . Mais sous quelle forme et avec quel contenu ? Si vous voulez que votre téléphone sonne, multipliez les atouts de votre candidature.
        </p>
        <p>
          Perdu dans vos démarches , notre Assistant Privé vous aide dans la rédaction de vos cv, lettre de motivation, ou courriers en souscrivant à notre offre
        </p>
      </div>
    </>
  )
}
