export function g_APl() {
  return (
    <>
      <div className="caf-wrapper">
        <p>
          L'aide personnalisée au logement (APL) est une aide financière
          destinée à réduire le montant de votre loyer (ou de votre redevance
          si vous résidez en foyer). Elle est versée en raison de la situation
          de votre logement et ce, quelle que soit votre situation familiale :
          célibataire, marié, avec ou sans personne à charge.
        </p>
        <p>
          Les conditions d'attribution diffèrent selon que vous êtes en
          location ou que vous résidez en foyer.
        </p>
        <h1>Les conditions d’obtention de l'APL</h1>
        <h2>Locataire, colocataire, sous-locataire</h2>
        <span>
          Que vous soyez locataire, colocataire, sous-locataire, vous avez
          accès aux APL. Si vous êtes sous-locataire (déclaré au
          propriétaire), vous devez remplir l’une de ces 2 conditions :
          <ul>
            <li>Vous êtes âgé de moins de 30 ans,</li>
            <li>
              Vous êtes hébergé chez un accueillant familial (une personne
              seule ou en couple qui vous accueille si vous êtes en situation
              de handicap ou une personne âgée, contre une rémunération).
            </li>
          </ul>
          Si vous êtes étranger résident en France, vous devez prouver la
          régularité de votre séjour sur le territoire. Votre résidence
          principale doit se trouver en France.
        </span>
        <h2>APL Propriétaire</h2>
        <span>
          Si vous êtes propriétaire, vous pouvez également bénéficier des APL.
          Pour ce faire, vous devez cumuler les conditions suivantes :
        </span>
        <ul>
          <li>
            Vous accédez à la propriété d’un logement ancien, situé en zone
            géographique 3 (agglomérations de moins de 100 000 habitants hors
            Île-de-France)
          </li>
          <li>
            Vous bénéficiez d’un de ces deux prêts :
            <ul>
              <li>
                Un prêt conventionné, qui est un prêt immobilier accordé par
                votre banque sous contrat avec l’État pour vous aider à
                acheter, rénover ou encore améliorer votre logement,
              </li>
              <li>
                Un prêt d’accession Sociale (destiné à aider les familles
                modestes à accéder à la propriété) conclu entre le 1er janvier
                2018 et le 1er janvier 2020.
              </li>
            </ul>
          </li>
        </ul>

      </div>
    </>
  );
}
