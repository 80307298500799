import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
const OurResults = () => {
    const ourResults = [
        { icon: 'mdi-heart', title: '4000', text: " utilisateurs bénéficient de notre accompagnement" },
        { icon: 'mdi-heart', title: '3400 €', text: " en moyenne perçus Annuellement par ces derniers." },
    ]


    return <div className="hOurResults">
        <Fade>
            <h2>Pour faire valoir vos droits à ces prestations , faites nous confiance</h2>
        </Fade>
        <section>
            <Fade top cascade>
                {
                    ourResults.map((or, index) => <div key={"our result nb" + index}>
                        <p> Près de </p>
                        <span> {or.title} </span>
                        <p> {or.text} </p>

                    </div>)
                }
                <Link to='/Simulation-gratuite'>Simuler gratuitement</Link>
            </Fade>
        </section>
    </div>
}

export default OurResults;