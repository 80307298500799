import React from 'react'
import useBlogBestArticles from '../../Hooks/blog/useBlogBestArticles'
import { ArticleCard } from '../blog/articles';

function HomeBestArticles() {
    const { blogBestArticles } = useBlogBestArticles();
    return (
        <div className='h-bestArticles'>
            <h2>À la une</h2>
            {
                blogBestArticles.length > 0 ? <div className="articles-container">
                    {blogBestArticles.map((item, index) => (
                        ArticleCard(item, 'home best article ' + index)
                    ))}
                </div> : ''
            }

        </div>
    )
}

export default HomeBestArticles