import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import simulationSomme from "../helper/SimulationMath";
import "../style/simulationResultat.css";
import axios from "axios";
import NouvellesAides from "../GlobalComponents/simulation/newAide";
import { calculateNewAidesTotal, countOfNewAides } from "../helper/SimulationMath";
import { getAllNewAides } from "../helper/getters";
import { CallToActionAfterSimulationResult, SomeReviews } from "../GlobalComponents/Others";
import OldAidesByOpenFisca from "../GlobalComponents/simulation/oldAides";
import { bothAuth } from "../hocs/bothAuth";

function SimulationResultat() {
  const simulation = useSelector((state) => state.simulation.simulation);
  let { sum, aides, val } = simulationSomme(simulation, calculateNewAidesTotal(simulation.data), countOfNewAides(simulation.data));

  useEffect(() => {
    if (simulation && simulation.data) {
      axios.post('/simulation/simulation_send_mail', {
        simulation: { ...simulation, newAides: getAllNewAides(simulation.data) }
      })
        .then(res => null)
        .catch(err => alert('Une erreur est survenue'))
    }
  }, []);

  return (
    <>
      <div className="simulationResu">
        <section>
          <div>
            <h2>Mes aides :</h2>
            <div>
              {sum} €<span>/ mois</span>
            </div>
            <div style={{ textAlign: "center", color: "var(--c2d1)" }}>
              <h3>{aides + "  "} aide{aides === 1 ? "" : "s"} disponible{aides === 1 ? "" : "s"}</h3>
            </div>
            {aides === 0 && (
              <h3>
                D’après nos calculs, vous n’êtes pas éligible à des aides
                financières.
              </h3>
            )}
          </div>

          <div>
            <OldAidesByOpenFisca aides={val} />
            {
              simulation && simulation.data && <NouvellesAides props={{ simulation }} />
            }
          </div>
          <span
            style={{
              color: "#3c3c3c",
              fontSize: 13,
              margin: "10px auto"
            }}
          >
            *Ces résultats sont fondés sur les seules informations que vous avez
            indiquées et ne constituent en aucune façon un engagement de la part des
            organismes cités. Les montants avancés sont arrondis à une dizaine
            d'euros près
          </span>
        </section>

        <CallToActionAfterSimulationResult />
      </div>
      <SomeReviews />
    </>
  );
}

export default bothAuth(SimulationResultat);
