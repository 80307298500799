export function g_Rsa() {
  return <>
    <div className="caf-wrapper">
      <p>
        Le Revenu de Solidarité Active (RSA) est une aide sociale dont vous pouvez bénéficier si vous percevez des revenus faibles ou si vous êtes démuni, afin de vous garantir un revenu minimal.
      </p>
      <p>
        C’est la Caisse d’Allocations Familiales (CAF) qui verse le RSA, au même titre que de nombreuses autres prestations telles que les allocations familiales ou la prime d’activité. Si toutefois vous dépendez de la Mutuelle Sociale Agricole (MSA), c’est cette institution qui vous délivrera le RSA.
      </p>
      <h1>Quelles sont les conditions d’attribution du RSA ?</h1>
      <p>
        Il existe certaines conditions pour bénéficier du RSA. Vous devez être âgé de 25 ans ou plus, sauf si vous êtes enceinte ou si vous avez déjà un enfant à charge.
      </p>
      <span>
        Les conditions à remplir sont les suivantes :
        <ul>
          <li>
            Vous habitez en France de façon stable (comprenez : plus de 6 mois par an)
          </li>
          <li>
            Vous êtes français, vous êtes ressortissant de l’Espace Économique Européen (EEE), vous êtes Suisse tout en vous justifiant d’un droit de séjour, ou enfin vous êtes ressortissant d’un autre pays et vous séjournez en France de façon régulière depuis une période minimum de 5 ans (quelques exceptions peuvent déroger à cette condition).
          </li>
          <li>
            Les ressources mensuelles moyennes de votre foyer pendant les 3 mois qui précèdent votre demande ne doivent pas dépasser un certain seuil
          </li>
          <li>
            Vous devez avoir fait valoir vos droits concernant toute autre prestation sociale (comme l’allocation chômage, la retraite) à laquelle vous pouvez prétendre
          </li>
        </ul>
      </span>
    </div>
  </>

}

