import React from 'react'
import { IonMdAlert } from './icons'

function StealerAlert() {
    return (
        <div className="stealerAlert">
            <span>
                <IonMdAlert />
            </span>
            <section>
                <b>
                    Attention: des individus mal intentionnés utilisent l'image de France Assist pour faire du démarchage à propos du CPF!!! Nous tenons à rappeler que France Assist ne fait pas de démarchage CPF. L'unique numéro de notre standard est +33 04 65 84 08 48.
                    Pour être certain qu'il s'agit bien de nous, contacter nous par mail à l'adresse suivante :
                </b>
                <a href="mailto:contact@france-assist.fr" target='_blank'>contact@france-assist.fr</a>
            </section>
        </div>
    )
}

export default StealerAlert