import { Link } from "react-router-dom";
const ListRessources = () => {
    return <section>
        <h2>Actualisez vos ressources financières <br />(Net à payer avant impôt sur le revenu) </h2>
        <div>
            <p>Ressources trimestrielles</p>
            <Link to="/Ressources/trimestrielles">Actualiser</Link>
        </div>
        <div>
            <p>Ressources annuelles</p>
            <Link to="/Ressources/annuelles">Actualiser</Link>
        </div>
    </section>
}

export default ListRessources;