export function AP() {
  return (
    <>
      <div className="caf-wrapper">
        <p>
          Les  Aides ponctuelles sont destinées à apporter une aide à l’étudiant ou toute personne qui rencontre des difficultés financières exceptionnelles et passagères.
        </p>
        <p>
          L’aide ponctuelle doit permettre de prendre en compte des situations nouvelles, imprévisibles qui interviennent en cours d’année universitaire. Ces situations, de par leur gravité, nécessitent qu’une aide ponctuelle soit apportée pour permettre à l’étudiant de poursuivre ses études. Elles sont attestées par une évaluation sociale. L’aide ponctuelle est cumulable avec une bourse sur critères sociaux, une aide d’urgence annuelle, une aide à la mobilité, une aide au mérite.
        </p>
        <h3>Critères d’attribution</h3>
        <p>
          Tout étudiant inscrit en formation initiale auprès d’un établissement ou d’une section d’établissement ouvrant droit au régime de sécurité sociale étudiante peut solliciter une aide ponctuelle. Si la situation de l’étudiant le justifie, plusieurs aides ponctuelles peuvent exceptionnellement être accordées au cours d’une même année universitaire, dans le cadre du budget des Aides Spécifiques.
        </p>
      </div>
    </>
  )
}
