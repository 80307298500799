import { Form, FormikProvider, useFormik } from 'formik'
import React from 'react'
// import { ReferralValidation } from '../../utils/front/form/fieldsValidation'
import { useAuth } from '../../contexts/auth'
import { referalProgramFields } from '../../helper/form/fields';
import FormikFieldController from '../../uikits/form';
import { FormButton } from '../../uikits/buttons';
import { ReferralSchema } from '../../helper/form/validations';
import axios from 'axios';
import { adminApiBaseUrl, successToast } from '../../helper/others';

function RPBecomeReferror() {
    const { user } = useAuth();
    const formik = useFormik({
        initialValues: {
            referrorName: user ? (user.societyName ? user.societyName : user.firstName + ' ' + user.lastName) : '',
            referrorEmail: user ? user.email : '',
            referredName: '',
            referredEmail: '',
            referredPhone: ''
        },
        validateOnMount: true,
        validationSchema: ReferralSchema,
        onSubmit: handleSubmit
    });

    const { isValid, isSubmitting, setSubmitting, resetForm } = formik;

    function handleSubmit(formValues) {
        axios.post(adminApiBaseUrl + '/admin/referral/add', formValues)
            .then(res => {
                successToast("Merci chers utilisateur,Votre message a bien été envoyé")
            })
            .catch(err => console.log(err))
            .finally(() => {
                resetForm()
            })
    }
    return (
        <div className='become-referror flex f-wrap'>
            <section className='flex f-column'>
                <h1>
                    Merci de votre intérêt pour le Programme de Recommandation des particuliers chez France-assist !
                </h1>

                <p>
                    Pour chaque particulier que vous recommandez avec succès sur la plateforme, nous vous offrirons une prime de recommandation.
                    Une recommandation sera considérée comme réussie si le particulier que vous recommandez :
                    <br />
                    <b>Est nouveau sur France-assist et complète une souscription à un forfait tarifaire dans les 30 jours suivant son inscription.</b>
                </p>

                <p>
                    Veuillez remplir les informations concernant votre recommandation à France-assist.
                </p>

            </section>
            <section>
                <FormikProvider value={formik}>
                    <Form action="">
                        {
                            referalProgramFields.map((item, i) => <FormikFieldController
                                {...item}
                                key={'referal field key nb ' + i}
                            />)
                        }
                        <article className='flex confirmForm'>
                            <input type="checkbox" />
                            <p>
                                En soumettant cette recommandation, vous confirmez avoir le consentement du destinataire pour l'envoi et la réception de communications (y compris des e-mails et des appels téléphoniques) aux coordonnées ci-dessus dans le cadre de ce programme de recommandation.*
                            </p>
                        </article>
                        <FormButton
                            text="Soumettre"
                            isValid={isValid}
                        />
                    </Form>
                </FormikProvider>
            </section>
        </div>
    )
}

export default RPBecomeReferror;
