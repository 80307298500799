import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NouvellesAides from "../GlobalComponents/simulation/newAide";
import { CallToActionAfterSimulationResult, SomeReviews } from "../GlobalComponents/Others";
import OldAidesByOpenFisca from "../GlobalComponents/simulation/oldAides";
import { bothAuth } from "../hocs/bothAuth";


const SimulationResultWithToken = () => {
    const { token } = useParams()
    const [simulationResult, setSimulationResult] = useState('')
    useEffect(() => {
        axios.post('/simulation/simulation-check', { token })
            .then(res => {
                console.log(res)
                let newObj = {}

                for (const property in res.data) {
                    if (res.data[property] != 0) {
                        newObj = { ...newObj, [property]: res.data[property] }
                    }
                }

                setSimulationResult(newObj)
            })
            .catch(err => console.log(err))
    }
        , [])
    return (
        <>
            <div className="simulationResu">
                <section>
                    <div>
                        <h2>Mes aides :</h2>
                    </div>

                    <div>
                        <OldAidesByOpenFisca aides={simulationResult} />
                        {
                            simulationResult && simulationResult.newAides && <NouvellesAides props={{ simulation: simulationResult }} />
                        }
                    </div>
                    <span
                        style={{
                            color: "#3c3c3c",
                            fontSize: 13,
                            margin: "10px auto"
                        }}
                    >
                        *Ces résultats sont fondés sur les seules informations que vous avez
                        indiquées et ne constituent en aucune façon un engagement de la part des
                        organismes cités. Les montants avancés sont arrondis à une dizaine
                        d'euros près
                    </span>
                </section>
                <CallToActionAfterSimulationResult />

            </div>
            <SomeReviews />
        </>
    );
}

export default bothAuth(SimulationResultWithToken);