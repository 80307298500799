export const S_I_PPA =
  <>
    <div >
      <p>
        La prime d’activité remplace le RSA activité et la prime pour l’emploi. Cette aide, qui vous est versée par la Caisse d’Allocations Familiales (CAF), ou la Mutualité Sociale Agricole (MSA), a pour but de favoriser le retour à l’emploi (en le rendant plus attractif financièrement).
      </p>
      <h3>
        Pouvez-vous bénéficier de la prime activité ?
      </h3>
      <h3>Bénéficier de la prime si vous êtes Français</h3>
      <span>
        Si vous êtes Français, pour être éligible à la prime d’activité, vous n’avez que deux conditions, simples mais primordiales, à remplir :

        <ul>
          <li>
            Vous devez exercer une activité professionnelle pour laquelle vous percevez des revenus modestes
          </li>
          <li>
            Vous devez résider en France de manière stable et effective (comprenez : votre résidence principale se trouve en France et vous ne devez pas quitter le territoire plus de 3 mois par année civile, consécutifs ou non).
          </li>
          <li>
            Et avoir 18 ans.
          </li>
        </ul>
      </span>
      <h3>
        Bénéficier de la prime si vous êtes étranger
      </h3>
      <span>
        Si vous êtes étranger, vous devez également respecter les conditions suivantes :
        <ul>
          <li>
            Avoir une activité professionnelle
          </li>
          <li>
            Résider en France de manière stable et effective
          </li>
        </ul>
      </span>
      <h3>
        Comment faire une simulation prime activité ?
      </h3>
      <h3>
        Calcul de la prime d’activité
      </h3>
      <span>
        Sont pris en compte pour le calcul de la prime d’activité :
        <ul>
          <li>
            D’une part, un montant forfaitaire, qui varie selon les ressources de votre foyer et du nombre d’enfant à votre charge. Ce montant peut être bonifié selon les revenus professionnels de chaque travailleur (valable aussi pour les travailleurs indépendants).
          </li>
          <li>
            D’autre part, les ressources prises en compte pour votre foyer
          </li>
        </ul>
      </span>


    </div>
  </>

