import React from 'react'
import { SimpleImage } from '../../GlobalComponents/img/global';
import { Link } from 'react-router-dom';

function RPDescription() {
    return (
        <div className='affiliation-desc'>
            <div className='ad-banner flex f-wrap'>
                <section className='flex f-column'>
                    <h1>Programme de référencement des particuliers</h1>
                    <p>
                        Référencez un particulier et obtenez une prime de référencement
                    </p>
                    {/* <SimpleButton customprops={{
                        color: 1, text: 'Référez un particulier', type: 'bg',
                        link: appAllPagesLinks.referralProgram.add
                    }} /> */}
                </section>

                <SimpleImage props={{
                    src: "referror.webp", alt: 'ref program banner image 1'
                }} />
            </div>


            <div className='ad-afterBanner'>
                <h2> Comment fonctionne le Programme de recommandation des particuliers ?</h2>
                <div className='flex f-wrap adab-stepDesc'>
                    <article>
                        <span>1</span>
                        <p>
                            Assurez-vous d'obtenir l'approbation du particulier avant de la recommander à France-assist.
                        </p>
                    </article>

                    <article>
                        <span>2</span>
                        <p>
                            Complétez le formulaire et notre équipe commerciale se chargera de collaborer avec l'entreprise recommandée afin d'évaluer l'adéquation de France-assist à ses besoins opérationnels.
                        </p>
                    </article>

                    <article>
                        <span>3</span>
                        <p>
                            Obtenez une prime de recommandation lorsque le particulier recommandée souscrit à un forfait tarifaire dans les 30 jours.
                        </p>
                    </article>
                </div>
                <br /> <br />
                <section className='textCenter'>
                    <button>
                        <Link to={'/Programme-de-reference/formulaire'}>Référez un particulier</Link>
                    </button>
                    {/* <SimpleButton customprops={{
                        color: 1, text: 'Référez un particulier', type: 'bg',
                        link: appAllPagesLinks.referralProgram.add
                    }} /> */}
                </section>
            </div>
        </div>
    )
}

export default RPDescription;
