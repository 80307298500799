export const S_I_MVA =
  <>
    <div>
      <p>
        La majoration pour la vie autonome (MVA) qui a remplacé l’aide à l’autonomie au 1er juillet 2015, est une prestation forfaitaire mensuelle qui s'ajoute à l'allocation aux adultes handicapés (AAH).
        <br />
        La MVA permet aux personnes en situation de handicap vivant dans un logement de faire face aux dépenses que cela implique.
      </p>
      <h3>
        Qui verse la majoration pour la vie autonome ?
      </h3>
      <span>
        La MVA est versée par :
        <ul>
          <li>
            La CAF
          </li>
        </ul>
        OU
        <ul>
          <li>
            La MSA pour les assurés au régime agricole
          </li>
        </ul>
      </span>
      <h3>
        Comment obtenir la majoration pour la vie autonome ?
      </h3>
      <span>
        Il n’y a pas de demande particulière à effectuer pour bénéficier de la majoration pour la vie autonome.
        <br />
        La MVA est attribuée de manière automatique lors de la demande d’AAH, si les critères sont remplis.
      </span>
      <h3>
        Quel est le montant de la majoration pour la vie autonome ?
      </h3>
      <span>
        Le montant de la majoration pour la vie autonome s’élève à 104,77 € par mois.
      </span>

    </div>
  </>
