import React from 'react'
import { FAQQuestionAndResponseLayout } from '../../Routes/Faq'
import { faqs } from '../../Raw/faqs'

function HomeSomeFAQ() {
    return (
        <div className='homeFaq'>
            <section className='hf-title'>
                <h2>Questions <br /> fréquentes</h2>
                <br />
                <p>
                    Les reponses aux questions fréquement posées sont ici et vous pouvez aussi
                    en poser une autre dont vous n’avez pas les reponses ici
                </p>
            </section>
            <section className='hf-questionList'>
                {
                    faqs.géneral.map((q, index) => <FAQQuestionAndResponseLayout key={'som faq nb' + index}
                        qr={q} />)
                }

            </section>
        </div>
    )
}

export default HomeSomeFAQ