import React from 'react'

export function OneChoice({ props }) {
    const { choiceArray, target, singleSelect } = props;
    return (
        <div>
            {
                choiceArray.map((ca, index) => <div key={"choice array " + target + "_" + index}
                    className={target}
                    onClick={(e) => singleSelect(e, "." + target)}
                    id={target}>
                    {ca}
                </div>)
            }
        </div>
    )
}