export const S_I_APA = <>
  <div>
    <p>
      L’APA est une aide principalement destinée aux personnes âgées dépendantes. Cette dernière est versée dans le cadre d’un maintien à domicile ou d’un hébergement en maison de retraite. Elle est destinée aux personnes âgées en perte d’autonomie, quels que soient leurs revenus.
    </p>
    <h3>
      Qui peut en bénéficier ?
    </h3>
    <span>
      Vous pouvez bénéficier de l’APA pour une durée illimitée. Les personnes suivante peuvent en bénéficier :

      <ul>
        <li>
          Les personnes âgées de 60 ans ou plus, résidant de façon régulière en France ou les personnes étrangères séjournant légalement en France.
        </li>
        <li>
          Les personnes en perte d’ autonomie en raison de leur état physique ou mental.
        </li>
        <li>
          Les personnes ayant besoin d’une aide pour l’accomplissement des actes essentiels de la vie
        </li>
      </ul>
    </span>
    <h3>
      Méthode de calcul de l’ APA
    </h3>
    <span>
      3 éléments sont pris en compte pour calculer le montant de l’ APA :
      <ul>
        <li>
          de vos revenus,
        </li>
        <li>
          du coût des aides prévues dans votre plan d’aide,
        </li>
        <li>
          de votre GIR.
        </li>
      </ul>
    </span>
    <h3>
      Montant maximum de l’APA à domicile
    </h3>
    <span>
      Le plan d’aide de l’APA est plafonné et le montant que vous allez percevoir ne peut pas dépasser le montant maximal qui est fixé par la grille Aggir.
      <ul>
        <li>
          Pour le GIR 1 : 1742,34 €/mois
        </li>
        <li>
          Pour le GIR 2 : 1399,03 €/mois
        </li>
        <li>
          Pour le GIR 3 : 1010,85 € /mois
        </li>
        <li>
          Pour le GIR 4 : 674,27 €/mois
        </li>
      </ul>
    </span>
    <h3>
      Calcul de la prime d’activitéLes 6 niveaux de GIR
    </h3>
    <span>
      C’est à partir des GIR que sont déterminés les tarifs de dépendance.
      <ul>
        <li>
          <b>GIR 1</b> : Ce sont des personnes très dépendantes tant au niveau physique que psychologique et qui nécessitent une assistance permanente.
        </li>
        <li>
          <b>GIR 2</b> : Il s’agit de personnes âgées physiquement dépendantes mais tout à fait lucides, ou bien physiquement indépendantes mais déséquilibrées.
        </li>
        <li>
          <b>GIR 3</b> : Ces personnes sont lucides mais en partie dépendantes. Elles nécessitent une aide plusieurs fois par jour.
        </li>
        <li>
          <b>GIR 4</b> : Ces personnes âgées doivent être assistées pour leurs déplacements et parfois pour les soins corporels (toilette, habillement) et les repas.
        </li>
        <li>
          <b>GIR 5</b> : Il s’agit de personnes âgées relativement autonomes qui doivent être assistées de façon ponctuelle pour les repas, le ménage ou la toilette.
        </li>
        <li>
          <b>GIR 6</b> : Ce sont des personnes qui sont autonomes pour effectuer les actes essentiels de la vie courante.
        </li>
      </ul>
    </span>
  </div>
</>

