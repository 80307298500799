const DashHowItWork = ({ props }) => {
    //     userFullName
    // userMentor
    const { userFullName, userMentor } = props;
    const mentorNum = userMentor.telephone;
    return <section className="dGuide">
        <article>
            <h2>Comment procéder ?</h2>
            <p>
                Afin de pouvoir analyser votre dossier, nous avons besoin de <b>justificatifs</b>.
                <br />
                Plus <b>tôt</b> vous les enverrez, plus <b>tôt</b> nous pourrons avancer.
            </p>
        </article>

        <article>
            <h2>Documents à envoyer :</h2>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <p>* Carte d'identité ou passeport pour vous et votre éventuel conjoint et enfants.</p>
                <p>* Si vous êtes étranger : titre de séjour.</p>
                <p>
                    * Justificatifs de revenus (fiches de paie, indemnités) des 3 derniers mois
                    (pour vous et votre éventuel conjoint).
                </p>
                <p>
                    * Contrat de location (4 premières pages) si vous êtes locataire.
                </p>
            </div>
        </article>

        <article>
            <h2>Comment envoyer vos documents ?</h2>
            <p>
                En envoyant vos documents dans la rubrique Inserer un document sur votre espace france Assist
                N'oubliez pas de prendre RDV avec moi pour faire le point
                <a href={userMentor.cal} target="_blank"> {userMentor.cal} </a>
                Vous êtes perdu(e) ? Appelez-moi ou envoyez-moi un WhatsApp au <br />
                <a href={"/"}> {userMentor.telephone} (gratuit)</a>
                Ou encore par mail sur <a href=''>{userMentor.email}</a>
            </p>
        </article>
    </section>
}

export default DashHowItWork;