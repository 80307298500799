import React from 'react'
function SelectTypeDocument({ props }) {
    const { typeDoc, setTypeDoc } = props;
    const tabArray = [
        {
            label: 'Mes documents', id: 'ud1', value: 'ofUser'
        },
        {
            label: 'Du Conseiller', id: 'ud2', value: 'ofAssist'
        }
    ]
    return (
        <section className='fSelectTab'>
            {
                tabArray.map((ta, index) => <label key={"doc tab nb" + index}
                    htmlFor={ta.id}
                    className={ta.value === typeDoc ? "fstSelected" : ""} >
                    <input type='radio' name="user_doc" id={ta.id}
                        onClick={(event) => setTypeDoc(ta.value)}
                        value={ta.value}
                        checked={ta.value === typeDoc} />
                    <span>{ta.label}</span>
                </label>)
            }
        </section>
    )
}

export default SelectTypeDocument
