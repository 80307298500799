export function g_Gj() {
  return (
    <>
      <div className="caf-wrapper">
        <p>
          La Garantie jeunes est un droit ouvert qui s’adresse aux jeunes de 16 à moins de 26 ans, en situation de précarité qui ne sont ni en emploi, ni en formation, ni en étude.
        </p>
        <h1>Qui est concerné ?</h1>
        <span>
          la garantie jeunes est accessible aux jeunes en situation de précarité : sans emploi, sortis de l’école et qui ne suivent pas de formation. L’objectif : les aider à intégrer la vie active de façon autonome.
        </span>
        <span>
          La Garantie jeunes s’adresse aux jeunes :
          <ul>
            <li>
              Âgés de 16 ans à moins de 26 ans
            </li>
            <li>
              Qui ne sont ni en emploi, ni à l’école, ni en formation
            </li>
            <li>
              Qui vivent hors du foyer de leurs parents ou au sein de ce foyer sans recevoir de soutien financier de leurs parents
            </li>
            <li>
              Et qui sont en situation de grande précarité (leurs ressources éventuelles ne doivent pas excéder un niveau actuellement fixé à 497,5 €)
            </li>
          </ul>
        </span>
      </div>
    </>
  )
}
