import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setModal } from "../../../store/actions/modalActions";
import moment from "moment";
import axios from "axios";
const DocumentList = ({ props }) => {
    const { documentList, typeDoc } = props;
    const [finalList, setFinalList] = useState(props.documentList);
    const [modalFileList, setMFL] = useState([])
    const dispatch = useDispatch();

    async function deleteFiles(item) {
        const theFiles = item.files;

        let trimedArray = [];
        theFiles.forEach((element) => {
            trimedArray.push(element.split('/uploads/')[1])
        });
        // console.log(trimedArray)

        try {
            let makeDelete = await axios.post('/user/email/' + item._id + '/delete', {
                filesArray: trimedArray
            });
            window.location.reload();
        } catch (error) {
            console.log(error);
            alert('Erreur lors de la suppression')
        }

    }

    function searchOnArray(initialData, searched, searhKey, resultSetter) {
        const copyData = initialData.slice();
        let filter = '';
        if (searhKey.length == 1) {
            filter = copyData.filter(item => item[searhKey[0]].toLowerCase().includes(searched))
        } else if (searhKey.length == 2) {
            filter = copyData.filter(item => item[searhKey[0]].toLowerCase().includes(searched)
                || item[searhKey[1]].toLowerCase().includes(searched)
            )
        } else if (searhKey.length == 3) {
            filter = copyData.filter(item => item[searhKey[0]].toLowerCase().includes(searched)
                || item[searhKey[1]].toLowerCase().includes(searched)
                || item[searhKey[2]].toLowerCase().includes(searched))
        }
        if (filter.length > 0) {
            resultSetter(filter)
        }
    }

    useEffect(() => {
        setFinalList(props.documentList)
    }, [props])
    return <section className="ffilesList">
        <section className='fSearch'>
            <input type="search" placeholder='Rechercher un document ici...'
                onChange={(event) => {
                    searchOnArray(documentList,
                        event.target.value.toLowerCase(), ['title'], setFinalList)
                }} />
        </section>
        <section className="tableContainer">
            <table>
                <thead>
                    <tr>
                        <th>*</th>
                        <th>Titre du doc</th>
                        <th>Date d'ajout</th>
                        <th>Actions</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        finalList.map((item, index) => <tr key={"doc nb" + index}>
                            {

                                (item.file || item.files) && <>
                                    <td>{index}</td>
                                    <td>
                                        <span>{item.title} </span>
                                    </td>
                                    <td>{moment(item.created_at).format("Do MMMM YYYY")}</td>
                                    <td>
                                        {
                                            (() => {
                                                if (item.files && item.files.length > 0) {
                                                    return <button onClick={() => {
                                                        setMFL(finalList[index].files)
                                                        dispatch(setModal(<FileModal modalFileList={finalList[index].files} />))

                                                    }}>Voir les fichier</button>
                                                } else {
                                                    if (Array.isArray(item.file)) {
                                                        return <button onClick={() => {
                                                            setMFL(finalList[index].file);
                                                            dispatch(setModal(<FileModal modalFileList={finalList[index].file} />))

                                                        }}>Voir les fichiers</button>
                                                    } else {
                                                        return <a href={item.file} target="_blank"> Voir le fichier</a>
                                                    }
                                                }
                                            })()
                                        }
                                        <br />
                                        {
                                            typeDoc == 'ofUser' ? <button onClick={() => deleteFiles(item)}>Supprimer</button> : ''
                                        }
                                    </td>
                                </>
                            }
                        </tr>)
                    }
                </tbody>
            </table>
        </section>
    </section>
}

const FileModal = ({ modalFileList }) => {
    return <div className="modalFileList">
        {
            modalFileList.length > 0 ?
                modalFileList.map((mfl, index) => <div key={"modal file nb" + index}>
                    <p>Fichier {index + 1} </p>
                    <a href={mfl} target="_blank"> Voir le fichier</a>
                </div>)
                : <h1>Aucun fichier disponible</h1>
        }
    </div>
}

export default DocumentList;