import React, { useEffect, useLayoutEffect, useState } from 'react'
import { NextSimulationStepButton, PrevSimulationStep } from '../../../GlobalComponents/simulation/nav';
function Credits({ setSection, setProgress, setData }) {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;
  const [page, setPage] = useState(2);
  const [credits, setCredits] = useState({
    creditsConso: "",
    mensualitesConso: "",
    restantConso: "",
    creditsSupp: "",
    creditsSuppVal: ""
  })
  function singleSelect(e, cl) {
    let doc = document.querySelectorAll(cl);
    if (!credits[e.target.id] || credits[e.target.id] !== e.target.innerText) {
      setCredits({ ...credits, [e.target.id]: e.target.innerText });
      for (let i = 0; i < doc.length; i++) {
        doc[i].classList.remove("selected");
      }
      e.target.classList.add("selected");
    } else {
      setCredits({ ...credits, [e.target.id]: "" });
      e.target.classList.remove("selected");
    }
  }



  useLayoutEffect(() => {
    let creditsConso = document.querySelectorAll(".creditsConso");
    let creditsSupp = document.querySelectorAll(".creditsSupp");

    if (credits.creditsConso && creditsConso) {
      for (let i = 0; i < creditsConso.length; i++) {
        if (creditsConso[i].innerText === credits.creditsConso)
          creditsConso[i].classList.add("selected");
      }
    }
    if (credits.creditsSupp && creditsSupp) {
      for (let i = 0; i < creditsSupp.length; i++) {
        if (creditsSupp[i].innerText === credits.creditsSupp)
          creditsSupp[i].classList.add("selected");
      }
    }


  }, [credits, page]);

  useEffect(() => {
    window.scrollTo(0, 160);
  }, [page]);

  const normalizeAgeNumber = (value) => {
    return value.replace(/\D/gi, "") || "";
  };


  return (
    <>
      {page === 1 && (
        <div className="simulateur-nationalite">
          <span>Combien de crédits consommation avez-vous ?</span>
          <div>
            <div
              className="creditsConso"
              onClick={(e) => singleSelect(e, ".creditsConso")}
              id="creditsConso"
            >
              0
            </div>
            <div
              className="creditsConso"
              onClick={(e) => singleSelect(e, ".creditsConso")}
              id="creditsConso"
            >
              1
            </div>
            <div
              className="creditsConso"
              onClick={(e) => singleSelect(e, ".creditsConso")}
              id="creditsConso"
            >
              2
            </div>
            <div
              className="creditsConso"
              onClick={(e) => singleSelect(e, ".creditsConso")}
              id="creditsConso"
            >
              3
            </div>
            <div
              className="creditsConso"
              onClick={(e) => singleSelect(e, ".creditsConso")}
              id="creditsConso"
            >
              4
            </div>
            <div
              className="creditsConso"
              onClick={(e) => singleSelect(e, ".creditsConso")}
              id="creditsConso"
            >
              5+
            </div>
          </div>
          <div className="button-simulateur">
            <PrevSimulationStep props={{
              setPage, prevPage: 1, anotherOperation: function () {
                setSection("mon logement");
                setProgress(60);
              }
            }} />
            <NextSimulationStepButton disabledCond={!credits.creditsConso}
              onClick={() => {
                switch (credits.creditsConso) {
                  case "0":
                    setSection("mes revenus");
                    setProgress(100);
                    setData(data => {
                      return {
                        ...data, creditsConso: "0",
                        mensualitesConso: "",
                        restantConso: "",
                        creditsSupp: ""
                      }
                    })
                    break;

                  default:
                    setPage(2)
                    break;
                }
              }}
            />
          </div>
        </div>
      )
      }

      {
        page === 2 && (
          <div className="loyer-wrap">
            <span>
              Montant de vos mensualités de crédit consommation
              <span style={{ color: "	#3c3c3c", fontSize: 13, display: "block" }}>
                Total de vos mensualités de crédit conso
              </span>
            </span>
            <div className="simulateur-age loyer">

              <div>
                <input
                  // ref={ageRef}
                  value={credits.mensualitesConso}
                  type="tel"
                  autoFocus
                  maxLength="4"
                  placeholder="200"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(
                      e.target.value
                    );
                    setCredits({ ...credits, mensualitesConso: e.target.value });
                  }}
                />
              </div>
              <span>€</span>
            </div>
            <div className="button-simulateur">
              <button
                style={{ backgroundColor: "#2e74b9" }}
                onClick={() => {
                  setSection("mon logement");
                  setProgress(60);
                }}
              >
                <i className="fas fa-chevron-left"></i>
                <span>Précédent</span>
              </button>
              {/* <PrevSimulationStep props={{ setPage, prevPage: 1 }} /> */}
              <NextSimulationStepButton disabledCond={!credits.mensualitesConso}
                onClick={() => {
                  setPage(3)
                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 3 && (
          <div className="loyer-wrap">
            <span>
              Total restant dû
            </span>
            <div className="simulateur-age loyer bien">

              <div>
                <input
                  // ref={ageRef}
                  value={credits.restantConso}
                  type="tel"
                  autoFocus
                  maxLength="6"
                  placeholder="3000"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(
                      e.target.value
                    );
                    setCredits({ ...credits, restantConso: e.target.value });
                  }}
                />
              </div>
              <span>€</span>
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 2 }} />
              <NextSimulationStepButton disabledCond={!credits.restantConso}
                onClick={() => {
                  setPage(4)
                }}
              />
            </div>
          </div >
        )
      }

      {
        page === 4 && (
          <div className="simulateur-nationalite">
            <span>Avez-vous besoin d’un crédit supplémentaire ?</span>
            <div>
              <div
                className="creditsSupp"
                onClick={(e) => singleSelect(e, ".creditsSupp")}
                id="creditsSupp"
              >
                Oui
              </div>
              <div
                className="creditsSupp"
                onClick={(e) => singleSelect(e, ".creditsSupp")}
                id="creditsSupp"
              >
                Non
              </div>

            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
              <NextSimulationStepButton disabledCond={!credits.creditsSupp}
                onClick={() => {
                  switch (credits.creditsSupp) {
                    case "Oui":
                      setPage(4.1)
                      break;

                    default:
                      setSection("mes revenus");
                      setProgress(100);
                      setData(data => { return { ...data, ...credits } })
                      break;
                  }
                }}
              />
            </div>
          </div >
        )
      }

      {
        page === 4.1 && (
          <div className="loyer-wrap">
            <span>
              Quel montant souhaitez-vous emprunter ?
            </span>
            <div className="simulateur-age loyer bien">

              <div>
                <input
                  // ref={ageRef}
                  value={credits.creditsSuppVal}
                  type="tel"
                  autoFocus
                  maxLength="5"
                  placeholder="10 000"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(
                      e.target.value
                    );
                    setCredits({ ...credits, creditsSuppVal: e.target.value });
                  }}
                />
              </div>
              <span>€</span>
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 4 }} />
              <NextSimulationStepButton disabledCond={!credits.creditsSuppVal}
                onClick={() => {
                  setSection("mes revenus");
                  setProgress(100);
                  setData(data => { return { ...data, ...credits } })
                }}
              />
            </div>
          </div >
        )
      }
    </>
  )
}

export default Credits
