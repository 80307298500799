export function AE() {
  return (
    <>
      <div className="caf-wrapper">
        <p>
          L’aide énergétique est une aide financière qui permet de voir le coût de vos travaux diminuer. Elle est destinée aux travaux de rénovation des installations énergétiques de votre logement (chaudière, isolation thermique du logement, etc.). Si vous souhaitez donc entreprendre de tels travaux, vous pourrez sans doute bénéficier d’un coup de pouce financier.
        </p>
        <p>
          Plusieurs aides énergétique permettent de financer vos travaux de nature énergétique. En revanche vous devrez respecter certaines conditions pour être éligible.
        </p>
        <h3>Critères d’attribution</h3>
        <span>
          Cette aide s’adresse aux :
          <ul>
            <li>
              propriétaires occupants, bailleurs ou syndicats de propriétaires
            </li>
            <li>
              locataires et occupants à titre gratuit
            </li>
            <li>
              sociétés civiles immobilières.
            </li>
          </ul>
        </span>
        <p>
          L'equipe France Assist, vous accompagne dans toutes vos démarches en souscrivant à notre offre
        </p>

      </div>
    </>
  )
}
