export function g_Aeeh() {
  return (
    <>
      <div className="caf-wrapper">
        <p>
          L’allocation d’Éducation de l’Enfant Handicapé (AEEH) est une prestation de la Caisse d’Allocations familiales (CAF) ou de la Mutualité Sociale Agricole (MSA), destinée à diminuer les frais d’éducation et de soins apportés à un enfant en situation de handicap. Elle vous est versée quand vous assumez la charge de cet enfant.
        </p>
        <h1>Pouvez-vous bénéficier de l’AEEH ?</h1>
        <p>
          L’allocation d’éducation de l’enfant handicapé est versée aux personnes (parents en couple ou parent isolé) qui ont la charge d’un enfant ou d’un adolescent âgé de moins de 20 ans.
        </p>
        <p>
          Des critères médicaux définis sont requis pour que l’enfant handicapé puisse prétendre à l’AEEH de base. L’enfant doit, en effet, présenter un taux d’incapacité permanente.
        </p>
        <span>
          Pour toucher l’AEEH, l’enfant handicapé de moins de 20 ans doit :
          <ul>
            <li>
              soit être atteint d’un taux d’incapacité supérieur ou égal à 80%
            </li>
            <li>
              soit présenter un taux d’incapacité compris entre 50 et 79%, s’il fréquente un établissement d’enseignement adapté, ou si son état exige le recours à un dispositif adapté ou à des soins indiqués dans la décision de la CDAPH
            </li>
          </ul>
        </span>

      </div>
    </>
  )
}
