import { withoutAuth } from "../hocs/withoutAuth";

const CGUV = () => {
    return <div className="legality">
        <section className='cguv'>
            <h1>
                CONDITIONS GÉNÉRALES D’UTILISATION ET DE VENTE(CGV)
            </h1>


            <h3>ARTICLE 1. DEFINITIONS</h3>
            <b> Abonnement : </b>désigne l’abonnement souscrit par le Client aux Services.
            <b> Abonné : </b>désigne un utilisateur d’un ou plusieurs Services ayant valablement souscrit un Abonnement à un ou plusieurs Services.
            <b> Contrat d’Abonnement» : </b>désigne le document signé par le Client, précisant les contours de l’offre France Assist à laquelle il souscrit, complétant les Conditions Générales.

            <b> Hébergement :</b> désigne l’hébergement de la Plateforme sur des serveurs externes.

            <b> Plateforme» : </b>désigne l’application Web permettant d'accéder à une partie des Services offerts par France Assist, accessible par Internet.

            <b> Services : </b> désigne l’accompagnement offert par France Assist dans le cadre de l’Abonnement, accessible notamment via la Plateforme, et détaillé à l'Article 5 des présentes Conditions Générales.


            <h3>ARTICLE 2. ACCÈS, INSCRIPTION ET ABONNEMENT</h3>
            <b>2.1. Procédure et conditions d’accès et d’inscription aux Services</b>
            L’inscription aux Services est Payante.
            L’acceptation des Conditions d’Utilisation lors de l’inscription formalise la conclusion du contrat d’adhésion aux Services. Elle permet de devenir Client, de découvrir les Services et donne accès aux fonctionnalités des Services décrites à l’article 4.1 ci-après.
            Les Services sont exclusivement destinés aux citoyens résident sur le territoire français.
            Si un Abonné n’est pas sur le territoire, il n’est pas autorisé à souscrire aux Services et doit immédiatement faire une demande de désabonnement.
            En souscrivant et en utilisant les Services, les Abonnés déclarent et certifient ce qui suit :
            <br />•	ils sont sur le territoire français.
            <br />•	ils remplissent les conditions d’inscription énoncées pour les Services concernés à tout moment.
            <br />•	ils se conforment aux présentes Conditions d’utilisation et à toutes les lois et réglementations applicables.
            <br />•	ils fournissent des informations correctes, exactes et véridiques ne prêtant pas à confusion. En particulier, ils doivent être sincères et honnêtes dans les réponses qu’ils fournissent lors de leur simulation gratuite.
            Ils mettent à jour, rapidement leur profil dans le cas où leur situation personnelle change.
            <br />•	ils ne se sont pas vus interdits d’utiliser les Services en vertu des lois applicables.
            France Assist se réserve le droit de suspendre ou résilier l’abonnement à tout moment si un Abonné ne remplit aucune de ces conditions.
            Il est précisé qu’un Abonné ne peut pas souscrire plusieurs fois avec une même adresse email.

            Lorsque les conditions nécessaires à la souscription sont remplies, chaque Membre dispose d’un identifiant et d’un mot de passe strictement confidentiel et personnel lui permettant d’accéder à son espace.
            l’Abonné est responsable de l’utilisation des éléments d’identification par des tiers ou des actions ou déclarations faites par l’intermédiaire de son Compte, qu’elles soient frauduleuses ou non, et garantit France Assist contre toute demande à ce titre, sauf en cas de faute exclusivement imputable à France Assist ou de défaillance technique du Services. Par ailleurs, France Assist n’a pas pour obligation et ne s’assure pas de l’identité des personnes s’inscrivant aux Services. Si l’Abonné a des raisons de penser qu’une personne utilise ses éléments d’identification ou son Compte, il devra en informer immédiatement France Assist.

            <b>2.2. Procédure et conditions d’abonnement</b>
            L’Abonnement aux Services, est payant. L’abonné a la possibilité de souscrire un Abonnement et, ainsi, de devenir Abonné et bénéficier des fonctionnalités propres au type d’Abonnement choisi.
            Pour ce faire, L’abonné doit préalablement s’identifier à l’aide de son adresse email et de son mot de passe confidentiel. Après avoir fait sa souscription et son moyen de paiement, l’abonné doit valider son paiement. Cette dernière étape formalise la conclusion du contrat d’abonnement aux Services Payants et l’acceptation sans réserve de l’intégralité des Conditions d’Utilisation. Une fois le paiement validé, l’Abonné est dirigé vers son espace client et reçoit par courrier électronique son reçu de paiement confirmant sa souscription  et un mail de Bienvenue.


            <h3>ARTICLE 3. DROIT DE RÉTRACTATION</h3>
            Conformément à l’article L.221-18 du Code de la Consommation, l’Abonné est informé qu’il dispose d’un délai de quatorze jours à compter du jour de la souscription d’un Abonnement pour exercer son droit de rétractation, sans pénalité et sans motif, en accédant dans son espace client, un bouton spécifique est disponible simplement « demande de résiliation » puis il sera recontacté par courrier électronique  par le service de résiliation à qui il notifiera sa rétractation.
            Dans tous les cas, l’accès immédiat aux Services ne prive pas l’Abonné de son droit de rétractation, qui continue à courir jusqu’à l’expiration du délai de quatorze jours. Toutefois, l’Abonné ayant demandé à bénéficier des Services Payants avant la fin du délai de rétractation devra payer un montant proportionnel à son utilisation des Services. France Assist remboursera alors à l’Abonné le prix de l’Abonnement déduction faite du montant correspondant au nombre de jours écoulés entre le jour de la première utilisation de l’Abonnement et celui de la réception par France Assist de la demande de rétractation, étant précisé que tout jour débuté est dû.
            Le remboursement interviendra dans un délai de quatorze jours à compter de la date à laquelle France Assist a été informée de la décision de l’Abonné de se rétracter, par le même moyen de paiement que celui utilisé par l’Abonné pour la transaction initiale, sauf instruction contraire de ce dernier.


            <h3>ARTICLE 4. FONCTIONNALITÉS DES SERVICES</h3>
            La souscription d’un Abonnement donne accès à l’abonné à un conseiller attitré en charge de son suivi et du traitement de ses dossiers. Le cas échéant, des services  complémentaires peuvent êtres souscrits. Ces services sont décrits sur le Site notamment nos offres à la tâche.

            <h3>ARTICLE 5. SERVICES</h3>
            5.1 Descriptions des services

            Selon l’offre souscrite par le Client, les Services peuvent comprendre :
            <br />•	Un droit d'accès et d’utilisation de la Plateforme ;
            <br />•	Un simulateur d’aides et avantages, incluant un système permettant de renseigner les informations du Bénéficiaire et de son foyer, et un algorithme faisant ressortir les aides et avantages auxquelles le Bénéficiaire (ou son foyer) est éligible.
            <br />•	Un accès à des fiches descriptives de chaque aide et avantage, ainsi que des démarches à réaliser pour les obtenir.
            <br />•	Un système de pré-remplissage automatique des formulaires rattachés aux aides ou avantages.
            <br />•	Un espace de stockage de documents, permettant de conserver les documents administratifs du Bénéficiaire et de son foyer.
            <br />•	Des alertes emails informant le Bénéficiaire des nouvelles aides et nouveaux avantages auxquels il pourrait être éligible.
            <br />•	Des Webinaires de formation à l’utilisation de la plateforme, et incluant une session de question-réponses avec l’équipe France Assist, disponibles durant toute la durée de l’abonnement sur une base mensuelle.
            <br />•	Un service d’assistance
            En utilisant le Service, les Abonnés doivent respecter à tout moment les règles de conduite suivantes et conviennent de ne pas :
            <br />•	Offrir en aucune circonstance à titre onéreux l’accès à la Plateforme ou aux Services à des tiers, ni à vendre, de quelque manière que ce soit, des informations obtenues via ces Services ;
            <br />•	A utiliser rigoureusement la Plateforme et les Services, que pour ses besoins propres ;
            <br />•	 porter atteinte aux droits de propriété intellectuelle détenus par France Assist sur la Plateforme ou sur les Services ;
            <br />•	reconstituer ou tenter de reconstituer, à partir de la Plateforme et/ou au moyen des Services, une base de données visant à offrir directement ou indirectement, à titre gratuit ou onéreux, le même service ou un service comparable, à des personnes non autorisées, et/ou diffuser ou vendre, de quelque manière que ce soit, des informations aux fins d'aider une personne à reconstituer, en tout ou partie, une telle base de données ou un service équivalent ;
            <br />•	Divulguer la confidentialité de son (ses) code(s) d'accès et prendre les mesures nécessaires pour qu'aucun tiers, en quelque qualité que ce soit, n'ait accès à son(ses) code(s) d'accès et/ou à son(ses) mot(s) de passe et puisse ainsi accéder illicitement aux Services ;
            <br />•
            <br />•	Le Client s’engage à faire respecter l’étendue de ces obligations par tous les Utilisateurs autorisés.

            <b>5.2 Accès aux services</b>

            France Assist va  s’engager à fournir aux Utilisateurs un accès et un droit d’utilisation non exclusif et non transmissible de la Plateforme, pendant la durée de l’Abonnement et conformément à ses termes et conditions.
            Chaque Utilisateur est responsable de la création et de la conservation / réinitialisation de son mot de passe.
            France Assist peut transmettre par courriel aux Utilisateurs, toutes les communications relatives à la Plateforme.

            <h3>ARTICLE 6. PRIX, MODALITÉS DE PAIEMENT ET DE RENOUVELLEMENT</h3>
            <b>6.1. Prix et modalités de paiement des Services Payants</b>
            Les prix et les modalités de paiement sont décrits sur le Site. Le paiement s’effectue soit par carte bancaire (sur internet ou sur un service mobile), soit au moyen d’un service de paiement en ligne (PayPal, Direct Debit etc.) selon les options proposées sur le Site concerné. Les prix sont indiqués en Euros TTC (toutes taxes comprises).
            Vous pouvez mettre à jour votre mode de paiement à tout moment. France Assist peut également les mettre à jour sur la base d’informations directement fournies par le prestataire de service de paiement. En acceptant les Conditions d’Utilisation, vous autorisez France Assist à continuer de percevoir le paiement pour chaque renouvellement d’abonnement à la suite de toute mise à jour de votre mode de paiement.
            Le prix de l’Abonnement pourra être révisé à tout moment. A défaut de réponse de la part du Client avant l’entrée en vigueur de la révision tarifaire lors de l’échéance de son Abonnement, le Client sera réputé l’avoir acceptée. En cas de refus exprès formulé par le Client avant l’entrée en vigueur de la révision tarifaire, le Client aura la possibilité de résilier l’Abonnement.

            <b>6.2 Modalités de règlement</b>

            Le paiement de l’Abonnement s’effectue mensuellement, soit par prélèvement automatique SEPA, soit par virement, excepté dans les cas pour lesquels un autre mode de paiement est prévu par le Contrat d’Abonnement.

            Pour les paiements par prélèvement automatique, France Assist utilisera le service de prélèvement sécurisé Stripe. Chaque prélèvement automatique via Stripe sera effectué à la date d'émission de la facture.
            Les factures pourront être émises dès la date du début de l’abonnement et aux dates de renouvellement (mensuellement).
            Les factures seront adressées exclusivement sous format électronique par France Assist au Client.

            6.3. Modalités de renouvellement de l’Abonnement
            Notre accompagne est conclus pour une durée indéterminée avec une clause de reconduction tacite et l'abonnement au service d'aide administrative de France Assit est résiliable à tout moment, sans frais.


            <h3>Article 7. ASSISTANCE UTILISATEURS</h3>
            Pour une assistance technique, l’Utilisateur pourra contacter France Assist :

            <br />•	par courriel à l’adresse suivante :  contact@france-assist.fr
            <br />•	au moyen du module de messagerie instantanée, accessibles depuis la Plateforme ;
            <br />•	ou au moyen du formulaire de contact sur le site Internet de France Assist.

            Tout problème lié à l'accès à Internet ou la configuration du matériel de l’utilisateur n'est pas couvert par l’assistance utilisateurs.



            <h3>ARTICLE 8. PROTECTION DES DONNEES PERSONNELLES</h3>

            La protection des données personnelles est une priorité pour France Assist.

            France Assist respecte les exigences du Règlement Général européen sur la Protection des Données Personnelles (Règlement nᵒ 2016/679, dit « RGPD ») et assure la sécurité et la confidentialité des données des Utilisateurs.
            En particulier, France Assist prend toutes les mesures nécessaires afin d’assurer la confidentialité et la sécurité des Données Personnelles dans le cadre de l’accès aux Services. Ces Données Personnelles ne seront utilisées que pour les besoins des Services.
            France Assist ne vend, ni ne communique, aucune Donnée Personnelle à des fins commerciales ou marketing à des tiers.
            Nous invitons à consulter attentivement les termes de notre Politique de confidentialité, afin d’en savoir plus sur la gestion de vos données personnelles :


            <h3>ARTICLE 9. PROPRIÉTÉ INTELLECTUELLE</h3>
            9.1. Contenus diffusés sur les Services
            Les dénominations des Services, les marques, les logos, les graphismes, les photographies, les animations, les vidéos et les textes contenus sur les Sites et dans les Services sont la propriété exclusive de France Assist, et le cas échéant de ses partenaires et ne peuvent être reproduits, utilisés ou représentés sans l’autorisation expresse, sous peine de poursuites judiciaires.
            Les droits d’utilisation concédés au à l’Abonné sont réservés à un usage privé et personnel dans le cadre et pour la durée de l’inscription aux Services. Toute autre utilisation par le Membre ou l’Abonné est interdite.
            l’Abonné s’interdit notamment de modifier, copier, reproduire, télécharger, diffuser, transmettre, exploiter commercialement et/ou distribuer de quelque façon que ce soit les Services, les pages des Sites, ou les codes informatiques des éléments composant les Services et les Sites, sous peine de poursuites judiciaires.

            <h3>ARTICLE 10. LOI APPLICABLE ET DIFFÉRENDS</h3>
            Les Conditions Générales sont régies par le droit français.
            En cas de différend, le Client et France Assist conviennent de rechercher une solution amiable. A cet effet, la partie s’estimant fondée à faire valoir un droit adressera un courrier recommandé avec avis de réception à l’autre partie.









        </section>
    </div>
}

export default withoutAuth(CGUV);