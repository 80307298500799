export const S_I_PAJE =
  <>
    <div>
      <p>
        La Prestation d’Accueil du Jeune Enfant (PAJE) est une aide sociale, faisant partie du dispositif d’allocations familiales de la Caisse d’Allocations Familiales (CAF) ou de la Mutualité Sociale Agricole (MSA). Cette aide allège vos dépenses destinées à l’entretien et l’éducation de votre jeune enfant.
      </p>
      <h3>
        Pouvez-vous bénéficier de l’allocation de base de la PAJE ?
      </h3>
      <p>
        Pour prétendre à l’allocation de base de la PAJE, vous devez avoir à votre charge un enfant de moins de 3 ans, et vos ressources ne doivent pas dépasser un certain plafond.
      </p>
      <span>
        Si vous vivez en couple, les plafonds des ressources annuelles à ne pas dépasser sont les suivants
      </span>
      <table>
        <tbody>
          <tr>
            <td>
              <p>
                <b>
                  Nombre d’enfants à charge
                </b>
              </p>
            </td>
            <td>
              <p>
                <b>Taux plein</b>
              </p>
            </td>
            <td>
              <p>
                <b>Taux partiel</b>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <b>1 enfant</b>
              </p>
            </td>
            <td>
              <p>171,91 €</p>
            </td>
            <td>
              <p>
                85,95 €
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <b>
                  2 enfants
                </b>
              </p>
            </td>
            <td>
              <p>515,73 €</p>
            </td>
            <td>
              <p>171,90 €</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <b>
                  3 enfants
                </b>
              </p>
            </td>
            <td>
              <p>515,22 €</p>
            </td>
            <td>
              <p>257,85 €</p>
            </td>
          </tr>
        </tbody>
      </table>
      <span>
        Le montant de la prime est :
        <ul>
          <li>
            prime à la naissance (PN) : 948,27 euros par enfant. En cas de naissances multiples, la CAF ou la MSA versent autant de primes que de bébés nés (jumeaux : 1.896,54 euros, triplés : 2.844,81 euros, etc.)
          </li>
          <li>
            prime à l’adoption (PA) : 1.896,52 euros par enfant adopté ou accueilli en vue d’adoption
          </li>
        </ul>
        En cas d’adoption, les plafonds de ressources sont identiques à ceux applicables à une naissance.
      </span>

    </div>
  </>
