export function g_Paje() {
  return (
    <>
      <div className="caf-wrapper">

        <p>
          La prestation d’accueil du jeune enfant (PAJE) est un dispositif comprenant plusieurs aides destinées aux parents d’un enfant venant de naître : prime à la naissance ou à l’adoption
        </p>
        <h1>Que comprend la prestation d’accueil du jeune enfant ?</h1>
        <span>
          La prestation d’accueil du jeune enfant permettent de faire face aux dépenses ou de compenser une perte de revenus professionnels :
          <ul>
            <li>
              La prime à la naissance ou à l'adoption permet de faire face aux premières dépenses liées à la naissance ou à l'adoption d'un enfant et à son entretien.
            </li>
            <li>
              L'allocation de base en cas de naissance ou en cas d'adoption a pour but de faire face aux dépenses liées à la naissance ou à l'adoption d'un enfant, à son entretien et à son éducation.
            </li>
            <li>
              La prestation partagée d'éducation de l'enfant (PréParE) permet à l'un ou aux 2 parents de réduire ou de cesser leur activité professionnelle pour s'occuper de leur enfant.
            </li>
            <li>
              Le complément de libre choix du mode de garde permet aux parents qui continuent à travailler de compenser le coût de la garde d'un enfant en cas d'emploi d'une assistante maternelle, d'une garde à domicile ou en micro-crèche.
            </li>
          </ul>
        </span>

      </div>
    </>
  )
}

