import { useParams } from "react-router-dom";
import UpdateProfil from "../RoutesSubComponents/dashboard/profil/update";
import { useAuth } from "../contexts/auth";
import { withAuth } from "../hocs/withAuth";

const Profil = () => {
    const { action } = useParams();
    const { user } = useAuth();
    const authedInfos = user

    return <div className="profil">
        {
            (() => {
                if (action === 'modification') {
                    return <UpdateProfil authedInfos={authedInfos} />
                }
            })()
        }
    </div>
}

export default withAuth(Profil);