import { AuthedNav, GeneralNav } from "../GlobalComponents/navigation/navbar";
import { useAuth } from "../contexts/auth";
import { Footer } from "../GlobalComponents/footer";
import { AppFullScreenSpinner } from "../uikits/others";


export const bothAuth = (WrappedComponent) => {
    return (props) => {
        const { user, userLoading } = useAuth();

        if (userLoading)
            return <AppFullScreenSpinner />;

        if (user) {
            return <>
                <AuthedNav user={user} />
                <main>
                    <WrappedComponent {...props} />
                </main>
            </>
        }

        return <>
            <GeneralNav />
            <main>
                <WrappedComponent {...props} />
            </main>
            <Footer />
        </>
    };
};