import { contactFields } from '../helper/form/fields';
import axios from "axios"
import { allSocialLinks } from '../Raw/socialLinks';
import { IonIosMail, IonIosTelephone } from '../GlobalComponents/icons';
import { withoutAuth } from '../hocs/withoutAuth';
import { Form, FormikProvider, useFormik } from 'formik';
import FormikFieldController from "../uikits/form/index"
import { FormButton } from '../uikits/buttons';
import { ContactSchema } from '../helper/form/validations';
import { adminApiBaseUrl, successToast } from '../helper/others';

const Contact = () => {
    const formik = useFormik({
        initialValues: {
            username: "",
            telephone: "",
            email: "",
            message: ""
        },
        validateOnMount: true,
        validationSchema: ContactSchema,
        onSubmit: handleSubmit
    })
    const { isValid } = formik;
    function handleSubmit(formValues) {
        axios.post(adminApiBaseUrl + '/form/contact/add', formValues)
            .then(res => {
                successToast("Merci chers utilisateur,Votre message a bien été envoyé," +
                    "l'equipe France-assist vous recontactera dans les plus bref delai")
            })
            .catch(err => console.log(err));
    }


    return <>
        {/* <StealerAlert /> */}
        <div className="contact">
            <section className='c-ourInfos'>
                <h2>Pour nous contacter</h2>
                <br />
                <h4>
                    Si vous souhaitez en savoir plus sur France Assist, c’est ici !
                </h4>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px 0" }}>
                    <p className='withIcon'>
                        <IonIosMail /> contact@france-assist.fr
                    </p>
                    <p className='withIcon'>
                        <IonIosTelephone />{process.env.REACT_APP_CONTACT_PHONE}
                    </p>
                </div>

                <article>
                    {
                        allSocialLinks.map((sml, index) => <a href={sml.link} className='socialIcon'
                            style={{ backgroundColor: 'white', color: 'var(--colorPrimary)' }}
                            key={"contact social media link nb" + index}
                            target={'_blank'}>
                            {sml.icon}
                        </a>)
                    }
                </article>
            </section>


            <section className='cSendMessageForm'>
                <h2>Dites nous quelque chose !</h2>
                <FormikProvider value={formik}>
                    <Form>
                        {
                            contactFields.map((item, i) => <FormikFieldController {...item}
                                key={'contact field nb' + i}
                            />)
                        }
                        <FormButton
                            text="J'envois mon message"
                            isValid={isValid}
                        />
                    </Form>
                </FormikProvider>
            </section>
        </div>
    </>
}


export default withoutAuth(Contact);