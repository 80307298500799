import { AuthedNav } from "../GlobalComponents/navigation/navbar";
import { useAuth } from "../contexts/auth";
import { Navigate } from "react-router-dom";
import { AppFullScreenSpinner } from "../uikits/others";

export const withAuth = (WrappedComponent, specificRole = false) => {
    return (props) => {
        const { user, userLoading } = useAuth();

        if (userLoading)
            return <AppFullScreenSpinner />;

        if (!user) {
            return <Navigate to='/Connexion' />;
        }
        return <>
            <AuthedNav user={user} />
            <main>
                <WrappedComponent {...props} />
            </main>
        </>
    };
};
