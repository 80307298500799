import { IonLogoInstagram, IonSocialFacebookOutline, IonSocialLinkedinOutline, IonSocialTwitterOutline, IonSocialYoutubeOutline } from "../GlobalComponents/icons";

export const allSocialLinks = [
    {
        label: '', icon: <IonSocialFacebookOutline />,
        link: "https://www.facebook.com/France-Assist-112422681101187"
    },
    {
        label: '', icon: <IonSocialYoutubeOutline />,
        link: "https://www.youtube.com/channel/UCMnnS8vlzb24q1aMhzzNdMQ"
    },
    {
        label: '', icon: <IonSocialLinkedinOutline />,
        link: "https://www.linkedin.com/company/franceassist/?viewAsMember=true"
    },
    {
        label: '', icon: <IonLogoInstagram />,
        link: "https://instagram.com/france_assist?utm_medium=copy_link"
    },
    {
        label: '', icon: <IonSocialTwitterOutline />,
        link: "https://twitter.com/assist_france?s=08"
    },

]