import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Login from "./auth/login";
import Signup from "./auth/signup";


export const AuthForm = ({ authType }) => {

    const [formValues, setFormValues] = useState({
        nom: "", prenom: "",
        codepostal: "", telephone: "", email: '',
        carte_expiration_mois: "", carte_expiration_année: "",
        number: "", code_derriere_carte: "",
        password: '', password_confirmation: ''
    });

    return <section className="uaForm">
        <h2>{authType.toUpperCase()} </h2>
        {
            authType == 'inscription' ?
                <>
                    <Signup />
                </> : <Login props={{ formValues, setFormValues }} />
        }
        <div className="uafOtherAuth">
            {
                authType == 'connexion'
                    ? <>
                        <Link to="/password/mail">Mot de passe oublié ?</Link>
                        <Link to="/Inscription">Vous n'avez pas de compte ! Inscrivez vous .</Link>
                    </>
                    : <Link to="/Connexion">Vous avez un compte ! Connectez vous .</Link>
            }
        </div>
    </section>
}