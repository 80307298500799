import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { showMenuAndMoveMainAnimation } from '../../Tools/animations';
import { NavLogo, AuthLinks, SearchBar, ShowSubMenuLevel1 } from './otherForNav';
import { AuthedSideBar, GeneralSideBar } from './sidebar';
import {
    generalNavLinks, generalNavLinksWithSubMenu
} from '../../Raw/menuLinks';
import { useDispatch } from 'react-redux';
import { setModal } from '../../store/actions/modalActions';
import { IonAppsSharp, IonChevronForward, IonMdLogOut, IonMdMenu, IonMdSearch } from '../icons';
import { SimpleImage } from '../img/global';
import { useAuth } from '../../contexts/auth';


export const GeneralNav = () => {
    const dispatch = useDispatch();
    const [menuLinks, setML] = useState([]);
    const [menuWithSubMenuLinks, setMWSML] = useState([]);
    const [subMenuIndex, setSMI] = useState(0)
    const [subMenu2Visible, setSM2V] = useState(false)

    useEffect(() => {
        setML(generalNavLinks)
        setMWSML(generalNavLinksWithSubMenu);
    }, []);


    function transformIconNext(e) {
        e.currentTarget.nextElementSibling.classList.toggle('iconRotate')
    }
    function handleSubMenus(e, index) {
        setSMI(index)
        setSM2V((prev) => !prev);
        transformIconNext(e)
    }

    function onSubMenuLinkClick() {
        setSMI(0)
        setSM2V((prev) => !prev);
    }
    return <>
        <nav className='generalNav'>
            <NavLogo />

            <section className='gnNavLinks'>
                {menuLinks.filter((gnl) => gnl.subLinks.length === 0).map((link, index) => <div
                    key={'nav link' + index}>
                    {
                        <Link to={link.link} >{link.label}</Link>
                    }
                </div>)}
            </section>
            <section className='gnSearchBar'>
                <span onClick={(event) => {
                    dispatch(setModal(<SearchBar />))
                }}>
                    <IonMdSearch />
                </span>
            </section>

            <AuthLinks />
            <section className='gn-linkWithSubLinks'>
                {
                    menuWithSubMenuLinks.map((item, i1) => <div key={'categorie item' + i1} className='withIcon'>
                        <b onClick={(e) => handleSubMenus(e, i1)}>{item.label} </b>
                        <IonChevronForward />
                    </div>
                    )
                }
                <ShowSubMenuLevel1 subMenuIndex={subMenuIndex} subMenu2Visible={subMenu2Visible}
                    subMenusLinks={menuWithSubMenuLinks} onSubMenuLinkClick={onSubMenuLinkClick} />
            </section>
        </nav >
        <MobileGeneralNavAndSidebar />
    </>
}

export function AuthedNav({ user }) {
    const { logout } = useAuth();
    const [authSidebar, setAuthSidebar] = useState(true);
    const authedInfos = user;
    const userMentor = user.agentData;

    useEffect(() => {
        let theMain = document.querySelector('main');
        theMain.classList.toggle('mainAuthMoveRight')
    }, [authSidebar]);

    return <nav className='authedNav' >
        <AuthedSideBar setAuthSidebar={setAuthSidebar} authSidebar={authSidebar}
            authedMentor={userMentor}
            authedInfos={authedInfos} />

        <div className='an-logo'>
            <SimpleImage props={{
                src: 'logo.webp', alt: 'authed sidebar logo'
            }} />
        </div>
        <div className='an-sidebarToggler withIcon'
            onClick={() => {
                setAuthSidebar((prev) => !prev)
            }}>
            <span>
                <IonAppsSharp />
            </span>
        </div>
        <div className='an-logout' onClick={() => {
            logout()
        }}>
            <IonMdLogOut />
        </div>

    </nav>
}



function MobileGeneralNavAndSidebar() {
    return <>
        <GeneralSideBar />
        <nav className='mobileGeneralNav'>
            <NavLogo />
            <section className='mgn-hambuger' onClick={(event) => showMenuAndMoveMainAnimation()}>
                <IonMdMenu />
            </section>
        </nav>
    </>
}

