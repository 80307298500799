import { Link } from "react-router-dom"
import { SimpleImage } from "./img/global"
import { allSocialLinks } from "../Raw/socialLinks"
import { IonCall, IonMail } from "./icons"

export const Footer = () => {
    const usefulLinks = [
        { link: '/', label: "Accueil" },
        { link: '/Simulation-gratuite', label: "Simuler gratuitement" },
        { link: '/Connexion', label: "Se connecter" },
        { link: '/Inscription', label: "S'inscrire" },
        { link: '/Faq/géneral/2', label: "A propos" },
        { link: '/contact', label: "Contact" },
        { link: '/', label: "Mentions légales" },
        { link: '/Politique-de-confidentialite', label: "Politique de confidentialité" },
        { link: '/CGV', label: "CGV" },
    ]

    const contactLinks = [
        { icon: <IonCall />, link: 'tel:' + process.env.REACT_APP_CONTACT_PHONE, label: process.env.REACT_APP_CONTACT_PHONE },
        { icon: <IonMail />, link: 'mailto:contact@france-assist.fr', label: "contact@france-assist.fr" },
    ]

    return <footer className="footer">
        <section className="fLogo">
            <SimpleImage props={{ src: 'logo.webp', alt: 'footer logo', classname: '' }} />
        </section>

        <section className="fUsefulInks">
            <b>Liens utiles</b>
            <div>
                {
                    usefulLinks.map((ul, index) => <Link key={"footer link" + index}
                        to={ul.link}>{ul.label}</Link>)
                }
                <Link to={'/Programme-de-reference/description'}>Programme de référencement</Link>
            </div>
        </section>

        <section className="fContact">
            <b>Nous contacter</b>
            <div>
                {
                    contactLinks.map((cl, index) => <a href={cl.link}
                        key={"footer link" + index}>
                        {cl.icon}
                        <span>
                            {cl.label}
                        </span>
                    </a>)
                }
            </div>
        </section>


        <section className="fSocialLinks">
            <b>Suivez-nous sur :</b>
            <div>
                {
                    allSocialLinks.map((sl, index) => <a key={"footer link" + index}
                        href={sl.link} className='socialIcon' target={'_blank'}
                        style={{ backgroundColor: 'var(--colorPrimary)', color: 'white' }}>
                        {sl.icon}
                    </a>)
                }
            </div>

        </section>
    </footer>
}