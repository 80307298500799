export function g_Aed() {
  return (
    <>
      <div className="caf-wrapper">
        <p>
          L'aide spécifique permet d'apporter une aide financière personnalisée à l'étudiant en difficulté. Elle peut vous être versée si vous rencontrez des difficultés financières durables et que vous ne bénéficiez pas de la bourse d'enseignement sur critères sociaux.
        </p>
        <h1>Qui a droit à l'Allocation étudiant en difficulté ?</h1>
        <span>
          Vous pouvez bénéficier de l'aide annuelle si vous êtes dans l'une des situations suivantes :
          <ul>
            <li>
              Vous avez plus de 28 ans et vous reprenez vos études. Vous ne devez pas percevoir une autre aide (par exemple allocations chômage ou le RSA). Vos ressources doivent être inférieures au plafond prévu par le barème d'attribution des bourses sur critères sociaux.
            </li>
            <li>
              Vous êtes français ou citoyen d'un autre pays de l'Espace économique européen (EEE) ou suisse et résidez seul en France. Les revenus déclarés de votre famille résidant à l'étranger ne permettent pas d'apprécier votre droit à la bourse
            </li>
            <li>
              Vous êtes élevé par un membre de votre famille sans décision judiciaire (oncle, tante, grands-parents par exemple)
            </li>
            <li>
              Vous êtes en situation de rupture familiale (après évaluation sociale de votre situation d'isolement et de précarité)
            </li>
          </ul>
        </span>
      </div>
    </>
  )
}
