
export function g_Css() {
  return (
    <>
      <div className="caf-wrapper">
        <p>
          Vous avez de faibles ressources ? La complémentaire santé solidaire (CSS) vous aide pour vos dépenses de santé.
          <br />
          Elle permet de vous faire rembourser la part complémentaire de vos dépenses de santé. Cela signifie que cette aide rembourse ce qui n'est pas remboursé par l'Assurance Maladie.
        </p>
        <h1>À quoi sert la complémentaire santé solidaire ?</h1>
        <p>
          Grâce à la complémentaire santé solidaire, vos dépenses de santé sont remboursées entièrement, dans la limite des tarifs de la sécurité sociale.
        </p>
        <span>
          En plus de l'aide financière, vous avez droit à d'autres avantages :
          <ul>
            <li>
              Pas de dépassements d'honoraires sur les tarifs médicaux si vous respectez votre parcours de soins
            </li>
            <li>
              En cas d'hospitalisation, remboursement du forfait journalier. C'est le montant quotidien que vous devez normalement payer pour l'hébergement et la nourriture
            </li>
            <li>
              Pas de franchise médicale ou de participation forfaitaire de 1 € à payer
            </li>
            <li>
              Forfaits de remboursement pour des dispositifs médicaux comme un canne, un fauteuil roulant ou des pansements
            </li>
          </ul>
        </span>
        <h1>Qui est éligible ?</h1>
        <span>
          Pour bénéficier de la Complémentaire Santé Solidaire, il faut remplir 2 conditions :
          <br />
          <ul>
            <li>
              Être bénéficiaire de l’assurance maladie
            </li>
            <li>
              disposer de revenus modestes inférieurs à des plafonds revalorisés en principe chaque année.
            </li>
          </ul>
          Selon les revenus du foyer, le bénéficiaire a droit à une CSS avec prise en charge totale ou une CSS avec contribution financière mensuelle.
        </span>

      </div>
    </>
  )
}
