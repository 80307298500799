import { useNavigate } from "react-router";
import { useAuth } from "../contexts/auth";
import { GeneralNav } from "../GlobalComponents/navigation/navbar";
import { Footer } from "../GlobalComponents/footer";
import { Navigate } from "react-router-dom";
import { AppFullScreenSpinner } from "../uikits/others";

export const withoutAuth = (WrappedComponent) => {
    return (props) => {
        const { user, userLoading } = useAuth();
        const navigate = useNavigate();

        if (userLoading)
            return <AppFullScreenSpinner />
        if (user) {
            return <Navigate to='/Dashboard' />;
        }

        return <>
            <GeneralNav />
            <main>
                <WrappedComponent {...props} />
            </main>
            <Footer />
        </>
    };
};