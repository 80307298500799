import { useEffect, useState } from "react"
import axios from "axios";

const useAppReviews = () => {
    const [datas, setDatas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refetch, setRefecth] = useState(false);

    const apiUrl = process.env.NODE_ENV == 'production' ? 'https://dashboard.france-assist.fr' : 'http://localhost:5110';
    useEffect(async () => {
        try {
            const allDatas = await axios.get(apiUrl + '/avis/like-trustpilot/all')
            setDatas(allDatas.data);
        } catch (error) {

        } finally {
            setLoading(false)
        }
        return setRefecth(false)
    }, [refetch])


    const refetchDatas = () => {
        setRefecth(true)
    };

    return { reviews: datas, reviewsLoading: loading, refetchDatas };
}

export default useAppReviews;