import React from 'react'
import { EyeLight, ArrowRightDown, CommentDots, CalendarMonth } from '../../GlobalComponents/icons';
import { PageTitleType1 } from '../../GlobalComponents/OtherOnWebsite';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import moment from 'moment/moment';
import { AppSectionSpinner } from '../../uikits/others';

function BlogArticles() {
    const [articles, setArticles] = useState([]);
    const [articlesLoading, setAL] = useState(true);
    const apiUrl = process.env.NODE_ENV === 'production' ? 'https://dashboard.france-assist.fr' : 'http://localhost:5110';

    useEffect(() => {
        axios.get(apiUrl + '/admin/blog/article/list')
            .then(res => setArticles(res.data))
            .catch(err => console.log('blog err'))
            .finally(() => setAL(false))
    }, [])
    if (articlesLoading)
        return <>
            <AppSectionSpinner />
            <p style={{ textAlign: 'center' }}>Chargement des articles</p>
        </>
    return (
        <div>
            {
                articles.length === 0 ? <div className='dataListEmptySimpleMessage'>
                    <p>Aucun Article n'a encore été publié</p>
                </div> :
                    <>
                        <PageTitleType1 title={'Derniers articles publiés'} />
                        <div className="articles-container">
                            {articles.map((item, index) => (
                                ArticleCard(item, index)
                            ))}
                        </div>

                    </>
            }
        </div>
    )
}

export function ArticleCard(item, index) {
    return <a className='articleCard' href={'/Blog/article/' + item._id}
        key={"Article Similaire " + index + 1}>
        <div className="article-img">
            <img src={item.banner} alt="" />
            <span>
                <ArrowRightDown />
            </span>
        </div>
        <p className="article-author">{item.autorId.prenom + ' ' + item.autorId.nom}</p>
        <h4 className="article-title">{item.title}</h4>
        <p className="article-category">
            <span></span>
            <span>{item.categoryId.name}</span>
        </p>
        <p className="article-desc">{item.description}</p>
        <div className="article-information">
            <div>
                <span>
                    <EyeLight />
                </span>{" "}
                {item.views.length}
            </div>
            <div>
                <span>
                    <CommentDots />
                </span>{" "}
                0
            </div>
            <div>
                <span>
                    <CalendarMonth />
                </span>{" "}
                {moment(item.created_at).fromNow()}
            </div>
        </div>
    </a>;
}

export default BlogArticles