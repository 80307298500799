import React from 'react'
import { Form } from 'formik';
import { FormikProvider, useFormik } from 'formik';
import { updateProfiGeneraFields } from '../../../../helper/form/fields';
import FormikFieldController from '../../../../uikits/form';
import { FormButton } from '../../../../uikits/buttons';
import { UpdateGeneralInfoSchema } from '../../../../helper/form/validations';
import { useAuth } from '../../../../contexts/auth';
import axios from 'axios';
import { errorToast, successToast } from '../../../../helper/others';
function UpdateGeneral({ props }) {
    const { user, refetchUserLoggedData } = useAuth();

    const formik = useFormik({
        initialValues: {
            nom: user.nom, prenom: user.prenom,
            date_naissance: user.date_naissance,
            codepostal: user.codepostal, adresse: user.address,
            email: user.email, telephone: user.telephone,
            situation_familiale: user.situation_familiale,
            nom_conjoint: user.nom_conjoint, prenom_conjoint: user.prenom_conjoint,
            date_naissance_conjoint: user.date_naissance_conjoint,

            situation_professionnelle: {
                actif: user.situation_professionnelle.actif,
                apprenti: user.situation_professionnelle.apprenti,
                demandeur_emploi: user.situation_professionnelle.demandeur_emploi,
                en_situation_de_handicap: user.situation_professionnelle.en_situation_de_handicap,
                enceinte: user.situation_professionnelle.enceinte,
                etudiant: user.situation_professionnelle.etudiant,
                inapte_au_travail: user.situation_professionnelle.inapte_au_travail,
                retraite: user.situation_professionnelle.retraite
            },
            situation_professionnelle_conjoint: {
                actif: user.situation_professionnelle_conjoint.actif,
                apprenti: user.situation_professionnelle_conjoint.apprenti,
                demandeur_emploi: user.situation_professionnelle_conjoint.demandeur_emploi,
                en_situation_de_handicap: user.situation_professionnelle_conjoint.en_situation_de_handicap,
                enceinte: user.situation_professionnelle_conjoint.enceinte,
                etudiant: user.situation_professionnelle_conjoint.etudiant,
                inapte_au_travail: user.situation_professionnelle_conjoint.inapte_au_travail,
                retraite: user.situation_professionnelle_conjoint.retraite
            },
            currentPassword: '', password: '', password_confirmation: "",
        },
        validationSchema: UpdateGeneralInfoSchema,
        validateOnMount: true,
        onSubmit: handleSubmit
    });
    const { isValid } = formik;

    function handleSubmit(formValues) {
        const formData = new FormData();

        let dataToSet = {
            "mainPassword": "",
            "email": formValues.email,
            "nom": formValues.nom,
            "prenom": formValues.prenom,
            "date_naissance": formValues.date_naissance,
            "telephone": formValues.telephone,

            "address": formValues.adresse,
            "codepostal": formValues.codepostal,
            "situationFamiliale": formValues.situation_familiale,

            "situationProfessionnelle": formValues.situation_professionnelle,

            "avatar": {},
        }

        if (formValues.password && formValues.password_confirmation && formValues.password == formValues.password_confirmation) {
            dataToSet.newPassword = formValues.password
        }

        if (formValues.situationFamiliale != "célibataire") {
            dataToSet = {
                ...dataToSet,
                "nom_conjoint": formValues.nom_conjoint,
                "prenom_conjoint": formValues.prenom_conjoint,
                "date_naissance_conjoint": formValues.date_naissance_conjoint,
                "situationProfessionnelleConjoint": formValues.situation_professionnelle_conjoint,
            }
        }
        formData.append("info", JSON.stringify(dataToSet))
        formData.append("avatar", formValues.avatar);
        axios.put('/user/update-profile', formData)
            .then(res => {
                successToast('Modification enregistré avec succès !');
                refetchUserLoggedData()
            })
            .catch(err => errorToast('Une erreur est survenue pendant la modification !'))
    }
    return (
        <FormikProvider value={formik}>
            <Form>
                {
                    updateProfiGeneraFields.map((item, i) => <FormikFieldController
                        {...item}
                        key={"upg form item nb" + i}
                    />)
                }
                <FormButton
                    text="Sauvegarder"
                    isValid={isValid}
                />
            </Form>
        </FormikProvider>
    )
}

export default UpdateGeneral;