export const S_I_AAH =
  <>
    <div>
      <p>
        L'allocation aux adultes handicapés (AAH) est une aide financière
        qui vous permet d'avoir un minimum de ressources. Cette aide est
        attribuée sous réserve de respecter des critères d’incapacité,
        d'âge, de résidence et de ressources. Elle est accordée sur décision
        de la commission des droits et de l'autonomie des personnes
        handicapées (CDAPH). Son montant vient compléter vos éventuelles
        autres ressources.
      </p>
      <h3>Montant de l’AAH</h3>
      <span>
        En 2021, le montant maximal de l’AAH est de 902,70 € par mois. Vous
        pouvez prétendre à ce maximum si vous ne touchez aucune ressource.
        Sinon, la somme qui vous est attribuée varie selon plusieurs
        facteurs.
      </span>
      <span>
        De manière générale, on peut estimer son montant d’AAH en raisonnant
        ainsi :
        <br />
        Montant de l’AAH = Montant maximum d’AAH – moyenne mensuelle des
        ressources perçues sur 3 mois
      </span>
      <table>
        <tbody>
          <tr>
            <td>
              <p>
                <b>Conditions de ressources</b>
              </p>
            </td>
            <td>
              <p>
                <b>Grille AAH : montant maximal perçu</b>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <b>Aucune ressource</b>
              </p>
            </td>
            <td>
              <p>860 €</p>
            </td>
          </tr>
          <tr>
            <td>
              <p> <b>
                Pension ou Rente
              </b> </p>
            </td>
            <td>
              <p>Montant de la pension – 860€</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <b>
                  Travail en Ésat
                </b>
              </p>
            </td>
            <td>
              <p>Montant selon les revenus d’activité</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <b>
                  Hospitalisation, incarcération, hébergement en maison
                  d’accueil spécialisée
                </b>
              </p>
            </td>
            <td>
              <p>{"Durée < 60 jours = AAH à taux plein"}</p>
              <p>{"Durée > 60 jours = AAH réduit à 30%"}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <h3>L'AAH peut-elle être cumulée ?</h3>
      <p>
        Sous certaines conditions, il est possible de cumuler l’AAH avec un emploi. Le montant de l’AAH varie toutefois en fonction du montant des revenus professionnels touchés et du milieu dans lequel l’activité est exercée.
      </p>
      <span>
        Dans un milieu ordinaire, les revenus professionnels ne sont pas pris en compte pendant les 6 premiers mois où le montant de l’AAH sera perçu en intégralité. A l’issue de ces 6 mois, l’AAH est réduite :
        <ul>
          <li>Pour les personnes au salaire brut mensuel inférieur à 456,36€, le taux d’abattement de l’AAH est de 80%</li>
          <li>Pour un salaire brut mensuel de plus de 456,36€, le taux d’abattement de l’AAH est de 40%</li>
        </ul>
        Dans le cas d’une activité exercée dans un Établissement et service d’aide par le travail (Ésat), la rémunération perçue varie entre 55% et 110% du Smic horaire.
      </span>

    </div>
  </>
