import { SIMULATION } from "../actions/types";
const initialState = {
  simulation: {},
};
export default function simulation(state = initialState, action) {
  switch (action.type) {
    case SIMULATION:
      return {
        ...state,
        simulation: action.payload,
      };
    default:
      return state;
  }
}