import { Field, useField } from "formik";


export function InputRadioWithFormik(props) {
    const { name, choices, label, isDisabled, conditionalFieldName, conditionalValues } = props;
    const [field, meta, helpers] = useField(name);
    const { value } = meta;
    let parsedValue = ["true", "false"].includes(value) ? JSON.parse(value) : value;

    return <div className={"formSegment flex f-column"} >
        <label>{label}</label>
        <section className="fs-radio_checkbox-row f-wrap">
            {
                choices.map((ffrg, index) => <label
                    htmlFor={name + '-' + index}
                    className={"formFieldRadio2 "}
                    key={'ffrg ' + index}>

                    <Field type="radio" name={name} value={ffrg.value}
                        id={name + '-' + index}
                        checked={parsedValue === ffrg.value}
                        disabled={isDisabled ? true : false}
                    />
                    <span>  {ffrg.label}</span>
                </label>)
            }
        </section>
    </div >
}

export function InputCheckBoxWithFormik(props) {
    const { name, choices, label, conditionalFieldName, conditionalValues } = props;
    const [field, meta, helpers] = useField(name);
    const { value } = meta;

    return <div className={"formSegment flex f-column"} >
        <label>{label}</label>
        <section className="flex f-column">
            {
                choices.map((ffrg, index) => <article
                    className={"formFieldCheckbox "}
                    key={'ffrg ' + index}>

                    <Field type="checkbox" name={name} value={ffrg.value}
                        id={name + '-' + index}
                        checked={value.includes(ffrg.value)} />
                    <label htmlFor={name + '-' + index}
                        className="flex ">
                        <span>
                            {value.includes(ffrg.value) && 'x'}
                        </span>
                        <p>{ffrg.label}</p>
                    </label>
                </article>)
            }
        </section>
    </div>;
}

export function InputCheckBox2WithFormik(props) {
    const { name, choices, label, conditionalFieldName, conditionalValues } = props;
    const [field, meta, helpers] = useField(name);
    const { value } = meta;
    const { setValue } = helpers;

    function handleCheckboxChange(e, ffrg) {
        setValue({ ...value, [ffrg.value]: JSON.stringify(e.target.checked) })
    }

    return <div className={"formSegment formSegmentCheckbox flex f-column"} id="checkBoxSegment" >
        <label>{label}</label>
        <section className="flex f-column">
            {
                choices.map((ffrg, index) => <label
                    htmlFor={name + '-' + index}
                    className={"flex "}
                    key={'ffrg ' + index}>

                    <input type="checkbox" name={name} value={ffrg.value}
                        id={name + '-' + index}
                        checked={value[ffrg.value] == true || value[ffrg.value] == "true"}
                        // checked={value[ffrg.value]}
                        onChange={(e) => handleCheckboxChange(e, ffrg)} />

                    <span>{ffrg.label}</span>

                </label>)
            }
        </section>
    </div>
}


// export function InputSearchWithMultipleChoice(props) {
//     const { name, choices, searchKey, otherKey, label, searchPh, maxChoices } = props;
//     const [field, meta, helpers] = useField(name);
//     const { value } = meta;
//     const { setValue } = helpers;
//     const [searched, setSearched] = useState('');
//     const [newSearch, setNewSearch] = useState(false);
//     const [searchLoading, setSearchLoading] = useState(false);
//     const [searchResults, setSearchResults] = useState([]);

//     useEffect(() => {
//         if (searched) {
//             const filter = choices.filter(item => item[searchKey].toLowerCase().includes(searched.toLowerCase()));

//             if (filter && filter.length > 0) {
//                 setSearchResults(filter);
//                 setSearchLoading(false)
//             }
//         }
//     }, [searched]);


//     function handleNewSearch(e) {
//         const { value } = e.target;
//         setSearched(value);
//         setSearchLoading(true);
//     }

//     function handleNewChoice(currentValue) {
//         const copy = [...value];
//         copy.push(currentValue)
//         setValue(copy);
//         setNewSearch(false);
//         setSearchResults([])
//         setSearched('');
//     }

//     function handleDeleteChoice(index) {
//         const copy = [...value];
//         copy.splice(index, 1)
//         // console.log(copy);
//         setValue(copy)
//     }

//     return <div className={"inputSearchWithMultipleChoice flex f-column"} >
//         <label>
//             <b>{label}</b>
//         </label>

//         {
//             value.length > 0 && <div className="searchValuesSelectedList">
//                 {
//                     value.map((item, i) => <p key={'serach value sected nb' + i}>
//                         {item}
//                         <span onClick={() => handleDeleteChoice(i)}>X</span>
//                     </p>)
//                 }
//             </div>
//         }
//         {
//             newSearch && <section className="flex f-column new-choice-by-search">
//                 <input type="search"
//                     placeholder={searchPh}
//                     onChange={handleNewSearch}
//                     value={searched} />
//                 <ul>
//                     {searchLoading && <li>Chargement des resultat...</li>}
//                     {
//                         searchResults.map((item, i) => <li key={'search suggestr nb' + i}
//                             onClick={() => handleNewChoice(item[searchKey] + '(' + item[otherKey] + ')')}>{item[searchKey] + '(' + item[otherKey] + ')'} </li>)
//                     }
//                 </ul>
//             </section>
//         }
//         {
//             (!newSearch) && <SimpleButton customprops={{
//                 text: 'Nouvelle zone d\'intervention'
//             }}
//                 onClick={() => setNewSearch(true)} />
//         }

//     </div>;
// }