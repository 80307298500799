export function g_Aah() {
  return (
    <>
      <div className="caf-wrapper">
        <p>
          L’allocation adultes handicapés est une aide financière, versée par la CAF (Caisse d’Allocation Familiale), qui permet d’assurer un minimum de ressources au personnes en situation de handicap ou atteintes d’une maladie chronique, quand leur condition les empêche de travailler normalement et ainsi de gagner convenablement leur vie.
        </p>
        <h1>Conditions d’attribution</h1>
        <h2>
          Être reconnu comme handicapé
        </h2>
        <p>
          Vous devez être atteint d’un certain taux d’incapacité, qui correspond à la sévérité de votre handicap, et ainsi être reconnu comme handicapé par la CDAPH.
        </p>
        <p>
          Vous devez être atteint d’un taux d’incapacité d’au minimum 80%.  Si votre taux de handicap est compris entre 50% et 79%, vous pouvez faire une demande d’AAH, à condition que votre handicap représente une restriction durable d’accès à l’emploi qui ne peut se compenser par des aménagements spécifiques comme un poste de travail adapté.
        </p>
        <h2>
          Conditions d’âge
        </h2>
        <p>
          Vous devez également être âgé d’au moins 20 ans, ou 16 ans si vous n’êtes plus considéré comme à charge de vos parents pour l’attribution des allocations de la CAF
        </p>
        <h2>
          Le lieu de résidence
        </h2>
        <p>
          Pour pouvoir bénéficier de l’AAH, vous devez résider en France de manière permanente. Si vous êtes étranger et que vous n’appartenez pas à l’Union Européenne, vous devez séjourner de manière régulière sur le territoire français.
        </p>
      </div>
    </>
  )
}
