export function enceinte_ou_enfant(statutPersonnel, statutPersonnel2, enfants) {

    const find = enfants.filter(enf => enf.age <= 2)
    if (statutPersonnel === "Enceinte" || find.length > 0 || statutPersonnel2.includes('Enceinte'))
        return true;
    return false
}
export function enceinte(statutPersonnel, statutPersonnel2) {
    if (statutPersonnel === "Enceinte" || statutPersonnel2.includes('Enceinte'))
        return true;
    return false
}
export function enfant_Entre_3_Et_6Ans_Scolarisé(enfants) {
    let finded = false;
    for (let index = 0; index < enfants.length; index++) {
        if ((enfants[index].age >= 3 && enfants[index].age <= 6) && enfants[index].statut.includes("Étudiant(e)")) {
            finded = true
            break;
        }
    }
    return finded;
}
export function enfant_ayant_moins_de_6ans(enfants) {
    let finded = false;
    for (let index = 0; index < enfants.length; index++) {
        if (enfants[index].age < 6) {
            finded = true
            break;
        }
    }
    return finded;
}
export function trois_enfants_de_moins_de_18(enfants) {
    let count = false;
    for (let index = 0; index < enfants.length; index++) {
        if (enfants[index].age < 18) {
            count = count + 1;
        }
    }
    return count >= 3;
}
export function enfant_deMoinsde_3an_et_2ans_auMoins_deTravail(enfants, TravailleFranceApres2015) {
    let finded = false;
    for (let index = 0; index < enfants.length; index++) {
        if (enfants[index].age < 3) {
            finded = ["1 à 2 ans consécutifs", "plus de 2 ans consécutifs"].includes(TravailleFranceApres2015) && true;
            break;
        }
    }
    return finded;
}


export function enfant_entre_tranche_dage(enfants, min, max) {

    let finded = false;
    for (let index = 0; index < enfants.length; index++) {
        if (enfants[index].age >= min && enfants[index].age <= max) {
            finded = true;
            break;
        }
    }
    return finded;
}
