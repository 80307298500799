import { getAllNewAides } from "./getters";
export function calculateNewAidesTotal(data) {
  const allNewAides = getAllNewAides(data);
  let newAidesTotal = 0

  for (const key in allNewAides) {
    if (allNewAides[key] != "Eligible" && parseInt(allNewAides[key]) < 1000) {
      newAidesTotal = newAidesTotal + parseInt(allNewAides[key])
    }
  }
  return newAidesTotal;
}

export function countOfNewAides(data) {
  let count = 0
  const allNewAides = getAllNewAides(data);
  for (const key in allNewAides) {
    count = count + 1;
  }
  return count;
}


export default function simulationSomme(obj, newAidesTotal, countNewAide) {
  let sum = newAidesTotal;
  let aides = countNewAide;
  let val = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value !== 0 && key !== "email" && key !== "prenom" && key !== "data") {
      if (
        key !== "mj" &&
        key !== "lp" &&
        key !== "visale" &&
        key !== "allocation_annuelle_etudiant" &&
        key !== "cpf"
      ) {
        sum += value;
      }
      aides += 1;
      val = { ...val, [key]: value };
    }
  }
  return { sum, aides, val };
}

export function dateEdit(mm, i, yyyy) {
  let newMonth = parseInt(mm) + i;

  if (newMonth <= 0) {
    newMonth = 12 + newMonth;
    yyyy -= 1;
  }
  if (newMonth > 12) {
    newMonth = newMonth - 12;
    yyyy += 1;
  }
  if (newMonth < 10) {
    newMonth = "0" + newMonth;
  }

  return { newMonth: newMonth.toString(), yyyy };
}

export function countChildren(tab) {
  let num = [];
  let sum = 0;
  for (let index = 0; index < tab.length; index++) {
    num = [...num, (sum += 1).toString()];
  }
  return num;
}

export function tauxHandi(str) {
  switch (str) {
    case "Moins de 50%":
      return 0.1;
    case "Entre 50% et 80%":
      return 0.5;
    default:
      return 0.8;
  }
}
export function tauxCpf(str) {
  switch (str) {
    case "1 à 2 ans consécutifs":
      return 500;
    case "plus de 2 ans consécutifs":
      return 1000;
    default:
      return 0;
  }
}

export function statutMap(tab) {
  if (tab.includes("Autre")) return "inactif";
  else if (tab.includes("Actif (salarié / indépendant)")) return "actif";
  else if (tab.includes("Demandeur d’emploi")) return "chomeur";
  else if (tab.includes("Apprenti(e)") || tab.includes("Étudiant(e)"))
    return "etudiant";
  if (tab.includes("Retraité(e)")) return "retraite";
  else return "inactif";
}

export function salairMap(tab) {
  if (tab.includes("Retraité(e)")) return "retraite_brute";
  else if (tab.includes("Demandeur d’emploi")) return "chomage_net";
  else return "salaire_net";
}

export function nationaliteMap(str) {
  switch (str) {
    case "Français(e)":
      return "FR";
    case "UE + Norvège + Islande + Suisse":
      return "ES";
    default:
      return "MA";
  }
}

export function locationMap(str1, str2) {
  if (str1 === "Locataire") {
    switch (str2) {
      case "Un logement non-meublé":
        return "locataire_vide";
      case "Un logement meublé ou hôtel":
        return "locataire_meuble";
      default:
        return "locataire_foyer";
    }
  } else if (str1 === "Propriétaire") {
    return "primo_accedant";
  } else if (str1 === "Hébergé") {
    return "loge_gratuitement";
  } else return "sans_domicile";
}

export function maritalMap(str) {
  switch (str) {
    case "Célibataire":
      return "celibataire";
    case "Marié(e)":
    case "En concubinage":
      return "marie";
    case "Pacsé(e)":
      return "pacse";
    default:
      return "veuf";
  }
}

export function calculCHeque(str1, couple, enf) {
  let uc = 1;
  let rfr = 0;
  let res = 0;
  if (
    couple === "Marié(e)" ||
    couple === "Pacsé(e)" ||
    couple === "En concubinage"
  )
    uc += 0.5;
  uc += enf.length * 0.3;

  rfr = Math.ceil(parseInt(str1) * 12 * 0.9);

  res = Math.ceil(rfr / uc);

  if (res <= 5600) {
    if (uc === 1) return 194;
    else if (uc < 2) return 240;
    else return 277;
  }
  if (res <= 6700) {
    if (uc === 1) return 146;
    else if (uc < 2) return 176;
    else return 202;
  }
  if (res <= 7700) {
    if (uc === 1) return 98;
    else if (uc < 2) return 113;
    else return 126;
  }
  if (res <= 10800) {
    if (uc === 1) return 48;
    else if (uc < 2) return 63;
    else return 76;
  } else return 0;
}

export function calculLocapass(str1) {
  switch (str1) {
    case "Oui":
      return 1;

    default:
      return 0;
  }
}
export function calculMobiliJeune(tab, str1) {
  if (tab.includes("Apprenti(e)")) {
    if (parseInt(str1) <= 30) return 1;
    else return 0;
  } else return 0;
}
export function calculEtudiantDiff(tab, str1, str2) {
  if (tab.includes("Étudiant(e)") || tab.includes("Apprenti(e)")) {
    if (parseInt(str1) <= 35) {
      if (parseInt(str2) <= 300) return 1;
      else return 0;
    } else return 0;
  } else return 0;
}

export function mapChildren(tab, data) {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  let sum = 0;
  let obj = {};
  for (let i = 0; i < tab.length; i++) {
    sum += 1;
    obj = {
      ...obj,
      [sum]: {
        garantie_jeunes: {
          [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("Garantie Jeunes")
            ? 0
            : null,
        },
        apa_domicile: {
          [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("APA") ? 0 : null,
        },
        allocation_annuelle_etudiant: {
          [`${yyyy}-${mm}`]: null,
        },
        asi: {
          [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("ASI") ? 0 : null,
        },
        aah: {
          [`${yyyy}-${mm}`]: data.aidesSocialesTab.includes("AAH") ? 0 : null,
        },
        mva: {
          [`${yyyy}-${mm}`]: tab[i].statut.includes("En situation de handicap")
            ? data.aidesSocialesTab.includes("MVA")
              ? 0
              : null
            : 0,
        },
        age_en_mois: {
          [`${yyyy}-${mm}`]: parseInt(tab[i].age) * 12,
          [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]:
            parseInt(tab[i].age) * 12,
          [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]:
            parseInt(tab[i].age) * 12,
          [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]:
            parseInt(tab[i].age) * 12,
          [`${dateEdit(mm, -4, yyyy).yyyy}-${dateEdit(mm, -4, yyyy).newMonth}`]:
            parseInt(tab[i].age) * 12,
          [`${dateEdit(mm, -5, yyyy).yyyy}-${dateEdit(mm, -5, yyyy).newMonth}`]:
            parseInt(tab[i].age) * 12,
        },
        age: {
          [`${yyyy}-${mm}`]: parseInt(tab[i].age),
          [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]:
            parseInt(tab[i].age),
          [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]:
            parseInt(tab[i].age),
          [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]:
            parseInt(tab[i].age),
          [`${dateEdit(mm, -4, yyyy).yyyy}-${dateEdit(mm, -4, yyyy).newMonth}`]:
            parseInt(tab[i].age),
          [`${dateEdit(mm, -5, yyyy).yyyy}-${dateEdit(mm, -5, yyyy).newMonth}`]:
            parseInt(tab[i].age),
        },
        activite: {
          [`${yyyy}-${mm}`]: statutMap(tab[i].statut),
          [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth}`]:
            statutMap(tab[i].statut),
          [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth}`]:
            statutMap(tab[i].statut),
          [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth}`]:
            statutMap(tab[i].statut),
          [`${dateEdit(mm, -4, yyyy).yyyy}-${dateEdit(mm, -4, yyyy).newMonth}`]:
            statutMap(tab[i].statut),
          [`${dateEdit(mm, -5, yyyy).yyyy}-${dateEdit(mm, -5, yyyy).newMonth}`]:
            statutMap(tab[i].statut),
          [`${dateEdit(mm, -6, yyyy).yyyy}-${dateEdit(mm, -6, yyyy).newMonth}`]:
            statutMap(tab[i].statut),
          [`${dateEdit(mm, -7, yyyy).yyyy}-${dateEdit(mm, -7, yyyy).newMonth}`]:
            statutMap(tab[i].statut),
          [`${dateEdit(mm, -8, yyyy).yyyy}-${dateEdit(mm, -8, yyyy).newMonth}`]:
            statutMap(tab[i].statut),
          [`${dateEdit(mm, -9, yyyy).yyyy}-${dateEdit(mm, -9, yyyy).newMonth}`]:
            statutMap(tab[i].statut),
          [`${dateEdit(mm, -10, yyyy).yyyy}-${dateEdit(mm, -10, yyyy).newMonth
            }`]: statutMap(tab[i].statut),
          [`${dateEdit(mm, -11, yyyy).yyyy}-${dateEdit(mm, -11, yyyy).newMonth
            }`]: statutMap(tab[i].statut),
          [`${dateEdit(mm, -12, yyyy).yyyy}-${dateEdit(mm, -12, yyyy).newMonth
            }`]: statutMap(tab[i].statut),
        },
        ...(tab[i].echelonDeBourse && {
          bourse_criteres_sociaux_echelon: {
            [`${yyyy}-${mm}`]: parseInt(tab[i].echelonDeBourse),
            [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth
              }`]: parseInt(tab[i].echelonDeBourse),
            [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth
              }`]: parseInt(tab[i].echelonDeBourse),
            [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth
              }`]: parseInt(tab[i].echelonDeBourse),
          },
        }),
        ...(tab[i].statut.includes("Enceinte") && {
          enceinte: {
            [`${yyyy}-${mm}`]: true,
            [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth
              }`]: true,
            [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth
              }`]: true,
            [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth
              }`]: true,
          },
        }),
        ...(tab[i].statut.includes("En situation de handicap") && {
          handicap: {
            [`${yyyy}-${mm}`]: true,
            [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth
              }`]: true,
            [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth
              }`]: true,
            [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth
              }`]: true,
          },
        }),
        ...(tab[i].statut.includes("En situation de handicap") && {
          taux_incapacite: {
            [`${yyyy}-${mm}`]: tauxHandi(tab[i].tauxHandicap),
            [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth
              }`]: tauxHandi(tab[i].tauxHandicap),
            [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth
              }`]: tauxHandi(tab[i].tauxHandicap),
            [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth
              }`]: tauxHandi(tab[i].tauxHandicap),
          },
        }),
        ...(tab[i].statut.includes("Inapte au travail") && {
          inapte_travail: {
            [`${yyyy}-${mm}`]: true,
            [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth
              }`]: true,
            [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth
              }`]: true,
            [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth
              }`]: true,
          },
        }),
        ...(tab[i].revenu && {
          salaire_net: {
            [`${yyyy}-${mm}`]: parseInt(tab[i].revenu),
            [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth
              }`]: parseInt(tab[i].revenu),
            [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth
              }`]: parseInt(tab[i].revenu),
            [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth
              }`]: parseInt(tab[i].revenu),
            [`${dateEdit(mm, -4, yyyy).yyyy}-${dateEdit(mm, -4, yyyy).newMonth
              }`]: parseInt(tab[i].revenu),
            [`${dateEdit(mm, -5, yyyy).yyyy}-${dateEdit(mm, -5, yyyy).newMonth
              }`]: parseInt(tab[i].revenu),
            [`${dateEdit(mm, -6, yyyy).yyyy}-${dateEdit(mm, -6, yyyy).newMonth
              }`]: parseInt(tab[i].revenu),
            [`${dateEdit(mm, -7, yyyy).yyyy}-${dateEdit(mm, -7, yyyy).newMonth
              }`]: parseInt(tab[i].revenu),
            [`${dateEdit(mm, -8, yyyy).yyyy}-${dateEdit(mm, -8, yyyy).newMonth
              }`]: parseInt(tab[i].revenu),
            [`${dateEdit(mm, -9, yyyy).yyyy}-${dateEdit(mm, -9, yyyy).newMonth
              }`]: parseInt(tab[i].revenu),
            [`${dateEdit(mm, -10, yyyy).yyyy}-${dateEdit(mm, -10, yyyy).newMonth
              }`]: parseInt(tab[i].revenu),
            [`${dateEdit(mm, -11, yyyy).yyyy}-${dateEdit(mm, -11, yyyy).newMonth
              }`]: parseInt(tab[i].revenu),
            [`${dateEdit(mm, -12, yyyy).yyyy}-${dateEdit(mm, -12, yyyy).newMonth
              }`]: parseInt(tab[i].revenu),
          },
        }),
        ...(tab[i].revenu && {
          salaire_de_base: {
            [`${yyyy}-${mm}`]: parseInt(tab[i].revenu) * 1.3,
            [`${dateEdit(mm, -1, yyyy).yyyy}-${dateEdit(mm, -1, yyyy).newMonth
              }`]: parseInt(tab[i].revenu) * 1.3,
            [`${dateEdit(mm, -2, yyyy).yyyy}-${dateEdit(mm, -2, yyyy).newMonth
              }`]: parseInt(tab[i].revenu) * 1.3,
            [`${dateEdit(mm, -3, yyyy).yyyy}-${dateEdit(mm, -3, yyyy).newMonth
              }`]: parseInt(tab[i].revenu) * 1.3,
            [`${dateEdit(mm, -4, yyyy).yyyy}-${dateEdit(mm, -4, yyyy).newMonth
              }`]: parseInt(tab[i].revenu) * 1.3,
            [`${dateEdit(mm, -5, yyyy).yyyy}-${dateEdit(mm, -5, yyyy).newMonth
              }`]: parseInt(tab[i].revenu) * 1.3,
            [`${dateEdit(mm, -6, yyyy).yyyy}-${dateEdit(mm, -6, yyyy).newMonth
              }`]: parseInt(tab[i].revenu) * 1.3,
            [`${dateEdit(mm, -7, yyyy).yyyy}-${dateEdit(mm, -7, yyyy).newMonth
              }`]: parseInt(tab[i].revenu) * 1.3,
            [`${dateEdit(mm, -8, yyyy).yyyy}-${dateEdit(mm, -8, yyyy).newMonth
              }`]: parseInt(tab[i].revenu) * 1.3,
            [`${dateEdit(mm, -9, yyyy).yyyy}-${dateEdit(mm, -9, yyyy).newMonth
              }`]: parseInt(tab[i].revenu) * 1.3,
            [`${dateEdit(mm, -10, yyyy).yyyy}-${dateEdit(mm, -10, yyyy).newMonth
              }`]: parseInt(tab[i].revenu) * 1.3,
            [`${dateEdit(mm, -11, yyyy).yyyy}-${dateEdit(mm, -11, yyyy).newMonth
              }`]: parseInt(tab[i].revenu) * 1.3,
            [`${dateEdit(mm, -12, yyyy).yyyy}-${dateEdit(mm, -12, yyyy).newMonth
              }`]: parseInt(tab[i].revenu) * 1.3,
          },
        }),
      },
    };
  }
  return obj;
}
