export const S_I_RSA =
  <>
    <div>
      <p>
        Le Revenu de Solidarité Active (RSA) est un minima social : cette
        aide financière vous permet d’assurer un revenu minimum de
        ressources.
      </p>
      <h3>RSA : conditions d’âge</h3>
      <p>
        Pour pouvoir bénéficier du RSA, vous devez être âgé de plus de 25
        ans. Cependant, vous pouvez tout de même bénéficier du RSA si vous
        avez entre 18 et 25 ans, sous certaines conditions strictes.
      </p>
      <p>
        De plus, si vous êtes enceinte ou que vous avez des enfants à
        charge, aucune condition d’âge ne s’applique.
      </p>
      <h3>RSA : conditions de résidence</h3>
      <span>
        De manière générale, vous devez habiter en France de façon stable
        (comprenez : plus de 6 mois par an). Vous devez également vous
        trouver dans l’une des situations suivantes :
        <ul>
          <li>Vous êtes français</li>
          <li>
            Ou vous êtes ressortissant de l’Espace Économique Européen
            (EEE), vous êtes Suisse et vous justifiez d’un droit de séjour
          </li>
          <li>
            Ou vous êtes ressortissant d’un autre pays et vous séjournez en
            France de façon régulière depuis une période minimum de 5 ans
            (quelques exceptions peuvent avoir lieu concernant cette période
            de 5 ans)
          </li>
        </ul>
      </span>
      <h3>RSA : conditions de ressources</h3>
      <p>
        Les ressources mensuelles moyennes de votre foyer pendant les 3 mois
        qui précèdent votre demande ne doivent pas dépasser un certain
        plafond
      </p>
      <h3>Montant du RSA</h3>
      <table>
        <tbody>
          <tr>
            <td></td>
            <th>
              <b>Nbre de personnes à charge</b>
            </th>
            <th>
              <b>Montant brut du RSA</b>
            </th>
          </tr>
          <tr>
            <td rowSpan="3">
              <p>
                <b>Pour un personne seule</b>
              </p>
            </td>
            <td>
              <p>0</p>
            </td>
            <td>
              <p>565,34 €</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>1</p>
            </td>
            <td>
              <p>848,01 €</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2</p>
            </td>
            <td>
              <p>1017,61 €</p>
            </td>
          </tr>
          <tr>
            <td rowSpan="3">
              <p>
                <b>Pour un couple</b>
              </p>
            </td>
            <td>
              <p>0</p>
            </td>
            <td>
              <p>848,01 €</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>1</p>
            </td>
            <td>
              <p>1017,61 €</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>2</p>
            </td>
            <td>
              <p>1187,21 €</p>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </>
