export const S_I_ASPA =
  <>
    <div >
      <p>
        L’allocation de solidarité aux personnes âgées Aspa ex minimum
        vieillesse est destinée aux personnes de 65 ans ou plus ayant de
        faibles ressources. Pour bénéficier de cette prestation sociale, un
        certain nombreuse de conditions sont à respecter.
      </p>
      <h3>Comment estimer ses droits à l’Aspa ?</h3>
      <span>
        L’Aspa est une allocation dont le montant que vous percevez dépend
        aussi bien de vos ressources que de votre situation personnelle. Un
        certain nombre de conditions sont à respecter et effectuer une
        estimation de ses droits seul n’est pas évident. Pour vous aider
        dans vos démarches vous pouvez utiliser le simulateur d’aides
        sociale France Assist. Il s’agit d’un simulateur totalement gratuit
        qui vous donnera une réponse fiable de vos droits en quelques clics.
      </span>
      <h3>Montant de l’Aspa</h3>
      <span>
        Le montant de l’Aspa est calculé à partir des 3 mois précédent sa
        date d’effet. Notez que ce montant peut varier selon les ressources
        que vous percevez.
      </span>
      <table>
        <tbody>
          <tr>
            <th>
              <b>Situation familiale</b>
            </th>
            <th>
              <b>Plafond de revenus annuel à ne pas dépasser</b>
            </th>
            <th>
              <b>Plafond mensuel de revenus à ne pas dépasser</b>
            </th>
          </tr>
          <tr>
            <td>
              <p>
                <b>Célibataire</b>
              </p>
            </td>
            <td>
              <p>10 838,40 €</p>
            </td>
            <td>
              <p>903,20 €</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                {" "}
                <b>Couple (Pacs, mariage, concubinage)</b>{" "}
              </p>
            </td>
            <td>
              <p>16 826,64 €</p>
            </td>
            <td>
              <p>1 402,22 €</p>
            </td>
          </tr>
        </tbody>
      </table>


    </div>
  </>
