import React from 'react'
import RPDescription from '../RoutesSubComponents/referal-program/desc'
import RPBecomeReferror from '../RoutesSubComponents/referal-program/become-referor'
import { bothAuth } from '../hocs/bothAuth'

function Referral({ view }) {
    return (
        <div>
            {
                view == "desc" ? <RPDescription /> : <RPBecomeReferror />
            }
        </div>
    )
}

export default bothAuth(Referral)