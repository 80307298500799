import React from 'react'
import { Form, useFormik } from "formik";
import { loginFields } from "../../helper/form/fields";
import { FormikProvider } from "formik";
import FormikFieldController from "../../uikits/form";
import { FormButton } from '../../uikits/buttons';
import { LoginSchema } from '../../helper/form/validations';
import { errorToast } from '../../helper/others';

import axios from 'axios';
import { useAuth } from '../../contexts/auth';


function Login({ props }) {
    const { login } = useAuth();
    const formik = useFormik({
        initialValues: { email: '', password: '' },
        validationSchema: LoginSchema,
        validateOnMount: true,
        onSubmit: handleSubmit
    });
    const { isValid, setSubmitting, isSubmitting } = formik;

    function handleSubmit(formData) {
        let apiUrl = '/user/login';
        axios.post(apiUrl, formData)
            .then(res => {
                let { token } = res.data;
                login(token)
            })
            .catch(err => {
                errorToast("Une erreur s\'est produite : " + err.response.data.message)
            }).finally(() => setSubmitting(false))
    }
    return (
        <FormikProvider value={formik}>
            <Form>
                {
                    loginFields.map((item, i) => <FormikFieldController
                        key={'item nb' + i}
                        {...item}
                    />)
                }
                <FormButton isValid={isSubmitting ? false : isValid}
                    text={isSubmitting ? '' : "Se connecter"} />
            </Form>
        </FormikProvider>
    )
}
export default Login;
