import { IllustrationImage } from "../../GlobalComponents/img/global";

const AuthBanner = ({ authType }) => {
    return <>
        {authType == 'inscription' ?
            <section id="authFullBanner">
                <h1>Souscription au service d’accompagnement</h1>
                <p>Réglez 29.90 € de frais d'abonnement trimestriel sans engagement. Résiliable à tout moment.</p>
            </section>
            : <section className="uaBanner">
                <IllustrationImage props={{
                    src: 'banners/' + authType.toLowerCase()
                        + '.svg', alt: 'Auth image', className: ''
                }} />
            </section>}
    </>
}

export default AuthBanner;