import React from 'react'

function OldAidesByOpenFisca({ aides }) {
    return (
        <>
            {aides.rsa && (
                <div>
                    <div>
                        <img src="/sources/aides/caf.jpeg" alt="aides" />
                    </div>
                    <div>
                        <div>Revenu de Solidarité Active</div>
                        <div>
                            {aides.rsa}€<span> /mois</span>
                        </div>
                    </div>
                </div>
            )}
            {aides.ppa && (
                <div>
                    <div>
                        <img src="/sources/aides/caf.jpeg" alt="aides" />
                    </div>
                    <div>
                        <div>Prime d’activité</div>
                        <div>
                            {aides.ppa}€<span> /mois</span>
                        </div>
                    </div>
                </div>
            )}
            {aides.aah && (
                <div>
                    <div>
                        <img src="/sources/aides/aah.png" alt="aides" />
                    </div>
                    <div>
                        <div>Allocation aux Adultes Handicapés</div>
                        <div>
                            {aides.aah}€<span> /mois</span>
                        </div>
                    </div>
                </div>
            )}
            {aides.mva && (
                <div>
                    <div>
                        <img src="/sources/aides/mva.png" alt="aides" />
                    </div>
                    <div>
                        <div>Majoration pour la vie autonome</div>
                        <div>
                            {aides.mva}€<span> /mois</span>
                        </div>
                    </div>
                </div>
            )}
            {aides.aspa && (
                <div>
                    <div>
                        <img src="/sources/aides/carsat.jpeg" alt="aides" />
                    </div>
                    <div>
                        <div>Allocation de solidarité aux personnes âgées</div>
                        <div>
                            {aides.aspa}€<span> /mois</span>
                        </div>
                    </div>
                </div>
            )}
            {aides.css && (
                <div>
                    <div>
                        <img src="/sources/aides/css.jpeg" alt="aides" />
                    </div>
                    <div>
                        <div>Complémentaire santé solidaire</div>
                        <div>
                            {aides.css}€<span> /mois</span>
                        </div>
                    </div>
                </div>
            )}
            {aides.asi && (
                <div>
                    <div>
                        <img src="/sources/aides/css.jpeg" alt="aides" />
                    </div>
                    <div>
                        <div>Allocation Supplémentaire d’Inaidesidité</div>
                        <div>
                            {aides.asi}€<span> /mois</span>
                        </div>
                    </div>
                </div>
            )}
            {aides.allocation_annuelle_etudiant && (
                <div>
                    <div>
                        <img
                            src="/sources/aides/crous.jpeg"
                            alt="aides"
                        />
                    </div>
                    <div>
                        <div>Allocation étudiant en difficulté</div>
                        {/* <div>
                {aides.allocation_annuelle_etudiant}€<span> /mois</span>
              </div> */}
                    </div>
                </div>
            )}
            {aides.asf && (
                <div>
                    <div>
                        <img src="/sources/aides/asf.png" alt="aides" />
                    </div>
                    <div>
                        <div>Allocation de soutien familial</div>
                        <div>
                            {aides.asf}€<span> /mois</span>
                        </div>
                    </div>
                </div>
            )}
            {aides.cf && (
                <div>
                    <div>
                        <img src="/sources/aides/cf.png" alt="aides" />
                    </div>
                    <div>
                        <div>Complément familial</div>
                        <div>
                            {aides.cf}€<span> /mois</span>
                        </div>
                    </div>
                </div>
            )}
            {aides.paje && (
                <div>
                    <div>
                        <img src="/sources/aides/paje.png" alt="aides" />
                    </div>
                    <div>
                        <div>Prestation d’accueil du jeune enfant</div>
                        <div>
                            {/* {aides.paje} */}
                            175€<span> /mois</span>
                        </div>
                    </div>
                </div>
            )}
            {aides.apl && (
                <div>
                    <div>
                        <img src="/sources/aides/caf.jpeg" alt="aides" />
                    </div>
                    <div>
                        <div>Aides Personnalisée au Logement</div>
                        <div>
                            {aides.apl}€<span> /mois</span>
                        </div>
                    </div>
                </div>
            )}
            {aides.cheque_energie && (
                <div>
                    <div>
                        <img src="/sources/aides/cheque_energie.jpeg" alt="aides" />
                    </div>
                    <div>
                        <div>Chèque énergie</div>
                        <div>
                            {aides.cheque_energie}€<span> /an</span>
                        </div>
                    </div>
                </div>
            )}
            {aides.af && (
                <div>
                    <div>
                        <img src="/sources/aides/caf.jpeg" alt="aides" />
                    </div>
                    <div>
                        <div>Allocations familiales</div>
                        <div>
                            {aides.af}€<span> /mois</span>
                        </div>
                    </div>
                </div>
            )}
            {aides.apa_domicile && (
                <div>
                    <div>
                        <img src="/sources/aides/apa.png" alt="aides" />
                    </div>
                    <div>
                        <div>Allocation personnalisée d’autonomie</div>
                        <div>
                            {aides.apa_domicile}€<span> /mois</span>
                        </div>
                    </div>
                </div>
            )}
            {aides.cpf && (
                <div>
                    <div>
                        <img src="/sources/aides/cpf.jpeg" alt="aides" />
                    </div>
                    <div>
                        <div>Compte personnel de formation</div>
                        <div>
                            {aides.cpf === 1000 ? ">" : "<"} {aides.cpf}€
                        </div>
                    </div>
                </div>
            )}
            {aides.lp && (
                <div>
                    <div>
                        <img src="/sources/aides/actionLogement.png" alt="aides" />
                    </div>
                    <div>
                        <div>AIDE LOCA-PASS</div>
                        <div>
                            1200€
                        </div>
                    </div>
                </div>
            )}
            {aides.visale && (
                <div>
                    <div>
                        <img src="/sources/aides/actionLogement.png" alt="aides" />
                    </div>
                    <div>
                        <div>Garantie VISALE</div>
                    </div>
                </div>
            )}
            {aides.mj && (
                <div>
                    <div>
                        <img src="/sources/aides/actionLogement.png" alt="aides" />
                    </div>
                    <div>
                        <div>AIDE MOBILI-JEUNE</div>
                    </div>
                </div>
            )}
        </>
    )
}

export default OldAidesByOpenFisca