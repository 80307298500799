import { Link, useParams } from "react-router-dom";
import { AE } from "../Raw/Aides/AE";
import { AP } from "../Raw/Aides/AP";
import { CP } from "../Raw/Aides/CP";
import { PE } from "../Raw/Aides/PE";
import { Redaction } from "../Raw/Aides/Redaction";
import { RL } from "../Raw/Aides/RL";
import { DI } from "../Raw/Aides/DI";
import { withoutAuth } from "../hocs/withoutAuth";

const Aide_Offres = () => {

    const { typeAide } = useParams();
    const aideOffreInfosObj = {
        "AP": { layout: AP, title: 'aide Ponctuelle' },
        "DI": { layout: DI, title: 'aide à la Déclaration impôts' },
        "PE": { layout: PE, title: 'aide à l\'Inscription pôle emploi' },
        "Redaction": { layout: Redaction, title: 'aide à la redaction de Cv,Lettre de motivation,courriers,etc....' },
        "AE": { layout: AE, title: ' aide à l\'energie' },
        "RCR": { layout: CP, title: 'aide à la Révision code de la route(permis)' },
        "ARL": { layout: RL, title: 'aide à la recherche des logements' },
    };
    return <div className="simulations-guides">
        <section className="sg-simulationInfos">
            <h1>Ce qu'il faut savoir sur notre {aideOffreInfosObj[typeAide].title}</h1>
            {aideOffreInfosObj[typeAide].layout()}
        </section>
        <section className="sg-link">
            <Link to={'/Souscription/' + typeAide}>Souscrire à l'aide</Link>
        </section>

    </div>
}

export default withoutAuth(Aide_Offres);