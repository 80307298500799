export const S_I_APL = <>
  <div>
    <p>
      L’Aide Personnalisée au Logement (APL) est une aide qui vous est versée par la Caisse d’Allocations Familiales (site de la CAF) ou par la Mutualité Sociale Agricole (MSA), selon votre situation familiale. Son but est de vous aider pour le paiement de votre loyer ou pour le remboursement d’un prêt.
    </p>
    <h3>
      Calcul de vos APL
    </h3>
    <span>
      Les règles de calcul des APL sont assez complexes. La formule de calcul de base est la suivante : Montant d’<b>APL = L + C-PP</b>, où :
      <ul>
        <li>
          <b>L</b> : loyer principal mensuel que vous payez, pris en compte dans la limite du plafond ci-dessous.
        </li>
        <li>
          <b>C</b> : montant forfaitaire de vos charges
        </li>
        <li>
          <b>PP</b> : participation personnelle de votre ménage à la dépense de logement. Cette participation personnelle est elle-même calculée selon la formule suivante : PP = P0 + Tp x Rp.
        </li>
        <br />
        <li>
          OU :
          <ul>
            <li>
              P0 : participation minimale du locataire (35,02 €).
            </li>
            <li>
              TP : taux de participation personnelle, en pourcentage (%).
            </li>
            <li>
              RP : différence entre les ressources du bénéficiaire et un montant forfaitaire.
            </li>

          </ul>
        </li>
      </ul>
    </span>
    <h3>
      Comment demander l’APL ?
    </h3>
    <span>
      Pour faire votre demande d’APL, vous devez remplir un formulaire mis à jour avec vos données personnelles. Y joindre les pièces justificatives exigées, afin de constituer un dossier auprès de votre Caisse d’Allocation Familiale (départementale) ou de votre Mutualité Sociale Agricole (MSA).
    </span>
    <span>
      Vous n’avez ni le temps ni l’envie de plonger dans ces tracas administratifs ? Pas d’inquiétude, France Assist s’occupe de toutes les démarches à votre place !
    </span>

  </div>
</>


