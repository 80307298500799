import { IllustrationImage, SimpleImage } from "../GlobalComponents/img/global";

const ResultatSouscription = () => {
    return <div className="souscription-result">


        <section>
            <IllustrationImage props={{
                src: "paymentValidate.svg",
                alt: "Payment validate", className: ""
            }} />
            <h1>
                Votre paiement pour la souscription à notre offre à la tâche a bien été effectué.
                <br />
                L'équipe France Assist vous recontactera dans de brefs délais...
            </h1>
        </section>
    </div>
}

export default ResultatSouscription;