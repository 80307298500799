import { isValidPhoneNumber } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import "./information.css";
import { NextSimulationStepButton, PrevSimulationStep } from "../../../GlobalComponents/simulation/nav";

function Informations({ setSection, setProgress, setData, setEnd }) {
  const [page, setPage] = useState(1);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [informations, setInformations] = useState({
    prenom: "",
    nom: "",
    email: "",
    phone: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 160);
  }, [page]);
  const normalizeName = (value) => {
    return value.replace(/[^A-Za-z\s]/gi, "") || "";
  };
  const normalizePhoneNumber = (value) => {
    return value.replace(/\D/gi, "") || "";
  };

  return (
    <>
      {page === 1 && (
        <div className="loyer-wrap">
          <span>Mon prénom</span>
          <div className="simulateur-age loyer name">
            <div>
              <input
                // ref={ageRef}
                value={informations.prenom}
                autoFocus
                placeholder="Votre prenom"
                onChange={(e) => {
                  e.target.value = normalizeName(e.target.value);
                  setInformations({
                    ...informations,
                    prenom: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="button-simulateur">
            <PrevSimulationStep props={{
              setPage, prevPage: 1, anotherOperation: function () {
                setSection("mes revenus");
                setProgress(80);
              }
            }} />
            <NextSimulationStepButton disabledCond={!informations.prenom}
              onClick={() => {
                setPage(2);
              }}
            />
          </div>
        </div>
      )
      }

      {
        page === 2 && (
          <div className="loyer-wrap">
            <span>Mon nom</span>
            <div className="simulateur-age loyer name">
              <div>
                <input
                  // ref={ageRef}
                  value={informations.nom}
                  autoFocus
                  placeholder="Votre nom"
                  onChange={(e) => {
                    e.target.value = normalizeName(e.target.value);
                    setInformations({
                      ...informations,
                      nom: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!informations.nom}
                onClick={() => {
                  setPage(3);
                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 3 && (
          <div className="loyer-wrap">
            <span>
              Mon email
              <span style={{ color: "	#3c3c3c", fontSize: 13, display: "block" }}>
                Nécessaire pour recevoir mon résultat par email
              </span>
            </span>
            <div className="simulateur-age loyer postal name ">
              <div>
                <input
                  type="text"
                  value={informations.email}
                  autoFocus
                  placeholder="Ex : mail@gmail.com"
                  onChange={(e) => {
                    setEmailError(false);
                    setInformations({
                      ...informations,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
              {emailError && <span>Email invalide</span>}
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 2 }} />
              <NextSimulationStepButton disabledCond={!informations.email}
                onClick={() => {
                  if (
                    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                      informations.email
                    )
                  ) {
                    setEmailError(false);
                    setPage(4);
                  } else {
                    setEmailError(true);
                  }
                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 4 && (
          <div className="loyer-wrap">
            <span>Mon téléphone
              <span style={{ color: "	#3c3c3c", fontSize: 13, display: "block" }}>
                {/* Pour recevoir un code de validation */}
                Pour accéder à nouveau à mes résultats
              </span>
            </span>
            <div className="simulateur-age loyer postal name ">
              <div>
                <input
                  // ref={ageRef}
                  value={informations.phone}
                  autoFocus
                  placeholder="06 12 34 56 78"
                  type="tel"
                  maxLength="10"
                  onChange={(e) => {
                    e.target.value = normalizePhoneNumber(e.target.value);
                    setPhoneError(false);
                    setInformations({
                      ...informations,
                      phone: e.target.value,
                    });
                  }}
                />
              </div>
              {phoneError && <span>Numéro invalide</span>}
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{
                setPage, prevPage: 1, anotherOperation: function () {
                  setLoading(false);
                  setPage(3);
                }
              }} />
              <NextSimulationStepButton disabledCond={!informations.phone || loading}
                onClick={() => {
                  if (informations.phone.slice(0, 2) === "06" || informations.phone.slice(0, 2) === "07") {
                    if (isValidPhoneNumber(informations.phone, "FR")) {
                      setPhoneError(false);
                      // onSignInSubmit();
                      setData((data) => {
                        return {
                          ...data,
                          ...informations,
                          email: informations.email.toLowerCase(),
                        };
                      });

                      setEnd(true);
                    } else {
                      setPhoneError(true);
                    }
                  }
                  else
                    setPhoneError(true);

                }}
              />
            </div>
            <div id="recaptcha-container"></div>
          </div >
        )
      }
    </>
  );
}

export default Informations;
