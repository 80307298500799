import { Link } from "react-router-dom"
import { IonCalendar, IonLockOpenOutline, IonStar, IonThumbsUpOutline } from "./icons"
import { IllustrationImage } from "./img/global"
import Slider from "react-slick"
import useAppReviews from "../Hooks/reviews"
import { useEffect, useState } from "react"
import axios from "axios"
import moment from "moment"

export function CallToActionAfterSimulationResult() {
    return (
        <div className="simulationResuTakeContact">
            <IllustrationImage props={{
                src: "assist.svg",
                alt: "Proceder a l'abo"
            }} />
            <h2>
                Sollicitez l'accompagnement d'un expert, souscrivez à l'assistance aux démarches.
            </h2>
            <ul>
                <li>
                    <IonLockOpenOutline />
                    <p>
                        <strong>Sans engagement</strong> , vous pouvez résilier à tout moment dans votre espace client.
                    </p>
                </li>
                <li>
                    <IonThumbsUpOutline />
                    <p>
                        <strong>Satisfait ou remboursé</strong> si vous n'êtes éligible à aucune aide.
                    </p>
                </li>
            </ul>
            <section>
                <Link to="/Inscription">Je souscris à l'accompagnement par un expert</Link>
                <p>
                    Je prends contact avec un(e) Conseiller(e) avant de souscrire
                </p>
                <a href="https://calendly.com/anneclaire-1/30min?month=2022-07" target={"_blank"}>
                    <IonCalendar /> Prise de rendez-vous
                </a>
            </section>
        </div>
    )
}


export function SomeReviews() {
    const { reviews, reviewsLoading } = useAppReviews();
    const [trustPilot, setTrustPilot] = useState('...');


    const settings = {
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    useEffect(() => {
        axios.get("https://dashboard.france-assist.fr/avis/get")
            .then(res => setTrustPilot(res.data))
            .catch(err => console.log(err))
    }, []);


    return <div className="appSomeReview">
        <h2>Quelques avis clients</h2>
        <section className="asr-reviewsList">
            {
                reviewsLoading ? <p>Chargement des avis</p> : <Slider {...settings}>
                    {
                        reviews.map((item, i) => <article className="asr-reviewBox"
                            key={'review nb' + i}>
                            <div className="asrr-head">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
                                    alt="app review user pic" />
                                <section>
                                    <b>
                                        {item.userName}
                                        {/* <sup>{item.userCountry}</sup> */}
                                    </b>
                                    <p>
                                        {
                                            [0, 0, 0, 0, 0].map((str, j) => <IonStar
                                                key={'review nb' + i + ' star nb' + j}
                                                className={(j + 1) <= item.stars ? 'activeStar' : ''} />)
                                        }
                                    </p>
                                </section>

                            </div>
                            <div className="asrr-body">
                                <p>
                                    {item.message}
                                </p>
                                <br />
                                <span>Date de l'expérience : <b>{moment(item.messageDate).format('DD/MM/YYYY')}</b> </span>
                            </div>
                            {/* <div className="asrr-foot">
                                <span>Sur: </span>
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Trustpilot_Logo_%282022%29.svg/2560px-Trustpilot_Logo_%282022%29.svg.png" alt="" />
                            </div> */}
                        </article>)
                    }
                </Slider>
            }
        </section>

        <section className="asr-viewAllReviews">
            <p>
                Voir tous les <a href="https://fr.trustpilot.com/review/france-assist.fr"
                    target="_blank"><b>{trustPilot} avis </b> </a> sur <b>TrustPilot</b>
            </p>
        </section>
    </div>
}