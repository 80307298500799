import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
const OurTaskOffer = () => {
    const ourTaskOffers = [
        { link: "/Nos-Aides/AP", label: "Aide Ponctuelle", img: "" },
        { link: "/Nos-Aides/DI", label: "Déclaration impôts", img: "" },
        { link: "/Nos-Aides/PE", label: "Inscription pôle emploi", img: "" },
        { link: "/Nos-Aides/Redaction", label: "Rédaction cv, lettre de motivation, courriers", img: "" },
        { link: "/Nos-Aides/AE", label: "Aide énergétique", img: "" },
        { link: "/Nos-Aides/RCR", label: "Révision code de la route (permis)", img: "" },
        { link: "/Nos-Aides/ARL", label: "Aide à la recherche des logements", img: "" },
    ]
    return <div className="hOurTaskOffer">
        <h2>Nos offres à la tâche</h2>
        <section>
            <Fade cascade top duration={1000}>
                {
                    ourTaskOffers.map((oto, index) => <div key={'our task offer nb' + index}>
                        <Link to={oto.link}>
                            <img src={require('../../Assets/images/ourTasks/intervention' + (index + 1) + ".webp")}
                                alt={'our task' + index}
                                height={90} width={90} />
                            <p>{oto.label}</p>
                        </Link>
                    </div>)
                }
            </Fade>
        </section>
    </div>
}

export default OurTaskOffer;

