export function g_Apa() {
  return (
    <>
      <div className="caf-wrapper">
        <p>
          Votre proche est dépendant et a plus de 60 ans ? L'Allocation personnalisée d’Autonomie (APA) est une aide fondamentale pour lui permettre de financer son maintien à domicile.
        </p>
        <h1>Qu'est-ce que l'Allocation Personnalisée d’Autonomie ?</h1>
        <span>
          L’APA est une allocation destinée aux personnes âgées de 60 ans et plus :
          <ul>
            <li>
              Qui ont besoin d’aide pour accomplir les actes essentiels de la vie quotidienne : se lever, se laver, s’habiller…
            </li>
            <li>
              Ou qui nécessitent une surveillance régulière.
            </li>
          </ul>
          L'allocation personnalisée d'autonomie finance le maintien à domicile de l’aîné en perte d’autonomie (on parle alors d'APA à domicile), mais peut également être demandée pour un séjour en maison de retraite (on parle ici d'APA en établissement).
        </span>
        <p>
          Le LEP n’échappe pas à la règle d’or soumise à tous les produits de l’épargne réglementée : un seul livret par personne.
        </p>
        <p>
          A noter que pour prétendre à un LEP, il faut être majeur et ne plus être rattaché au foyer fiscal de ses parents.
        </p>
        <h1>
          Quelles sont les conditions pour bénéficier de l’APA ?
        </h1>
        <p>
          L’APA est un droit universel et concerne ainsi l’ensemble des personnes âgées en situation de dépendance de 60 ans et plus, sans condition de ressources.
        </p>
        <span>
          Pour en bénéficier, vous devez tout de même respecter quelques conditions. Alors, qui peu prétendre à l'APA ?
          <ul>
            <li>
              Les personnes âgées de 60 ans et plus
            </li>
            <li>
              Résidant en France
            </li>
            <li>
              En situation de perte d'autonomie
            </li>
          </ul>
        </span>

      </div>
    </>
  )
}
