import React from 'react'
import { useParams } from 'react-router-dom'
import { PageBannerType1 } from '../GlobalComponents/OtherOnWebsite';
import BlogArticleDetail from '../RoutesSubComponents/blog/article';
import BlogArticles from '../RoutesSubComponents/blog/articles';
import { SimulationLink } from '../GlobalComponents/Btn';
import { bothAuth } from '../hocs/bothAuth';

function Blog() {
    const { articleId } = useParams();

    function dispatchBlogComponent() {
        if (articleId) {
            return <BlogArticleDetail articleId={articleId} />
        } else {
            return <>
                <PageBannerType1 title={'Blog'} />
                <BlogArticles />
            </>
        }
    }
    return (
        <div>
            {
                dispatchBlogComponent()
            }
            <div className='makeSimulationCallToAction1'>
                <h2>Faites votre estimation</h2>
                <SimulationLink />
            </div>
        </div>
    )
}

export default bothAuth(Blog)