export const SimpleImage = ({ props }) => {
    const { src, alt, className } = props;
    return <img alt={alt}
        src={require('../../Assets/images/' + src)} className={className ? className : ''} />
}

export const IllustrationImage = ({ props }) => {
    const { src, alt, className } = props;
    return <img alt={alt}
        src={require('../../Assets/images/illustrations/' + src)} className={className} />
}


export const UrlImage = ({ props }) => {
    const { src, alt, rounded, className, id } = props;
    return <img alt={alt} className={className + ' ' + ((rounded) ? 'rounded' : '')}
        src={src} id={id} />
}