import { SimpleImage, IllustrationImage } from "./img/global";
export const ResumeDeLaCommande = ({ props }) => {

    return <section className='paiementResumé'>
        <h2>Résumé de la commande</h2>
        <br />
        <p>
            Un expert dédié qui vous accompagne dans toutes vos démarches.
        </p>
        <br /><br /><br />
        <div>
            <h2>Nos résultats :</h2>
            <br />
            <p>
                <b>267€</b>
                reçus par nos utilisateurs
                par mois en moyenne
            </p>
            <br />
            <p>
                <b>16 547</b>
                Nouveaux bénéficiaires
                d’aides grâce à nous
            </p>
        </div>

    </section>

}

export function PageBannerType1({ title, withSearch }) {
    return <div className="pageBannerType1">
        <h1>{title}</h1>
    </div>
}
export function PageTitleType1({ title }) {
    return <div className="pageTitleType1">
        <h2>{title} </h2>
    </div>
}


export function SuccessOperationModal({ text }) {
    return <div className='successOperationModal'>
        <IllustrationImage props={{
            src: "goodAchievement.svg",
            alt: "Success operation modal for prelevement"
        }} />
        <h3>
            {text}
        </h3>
    </div>
}
