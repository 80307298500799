export function g_Asf() {
  return (
    <>
      <div className="caf-wrapper">
        <p>
          L’allocation de soutien familial (ASF) est une aide financière versée aux parents isolés qui élèvent seuls un enfant ou dont le montant de la pension alimentaire est faible.
          <p>
            L’ASF est versée par la Caisse d’allocations familiales (CAF) ou la Mutualité sociale agricole (MSA) selon la caisse dont dépend l’assuré.
          </p>
        </p>
        <h1>Qui peut bénéficier de l’allocation de soutien familial ?</h1>
        <span>
          Dans tous les cas, pour avoir droit à l'ASF, vous devez remplir l'ensemble des conditions suivantes :
          <ul>
            <li>
              Vivre seul(e)
            </li>
            <li>
              Résider en France
            </li>
            <li>
              Avoir au moins 1 enfant à charge pour lequel l'autre parent ne participe plus à l'entretien depuis au moins 1 mois
            </li>
          </ul>
        </span>

      </div>
    </>
  )
}
