import React, { useEffect } from 'react'
import UpdateGeneral from './general';
import AddChildren from './child';
import { useAuth } from '../../../../contexts/auth';
function UpdateProfil({ authedInfos }) {
    const { user } = useAuth();
    return (
        <>
            <section>
                <h3>Modifier vos informations</h3>
                <UpdateGeneral />
            </section>
            {
                user.situation_familiale === "marié_avec_enfant" ?
                    <section>
                        <h3>Ajouter des enfants</h3>
                        <AddChildren />
                    </section> : null
            }
        </>
    )
}

export default UpdateProfil
