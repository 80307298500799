
import React, { useEffect, useLayoutEffect, useState } from "react";
import { NextSimulationStepButton, PrevSimulationStep } from "../../../GlobalComponents/simulation/nav";
import { dateEdit } from "../../../helper/SimulationMath"
import "./revenus.css";

function Revenus({ setSection, setProgress, setData, data }) {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = yyyy + "-" + mm + "-" + dd;
  var today1 = new Date(dateEdit(mm, -1, yyyy).yyyy + "-" + dateEdit(mm, -1, yyyy).newMonth + "-" + dd);
  var today2 = new Date(dateEdit(mm, -2, yyyy).yyyy + "-" + dateEdit(mm, -2, yyyy).newMonth + "-" + dd);
  var today3 = new Date(dateEdit(mm, -3, yyyy).yyyy + "-" + dateEdit(mm, -3, yyyy).newMonth + "-" + dd);
  const month1 = today1.toLocaleString("fr", { month: "long" });
  const month2 = today2.toLocaleString("fr", { month: "long" });
  const month3 = today3.toLocaleString("fr", { month: "long" });

  const [page, setPage] = useState(data.pensionInvalidite ? 5 : 1);
  const [revenus, setRevenus] = useState({
    mesRevenus: "",
    mesRevenusVal: "",
    conjointRevenus: "",
    mesRevenusValMulti1: "",
    mesRevenusValMulti2: "",
    mesRevenusValMulti3: "",
    conjointRevenusVal: "",
    conjointRevenusValMulti1: "",
    conjointRevenusValMulti2: "",
    conjointRevenusValMulti3: "",
    conjointPensionInvaliditeVal: "",
    PensionInvaliditeVal: "",
    aidesSociales: "",
    aidesSocialesTab: []
  });

  function singleSelect(e, cl) {
    let doc = document.querySelectorAll(cl);
    if (!revenus[e.target.id] || revenus[e.target.id] !== e.target.innerText) {
      setRevenus({ ...revenus, [e.target.id]: e.target.innerText });
      for (let i = 0; i < doc.length; i++) {
        doc[i].classList.remove("selected");
      }
      e.target.classList.add("selected");
    } else {
      setRevenus({ ...revenus, [e.target.id]: "" });
      e.target.classList.remove("selected");
    }
  }
  function multiSelect(e, cl) {
    if (!revenus[e.target.id].includes(e.target.innerText)) {
      setRevenus({
        ...revenus,
        [e.target.id]: [...revenus[e.target.id], e.target.innerText],
      });

      e.target.classList.add("selected");
    } else {
      setRevenus({
        ...revenus,
        [e.target.id]: revenus[e.target.id].filter(
          (el) => el !== e.target.innerText
        ),
      });
      // profil[e.target.id].filter(el=>el!==e.target.id)
      e.target.classList.remove("selected");
    }
  }

  useLayoutEffect(() => {
    let mesRevenus = document.querySelectorAll(".mesRevenus");
    let conjointRevenus = document.querySelectorAll(".conjointRevenus");
    let aidesSociales = document.querySelectorAll(".aidesSociales");
    let aidesSocialesTab = document.querySelectorAll(".aidesSocialesTab");

    if (revenus.mesRevenus && mesRevenus) {
      for (let i = 0; i < mesRevenus.length; i++) {
        if (mesRevenus[i].innerText === revenus.mesRevenus)
          mesRevenus[i].classList.add("selected");
      }
    }
    if (revenus.conjointRevenus && conjointRevenus) {
      for (let i = 0; i < conjointRevenus.length; i++) {
        if (conjointRevenus[i].innerText === revenus.conjointRevenus)
          conjointRevenus[i].classList.add("selected");
      }
    }
    if (revenus.aidesSociales && aidesSociales) {
      for (let i = 0; i < aidesSociales.length; i++) {
        if (aidesSociales[i].innerText === revenus.aidesSociales)
          aidesSociales[i].classList.add("selected");
      }
    }
    if (revenus.aidesSocialesTab.length && aidesSocialesTab) {
      for (let i = 0; i < aidesSocialesTab.length; i++) {
        if (
          revenus.aidesSocialesTab.includes(
            aidesSocialesTab[i].innerText
          )
        )
          aidesSocialesTab[i].classList.add("selected");
      }
    }
  }, [revenus, page]);

  useEffect(() => {
    window.scrollTo(0, 160);
  }, [page]);

  const normalizeAgeNumber = (value) => {
    return value.replace(/\D/gi, "") || "";
  };

  return (
    <>
      {page === 5 && (
        <div className="loyer-wrap">
          <span>Ma pension d’invalidité</span>
          <div className="simulateur-age loyer">
            <div>
              <input
                // ref={ageRef}
                value={revenus.PensionInvaliditeVal}
                type="tel"
                autoFocus
                maxLength="4"
                placeholder="0"
                onChange={(e) => {
                  e.target.value = normalizeAgeNumber(e.target.value);
                  setRevenus({ ...revenus, PensionInvaliditeVal: e.target.value });
                }}
              />
            </div>
            <span>€</span>
          </div>

          <div className="button-simulateur">
            <PrevSimulationStep props={{
              setPage, prevPage: 1, anotherOperation: function () {
                setSection("mon logement");
                setProgress(60);
              }
            }} />
            <button
              disabled={!revenus.PensionInvaliditeVal}
              style={{ backgroundColor: revenus.PensionInvaliditeVal ? "var(--colorPrimary)" : "#efefef" }}
              onClick={() => {
                setPage(1)
              }}
            >
              <span>Suivant</span>
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      )
      }
      {
        page === 1 && (
          <div className="simulateur-nationalite">
            <span>Mes revenus sont stables d'un mois à l'autre</span>
            <div>
              <div
                className="mesRevenus"
                onClick={(e) => singleSelect(e, ".mesRevenus")}
                id="mesRevenus"
              >
                Oui
              </div>
              <div
                className="mesRevenus"
                onClick={(e) => singleSelect(e, ".mesRevenus")}
                id="mesRevenus"
              >
                Non
              </div>
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{
                setPage, prevPage: 1, anotherOperation: function () {
                  if (data.pensionInvalidite)
                    setPage(5)
                  else {
                    setSection("mon logement");
                    setProgress(60);
                  }
                }
              }} />

              <NextSimulationStepButton disabledCond={!revenus.mesRevenus}
                onClick={() => {
                  switch (revenus.mesRevenus) {
                    case "Oui":
                      setRevenus({ ...revenus, mesRevenusValMulti1: "", mesRevenusValMulti2: "", mesRevenusValMulti3: "" })
                      setPage(1.1);
                      break;
                    default:
                      setRevenus({ ...revenus, mesRevenusVal: "" })
                      setPage(1.2);
                      break;
                  }
                }}
              />
            </div>
          </div >
        )
      }

      {
        page === 1.1 && (
          <div className="loyer-wrap">
            <span>Mes revenus mensuels nets</span>
            <div className="simulateur-age loyer">
              <div>
                <input
                  // ref={ageRef}
                  value={revenus.mesRevenusVal}
                  type="tel"
                  autoFocus
                  maxLength="4"
                  placeholder="0"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(e.target.value);
                    setRevenus({ ...revenus, mesRevenusVal: e.target.value });
                  }}
                />
              </div>
              <span>€</span>
            </div>
            <span
              style={{
                color: "	#3c3c3c",
                fontSize: 13,
                display: "block",
                textAlign: "left",
                marginTop: 5,
              }}
            >
              Correspond à<br />
              <br />
              - mon salaire, retraite ou chômage nets (ce que je reçois sur mon
              compte en banque)
              <br />
              - mes pensions alimentaires perçues
              <br />
              - mon bénéfice net imposable mensualisé si je suis indépendant
              <br />
              <br />
              N’inclut pas les aides que je reçois de la CAF ou de tout autre
              organisme public (ex: RSA), ni les revenus des autres personnes de
              mon foyer
            </span>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!revenus.mesRevenusVal}
                onClick={() => {
                  if (data.etatLegal === "Marié(e)" || data.etatLegal === "Pacsé(e)" || data.etatLegal === "En concubinage") {
                    if (data.conjointPensionInvalidite)
                      setPage(3)
                    else
                      setPage(2);
                  } else {
                    setPage(6)
                  }
                }}
              />
            </div>
          </div >
        )
      }

      {
        page === 1.2 && (
          <div className="loyer-wrap">
            <span>Mes 3 derniers revenus mensuels nets</span>
            <div className="simulateur-age revenuMulti">
              <div>
                <span
                  style={{
                    position: "relative",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    marginTop: 15,
                    color: "#3c3c3c",
                  }}
                >
                  {month1} {today1.getFullYear()}
                </span>
                <input
                  // ref={ageRef}
                  value={revenus.mesRevenusValMulti1}
                  type="tel"
                  autoFocus
                  maxLength="4"
                  placeholder="0"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(e.target.value);
                    setRevenus({
                      ...revenus,
                      mesRevenusValMulti1: e.target.value,
                    });
                  }}
                />
              </div>
              <span>€</span>
            </div>
            <div className="simulateur-age revenuMulti">
              <div>
                <span
                  style={{
                    position: "relative",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    marginTop: 15,
                    color: "#3c3c3c",
                  }}
                >
                  {month2} {today2.getFullYear()}
                </span>
                <input
                  // ref={ageRef}
                  value={revenus.mesRevenusValMulti2}
                  type="tel"
                  maxLength="4"
                  placeholder="0"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(e.target.value);
                    setRevenus({
                      ...revenus,
                      mesRevenusValMulti2: e.target.value,
                    });
                  }}
                />
              </div>
              <span>€</span>
            </div>
            <div className="simulateur-age revenuMulti">
              <div>
                <span
                  style={{
                    position: "relative",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    marginTop: 15,
                    color: "#3c3c3c",
                  }}
                >
                  {month3} {today3.getFullYear()}
                </span>
                <input
                  // ref={ageRef}
                  value={revenus.mesRevenusValMulti3}
                  type="tel"
                  maxLength="4"
                  placeholder="0"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(e.target.value);
                    setRevenus({
                      ...revenus,
                      mesRevenusValMulti3: e.target.value,
                    });
                  }}
                />
              </div>
              <span>€</span>
            </div>
            <span
              style={{
                color: "#3c3c3c",
                fontSize: 13,
                display: "block",
                textAlign: "left",
                marginTop: 5,
              }}
            >
              Correspond à<br />
              <br />
              - mon salaire, retraite ou chômage nets (ce que je reçois sur mon
              compte en banque)
              <br />
              - mes pensions alimentaires perçues
              <br />
              - mon bénéfice net imposable mensualisé si je suis indépendant
              <br />
              <br />
              N’inclut pas les aides que je reçois de la CAF ou de tout autre
              organisme public (ex: RSA), ni les revenus des autres personnes de
              mon foyer
            </span>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!revenus.mesRevenusValMulti1 || !revenus.mesRevenusValMulti2 || !revenus.mesRevenusValMulti3}
                onClick={() => {
                  if (data.etatLegal === "Marié(e)" || data.etatLegal === "Pacsé(e)" || data.etatLegal === "En concubinage") {
                    setPage(2);
                  } else {
                    setPage(6)
                  }
                }}
              />
            </div>
          </div>
        )
      }


      {
        page === 3 && (
          <div className="loyer-wrap">
            <span>La pension d’invalidité de mon conjoint</span>
            <div className="simulateur-age loyer">
              <div>
                <input
                  // ref={ageRef}
                  value={revenus.conjointPensionInvaliditeVal}
                  type="tel"
                  autoFocus
                  maxLength="4"
                  placeholder="0"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(e.target.value);
                    setRevenus({ ...revenus, conjointPensionInvaliditeVal: e.target.value });
                  }}
                />
              </div>
              <span>€</span>
            </div>

            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!revenus.conjointPensionInvaliditeVal}
                onClick={() => {
                  setPage(2)
                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 2 && (
          <div className="simulateur-nationalite">
            <span>
              Les revenus de mon conjoint sont stables d'un mois à l'autre
            </span>
            <div>
              <div
                className="conjointRevenus"
                onClick={(e) => singleSelect(e, ".conjointRevenus")}
                id="conjointRevenus"
              >
                Oui
              </div>
              <div
                className="conjointRevenus"
                onClick={(e) => singleSelect(e, ".conjointRevenus")}
                id="conjointRevenus"
              >
                Non
              </div>
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!revenus.conjointRevenus}
                onClick={() => {
                  switch (revenus.conjointRevenus) {
                    case "Oui":
                      setRevenus({ ...revenus, conjointRevenusValMulti1: "", conjointRevenusValMulti2: "", conjointRevenusValMulti3: "" })

                      setPage(2.1);
                      break;
                    default:
                      setRevenus({ ...revenus, conjointRevenusVal: "" })

                      setPage(2.2);
                      break;
                  }
                }}
              />
            </div>
          </div >
        )
      }

      {
        page === 2.1 && (
          <div className="loyer-wrap">
            <span>Les revenus mensuels nets de mon conjoint</span>
            <div className="simulateur-age loyer">
              <div>
                <input
                  // ref={ageRef}
                  value={revenus.conjointRevenusVal}
                  type="tel"
                  autoFocus
                  maxLength="4"
                  placeholder="0"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(e.target.value);
                    setRevenus({
                      ...revenus,
                      conjointRevenusVal: e.target.value,
                    });
                  }}
                />
              </div>
              <span>€</span>
            </div>

            <span
              style={{
                color: "	#3c3c3c",
                fontSize: 13,
                display: "block",
                textAlign: "left",
                marginTop: 5,
              }}
            >
              Correspond à<br />
              <br />
              - mon salaire, retraite ou chômage nets (ce que je reçois sur mon
              compte en banque)
              <br />
              - mes pensions alimentaires perçues
              <br />
              - mon bénéfice net imposable mensualisé si je suis indépendant
              <br />
              <br />
              N’inclut pas les aides que je reçois de la CAF ou de tout autre
              organisme public (ex: RSA), ni les revenus des autres personnes de
              mon foyer
            </span>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 2 }} />
              <NextSimulationStepButton disabledCond={!revenus.conjointRevenusVal}
                onClick={() => {
                  setPage(6)
                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 2.2 && (
          <div className="loyer-wrap">
            <span>Les 3 derniers revenus mensuels nets de mon conjoint</span>
            <div className="simulateur-age revenuMulti">
              <div>
                <span
                  style={{
                    position: "relative",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    marginTop: 15,
                    color: "#3c3c3c",
                  }}
                >
                  {month1} {today1.getFullYear()}
                </span>
                <input
                  // ref={ageRef}
                  value={revenus.conjointRevenusValMulti1}
                  type="tel"
                  autoFocus
                  maxLength="4"
                  placeholder="0"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(e.target.value);
                    setRevenus({
                      ...revenus,
                      conjointRevenusValMulti1: e.target.value,
                    });
                  }}
                />
              </div>
              <span>€</span>
            </div>
            <div className="simulateur-age revenuMulti">
              <div>
                <span
                  style={{
                    position: "relative",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    marginTop: 15,
                    color: "#3c3c3c",
                  }}
                >
                  {month2} {today2.getFullYear()}
                </span>
                <input
                  // ref={ageRef}
                  value={revenus.conjointRevenusValMulti2}
                  type="tel"
                  maxLength="4"
                  placeholder="0"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(e.target.value);
                    setRevenus({
                      ...revenus,
                      conjointRevenusValMulti2: e.target.value,
                    });
                  }}
                />
              </div>
              <span>€</span>
            </div>
            <div className="simulateur-age revenuMulti">
              <div>
                <span
                  style={{
                    position: "relative",
                    textTransform: "capitalize",
                    fontWeight: 600,
                    marginTop: 15,
                    color: "#3c3c3c",
                  }}
                >
                  {month3} {today3.getFullYear()}
                </span>
                <input
                  // ref={ageRef}
                  value={revenus.conjointRevenusValMulti3}
                  type="tel"
                  maxLength="4"
                  placeholder="0"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(e.target.value);
                    setRevenus({
                      ...revenus,
                      conjointRevenusValMulti3: e.target.value,
                    });
                  }}
                />
              </div>
              <span>€</span>
            </div>
            <span
              style={{
                color: "#3c3c3c",
                fontSize: 13,
                display: "block",
                textAlign: "left",
                marginTop: 5,
              }}
            >
              Correspond à<br />
              <br />
              - mon salaire, retraite ou chômage nets (ce que je reçois sur mon
              compte en banque)
              <br />
              - mes pensions alimentaires perçues
              <br />
              - mon bénéfice net imposable mensualisé si je suis indépendant
              <br />
              <br />
              N’inclut pas les aides que je reçois de la CAF ou de tout autre
              organisme public (ex: RSA), ni les revenus des autres personnes de
              mon foyer
            </span>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 2 }} />
              <NextSimulationStepButton disabledCond={!revenus.conjointRevenusValMulti1 || !revenus.conjointRevenusValMulti2 || !revenus.conjointRevenusValMulti3}
                onClick={() => {
                  setPage(6)
                }}
              />
            </div>
          </div>
        )
      }
      {
        page === 6 && (
          <div className="simulateur-nationalite">
            <span>
              Je reçois déjà des aides sociales
            </span>
            <div>
              <div
                className="aidesSociales"
                onClick={(e) => singleSelect(e, ".aidesSociales")}
                id="aidesSociales"
              >
                Oui
              </div>
              <div
                className="aidesSociales"
                onClick={(e) => singleSelect(e, ".aidesSociales")}
                id="aidesSociales"
              >
                Non
              </div>
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!revenus.aidesSociales}
                onClick={() => {
                  switch (revenus.aidesSociales) {
                    case "Oui":
                      setPage(6.1);
                      break;
                    default:
                      setRevenus({ ...revenus, aidesSocialesTab: [] })
                      setData((data) => {
                        return { ...data, ...revenus, aidesSocialesTab: [] };
                      });
                      setSection("mes informations");
                      setProgress(100);
                      break;
                  }
                }}
              />
            </div>
          </div >
        )
      }

      {
        page === 6.1 && (
          <div className="simulateur-nationalite">
            <span>
              Sélectionnez les aides que vous recevez déjà :
              <span
                style={{ color: "#3c3c3c", fontSize: 13, display: "block" }}
              >
                Plusieurs réponses possibles
              </span>
            </span>
            <div>


              <div
                className="aidesSocialesTab"
                onClick={(e) => multiSelect(e, ".aidesSocialesTab")}
                id="aidesSocialesTab"
              >
                RSA
              </div>
              <div
                className="aidesSocialesTab"
                onClick={(e) => multiSelect(e, ".aidesSocialesTab")}
                id="aidesSocialesTab"
              >
                ASPA
              </div>
              <div
                className="aidesSocialesTab"
                onClick={(e) => multiSelect(e, ".aidesSocialesTab")}
                id="aidesSocialesTab"
              >
                Prime d'activité
              </div>
              <div
                className="aidesSocialesTab"
                onClick={(e) => multiSelect(e, ".aidesSocialesTab")}
                id="aidesSocialesTab"
              >
                AAH
              </div>
              <div
                className="aidesSocialesTab"
                onClick={(e) => multiSelect(e, ".aidesSocialesTab")}
                id="aidesSocialesTab"
              >
                AEEH
              </div>
              <div
                className="aidesSocialesTab"
                onClick={(e) => multiSelect(e, ".aidesSocialesTab")}
                id="aidesSocialesTab"
              >
                MVA
              </div>
              <div
                className="aidesSocialesTab"
                onClick={(e) => multiSelect(e, ".aidesSocialesTab")}
                id="aidesSocialesTab"
              >
                CSS
              </div>
              <div
                className="aidesSocialesTab"
                onClick={(e) => multiSelect(e, ".aidesSocialesTab")}
                id="aidesSocialesTab"
              >
                ASI
              </div>
              <div
                className="aidesSocialesTab"
                onClick={(e) => multiSelect(e, ".aidesSocialesTab")}
                id="aidesSocialesTab"
              >
                ASF
              </div>
              <div
                className="aidesSocialesTab"
                onClick={(e) => multiSelect(e, ".aidesSocialesTab")}
                id="aidesSocialesTab"
              >
                Complément familial
              </div>
              <div
                className="aidesSocialesTab"
                onClick={(e) => multiSelect(e, ".aidesSocialesTab")}
                id="aidesSocialesTab"
              >
                PAJE
              </div>
              <div
                className="aidesSocialesTab"
                onClick={(e) => multiSelect(e, ".aidesSocialesTab")}
                id="aidesSocialesTab"
              >
                APL
              </div>
              <div
                className="aidesSocialesTab"
                onClick={(e) => multiSelect(e, ".aidesSocialesTab")}
                id="aidesSocialesTab"
              >
                Allocations familiales
              </div>
              <div
                className="aidesSocialesTab"
                onClick={(e) => multiSelect(e, ".aidesSocialesTab")}
                id="aidesSocialesTab"
              >
                APA
              </div>
              <div
                className="aidesSocialesTab"
                onClick={(e) => multiSelect(e, ".aidesSocialesTab")}
                id="aidesSocialesTab"
              >
                Garantie Jeunes
              </div>
              <div
                className="aidesSocialesTab"
                onClick={(e) => multiSelect(e, ".aidesSocialesTab")}
                id="aidesSocialesTab"
              >
                Chèque énergie
              </div>
              <div
                className="aidesSocialesTab"
                onClick={(e) => multiSelect(e, ".aidesSocialesTab")}
                id="aidesSocialesTab"
              >
                Mobili Jeune
              </div>
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 6 }} />
              <NextSimulationStepButton disabledCond={!revenus.aidesSocialesTab.length}
                onClick={() => {
                  setSection("mes informations");
                  setProgress(100);
                  setData((data) => {
                    return { ...data, ...revenus };
                  });
                }}
              />
            </div>
          </div >
        )
      }
    </>
  );
}

export default Revenus;
