import React from 'react'
import { IllustrationImage } from '../img/global'

function DataNotFoundMessage({ text }) {
    return (
        <div className='notFound'>
            <IllustrationImage props={{
                src: 'no_data.svg',
                alt: 'No dat found image'
            }} />
            <br />
            <h3>{text} </h3>
        </div>
    )
}

export default DataNotFoundMessage