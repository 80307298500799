import { toast } from "react-toastify"


export function successToast(toastMsg) {
    toast.success(toastMsg, {
        autoClose: 2000,
        hideProgressBar: true,
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: 'khalass-bien mangé'
    })
}

export function errorToast(toastMsg) {
    toast.error(toastMsg, {
        autoClose: 2000,
        hideProgressBar: true,
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: 'khalass-bien mangé'
    })
}

export const adminApiBaseUrl = process.env.NODE_ENV == 'production' ?
    'https://dashboard.france-assist.fr' : 'http://localhost:5110'