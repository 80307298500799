export function g_Ce() {
  return (
    <>
      <div className="caf-wrapper">


        <p>
          L’aide énergie est une aide financière qui permet de voir le coût de
          vos travaux diminuer. Elle est destinée aux travaux de rénovation
          des installations énergétiques de votre logement (chaudière,
          isolation thermique du logement, etc.). Si vous souhaitez donc
          entreprendre de tels travaux, vous pourrez sans doute bénéficier
          d’un coup de pouce financier.
        </p>
        <h1>Le chèque énergie</h1>
        <p>
          Ce chèque est destiné aux foyers aux ressources modestes.
          L’administration fiscale établit chaque année la liste des
          bénéficiaires, en fonction du revenu fiscal de référence et de la
          composition du foyer.
        </p>

      </div>
    </>
  );
}

