export const faqs = {
    géneral: [
        {
            question: "Comment ça marche ?",
            response: "France Assist est votre partenaire en aides financières. En quelques clics, vous pouvez comprendre votre situation et à quelles aides et dispositifs vous êtes éligible via notre simulateur gratuit en ligne ou par téléphone avec l'un de nos experts, sans engagement. A l'issue de ce test de qualification, vous pouvez choisir l'accompagnement d'un de nos experts. Ce type d'accompagnement assuré par des experts dédiés est payant, jusqu'à 29,90 euros TTC par trimestre (résiliable à tout moment). Votre expert vous accompagnera dans toutes les démarches où vous produisez des pièces justificatives pour demander toutes les aides auxquelles vous avez droit. Si vous ne souhaitez pas que quelqu'un vous accompagne, vous êtes bien entendu entièrement libre de soumettre vous-même divers documents.",
        },
        {
            question: "Qui est France Assist ?",
            response: "France Assist est une société privée et indépendante, distincte des organismes sociaux qui propose un service en ligne d’assistance administrative et sociale aux particuliers et aux entreprises. Nous sommes partis d’un constat simple: plusieurs milliards d’euros d’aides sont non réclamées chaque année et plus 20% des personnes éligibles ignorent leurs droits. Nous voulons faire le lien entre les particuliers et les organismes sociaux, débordés, afin de faire valoir le droit aux aides des personnes éligibles.",
        },
        {
            question: "Que faire si je ne suis éligible à aucune aide ?",
            response: "Si vous n'êtes pas éligible à des aides, cela signifie que votre situation personnelle est relativement bonne et que vous n'avez pas besoin de l'aide de l'Etat : c'est donc plutôt une bonne nouvelle. Si vous n’êtes pas éligible aujourd’hui, nous vous invitons à refaire une simulation dans quelques mois ou dès que votre situation familiale et/ou professionnelle aura changé",
        },
        {
            question: "A combien aurai-je droit ?",
            response: "En moyenne, les clients qui ont opté pour notre service d’accompagnement perçoivent 3400€ par an d’aides financières. Cependant, ce chiffre est une moyenne. Le montant d'aides que vous pouvez obtenir dépend de beaucoup de critères, nous vous invitons donc à faire la simulation pour savoir à combien vous avez droit selon votre situation personnelle et professionnelle.",
        },
        {
            question: "Que se passe-t-il si je fournis de fausses informations sur ma situation personnelle et/ou professionnelle ?",
            response: "Vous êtes entièrement responsable des informations que vous nous fournissez (telles que salaire, loyer, état civil, statut professionnel). Les experts de France Assist et l'organisation recevant les fichiers peuvent vérifier automatiquement vos fichiers." +
                "Nous utilisons des fichiers de support pour vérifier toutes les informations fournies par l'utilisateur. Ainsi, France Assit contribue à lutter contre la fraude.",
        },


    ],
    tarifs: [
        {
            question: "Combien ça coûte ?",
            response: "La simulation de qualification des aides est entièrement gratuite et sans engagement. Cela peut être fait en ligne ou par téléphone par l'un de nos experts. Cependant, l'accompagnement des experts dédiés de France Assist est payant. Cet expert soutiendra vos efforts et vous apportera toute l'aide à laquelle vous avez droit pour augmenter votre pouvoir d'achat. Vous pouvez payer directement sur le site à la fin de la simulation. Le coût est de 29.90€ TTC par trimestre (peut être annulé à tout moment) Enfin, si vous ne souhaitez pas être accompagné par France Assit, vous êtes bien entendu libre de déposer vos différents documents de demande d'aide directement à l'organisme sans passer par notre prestations de service.",
        },
        {
            question: "Peut-on résilier l'abonnement ?",
            response: "Oui, l'abonnement au service d'aide administrative de France Assit est résiliable à tout moment, sans frais.",
        },
    ]
}