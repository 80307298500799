import axios from "axios"
import { useEffect, useState } from "react"
import { FormButton } from "../uikits/buttons"
import { FormikProvider, useFormik } from "formik"
import { Form } from "formik"
import { SimpleInputWithFormik } from "../uikits/form/simple"
import { allFields } from "../helper/form/fields"
import { ResetPasswordStep1, ResetPasswordStep2 } from "../helper/form/validations"
import { InputPasswordWithFormik } from "../uikits/form/password"
import { errorToast, successToast } from "../helper/others"

export const SearchMail = ({ fields }) => {
    const [searchSuccess, setSS] = useState(false)
    const [searchErr, setSE] = useState("")
    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validateOnMount: true,
        validationSchema: ResetPasswordStep1,
        onSubmit: handleSubmit,
    });

    const { isValid, resetForm } = formik;

    function handleSubmit(formValues) {
        axios.post('/user/password', formValues)
            .then(res => {
                resetForm()
                setSS(res.data.message)
            })
            .catch(err => setSE("Email non trouvé"))
    }


    return <section>
        <h2>Veuillez saisir le mail utilisé lors de votre inscription</h2>
        <b className="errorField"> {searchErr} </b>
        <b className="successField">{searchSuccess} </b>
        <FormikProvider value={formik}>
            <Form>
                <SimpleInputWithFormik {...allFields.mail} />
                <FormButton text="Rechercher mon mail"
                    isValid={isValid} />
            </Form>
        </FormikProvider>
    </section>
}

export const ChangePassword = ({ props }) => {
    const { passwordId, passwordToken } = props
    const formik = useFormik({
        initialValues: { password: "", password_confirmation: "", id: passwordId, token: passwordToken },
        onSubmit: handleSubmit,
        validateOnMount: true,
        validationSchema: ResetPasswordStep2
    });
    const { isValid } = formik;
    const [viewUpdateForm, setVUF] = useState(false);

    function handleSubmit(formValues) {
        axios.post("/user/change-password", formValues)
            .then(res => {
                if (res.data.message) {
                    successToast('Mot de passe modifié avec success')
                }
            })
            .catch(err => {
                errorToast('Une erreur s\'est produite')
            })
    }

    useEffect(() => {
        axios.post("/user/password-reset",
            { id: passwordId, token: passwordToken })
            .then(res => setVUF(true))
            .catch(err => setVUF(false))
    }, [props])

    return <section>
        {
            viewUpdateForm ?
                <>
                    <h2>Veuillez saisir le nouveau mot de passe</h2>
                    <FormikProvider value={formik}>
                        <Form>
                            <InputPasswordWithFormik {...allFields.password} />
                            <InputPasswordWithFormik {...allFields.password_confirmation} />

                            <FormButton
                                text="Valider"
                                isValid={isValid}
                            />
                        </Form>
                    </FormikProvider>
                </>
                : <h2>Desolé,Vous ne pouvez pas modifié votre mot de passe</h2>
        }
    </section>
}