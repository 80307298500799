import axios from "axios";
import { useAuth } from "../../../contexts/auth";
import { errorToast, successToast } from "../../../helper/others";
import { Form, FormikProvider, useFormik } from "formik";
import { newDocFields } from "../../../helper/form/fields";
import FormikFieldController from "../../../uikits/form";
import { FormButton } from "../../../uikits/buttons";
import { NewDocSchema } from "../../../helper/form/validations";

export default function AddDocument({ props }) {
    const { agent } = props;
    const { user } = useAuth()
    const authedInfos = user;

    const formik = useFormik({
        initialValues: { docFiles: [], title: '', clientId: authedInfos._id, userId: agent._id },
        validateOnMount: true,
        validationSchema: NewDocSchema,
        onSubmit: handleSubmit
    });

    const { isValid } = formik;

    function handleSubmit(formValues) {
        let newdoc = {
            titre: formValues.title,
            // file: formValues.file_1,
            clientId: formValues.clientId,
            userId: formValues.userId
        }

        let formData = new FormData();
        formData.append("info", JSON.stringify(newdoc));

        for (let index = 0; index < formValues.docFiles.length; index++) {
            formData.append("files", formValues.docFiles[index])
        }

        axios.post('/user/email', formData)
            .then(res => {
                if (res.data._id) {
                    successToast('Document ajouté avec success !');
                    // window.location = '/Documents/liste'
                }
            })
            .catch(err => errorToast('Une erreur est survenue'))
            .finally(() => {
            })
    }
    return <section className='fAddDoc'>
        <h2>Ajouter un document</h2>
        <FormikProvider value={formik}>
            <Form>
                <div className='formSegment'>
                    <label>Expert</label>
                    <section>
                        <input type="text" placeholder="Nom de l'expert"
                            value={agent.prenom + ' ' + agent.nom} readOnly />
                    </section>
                </div>
                {
                    newDocFields.map((item, i) => <FormikFieldController
                        {...item}
                        key={"ndf " + i}
                    />)
                }
                <FormButton
                    text="Soumettre"
                    isValid={isValid}
                />
            </Form>
        </FormikProvider>


    </section >
}

