import { useState } from 'react';
import axios from 'axios';
import useBlogComments from '../../Hooks/blog/useBlogComments';
import { useAuth } from '../../contexts/auth';
import { successToast } from '../../helper/others';
import { Form, FormikProvider, useFormik } from 'formik';
import { blogCommentsFields } from '../../helper/form/fields';
import FormikFieldController from '../../uikits/form';
import { FormButton } from '../../uikits/buttons';
import { CommentSchema } from '../../helper/form/validations';
import { IonMdPerson } from '../../GlobalComponents/icons';


export function ArticleCommentsSection({ apiUrl, articleId }) {
    const { user } = useAuth();
    const authed = user;
    const formik = useFormik({
        initialValues: {
            autorName: authed ? (authed.nom + ' ' + authed.prenom) : "",
            autorEmail: authed ? authed.email : '', message: ''
        },
        validateOnMount: true,
        validationSchema: CommentSchema
    })
    const { isValid, resetForm } = formik;
    const { blogComments, refetchDatas } = useBlogComments(articleId);
    const [commentResponsesVisibleId, setCRVI] = useState(-1);
    const [commentFormVisibleId, setCFVI] = useState(-1);


    function toggleAllCommentResponse(i) {
        setCRVI((prev) => {
            return prev == i ? -1 : i;
        })
    }

    function handleCommentAdd() {
        axios.post(apiUrl + '/admin/blog/comment/add', { ...formik.values, articleId })
            .then(res => successToast('Commentaire ajouté'))
            .catch(err => console.log('blog err'))
            .finally(() => {
                refetchDatas()
                resetForm()
            })
    }

    function handleCommentResponseAdd(commentId) {
        axios.post(apiUrl + '/admin/blog/comment/' + commentId + '/response/add', formik.values)
            .then(res => {
                successToast('Reponse ajouté');
            })
            .catch(err => console.log('blog err'))
            .finally(() => {
                refetchDatas()
                setCFVI(-1)
                resetForm()
            })
    }

    function toggleCurrentForm(i) {
        setCFVI((prev) => {
            return prev == i ? -1 : i;
        })
    }
    return <div className="articleCommentSection">
        <h2>Espace commentaire</h2>

        <br /><br />
        <div className="accCommentList">
            {
                blogComments.length ? <>
                    {
                        blogComments.map((bc, i) => <article className='commentSection' key={'blog com' + i}>
                            <span>
                                <IonMdPerson />
                            </span>
                            <div>
                                <b>{bc.autorName}</b>
                                {/* {bc._id} */}
                                <br />
                                <p>
                                    {
                                        bc.message
                                    }
                                    <br />
                                </p>
                                <br />
                                <section className='cs-actions'>
                                    <button onClick={() => {
                                        toggleCurrentForm(i + 1)
                                    }}>Repondre</button>
                                    <button onClick={() => toggleAllCommentResponse(i + 1)}>
                                        Voir les reponses</button>
                                </section>
                                <section className={'accCommentResponsesList '
                                    + (commentResponsesVisibleId == (i + 1) ? 'accCommentResponsesListVisible' : '')}>
                                    {
                                        bc.responses.length > 0 ? <>
                                            {
                                                bc.responses.map((bcr, j) => <ResponseCommentLayout
                                                    response={bcr}
                                                    key={'commen' + i + 'response ' + j}
                                                />)
                                            }
                                        </>
                                            : 'Aucune reponse a commentaire'
                                    }
                                </section>
                                <FormikProvider value={formik}>
                                    <Form onSubmit={() => handleCommentResponseAdd(bc._id)}
                                        className={'commentForm ' +
                                            (commentFormVisibleId == (i + 1) ? 'commentFormVisible' : '')}>
                                        {
                                            blogCommentsFields.map((item, i) => <FormikFieldController {...item}
                                                key={'blog com reponse field nb' + i} />)
                                        }
                                        <FormButton
                                            text="Enregistrer"
                                            isValid={isValid}
                                        />
                                    </Form>
                                </FormikProvider>
                            </div>
                        </article>)
                    }
                </> : 'Aucun commentaires'
            }
        </div>
        <br /><br />

        <h4>Mon commentaire</h4>
        <FormikProvider value={formik}>
            <Form onSubmit={handleCommentAdd}>
                {
                    blogCommentsFields.map((item, i) => <FormikFieldController {...item}
                        key={'blog com reponse field nb' + i} />)
                }
                <FormButton
                    text="Enregistrer"
                    isValid={isValid}
                />
            </Form>
        </FormikProvider>
    </div>
}



export function ResponseCommentLayout({ response }) {
    return <article className='commentSection' id={'commentResponseId-' + response._id}>
        <span>
            <IonMdPerson />
        </span>
        <div>
            <b>{response.autorName}</b>
            <br />
            <p>
                {
                    response.message
                }
                <br />
            </p>
        </div>
    </article>
}
