import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { faqs } from '../Raw/faqs'
import { toggleMenuAndScrollBar } from '../Tools/animations'
import { IonMinusCircled, IonPlusCircled } from '../GlobalComponents/icons'
import { useState } from 'react'
import { withoutAuth } from '../hocs/withoutAuth'


const Faq = () => {
    const { qType, qId } = useParams();
    useEffect(() => {
        const faqExist = document.getElementById('faq-' + qType + '-' + qId)
        if (qType && qId && faqExist) {
            faqExist.classList.toggle('isDropDownVisibleByHeightAnimation')
            if (document.querySelector('main').classList.contains('mainMoveRight')) {
                toggleMenuAndScrollBar()
            }
            window.scrollTo({ top: faqExist.offsetTop - 150, behavior: 'smooth' });
        } else {
            console.log('oh')
        }

    }, [qType, qId])


    return <>
        {/* <StealerAlert /> */}
        <div className="faq">

            <h2>Foire aux questions</h2>
            <br />
            <p>
                Pour répondre a toutes vos preoccupations ou pour vous guider sur notre plateforme
            </p>
            <section>
                <h4>Questions générales</h4>
                {
                    faqs.géneral.map((q, index) => <FAQQuestionAndResponseLayout
                        key={"faq general nb " + index} qr={q} openQ={index === (qId - 1) ? { qType, qId } : false} />)
                }
            </section>

            <section>
                <h4>Questions sur le tarif</h4>
                {
                    faqs.tarifs.map((q, index) => <FAQQuestionAndResponseLayout
                        key={"faq tarif nb " + index} qr={q} />)
                }
            </section>
        </div>
    </>
}


export function FAQQuestionAndResponseLayout({ qr, openQ }) {
    const [toggleIcon, setTI] = useState(false);

    useEffect(() => {
        if (openQ) {
            setTI(true)
        }
    }, [openQ])
    function toggleQuestionResponse(e) {
        e.currentTarget.nextElementSibling.classList.toggle('qarl-responseVisible')
        setTI((prev) => !prev)
    }
    return <div className='questionAndResponseLayout'>
        <b className='' onClick={toggleQuestionResponse}>
            <span>{qr.question}</span>
            {
                toggleIcon ? <IonMinusCircled /> : <IonPlusCircled />
            }
        </b>
        <p className={openQ ? 'qarl-responseVisible' : ''}>
            {qr.response}
        </p>
    </div>
}

export default withoutAuth(Faq);