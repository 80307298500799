import moment from "moment";

const DashHomeLastOperation = ({ props }) => {
    return <section className="dLastInfos">
        <h1>Dernières transactions</h1>
        <br />
        {
            props.lastOperation.map((item, index) => <div key={"last info nb" + index}>
                <p>
                    <b>Titre :</b>{item.titre.split('-')[1]}
                </p>
                <p>
                    <b>Fait le :</b> {moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                </p>
                <p>
                    <b>Aide :</b>{item.aide}
                </p>
                <p>
                    <b>Statut :</b>{item.phase}
                </p>
            </div>)
        }
    </section>
}

export default DashHomeLastOperation;