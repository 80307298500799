import axios from "axios"
import { IcSharpMarkUnreadChatAlt, IonAndroidContact, IonBarChart, IonCalendar, IonDocumentAttach, IonDocuments, IonEasel, IonIosCard, PhDotsNineBold } from "../GlobalComponents/icons"

export const guidesLinksList = [
    { label: 'Revenu de Solidarité Active (RSA)', link: '/Guides/RSA' },
    { label: "Prime d'activité (PA)", link: '/Guides/PA' },
    { label: 'Allocation aux adultes handicapés (AAH) ', link: '/Guides/AAH' },
    { label: 'Allocation d’Éducation de l’Enfant Handicapé (AEEH)', link: '/Guides/AEEH' },
    { label: 'Majoration pour la vie autonome (MVA)', link: '/Guides/MVA' },
    { label: 'Allocation de solidarité aux personnes âgées (ASPA)', link: '/Guides/ASPA' },
    { label: 'Complémentaire santé solidaire (CSS)', link: '/Guides/CSS' },
    { label: "Allocation supplémentaire d'invalidité (ASI) ", link: '/Guides/ASI' },
    { label: 'Allocation étudiant en difficulté (AED) ', link: '/Guides/AED' },
    { label: 'Allocation de soutien familial (ASF)', link: '/Guides/ASF' },
    { label: 'Complément familial (CF) ', link: '/Guides/CF' },
    { label: "Prestation d’accueil du jeune enfant (PAJE)", link: '/Guides/PAJE' },
    { label: 'Aide personnalisée au logement (APL) ', link: '/Guides/APL' },
    { label: 'Chèque énergies (CE) ', link: '/Guides/CE' },
    { label: 'Allocations familiale (AF) ', link: '/Guides/AF' },
    { label: "Allocation personnalisée d’autonomie (APA)", link: '/Guides/APA' },
    { label: 'Garantie jeunes (GJ) ', link: '/Guides/GJ' },
    { label: 'Compte personnel de formation (CPF) ', link: '/Guides/CPF' },
]



export const simulateurLinks = [
    { label: 'Toutes nos simulations', link: '/Simulations' },
    { label: 'Simulation RSA', link: '/Simulations/RSA' },
    { label: 'Simulation PPA', link: '/Simulations/PPA' },
    { label: 'Simulation AAH', link: '/Simulations/AAH' },
    { label: 'Simulation AEEH', link: '/Simulations/AEEH' },
    { label: 'Simulation MVA', link: '/Simulations/MVA' },
    { label: 'Simulation ASPA', link: '/Simulations/ASPA' },
    { label: 'Simulation PAJE', link: '/Simulations/PAJE' },
    { label: 'Simulation APL', link: '/Simulations/APL' },
    { label: 'Simulation Allocations familiales', link: '/Simulations/AF' },
    { label: 'Simulation APA', link: '/Simulations/APA' },
]

export const simulateurLinks2 = [
    { label: 'Simulation RSA', link: '/Simulations/RSA' },
    { label: 'Simulation PPA', link: '/Simulations/PPA' },
    { label: 'Simulation AAH', link: '/Simulations/AAH' },

    { label: 'Simulation AEEH', link: '/Simulations/AEEH' },
    { label: 'Simulation MVA', link: '/Simulations/MVA' },
    { label: 'Simulation ASPA', link: '/Simulations/ASPA' },

    { label: 'Simulation PAJE', link: '/Simulations/PAJE' },
    { label: 'Simulation APL', link: '/Simulations/APL' },
    { label: 'Simulation Allocations familiales', link: '/Simulations/AF' },

    { label: 'Simulation APA', link: '/Simulations/APA' },
]

export const offreTacheLinks = [
    { label: 'Aide Ponctuelle', link: '/Nos-Aides/AP' },
    { label: 'Déclaration impôts', link: '/Nos-Aides/DI' },
    { label: 'Inscription pôle emploi', link: '/Nos-Aides/PE' },
    { label: 'Rédaction cv, lettre de motivation, courriers', link: '/Nos-Aides/Redaction' },
    { label: 'Aide énergétique', link: '/Nos-Aides/AE' },
    { label: 'Révision code de la route (permis)', link: '/Nos-Aides/RCR' },
    { label: 'Aide à la recherche des logements', link: '/Nos-Aides/ARL' },
]

export const generalNavLinks = [
    { label: 'Accueil', link: '/', subLinks: [], visibleIfAuthed: false },
    { label: 'Simuler Gratuitement', link: '/Simulation-gratuite', subLinks: [], visibleIfAuthed: false },
    { label: 'Blog', link: '/Blog', subLinks: [], visibleIfAuthed: false },
    { label: 'A propos', link: '/Faq/géneral/2', subLinks: [], visibleIfAuthed: false },
    { label: 'Contact', link: '/Contact', subLinks: [], visibleIfAuthed: false },
    { label: 'Référencement', link: '/Programme-de-reference/description', subLinks: [], visibleIfAuthed: false },
]

export const generalNavLinksWithSubMenu = [
    {
        label: 'Liste des aides', link: '/Guides', subLinks: guidesLinksList, visibleIfAuthed: false
    },
    {
        label: 'Simulateurs', subLinks: simulateurLinks, visibleIfAuthed: false
    },
    {
        label: 'Offres à la tâche', link: '/', subLinks: offreTacheLinks, visibleIfAuthed: false
    },
    {
        label: 'Autres', subLinks: [
            { label: 'Nos coordonnées', link: '/Contact' },
            { label: 'Foire aux questions', link: '/Faq' },
        ], visibleIfAuthed: false
    }
]

export const authedNavLinks = [
    { label: 'Mon Espace', link: '/Dashboard', icon: <IonEasel /> },
    { label: 'Prise de Rdv', link: '/Agenda', icon: <IonCalendar /> },
    { label: 'Mon Abonnement', link: '/Cancel/subscription', icon: <IonIosCard /> },
    { label: 'Blog', link: '/Blog', icon: <IcSharpMarkUnreadChatAlt /> },
    { label: 'Modifier mon profil', link: '/Profil/modification', icon: <IonAndroidContact /> },
]
export const authedNavLinksWithSubMenu = [
    {
        label: 'Profil', subLinks: [
            { label: 'Mon Abonement', link: '/Cancel/subscription' },
            { label: 'Modifier mon profil', link: '/Profil/modification' },
        ]
    },
    {
        label: 'Ressources', subLinks: [
            { label: 'Liste ressources', link: '/Ressources' },
            { label: 'Resources annuelles', link: '/Ressources/annuelles' },
            { label: 'Resources trimestrielles', link: '/Ressources/trimestrielles' },
        ]
    },
    {
        label: 'Document', subLinks: [
            { label: 'Mes documents', link: '/Documents/liste' },
            { label: 'Joindre un document', link: '/Documents/ajouter' },
        ]
    }
]

export const authedNavLinkSubMenus = [

    { label: 'Liste des ressources', link: '/Ressources', icon: <IonBarChart /> },
    { label: 'Ressources annuelles', link: '/Ressources/annuelles', icon: <IonBarChart /> },
    { label: 'Ressources trimestrielles', link: '/Ressources/trimestrielles', icon: <IonBarChart /> },


    { label: 'Mes documents', link: '/Documents/liste', icon: <IonDocuments /> },
    { label: 'Joindre un document', link: '/Documents/ajouter', icon: <IonDocumentAttach /> },

]