export function CP() {
  return (
    <>
      <div className="caf-wrapper">
        <p>
          L’examen du code est une étape essentielle pour apprendre à conduire. Pour le réussir, une bonne préparation est indispensable
        </p>

        <span>
          Notre offre comprend :
          <ul>
            <li>
              cours théoriques : des cours de code illustrés par des photos et vidéos
            </li>
            <li>
              Des tests pour vous entraîner
            </li>
            <li>
              Suivi de votre progression tout au long de vos révisions
            </li>
            <li>
              un conseil pour réviser le code efficacement
            </li>
          </ul>
        </span>
        <p>
          Profiter de notre offre et nous prendrons en charge votre réussite
        </p>

      </div>
    </>
  )
}

