
import { useParams } from "react-router-dom";
import ListRessources from "../RoutesSubComponents/dashboard/ressources/listRessources";
import UpdateRessources from "../RoutesSubComponents/dashboard/ressources/updateRessource";
import { withAuth } from "../hocs/withAuth";
const Ressources = () => {
    const { typeRessources } = useParams();

    return <div className="ressources">
        <h1>Vos ressources</h1>
        {(() => {
            if (typeRessources) {
                return <UpdateRessources props={{ typeRessources }} />
            } else {
                return <ListRessources />

            }
        })()}
    </div>
}

export default withAuth(Ressources);