import { useParams } from "react-router-dom"
import { ChangePassword, SearchMail } from "../RoutesSubComponents/motDePasse";
import { withoutAuth } from "../hocs/withoutAuth";

const Mot_de_passe = () => {
    const { passwordId, passwordToken } = useParams();

    return <div className="password">
        {
            passwordId && passwordToken ?
                <ChangePassword props={{ passwordId, passwordToken }} />
                : <SearchMail />
        }
    </div>
}

export default withoutAuth(Mot_de_passe);