export function g_Mva() {
  return (
    <>
      <div className="caf-wrapper">

        <p>
          La Majoration pour la Vie Autonome (MVA) a pour but de favoriser le
          maintien à domicile des personnes handicapées. C’est une aide
          financière qui vient en complément de l’AAH. Mais qui peut y
          prétendre ? Quelles sont les démarches à accomplir ? France Assist
          vous dit tout.
        </p>
        <h1>MVA : Qu’est-ce que c’est ?</h1>
        <p>
          La MVA est une aide permettant de financer une partie des dépenses
          liées à votre handicap (par exemple, installation d'un
          monte-escalier, transformation d'une baignoire en douche).
        </p>
        <p>
          Elle permet aux personnes en situation de handicap vivant dans un
          logement de faire face aux dépenses que cela implique. Elle couvre
          les dépenses d’aménagement de leur logement (par exemple,
          l’installation d’un monte-escalier).
        </p>
        <h1>Qui a droit à la majoration pour la vie autonome ?</h1>
        <span>
          Pour pouvoir percevoir la MVA, il faut remplir les 5 conditions
          suivantes :
          <ul>
            <li>
              Percevoir l'AAH à taux plein ou en complément d'une retraite,
              d'une pension d'invalidité ou d'une rente accident du travail
            </li>
            <li>Justifier d’un taux d’incapacité d’au moins 80 %</li>
            <li>
              Vivre dans un logement indépendant, c’est-à-dire un logement qui
              n’appartient pas à un établissement. Si le bénéficiaire d’une
              MVA est hébergé par un tiers à son domicile, le logement n’est
              pas considéré comme indépendant sauf si la personne qui héberge
              vit en couple avec l’allocataire
            </li>
            <li>Percevoir une aide au logement</li>
            <li>
              Ne pas percevoir de revenu d’activité à caractère professionnel
              propre
            </li>
          </ul>
        </span>

      </div>
    </>
  );
}

