export function invalide_et_pasRetraité(statutPersonnel, statutPersonnel2) {
    if (statutPersonnel != "Retraité(e)") {
        if (statutPersonnel2.includes("En situation de handicap") || statutPersonnel2.includes("Inapte au travail")) {
            return true
        }
    }
    return false;
}

export function en_handicap(statutPersonnel, statutPersonnel2) {
    if (statutPersonnel === "En situation de handicap" || statutPersonnel2.includes("En situation de handicap"))
        return true;
    return false;
}

export function entre_15_et_25ans(age) {
    if (age >= 15 && age <= 25)
        return true
    return false;
}
export function entre_18_et_25ans(age) {
    if (age >= 18 && age <= 25)
        return true;
    return false
}

export function entre_tranche_dage(age, min, max) {
    if (age >= min && age <= max)
        return true;
    return false
}


export function apprent_et_pasDePermis(statutPersonnel, permisConduite) {
    if (statutPersonnel === "Apprenti(e)" && permisConduite == "Non")
        return true;
    return false
}

export function avoir_28AnsMax_et_etudier_en_superieur(age, nationalite, formation, titreDeSejour, dateEnFrance) {
    if (age <= 28) {
        if (nationalite !== "Autre") {
            if (["License", "Master", "BTS ou DUT", "Doctorat", "Aucune"].includes(formation))
                return true;
        } else {
            if (titreDeSejour && dateEnFrance)
                return true
        }
    }
    return false
}

export function etudiant_et_boursier(formation, bourse) {
    if (formation == "Étudiant(e)" && bourse == "Oui")
        return true;
    return false
}

export function avoir_65AnsMinimum_et_perte_dautonomie(age, besoinSouventDaide) {
    if (age > 65 && besoinSouventDaide != "Non")
        return true
    return false
}


export function vivreAParis_Avec_Sans_enfant_selon_sm(codePostal, etatLegal, enfants) {
    // codePostal.startsWith("75")
    if (codePostal.startsWith("75")) {
        if (etatLegal == "Célibataire") {
            if (enfants.length > 0)
                return true
        } else {
            return true
        }
        return false;
    }
    return false;
}


export function pas_de_permis(data) {
    const { permisConduite } = data;
    if (permisConduite == "Non")
        return true;
    return false;
}
export function as_le_permis(data) {
    const { permisConduite } = data;
    if (permisConduite == "Oui")
        return true;
    return false;
}

export function est_apprentis(data) {
    const { statutPersonnel } = data;
    if (statutPersonnel === "Apprenti(e)" || statutPersonnel.includes("Apprenti(e)"))
        return true;
    return false;
}