import { useDispatch, useSelector } from "react-redux";
import modalSelector from "../../store/selectors/modalSelector";
import { setModalOnStore } from "../../store/actions/modalActions"
import { useEffect } from "react";
import { useLocation } from "react-router-dom"
import { IonCloseCircle } from "../icons";

export const Modal = ({ props }) => {
    const dispatch = useDispatch()
    const modal = useSelector(modalSelector).open;
    const content = useSelector(modalSelector).content;
    const className = props && props.className ? props.className : "";
    const location = useLocation()

    useEffect(() => {
        if (modal) {
            document.querySelector('body').style.overflowY = "hidden"
        } else {
            document.querySelector('body').style.overflowY = "initial"
        }
    }, [modal])

    useEffect(() => {
        if (modal) {
            return dispatch(setModalOnStore(false))
        }
    }, [location.pathname]);


    if (!modal) {
        return null;
    }
    return <div className="modal" style={{ zIndex: '10000' }}>
        <section className="modal-body">
            <div className="mb-head-closer">
                <IonCloseCircle onClick={() => dispatch(setModalOnStore(false))} />
            </div>
            {content}
            {/* <div className="mb-foot-closer">
                <button>Fermer</button>
            </div> */}
        </section>
    </div>
}

export const ConfirmationMessage = ({ props }) => {
    const dispatch = useDispatch()
    const { text, functionAfterConfirm, param } = props
    return <div className="confirmationModal">
        <p>
            {text}
        </p>

        <section>
            <span onClick={() => functionAfterConfirm(param)}>Confirmer</span>
            <span onClick={() => dispatch(setModalOnStore(false))}>Annuler</span>
        </section>
    </div>
}