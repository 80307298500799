import React, { useState } from 'react'
import axios from "axios"
import { SuccessOperationModal } from '../GlobalComponents/OtherOnWebsite'
import { setModal, setModalOnStore } from '../store/actions/modalActions'
import { useDispatch } from 'react-redux'


function PrelevementForm() {
    const [formValues, setFormValues] = useState({
        nom: "", prenom: "", email: "", codepostal: "", address: "", telephone: "", rib: ""
    })
    const dispatch = useDispatch()
    const [reqStatus, setReqStatus] = useState("")
    function handleChange(e) {
        let value = e.target.value
        let name = e.target.name

        setFormValues({ ...formValues, [name]: value })
    }

    const mailSetterInfo = "contact@france-assist.fr?subject=Prelevement SEPA de client" +
        "&body=Nom et prenom du client : " + formValues.nom + " " + formValues.prenom +
        "%0D%0AMail du client : " + formValues.email +
        "%0D%0ACode postal du client : " + formValues.codepostal +
        "%0D%0AVille du client : %0D%0A " + formValues.address +
        "%0D%0ARIB du client : %0D%0A " + formValues.rib;

    const formCond = formValues.nom != "" && formValues.prenom != ""
        && formValues.codepostal != "" && formValues.address != ""
        && formValues.telephone != "" && formValues.rib != ""


    function handleSubmit(e) {
        e.preventDefault();

        axios.post('https://dashboard.france-assist.fr/form/prelevement/add', formValues)
            .then(res => {
                dispatch(setModal(<SuccessOperationModal
                    text={"Merci chers client,vos informations ont bien été enregistrées ." +
                        "L'equipe France-assist vous repondra dans les plus brefs delais pour la suite des demarches."} />))

            })
            .catch(err => console.log(err))

    }

    return (
        <div className='prelevementForm'>
            <h1>Mandat de prélèvement SEPA</h1>
            <p>
                Nous avons besoin de votre autorisation afin de récupérer le coût de l'abonnement
                <br />
                à notre service d'accompagnement, soit 99€ par an.
                <br />
            </p>
            <h3>
                <b>NB:</b>   si vous ne percevez pas d'aides suite à nos démarches, vous ne payerez rien.
            </h3>
            <form onSubmit={(event) => handleSubmit(event)}>
                <div className='formSegment'>
                    <label htmlFor="">Nom</label>
                    <input type="text" name='nom'
                        placeholder='Ex: Votre nom'
                        onChange={(event) => handleChange(event)} />
                </div>
                <div className='formSegment'>
                    <label htmlFor="">Prénom</label>
                    <input type="text" name='prenom'
                        placeholder='Ex: Votre prenom*'
                        onChange={(event) => handleChange(event)} />
                </div>
                <div className='formSegment'>
                    <label htmlFor="">Email</label>
                    <input type="text" name='email'
                        placeholder='Ex: mail@gmail.com'
                        onChange={(event) => handleChange(event)} />
                </div>
                <div className='formSegment'>
                    <label htmlFor="">Code postal</label>
                    <input type="text" name='codepostal'
                        placeholder='Ex: 75010'
                        onChange={(event) => handleChange(event)} />
                </div>
                <div className='formSegment'>
                    <label htmlFor="">Adresse</label>
                    <input type="text" name='address'
                        placeholder='Ex: Paris'
                        onChange={(event) => handleChange(event)} />
                </div>
                <div className='formSegment'>
                    <label htmlFor="">Téléphone</label>
                    <input type="text" name='telephone'
                        placeholder='Ex: 0600000000'
                        onChange={(event) => handleChange(event)} />
                </div>
                <div className='formSegment'>
                    Votre compte courant (sur lequel vous souhaitez percevoir vos aides)
                    <label htmlFor="">IBAN (RIB)</label>
                    <input type="text" name='rib'
                        placeholder='Ex: FR76 3000 1007 9412 3456 7890 185'
                        onChange={(event) => handleChange(event)} />
                </div>
                <div className='formBtn'>
                    {
                        formCond ?
                            <button>Je signe mon mandat</button>
                            :
                            <button disabled className='btnDisable'>Je signe mon mandat</button>
                    }
                </div>
                {
                    reqStatus != "" && <p className="successField"> {reqStatus} </p>
                }
            </form>

            <p>
                Je mandate France Assist  pour prélever sur mon compte bancaire,<br />
                le tarif de l'abonnement de 99€ TTC par an après qu'ils aient effectués mes démarches <br />
                et acceptation d'une aide.
                En remplissant ce formulaire, il vaut mandat de prélèvement SEPA.
            </p>


        </div>
    )
}

export default PrelevementForm
