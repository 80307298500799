export function g_Cpf() {
  return (
    <>
      <div className="caf-wrapper">

        <p>
          Le Compte personnel de formation (CPF) permet à toute personne active, dès son entrée sur le marché du travail et jusqu’à la date à laquelle elle fait valoir l’ensemble de ses droits à la retraite, d’acquérir des droits à la formation mobilisables tout au long de sa vie professionnelle.
        </p>
        <h1>À qui s’adresse le Compte personnel de formation (CPF) ?</h1>
        <span>
          Le Compte personnel de formation (CPF) s’adresse à :
          <ul>
            <li>
              Toutes les personnes de 16 ans et plus
            </li>
            <li>
              Par dérogation, les jeunes de 15 ans, ayant signé un contrat d’apprentissage, sont également concernés
            </li>
            <li>
              Le Compte personnel de formation (CPF) est fermé à la date à la laquelle son titulaire à fait valoir l’ensemble de ses droits à la retraite, par exception au titre des activités bénévoles et de volontariat que le salarié exerce
            </li>
          </ul>
        </span>

      </div>
    </>
  )
}
