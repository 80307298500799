export function PrevSimulationStep({ props }) {
  const { setPage, prevPage, anotherOperation } = props;
  return <button
    style={{ backgroundColor: "var(--colorPrimary)", color: "white" }}
    onClick={() => {
      anotherOperation ? anotherOperation() : setPage(prevPage)
    }}
  >
    <i className="fas fa-chevron-left"></i>
    <span>Précédent</span>
  </button>
}

export function NextSimulationStepButton(props) {
  const { disabledCond } = props;
  return <button
    disabled={disabledCond}
    style={{
      backgroundColor: !disabledCond ? "var(--colorPrimary)" : "#efefef",
      color: !disabledCond ? "white" : "grey"
    }}
    {...props}
  >
    <span>Suivant</span>
  </button>
}