import React, { useEffect, useLayoutEffect, useState } from "react";
import { OneChoice } from "../../../GlobalComponents/simulation/choices";
import { NextSimulationStepButton, PrevSimulationStep } from "../../../GlobalComponents/simulation/nav";
import "./foyer.css";

function Foyer({
  setSection,
  setProgress,
  setData,
  setModal,
  modalData,
  setModalData,
}) {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;
  const [page, setPage] = useState(1);
  const [foyer, setFoyer] = useState({
    enfantsACharge: "",
    enfants: "",
    etatLegal: "",
    visSeul: "",
    nbrFrere: "",
    nbrFrereEnseignementSup: "",
    conjointAge: "",
    conjointStatutPersonnel: "",
    conjointStatutPersonnel2: "",
    conjointFormation: "",
    conjointPartirEtranger: "",
    conjointBourse: "",
    conjointBourseEchelon: "",
    conjointAideFinanciere: "",
    conjointTravailleFranceDemandeurEmploi: "",
    conjointDernierContratTravail: "",
    conjointTauxHandicap: "",
    conjointPensionInvalidite: ""
  });
  useEffect(() => {
    setFoyer((foyer) => {
      return { ...foyer, enfants: modalData };
    });
  }, [modalData]);
  function singleSelect(e, cl) {
    let doc = document.querySelectorAll(cl);
    if (!foyer[e.target.id] || foyer[e.target.id] !== e.target.innerText) {
      setFoyer({ ...foyer, [e.target.id]: e.target.innerText });
      for (let i = 0; i < doc.length; i++) {
        doc[i].classList.remove("selected");
      }
      e.target.classList.add("selected");
    } else {
      setFoyer({ ...foyer, [e.target.id]: "" });
      e.target.classList.remove("selected");
    }
  }
  function multiSelect(e, cl) {
    if (!foyer[e.target.id].includes(e.target.innerText)) {
      setFoyer({
        ...foyer,
        [e.target.id]: [...foyer[e.target.id], e.target.innerText],
      });

      e.target.classList.add("selected");
    } else {
      setFoyer({
        ...foyer,
        [e.target.id]: foyer[e.target.id].filter(
          (el) => el !== e.target.innerText
        ),
      });
      // profil[e.target.id].filter(el=>el!==e.target.id)
      e.target.classList.remove("selected");
    }
  }

  useLayoutEffect(() => {
    let enfantsACharge = document.querySelectorAll(".enfantsACharge");
    let etatLegal = document.querySelectorAll(".etatLegal");
    let visSeul = document.querySelectorAll(".visSeul");
    let conjointStatutPersonnel = document.querySelectorAll(
      ".conjointStatutPersonnel"
    );
    let conjointStatutPersonnel2 = document.querySelectorAll(
      ".conjointStatutPersonnel2"
    );
    let conjointFormation = document.querySelectorAll(".conjointFormation");
    let conjointPartirEtranger = document.querySelectorAll(
      ".conjointPartirEtranger"
    );
    let conjointBourse = document.querySelectorAll(".conjointBourse");
    let conjointBourseEchelon = document.querySelectorAll(
      ".conjointBourseEchelon"
    );
    let conjointAideFinanciere = document.querySelectorAll(
      ".conjointAideFinanciere"
    );
    let conjointTravailleFranceDemandeurEmploi = document.querySelectorAll(
      ".conjointTravailleFranceDemandeurEmploi"
    );
    let conjointTauxHandicap = document.querySelectorAll(
      ".conjointTauxHandicap"
    );
    let conjointPensionInvalidite = document.querySelectorAll(
      ".conjointPensionInvalidite"
    );

    if (foyer.enfantsACharge && enfantsACharge) {
      for (let i = 0; i < enfantsACharge.length; i++) {
        if (enfantsACharge[i].innerText === foyer.enfantsACharge)
          enfantsACharge[i].classList.add("selected");
      }
    }
    if (foyer.etatLegal && etatLegal) {
      for (let i = 0; i < etatLegal.length; i++) {
        if (etatLegal[i].innerText === foyer.etatLegal)
          etatLegal[i].classList.add("selected");
      }
    }
    if (foyer.visSeul && visSeul) {
      for (let i = 0; i < visSeul.length; i++) {
        if (visSeul[i].innerText === foyer.visSeul)
          visSeul[i].classList.add("selected");
      }
    }
    if (foyer.conjointStatutPersonnel && conjointStatutPersonnel) {
      for (let i = 0; i < conjointStatutPersonnel.length; i++) {
        if (
          conjointStatutPersonnel[i].innerText === foyer.conjointStatutPersonnel
        )
          conjointStatutPersonnel[i].classList.add("selected");
      }
    }
    if (foyer.conjointStatutPersonnel2.length && conjointStatutPersonnel2) {
      for (let i = 0; i < conjointStatutPersonnel2.length; i++) {
        if (
          foyer.conjointStatutPersonnel2.includes(
            conjointStatutPersonnel2[i].innerText
          )
        )
          conjointStatutPersonnel2[i].classList.add("selected");
      }
    }
    if (foyer.conjointFormation && conjointFormation) {
      for (let i = 0; i < conjointFormation.length; i++) {
        if (conjointFormation[i].innerText === foyer.conjointFormation)
          conjointFormation[i].classList.add("selected");
      }
    }
    if (foyer.conjointPartirEtranger && conjointPartirEtranger) {
      for (let i = 0; i < conjointPartirEtranger.length; i++) {
        if (
          conjointPartirEtranger[i].innerText === foyer.conjointPartirEtranger
        )
          conjointPartirEtranger[i].classList.add("selected");
      }
    }
    if (foyer.conjointBourse && conjointBourse) {
      for (let i = 0; i < conjointBourse.length; i++) {
        if (conjointBourse[i].innerText === foyer.conjointBourse)
          conjointBourse[i].classList.add("selected");
      }
    }
    if (foyer.conjointBourseEchelon && conjointBourseEchelon) {
      for (let i = 0; i < conjointBourseEchelon.length; i++) {
        if (conjointBourseEchelon[i].innerText === foyer.conjointBourseEchelon)
          conjointBourseEchelon[i].classList.add("selected");
      }
    }
    if (foyer.conjointAideFinanciere && conjointAideFinanciere) {
      for (let i = 0; i < conjointAideFinanciere.length; i++) {
        if (
          conjointAideFinanciere[i].innerText === foyer.conjointAideFinanciere
        )
          conjointAideFinanciere[i].classList.add("selected");
      }
    }
    if (
      foyer.conjointTravailleFranceDemandeurEmploi &&
      conjointTravailleFranceDemandeurEmploi
    ) {
      for (let i = 0; i < conjointTravailleFranceDemandeurEmploi.length; i++) {
        if (
          conjointTravailleFranceDemandeurEmploi[i].innerText ===
          foyer.conjointTravailleFranceDemandeurEmploi
        )
          conjointTravailleFranceDemandeurEmploi[i].classList.add("selected");
      }
    }
    if (foyer.conjointTauxHandicap && conjointTauxHandicap) {
      for (let i = 0; i < conjointTauxHandicap.length; i++) {
        if (conjointTauxHandicap[i].innerText === foyer.conjointTauxHandicap)
          conjointTauxHandicap[i].classList.add("selected");
      }
    }
    if (foyer.conjointPensionInvalidite && conjointPensionInvalidite) {
      for (let i = 0; i < conjointPensionInvalidite.length; i++) {
        if (conjointPensionInvalidite[i].innerText === foyer.conjointPensionInvalidite)
          conjointPensionInvalidite[i].classList.add("selected");
      }
    }
  }, [foyer, page]);

  useEffect(() => {
    window.scrollTo(0, 160);
  }, [page]);

  const normalizeAgeNumber = (value) => {
    return value.replace(/\D/gi, "") || "";
  };

  const normalizeDateNumber = (value) => {
    if (new Date(value) > new Date(today)) return "";
    else return value;
  };

  return (
    <>
      {page === 1 && (
        <div className="simulateur-nationalite">
          <span>
            J’ai des enfants à charge
            <span style={{ color: "#3c3c3c", fontSize: 13, display: "block" }}>
              Les personnes de moins de 25 ans ou handicapées dont vous assumez
              la responsabilité, même sans lien de parenté
            </span>
          </span>
          <OneChoice props={{
            choiceArray: ["Oui", "Non"], target: "enfantsACharge", singleSelect
          }} />
          <div className="button-simulateur">
            <PrevSimulationStep props={{
              setPage, prevPage: 1, anotherOperation: function () {
                setSection("profil");
                setProgress(20)
              }
            }} />
            <NextSimulationStepButton disabledCond={!foyer.enfantsACharge}
              onClick={() => {
                switch (foyer.enfantsACharge) {
                  case "Oui":
                    setPage(1.1);
                    break;
                  default:
                    setModalData([]);
                    setPage(2);
                    break;
                }
              }}
            />
          </div>
        </div>
      )
      }

      {
        page === 1.1 && (
          <div className="simulateur-enfants">
            <span>
              Mes enfants à charge
              <span
                style={{
                  color: "#3c3c3c",
                  fontSize: 13,
                  display: "block",
                }}
              >
                Ou personnes handicapées
              </span>
            </span>
            <div className="enfants-map">
              {modalData.length ? (
                <p>
                  {modalData.length} enfant{modalData.length === 1 ? "" : "s"}
                </p>
              ) : null}
              {modalData.map((el) => (
                <React.Fragment key={el.id}>
                  <div>
                    <div>
                      <div>{el.age} ans</div>
                      {el.statut.map((el, id) => (
                        <div key={id}>{el}</div>
                      ))}
                    </div>
                    <div>
                      <button onClick={() =>
                        setModalData((data) =>
                          data.filter((elm) => elm.id !== el.id)
                        )
                      }>Supprimer</button>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop:
                        !el.echelonDeBourse &&
                        !el.tauxHandicap &&
                        !el.revenu &&
                        0,
                      paddingTop:
                        !el.echelonDeBourse &&
                        !el.tauxHandicap &&
                        !el.revenu &&
                        0,
                      borderTop:
                        !el.echelonDeBourse &&
                        !el.tauxHandicap &&
                        !el.revenu &&
                        "none",
                    }}
                  >
                    {el.echelonDeBourse ? (
                      <div>
                        <div>Echelon de bourse</div>
                        <div>{el.echelonDeBourse}</div>
                      </div>
                    ) : null}
                    {el.tauxHandicap ? (
                      <div>
                        <div>Taux de handicap</div>
                        <div>{el.tauxHandicap}</div>
                      </div>
                    ) : null}
                    {el.revenu ? (
                      <div>
                        <div>Revenus</div>
                        <div>{el.revenu}€</div>
                      </div>
                    ) : null}
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div
              className="enfants-ajout"
              onClick={() => {
                setModal((modal) => !modal);
                document.querySelector(".App").style.overflowY = "hidden";
                document.querySelector(".App").style.height = "100vh";
                document.body.style.overflowY = "hidden";
                document.body.style.height = "100vh";
              }}
            >
              <div>Ajouter un enfant</div>
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!modalData.length}
                onClick={() => setPage(2)}
              />
            </div>
          </div >
        )
      }

      {
        page === 2 && (
          <div className="simulateur-nationalite">
            <span>Je suis</span>
            <OneChoice props={{
              choiceArray: ["Célibataire", "Marié(e)", "Pacsé(e)", "En concubinage", "Veuf(ve)"], target: "etatLegal", singleSelect
            }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!foyer.etatLegal}
                onClick={() => {
                  switch (foyer.etatLegal) {
                    case "Célibataire":
                      return setPage(2.1);

                    default:
                      setFoyer({ ...foyer, visSeul: "" });
                      // return setPage(3);
                      switch (foyer.etatLegal) {
                        case "Marié(e)":
                        case "Pacsé(e)":
                        case "En concubinage":
                          setPage(5);
                          break;

                        default:
                          setSection("mon logement");
                          setProgress(60);
                          setData((data) => {
                            return { ...data, ...foyer, conjointStatutPersonnel: [foyer.conjointStatutPersonnel, ...foyer.conjointStatutPersonnel2].filter((el) => el !== "") };
                          });
                          break;
                      }
                      break;
                  }
                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 2.1 && (
          <div className="simulateur-nationalite">
            <span>Je vis seul(e) depuis</span>
            <OneChoice props={{
              choiceArray: ["Moins de 18 mois", "Plus de 18 mois"], target: "visSeul", singleSelect
            }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 2 }} />
              <NextSimulationStepButton disabledCond={!foyer.visSeul}
                onClick={() => {
                  // setPage(3);
                  switch (foyer.etatLegal) {
                    case "Marié(e)":
                    case "Pacsé(e)":
                    case "En concubinage":
                      setPage(5);
                      break;

                    default:
                      setSection("mon logement");
                      setProgress(60);
                      setData((data) => {
                        return { ...data, ...foyer, conjointStatutPersonnel: [foyer.conjointStatutPersonnel, ...foyer.conjointStatutPersonnel2].filter((el) => el !== "") };
                      });
                      break;
                  }
                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 3 && (
          <>
            <div className="simulateur-date">
              <span>
                Nombre de frères et soeurs à la charge de mes parents
                <span
                  style={{ color: "#3c3c3c", fontSize: 13, display: "block" }}
                >
                  Sans me compter
                </span>
              </span>
              <div>
                <input
                  style={{ width: "7ch" }}
                  // ref={ageRef}
                  value={foyer.nbrFrere}
                  type="tel"
                  autoFocus
                  maxLength="2"
                  placeholder="2"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(e.target.value);
                    setFoyer({
                      ...foyer,
                      nbrFrere: e.target.value,
                    });
                  }}
                />
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 2 }} />
              <NextSimulationStepButton disabledCond={!foyer.nbrFrere}
                onClick={() => {
                  setPage(4);
                }}
              />
            </div>
          </>
        )
      }
      {
        page === 4 && (
          <>
            <div className="simulateur-date">
              <span>
                Nombre de frères et soeurs étudiant dans l’enseignement supérieur
              </span>
              <div>
                <input
                  style={{ width: "7ch" }}
                  // ref={ageRef}
                  value={foyer.nbrFrereEnseignementSup}
                  type="tel"
                  autoFocus
                  maxLength="2"
                  placeholder="2"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(e.target.value);
                    setFoyer({
                      ...foyer,
                      nbrFrereEnseignementSup: e.target.value,
                    });
                  }}
                />
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
              <NextSimulationStepButton disabledCond={!foyer.nbrFrereEnseignementSup}
                onClick={() => {
                  switch (foyer.etatLegal) {
                    case "Marié(e)":
                    case "Pacsé(e)":
                    case "En concubinage":
                      setPage(5);
                      break;

                    default:
                      setSection("mon logement");
                      setProgress(60);
                      setData((data) => {
                        return { ...data, ...foyer, conjointStatutPersonnel: [foyer.conjointStatutPersonnel, ...foyer.conjointStatutPersonnel2].filter((el) => el !== "") };
                      });
                      break;
                  }
                }}
              />
            </div>
          </>
        )
      }

      {
        page === 5 && (
          <>
            <div className="simulateur-age">
              <span>Mon conjoint a</span>
              <div>
                <input
                  // ref={ageRef}
                  value={foyer.conjointAge}
                  type="tel"
                  placeholder="24"
                  autoFocus
                  maxLength="2"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(e.target.value);
                    setFoyer({ ...foyer, conjointAge: e.target.value });
                  }}
                />
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <span>ans</span>
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 4 }} />
              <NextSimulationStepButton disabledCond={foyer.conjointAge <= 14}
                onClick={() => setPage(6)}
              />
            </div>
          </>
        )
      }

      {
        page === 6 && (
          <div className="simulateur-nationalite">
            <span>
              Mon conjoint est
              <span style={{ color: "	#3c3c3c", fontSize: 13, display: "block" }}>
                Plusieurs réponses possibles
              </span>
            </span>
            <OneChoice props={{
              choiceArray: ["Étudiant(e)", "Apprenti(e)", "Demandeur d’emploi", "Enceinte",
                "En situation de handicap", "Inapte au travail", "Actif (salarié / indépendant)"],
              target: "conjointStatutPersonnel", singleSelect
            }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 5 }} />
              <NextSimulationStepButton disabledCond={!foyer.conjointStatutPersonnel && !foyer.conjointStatutPersonnel2.length}
                onClick={() => {
                  switch (foyer.conjointStatutPersonnel) {
                    case "Étudiant(e)":
                    case "Apprenti(e)":
                      setFoyer({
                        ...foyer,
                        conjointFormation: "",
                        conjointPartirEtranger: "",
                        conjointBourse: "",
                        conjointBourseEchelon: "",
                        conjointAideFinanciere: "",
                        conjointTravailleFranceDemandeurEmploi: "",
                        conjointDernierContratTravail: "",
                        conjointTauxHandicap: "",
                        conjointPensionInvalidite: ""
                      });
                      return setPage(6.1);
                    // break
                    case "Retraité(e)":
                      setFoyer({
                        ...foyer,
                        conjointFormation: "",
                        conjointPartirEtranger: "",
                        conjointBourse: "",
                        conjointBourseEchelon: "",
                        conjointAideFinanciere: "",
                        conjointTravailleFranceDemandeurEmploi: "",
                        conjointDernierContratTravail: "",
                        conjointTauxHandicap: "",
                        conjointPensionInvalidite: ""
                      });
                      if (
                        foyer.conjointStatutPersonnel2.includes(
                          "En situation de handicap"
                        )
                      )
                        return setPage(6.8);
                      if (
                        foyer.conjointStatutPersonnel2.includes(
                          "Inapte au travail"
                        )
                      )
                        return setPage(6.9);

                      setSection("mon logement");
                      setProgress(60);
                      setData((data) => {
                        return { ...data, ...foyer, conjointStatutPersonnel: [foyer.conjointStatutPersonnel, ...foyer.conjointStatutPersonnel2].filter((el) => el !== "") };
                      });
                      break;
                    case "Demandeur d’emploi":
                      setFoyer({
                        ...foyer,
                        conjointFormation: "",
                        conjointPartirEtranger: "",
                        conjointBourse: "",
                        conjointBourseEchelon: "",
                        conjointAideFinanciere: "",
                        conjointTravailleFranceDemandeurEmploi: "",
                        conjointDernierContratTravail: "",
                        conjointTauxHandicap: "",
                        conjointPensionInvalidite: ""
                      });
                      return setPage(6.6);
                    // break
                    default:
                      break;
                  }
                  if (foyer.conjointStatutPersonnel == "Enceinte" ||
                    foyer.conjointStatutPersonnel2.includes("Enceinte")) {
                    setFoyer({
                      ...foyer,
                      conjointFormation: "",
                      conjointPartirEtranger: "",
                      conjointBourse: "",
                      conjointBourseEchelon: "",
                      conjointAideFinanciere: "",
                      conjointTravailleFranceDemandeurEmploi: "",
                      conjointDernierContratTravail: "",
                      conjointTauxHandicap: "",
                      conjointPensionInvalidite: ""
                    });
                    if (foyer.conjointStatutPersonnel == "En situation de handicap" ||
                      foyer.conjointStatutPersonnel2.includes("En situation de handicap"))
                      return setPage(6.8);
                    if (foyer.conjointStatutPersonnel == "Inapte au travail" ||
                      foyer.conjointStatutPersonnel2.includes("Inapte au travail"))
                      return setPage(6.9);

                    setSection("mon logement");
                    setProgress(60);
                    setData((data) => {
                      return { ...data, ...foyer, conjointStatutPersonnel: [foyer.conjointStatutPersonnel, ...foyer.conjointStatutPersonnel2].filter((el) => el !== "") };
                    });
                  }
                  if (foyer.conjointStatutPersonnel == "En situation de handicap" ||
                    foyer.conjointStatutPersonnel2.includes("En situation de handicap")
                  ) {
                    setFoyer({
                      ...foyer,
                      conjointFormation: "",
                      conjointPartirEtranger: "",
                      conjointBourse: "",
                      conjointBourseEchelon: "",
                      conjointAideFinanciere: "",
                      conjointTravailleFranceDemandeurEmploi: "",
                      conjointDernierContratTravail: "",
                      conjointTauxHandicap: "",
                      conjointPensionInvalidite: ""
                    });
                    return setPage(6.8);
                  }
                  if (foyer.conjointStatutPersonnel == "Inapte au travail" ||
                    foyer.conjointStatutPersonnel2.includes("Inapte au travail")) {
                    setFoyer({
                      ...foyer,
                      conjointFormation: "",
                      conjointPartirEtranger: "",
                      conjointBourse: "",
                      conjointBourseEchelon: "",
                      conjointAideFinanciere: "",
                      conjointTravailleFranceDemandeurEmploi: "",
                      conjointDernierContratTravail: "",
                      conjointTauxHandicap: "",
                      conjointPensionInvalidite: ""
                    });
                    return setPage(6.9);
                  }
                  if (foyer.conjointStatutPersonnel == "Actif (salarié / indépendant)" ||
                    foyer.conjointStatutPersonnel2.includes("Actif (salarié / indépendant)")) {
                    setFoyer({
                      ...foyer,
                      conjointFormation: "",
                      conjointPartirEtranger: "",
                      conjointBourse: "",
                      conjointBourseEchelon: "",
                      conjointAideFinanciere: "",
                      conjointTravailleFranceDemandeurEmploi: "",
                      conjointDernierContratTravail: "",
                      conjointTauxHandicap: "",
                      conjointPensionInvalidite: ""
                    });
                    setSection("mon logement");
                    setProgress(60);
                    setData((data) => {
                      return { ...data, ...foyer, conjointStatutPersonnel: [foyer.conjointStatutPersonnel, ...foyer.conjointStatutPersonnel2].filter((el) => el !== "") };
                    });
                  }
                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 6.1 && (
          <div className="simulateur-nationalite">
            <span>Il/elle suit une formation en</span>
            <OneChoice props={{
              choiceArray: ["Apprentissage", "Lycée", "Lycée professionnel", "Licence",
                "Master", "Doctorat", "BTS ou DUT", "Sanitaire et sociale", "Stagiaire", "Autre"],
              target: "conjointFormation", singleSelect
            }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 6 }} />
              <NextSimulationStepButton disabledCond={!foyer.conjointFormation}
                onClick={() => {
                  switch (foyer.conjointFormation) {
                    case "Apprentissage":
                      setFoyer({
                        ...foyer,
                        conjointBourse: "",
                        conjointBourseEchelon: "",
                        conjointAideFinanciere: "",
                      });
                      setPage(6.2);
                      break;
                    default:
                      setFoyer({
                        ...foyer,
                        conjointPartirEtranger: "",
                      });
                      setPage(6.3);
                      break;
                  }
                }}
              />
            </div>
          </div >
        )
      }

      {
        page === 6.2 && (
          <div className="simulateur-nationalite">
            <span>
              Il/elle compte partir à l’étranger dans le cadre de ses études
            </span>
            <OneChoice props={{
              choiceArray: ["Oui", "Non"], target: "conjointPartirEtranger", singleSelect
            }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 6 }} />
              <NextSimulationStepButton disabledCond={!foyer.conjointPartirEtranger}
                onClick={() => {
                  if (
                    foyer.conjointStatutPersonnel2.includes(
                      "En situation de handicap"
                    )
                  )
                    return setPage(6.8);
                  if (
                    foyer.conjointStatutPersonnel2.includes("Inapte au travail")
                  )
                    return setPage(6.9);

                  setSection("mon logement");
                  setProgress(60);
                  setData((data) => {
                    return { ...data, ...foyer, conjointStatutPersonnel: [foyer.conjointStatutPersonnel, ...foyer.conjointStatutPersonnel2].filter((el) => el !== "") };
                  });
                }}
              />
            </div>
          </div >
        )
      }

      {
        page === 6.3 && (
          <div className="simulateur-nationalite">
            <span>Mon conjoint est boursier :</span>
            <OneChoice props={{
              choiceArray: ["Oui", "Non"], target: "conjointBourse", singleSelect
            }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 2 }} />
              <NextSimulationStepButton disabledCond={!foyer.conjointBourse}
                onClick={() => {
                  switch (foyer.conjointBourse) {
                    case "Oui":
                      setPage(6.4);
                      break;
                    default:
                      setPage(6.5);
                      break;
                  }
                }}
              />
            </div>
          </div >
        )
      }

      {
        page === 6.4 && (
          <div className="simulateur-nationalite">
            <span>Quel est son échelon de bourse ?</span>
            <OneChoice props={{
              choiceArray: ['0', '1', '2', '3', '4', '5', '6', '7'],
              target: "conjointBourseEchelon", singleSelect
            }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 6 }} />
              <NextSimulationStepButton disabledCond={!foyer.conjointBourseEchelon}
                onClick={() => {
                  setPage(6.2);
                }}
              />
            </div>
          </div >
        )
      }

      {
        page === 6.5 && (
          <div className="simulateur-nationalite">
            <span>Ses parents l’aident financièrement :</span>
            <OneChoice props={{
              choiceArray: ["Oui", "Non"], target: "conjointAideFinanciere", singleSelect
            }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 6 }} />
              <NextSimulationStepButton disabledCond={!foyer.conjointAideFinanciere}
                onClick={() => {
                  setPage(6.2);
                }}
              />
            </div>
          </div >
        )
      }

      {
        page === 6.6 && (
          <div className="simulateur-nationalite">
            <span>Il/elle a déjà travaillé en France</span>
            <OneChoice props={{
              choiceArray: ["Oui", "Non"], target: "conjointTravailleFranceDemandeurEmploi", singleSelect
            }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 2 }} />
              <NextSimulationStepButton disabledCond={!foyer.conjointTravailleFranceDemandeurEmploi}
                onClick={() => {
                  switch (foyer.conjointTravailleFranceDemandeurEmploi) {
                    case "Oui":
                      setPage(6.7);
                      break;
                    default:
                      if (
                        foyer.conjointStatutPersonnel2.includes(
                          "En situation de handicap"
                        )
                      )
                        return setPage(6.8);
                      if (
                        foyer.conjointStatutPersonnel2.includes(
                          "Inapte au travail"
                        )
                      )
                        return setPage(6.9);

                      setSection("mon logement");
                      setProgress(60);
                      setData((data) => {
                        return { ...data, ...foyer, conjointStatutPersonnel: [foyer.conjointStatutPersonnel, ...foyer.conjointStatutPersonnel2].filter((el) => el !== "") };
                      });
                      break;
                  }
                }}
              />
            </div>
          </div >
        )
      }

      {
        page === 6.7 && (
          <>
            <div className="simulateur-date">
              <span>Son dernier contrat de travail s'est terminé en</span>
              <div>
                <input
                  // ref={ageRef}
                  value={foyer.conjointDernierContratTravail}
                  type="date"
                  autoFocus
                  max={today}
                  onChange={(e) => {
                    e.target.value = normalizeDateNumber(e.target.value);
                    setFoyer({
                      ...foyer,
                      conjointDernierContratTravail: e.target.value,
                    });
                  }}
                />
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 6 }} />
              <NextSimulationStepButton disabledCond={!foyer.conjointDernierContratTravail}
                onClick={() => {
                  setSection("mon logement");
                  setProgress(60);
                  setData((data) => {
                    return { ...data, ...foyer, conjointStatutPersonnel: [foyer.conjointStatutPersonnel, ...foyer.conjointStatutPersonnel2].filter((el) => el !== "") };
                  });
                }}
              />
            </div>
          </>
        )
      }

      {
        page === 6.8 && (
          <div className="simulateur-nationalite">
            <span>Son taux de handicap est de :</span>
            <OneChoice props={{
              choiceArray: ['Moins de 50%', 'Entre 50% et 80%', 'Plus de 80%'],
              target: "conjointTauxHandicap", singleSelect
            }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 6 }} />
              <NextSimulationStepButton disabledCond={!foyer.conjointTauxHandicap}
                onClick={() => {
                  if (
                    foyer.conjointStatutPersonnel2.includes("Inapte au travail")
                  )
                    return setPage(6.9);

                  setSection("mon logement");
                  setProgress(60);
                  setData((data) => {
                    return { ...data, ...foyer, conjointStatutPersonnel: [foyer.conjointStatutPersonnel, ...foyer.conjointStatutPersonnel2].filter((el) => el !== "") };
                  });
                }}
              />
            </div>
          </div >
        )
      }

      {
        page === 6.9 && (
          <div className="simulateur-nationalite">
            <span>Il/elle bénéficie d’une pension d’invalidité</span>
            <OneChoice props={{
              choiceArray: ["Oui", "Non"], target: "conjointPensionInvalidite", singleSelect
            }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 6 }} />
              <NextSimulationStepButton disabledCond={!foyer.conjointPensionInvalidite}
                onClick={() => {
                  setSection("mon logement");
                  setProgress(60);
                  setData((data) => {
                    return { ...data, ...foyer, conjointStatutPersonnel: [foyer.conjointStatutPersonnel, ...foyer.conjointStatutPersonnel2].filter((el) => el !== "") };
                  });
                }}
              />
            </div>
          </div >
        )
      }
    </>
  );
}

export default Foyer;
