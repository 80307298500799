import { useAuth } from "../contexts/auth";
import "moment/locale/fr";
import { withAuth } from "../hocs/withAuth";

const Agenda = () => {
    const { user } = useAuth()
    const mentor = user.agentData;

    return <div className="agenda">
        <h1>Prenez un rendez-vous avec votre conseiller</h1>
        <section>
            <a href={mentor.cal} target="_blank">Prendre un rendez-vous</a>
        </section>
    </div>
}

export default withAuth(Agenda);