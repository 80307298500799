import React from 'react'

function DashWelcome({ authedInfos, userMentor }) {
    return (
        <section className="d-welcome">
            <h2>Bonjour {authedInfos.prenom}</h2>
            <p>
                Mr/Mme <b>{authedInfos.prenom}</b> Avant tout nous vous remercions d'avoir signé
                le contrat d'accompagnement France Assist.
                <br />
                <br />
                Votre expert attitré est <b>{userMentor.nom + ' ' + userMentor.prenom}</b>
            </p>
        </section>
    )
}

export default DashWelcome