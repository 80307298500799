import moment from "moment"

const situationProOptions = [
    { label: 'Étudiant(e)', value: 'etudiant' },
    { label: 'Apprenti(e)', value: 'apprenti' },
    { label: 'Retraité(e)', value: 'retraite' },
    { label: 'Demandeur d’emploi', value: 'demandeur_emploi' },
    { label: 'Enceinte', value: 'enceinte' },
    { label: 'En situation de handicap', value: 'en_situation_de_handicap' },
    { label: 'Inapte au travail', value: 'inapte_au_travail' },
    { label: 'Actif (salarié / indépendant)', value: 'actif' },
]


export const allFields = {
    nom: {
        fieldType: 'text', label: 'Nom', name: 'nom', ph: 'Votre nom', errmsgname: 'nom', size: 'fsSemi'
    },
    prenom: {
        fieldType: 'text', label: 'Prénom', name: 'prenom', ph: 'Votre prénom', errmsgname: 'prenom', size: 'fsSemi'
    },
    nom_conjoint: {
        fieldType: 'text', label: 'Nom du conjoint', name: 'nom_conjoint', ph: 'Nom de votre conjoint', errmsgname: 'nom_conjoint', size: 'fsSemi', conditionalFieldName: 'situation_familiale', conditionalValues: ["marié_avec_enfant", "marié_sans_enfant"]
    },
    prenom_conjoint: {
        fieldType: 'text',
        label: 'Prénom du conjoint', name: 'prenom_conjoint', ph: 'Prénom de votre conjoint', errmsgname: 'prenom_conjoint', size: 'fsSemi', conditionalFieldName: 'situation_familiale', conditionalValues: ["marié_avec_enfant", "marié_sans_enfant"]
    },
    nom_enfant: {
        fieldType: 'text', label: 'Nom enfant', name: 'nom_enfant', ph: 'Nom de votre enfant', errmsgname: 'nom_enfant', size: 'fsSemi'
    },
    prenom_enfant: {
        fieldType: 'text', label: 'Prénom enfant', name: 'prenom_enfant', ph: 'Prénom de votre enfant', errmsgname: 'prenom_enfant', size: 'fsSemi'
    },
    adresse: {
        fieldType: 'text', label: 'Adresse', name: 'adresse', ph: 'Votre adresse', errmsgname: 'adresse', size: 'fsSemi'
    },
    date_de_naissance: {
        fieldType: 'date', label: 'Date de naissance', name: 'date_naissance', ph: 'Votre date de naissance', errmsgname: 'date', size: 'fsSemi'
    },
    date_de_naissance_conjoint: {
        fieldType: 'date', label: 'Date de naissance', name: 'date_naissance_conjoint', ph: 'Date de naissance conjoint', errmsgname: 'date de conjoint', size: 'fsSemi'
    },
    date_de_naissance_enfant: {
        fieldType: 'date', label: 'Date de naissance', name: 'date_de_naissance_enfant', ph: 'Date de naissance enfant', errmsgname: 'date de enfant', size: 'fsSemi'
    },
    mail: {
        fieldType: 'email', label: 'Email', name: 'email', ph: 'Exemple: monmail@gmail.com', errmsgname: 'mail'
    },
    codepostal: {
        fieldType: 'text',
        label: 'Code postal', name: 'codepostal',
        ph: 'Exemple: 75000', errmsgname: 'codepostal', size: 'fsSemi'
    },
    telephone: {
        fieldType: 'text',
        label: 'Télephone portable', name: 'telephone',
        ph: 'Exemple: 0612345678', errmsgname: 'numero de telephone', size: 'fsSemi'
    },
    codecarte: {
        fieldType: 'text',
        label: 'Numéro de carte', name: 'number',
        ph: '1234 1234 1234 1234', errmsgname: 'numero de carte'
    },
    codederrierecarte: {
        fieldType: 'text',
        label: '3 derniers chiffres au dos', name: 'code_derriere_carte',
        ph: 'Exemple: 123', errmsgname: 'numero de carte'
    },
    mois_expiration_carte: {
        fieldType: 'text',
        label: "Date expiration(mois)", name: 'carte_expiration_mois',
        ph: 'Exemple: 06', errmsgname: 'numero', size: 'fsSemi'
    },
    année_expiration_carte: {
        fieldType: 'text',
        label: "Date expiration(année)", name: 'carte_expiration_année',
        ph: 'Exemple: 2000', errmsgname: 'numero', size: 'fsSemi'
    },
    password: {
        fieldType: 'password',
        label: 'Mot de passe', name: 'password',
        ph: 'Mon Mot De Passe', errmsgname: 'mot de passe'
    },
    password_confirmation: {
        fieldType: 'password',
        label: 'Confirmation', name: 'password_confirmation',
        ph: 'Mon Mot De Passe', errmsgname: 'confirmation'
    },

    salaire_annuelle: {
        fieldType: 'number',
        label: "Salaire (Annuel)", name: 'salaire_annuelle',
        ph: '0€', errmsgname: 'salaire annuelle', size: 'fsSemi'
    },
    charge_deductible: {
        fieldType: 'number',
        label: "Charge(Déductible)", name: 'charge_deductible',
        ph: '0€', errmsgname: 'charge', size: 'fsSemi'
    },
    il_ya_trois_month: {
        fieldType: 'number',
        label: "Ce que vous avez gagnez En " + moment().subtract(3, 'month').format('MMMM'), name: 'trimestre_1',
        ph: '0€', errmsgname: 'salaire', size: 'fsSemi'
    },
    il_ya_deux_month: {
        fieldType: 'number',
        label: "Ce que vous avez gagnez En " + moment().subtract(2, 'month').format('MMMM'), name: 'trimestre_2',
        ph: '0€', errmsgname: 'salaire', size: 'fsSemi'
    },
    il_ya_un_month: {
        fieldType: 'number',
        label: "Ce que vous avez gagnez En " + moment().subtract(1, 'month').format('MMMM'), name: 'trimestre_3',
        ph: '0€', errmsgname: 'salaire', size: 'fsSemi'
    },

    nom_prenom: {
        fieldType: 'text',
        label: 'Votre Nom et prenom', name: 'username',
        ph: 'Nom et prenom', errmsgname: 'nom', size: 'fsSemi'
    },
    telephone: {
        fieldType: 'text',
        label: 'Votre Télephone portable', name: 'telephone',
        ph: 'Téléphone portable', errmsgname: 'numero de telephone', size: 'fsSemi'
    },
    message: {
        comp: 'textarea', label: 'Votre message', name: 'message',
        ph: 'Votre Message',
    },
    situation_familiale: {
        fieldType: 'radio', label: 'Votre situation familiale', name: 'situation_familiale',
        ph: 'Votre situation familiale', choices: [
            { value: "célibataire", label: "Célibataire" },
            { value: "marié_avec_enfant", label: "Marié avec enfant" },
            { value: "marié_sans_enfant", label: "Marié sans enfant" },
        ]
    },
    situation_professionnelle: {
        fieldType: 'checkbox2', label: 'Votre situation Professionnelle', name: 'situation_professionnelle',
        ph: 'Votre situation Professionnelle', choices: situationProOptions
    },
    situation_professionnelle_conjoint: {
        fieldType: 'checkbox2', label: 'Situation Professionnelle de votre conjoint', name: 'situation_professionnelle_conjoint',
        ph: 'Situation Professionnelle de votre conjoint', choices: situationProOptions,
        conditionalFieldName: 'situation_familiale', conditionalValues: ["marié_avec_enfant", "marié_sans_enfant"]
    },
    docTitle: {
        fieldType: 'text', label: 'Titre du document', name: 'title',
        ph: 'Titre du document', errmsgname: "message"
    },
    docFiles: {
        fieldType: 'files', label: 'Fichier du document', name: 'docFiles',
        ph: '', errmsgname: "message"
    },
    cancelReason: {
        fieldType: 'textarea', label: 'Les raisons de ma résiliation', name: 'cancelReason',
        ph: 'Les raisons de ma résiliation', errmsgname: "message"
    },

    nom_prenom: {
        fieldType: 'text', htmlType: 'text',
        label: 'Votre Nom et prenom', name: 'username',
        ph: 'Nom et prenom', errmsgname: 'nom', size: 'fsSemi'
    },
    message: {
        fieldType: 'textarea', label: 'Votre message', name: 'message',
        ph: 'Votre Message',
    },
    autorName: {
        fieldType: 'text', label: 'Votre nom complet', name: 'autorName',
        ph: 'Votre nom complet',
    },
    autorEmail: {
        fieldType: 'email', label: 'Votre email', name: 'autorEmail',
        ph: 'Votre email',
    },
    referrorEmail: {
        fieldType: 'email', label: 'Votre email', name: 'referrorEmail',
        ph: 'Votre email',
    },
    referredEmail: {
        fieldType: 'email', label: 'Email du particulier', name: 'referredEmail',
        ph: 'Email du particulier',
    },
    referrorName: {
        fieldType: 'text', label: 'Votre nom', name: 'referrorName',
        ph: 'Votre nom',
    },
    referredName: {
        fieldType: 'text', label: 'Nom du particulier', name: 'referredName',
        ph: 'Nom du particulier',
    },
    referredPhone: {
        fieldType: 'text', label: 'Telephone du particulier', name: 'referredPhone',
        ph: 'Telephone du particulier',
    },
}






export const loginFields = [
    allFields.mail, allFields.password
]

export const signupFields = [
    allFields.nom, allFields.prenom,
    allFields.mail,
    allFields.codepostal, allFields.telephone,
    allFields.password, allFields.password_confirmation,
    allFields.codecarte,
    allFields.mois_expiration_carte, allFields.année_expiration_carte,
    allFields.codederrierecarte
]

export const updateProfiGeneraFields = [
    allFields.nom, allFields.prenom, allFields.mail,
    allFields.date_de_naissance, allFields.telephone,
    allFields.situation_familiale,
    allFields.adresse, allFields.codepostal,
    allFields.situation_professionnelle,
    allFields.nom_conjoint, allFields.prenom_conjoint,
    allFields.situation_professionnelle_conjoint,
    allFields.password, allFields.password_confirmation
]

export const newDocFields = [
    allFields.docTitle, allFields.docFiles
]

export const threeMonthsRessourceFields = [
    allFields.salaire_annuelle, allFields.charge_deductible
]

export const yearRessourceFields = [
    allFields.il_ya_trois_month, allFields.il_ya_deux_month, allFields.il_ya_un_month
]

export const cancelPlanFields = [
    allFields.cancelReason
]


export const contactFields = [
    allFields.nom_prenom,
    allFields.mail,
    allFields.telephone,
    allFields.message
]

export const blogCommentsFields = [
    allFields.autorName,
    allFields.autorEmail,
    allFields.message
]

export const childFields = [
    allFields.nom_enfant,
    allFields.prenom_enfant,
    allFields.date_de_naissance_enfant
]

export const referalProgramFields = [
    allFields.referrorName,
    allFields.referrorEmail,
    allFields.referredName,
    allFields.referredEmail,
    allFields.referredPhone,
]