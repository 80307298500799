import { Fade } from "react-reveal";
import { SimulationLink } from "../../GlobalComponents/Btn";

const HowItWord = () => {
    const hiws = [
        {
            title2: "En fonction de votre âge , statut ou revenus " +
                "vous pouvez estimer vos prestations sociales et voir quelles aides vous correspondent",
            title1: 'Afin de réclamer ces prestations ,France Assist vous accompagne',
            img: 'calculatorb.svg', align: 'right'
        },
        {
            title1: 'Un conseiller attitré vous accompagne pas à pas',
            title2: "Tout est fait pour vous faire gagner du temps et avoir l'esprit tranquille.",
            img: 'expertb.svg', align: 'left'
        },
        {
            title1: "Réclamez et recevez vos aides de la part de l'Etat",
            title2: "Après analyse de votre simulation, nous entamons la réclamation de vos aides auprès des différents organismes concernés.",
            img: 'getMoneyb.svg', align: 'right'
        }
    ]

    const dispastchAnim = (key, align, children) => {
        return align == 'left' ?
            <Fade bottom key={key} duration={1000}>
                {children}
            </Fade> :
            <Fade bottom key={key} duration={1000}>
                {children}
            </Fade>
    }
    return <div className='h-howItWord'>
        <Fade>
            <div className="hh-title">
                <p>
                    Que vous offre <b>France-assist?</b>
                </p>
                <br />
                <span>Notre but est de faciliter l’accès au droit social qui vous revient</span>
            </div>
        </Fade>
        <SimulationLink />

        <section className="hh-cardsList">
            {hiws.map((hiw, index) => dispastchAnim('hiw nb' + index, hiw.align, <section
                className='hh-card' >
                <span className="rounded">{index + 1} </span>
                <img src={require('../../Assets/images/illustrations/howItWork/' + hiw.img)}
                    alt={'our task' + index}
                    height={200} width={200} />
                <div>
                    <h3>{hiw.title1}</h3>
                    <br />
                    <p>{hiw.title2}</p>
                </div>

            </section>))}
        </section>
    </div>
}

export default HowItWord;