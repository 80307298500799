import { useState } from "react";
import uniqId from 'uniqid';
import axios from "axios";
import { useAuth } from "../../../../contexts/auth";
import { FormButton } from "../../../../uikits/buttons";
import { Form, FormikProvider, useFormik } from "formik";
import { childFields } from "../../../../helper/form/fields";
import FormikFieldController from "../../../../uikits/form";
import { NewChildSchema } from "../../../../helper/form/validations";

const AddChildren = ({ props }) => {
    const formik = useFormik({
        initialValues: {
            nom_enfant: "", prenom_enfant: "",
            date_de_naissance_enfant: ""
        },
        onSubmit: addNewChild,
        validateOnMount: true,
        validationSchema: NewChildSchema
    });
    const { isValid } = formik;
    const { user } = useAuth();
    const authedInfos = user;
    const [currentField, setCurrentField] = useState(false);
    const [childList, setChildList] = useState(authedInfos.enfants)

    function handleNewChildField(e) {
        e.preventDefault()
        setCurrentField(true)
    }

    function addNewChild(childFormValues) {
        let childCopy = childList;

        if (childCopy.length > 0) {
            childCopy.push({
                id: uniqId(),
                nom: childFormValues.nom_enfant,
                prenom: childFormValues.prenom_enfant,
                date_naissance: childFormValues.date_de_naissance_enfant
            })
        } else {
            childCopy = [
                {
                    id: uniqId(),
                    nom: childFormValues.nom_enfant,
                    prenom: childFormValues.prenom_enfant,
                    date_naissance: childFormValues.date_de_naissance_enfant
                }
            ]
        }
        setCurrentField(false)
        axios.put('/user/update-enfants', { enfants: childCopy })
            .then(res => {
                setChildList(childCopy)
            })
            .catch(err => null)
    }

    function ret() {
        if (currentField) {
            return (<FormikProvider value={formik}>
                <Form>
                    {
                        childFields.map((item, i) => <FormikFieldController {...item}
                            key={'new chield field nb' + i} />)
                    }
                    <FormButton
                        text="Sauvegarder cet enfant"
                        isValid={isValid}
                    />
                </Form>
            </FormikProvider>)
        }
    }

    return <div>
        <form>
            <h3>Consulter/Ajouter/Modifier la liste de vos enfants</h3>
            <section className="upProfilChildList">
                {
                    childList.length > 0 ?
                        childList.map((ch, index) => <div key={"client child nb" + index}>
                            <p>
                                <b>Nom: </b>
                                <span>{ch.nom}</span>
                            </p>
                            <p>
                                <b>Prénom: </b>
                                <span>{ch.prenom}</span>
                            </p>
                            <p>
                                <b>Naissance: </b>
                                <span>{ch.date_naissance}</span>
                            </p>
                            <p>
                                <button>Supprimer cet enfant</button>
                            </p>
                        </div>)
                        : ''
                }
            </section>
            {
                !currentField ?
                    <div className="childAdderBtn">
                        <button type="action" onClick={(event) => handleNewChildField(event)}>
                            Ajouter un nouvel enfant</button>
                    </div> : ''
            }
            {ret(currentField)}
        </form>
    </div>
}

export default AddChildren;