import { Link, useParams } from "react-router-dom";
import { g_Rsa } from "../Raw/Guide-pages/Rsa";
import { g_PrimeActivite } from "../Raw/Guide-pages/PrimeActivite";
import { g_Aah } from "../Raw/Guide-pages/Aah";
import { g_Af } from "../Raw/Guide-pages/Af";
import { g_Aeeh } from "../Raw/Guide-pages/AEEH";
import { g_Asf } from "../Raw/Guide-pages/Asf";
import { g_Aspa } from "../Raw/Guide-pages/Aspa";
import { g_Ce } from "../Raw/Guide-pages/Ae";
import { g_Cf } from "../Raw/Guide-pages/Cf";
import { g_Paje } from "../Raw/Guide-pages/Paje";
import { g_Apa } from "../Raw/Guide-pages/Apa";
import { g_Cpf } from "../Raw/Guide-pages/Cpf";
import { g_Css } from "../Raw/Guide-pages/Css";
import { g_APl } from "../Raw/Guide-pages/Apl";
import { g_Gj } from "../Raw/Guide-pages/GarantiJeune";
import { g_Mva } from "../Raw/Guide-pages/VieAutonome";
import { g_Asi } from "../Raw/Guide-pages/Asi";
import { g_Aed } from "../Raw/Guide-pages/Aed";
import { guidesLinksList } from "../Raw/menuLinks";
import { EndPageSimulationLink } from "../GlobalComponents/Btn";
import { PageBannerType1 } from "../GlobalComponents/OtherOnWebsite";


const Guides = () => {
    const { typeGuide } = useParams();
    const guideInfosObj = {
        "RSA": g_Rsa,
        "PA": g_PrimeActivite,
        "AAH": g_Aah,
        "AEEH": g_Aeeh,
        "MVA": g_Mva,
        "ASPA": g_Aspa,
        "CSS": g_Css,
        "ASI": g_Asi,
        "AED": g_Aed,
        "ASF": g_Asf,
        "CF": g_Cf,
        "PAJE": g_Paje,
        "APL": g_APl,
        "CE": g_Ce,
        "AF": g_Af,
        "APA": g_Apa,
        "GJ": g_Gj,
        "CPF": g_Cpf,
    }

    const guideTitle = guidesLinksList.filter(item => item.link === '/Guides/' + typeGuide)


    return <div className="simulations-guides">
        <PageBannerType1 title={typeGuide ? typeGuide : 'Guide des aides'} />
        {
            !typeGuide ?
                <section className="sg-allGuides">
                    <h1>Les guides France Assist</h1>
                    <p>Retrouvez ici la liste exhaustive de tous nos guides pour les aides disponible</p>
                    <div >
                        {
                            guidesLinksList.map((sl, index) => <div
                                key={"guide nb" + index}>
                                <b>{sl.label}</b>
                                <Link to={sl.link}>Voire notre guide</Link>
                            </div>)
                        }
                    </div>
                </section>
                : <section className="sg-guideInfos">
                    <h1>Ce qu'il faut savoir sur les {guideTitle[0].label} </h1>
                    {guideInfosObj[typeGuide]()}
                </section>

        }
        <EndPageSimulationLink />
    </div>
}

export default Guides;