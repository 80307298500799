export function PE() {
  return (
    <>
      <div className="caf-wrapper">
        <p>
          Si vous recherchez un emploi, vous pouvez vous inscrire à Pôle emploi. Si vous n'avez jamais été inscrit, il s'agit d'une première inscription. Sinon, c'est une réinscription.
        </p>
        <p>
          Une inscription ou une réinscription à Pôle Emploi est souvent subie comme une étape pénible, tant du point de vue psychologique, car une période de chômage peut être mal vécue, que du point de vue administratif, car les démarches sont perçues comme étant nombreuses et opaques.
        </p>
        <p>
          Afin de faciliter votre inscription ou réinscription à Pôle Emploi, profiter de notre offre et nous prendrons en charge toutes les démarches nécessaires.
        </p>

      </div>
    </>
  )
}

