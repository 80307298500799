
import React, { useEffect, useLayoutEffect, useState } from 'react'
import "./logement.css";
import cities from "../../../zip-codes/cities.json";
import { NextSimulationStepButton, PrevSimulationStep } from '../../../GlobalComponents/simulation/nav';
import { OneChoice } from '../../../GlobalComponents/simulation/choices';
import { addSelectedClassToSelectedOptionForSimulator } from '../../../helper/simulation/others';

function Logement({ setSection, setProgress, setData }) {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;
  const [page, setPage] = useState(1);
  const [logement, setLogement] = useState({
    logementUser: "",
    colocation: "",
    locationType: "",
    loyer: "",
    valeurMonBien: "",
    creditImmo: "",
    creditImmoVal: "",
    creditImmoValRest: "",
    codePostal: "",
    demenagement: "",
    demenagementDate: "",

    demande_logement_social: "",
    faire_economie_sur_assurance: "",
    travaux_renovation_de_letat: "",
    impayer_de_loyer_ou_energie: "",
    type_de_propriete: ''

  });
  const [codePostalError, setCodePostalError] = useState(false);

  function singleSelect(e, cl) {
    let doc = document.querySelectorAll(cl);
    if (!logement[e.target.id] || logement[e.target.id] !== e.target.innerText) {
      setLogement({ ...logement, [e.target.id]: e.target.innerText });
      for (let i = 0; i < doc.length; i++) {
        doc[i].classList.remove("selected");
      }
      e.target.classList.add("selected");
    } else {
      setLogement({ ...logement, [e.target.id]: "" });
      e.target.classList.remove("selected");
    }
  }

  useLayoutEffect(() => {
    let logementUser = document.querySelectorAll(".logementUser");

    addSelectedClassToSelectedOptionForSimulator(logementUser, logement, 'logementUser')
    addSelectedClassToSelectedOptionForSimulator(logementUser, logement, 'colocation')
    addSelectedClassToSelectedOptionForSimulator(logementUser, logement, 'locationType')
    addSelectedClassToSelectedOptionForSimulator(logementUser, logement, 'creditImmo')
    addSelectedClassToSelectedOptionForSimulator(logementUser, logement, 'demenagement')
    addSelectedClassToSelectedOptionForSimulator(logementUser, logement, 'demande_logement_social')
    addSelectedClassToSelectedOptionForSimulator(logementUser, logement, 'faire_economie_sur_assurance')
    addSelectedClassToSelectedOptionForSimulator(logementUser, logement, 'travaux_renovation_de_letat')
    addSelectedClassToSelectedOptionForSimulator(logementUser, logement, 'impayer_de_loyer_ou_energie')
    addSelectedClassToSelectedOptionForSimulator(logementUser, logement, 'type_de_propriete')

  }, [logement, page]);

  const normalizeAgeNumber = (value) => {
    return value.replace(/\D/gi, "") || "";
  };

  useEffect(() => {
    window.scrollTo(0, 160);
  }, [page]);
  return (
    <>
      {page === 1 && (
        <div className="simulateur-nationalite">
          <span>
            Je suis :
          </span>
          <div>
            <div
              className="logementUser"
              onClick={(e) => singleSelect(e, ".logementUser")}
              id="logementUser"
            >
              Locataire
            </div>
            <div
              className="logementUser"
              onClick={(e) => singleSelect(e, ".logementUser")}
              id="logementUser"
            >
              Propriétaire
            </div>
            <div
              className="logementUser"
              onClick={(e) => singleSelect(e, ".logementUser")}
              id="logementUser"
            >
              Hébergé
            </div>
            <div
              className="logementUser"
              onClick={(e) => singleSelect(e, ".logementUser")}
              id="logementUser"
            >
              Sans domicile stable
            </div>

          </div>
          <div className="button-simulateur">
            <PrevSimulationStep props={{
              setPage, prevPage: 1, anotherOperation: function () {
                setSection("mon foyer");
                setProgress(40);
              }
            }} />
            <NextSimulationStepButton disabledCond={!logement.logementUser}
              onClick={() => {
                switch (logement.logementUser) {
                  case "Locataire":
                    setPage(1.01)
                    break;
                  case "Propriétaire":
                    setPage(1.001)
                    break;
                  default:
                    setLogement({
                      ...logement, colocation: "",
                      locationType: "",
                      loyer: "", valeurMonBien: "", creditImmo: "", creditImmoVal: "", creditImmoValRest: ""
                    })
                    setPage(2);
                    break;
                }
              }}
            />
          </div>
        </div>
      )
      }
      {
        page === 1.001 && (
          <div className="simulateur-nationalite">
            <span>
              De quoi Êtes-vous propriétaire ?
            </span>
            <OneChoice props={{
              choiceArray: ["Une maison", "Un appartement"], target: "type_de_propriete",
              singleSelect
            }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!logement.type_de_propriete}
                onClick={() => {
                  setPage(1.01);

                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 1.01 && (
          <div className="simulateur-nationalite">
            <span>
              Avez-vous des impayés de loyer ou d'énergie (factures d'électricité et de gaz)?
            </span>
            <OneChoice props={{
              choiceArray: ["Oui", "Non"], target: "impayer_de_loyer_ou_energie",
              singleSelect
            }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!logement.impayer_de_loyer_ou_energie}
                onClick={() => {
                  switch (logement.logementUser) {
                    case "Locataire":
                      setLogement({ ...logement, valeurMonBien: "", creditImmo: "", creditImmoVal: "", creditImmoValRest: "" })
                      setPage(1.1);
                      break;
                    case "Propriétaire":
                      setLogement({
                        ...logement, colocation: "",
                        locationType: "",
                        loyer: ""
                      })
                      setPage(1.2);
                      break;
                    default:
                      setPage(2);
                      break;
                  }

                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 1.1 && (
          <div className="simulateur-nationalite">
            <span>
              Je suis en colocation
            </span>
            <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "colocation", singleSelect }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!logement.colocation}
                onClick={() => {
                  setPage(1.3)
                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 1.3 && (
          <div className="simulateur-nationalite">
            <span>
              Je loue
            </span>
            <OneChoice props={{
              choiceArray: ["Un logement non-meublé", "Un logement meublé ou hôtel",
                "Une résidence universitaire", "Une résidence sociale",
                "Foyer de jeunes travailleurs"], target: "locationType", singleSelect
            }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!logement.locationType}
                onClick={() => {
                  setPage(1.4)
                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 1.4 && (
          <div className="loyer-wrap">
            <span>
              Mon loyer mensuel charges comprises est de
            </span>
            <div className="simulateur-age loyer">

              <div>
                <input
                  // ref={ageRef}
                  value={logement.loyer}
                  type="tel"
                  autoFocus
                  maxLength="4"
                  placeholder="0"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(
                      e.target.value
                    );
                    setLogement({ ...logement, loyer: e.target.value });
                  }}
                />
              </div>
              <span>€</span>
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!logement.loyer}
                onClick={() => {
                  setPage(2)
                }}
              />
            </div>
          </div >
        )
      }

      {
        page === 1.2 && (
          <div className="loyer-wrap">
            <span>
              La valeur de mon bien
            </span>
            <div className="simulateur-age loyer bien">

              <div>
                <input
                  // ref={ageRef}
                  value={logement.valeurMonBien}
                  type="tel"
                  autoFocus
                  maxLength="7"
                  placeholder="100 000"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(
                      e.target.value
                    );
                    setLogement({ ...logement, valeurMonBien: e.target.value });
                  }}
                />
              </div>
              <span>€</span>
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!logement.valeurMonBien}
                onClick={() => {
                  setPage(1.5)
                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 1.5 && (
          <div className="simulateur-nationalite">
            <span>
              Je rembourse un crédit pour ce logement
            </span>
            <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "creditImmo", singleSelect }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!logement.creditImmo}
                onClick={() => {
                  switch (logement.creditImmo) {
                    case "Oui":
                      setPage(1.6)
                      break;

                    default:
                      setPage(2)
                      break;
                  }
                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 1.51 && (
          <div className="simulateur-nationalite">
            <span>
              Souhaitez-vous faire des économies sur votre assurance emprunteur?
            </span>
            <OneChoice props={{
              choiceArray: ['Oui', "Non"], target: "faire_economie_sur_assurance", singleSelect
            }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1.5 }} />
              <NextSimulationStepButton disabledCond={!logement.faire_economie_sur_assurance}
                onClick={() => {
                  setPage(1.52)
                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 1.52 && (
          <div className="simulateur-nationalite">
            <span>
              Souhaitez-vous bénéficier des travaux de rénovation pris en charge par l'État?
            </span>
            <OneChoice props={{
              choiceArray: ['Oui, pour changer mon mode de chauffage', 'Oui, pour isoler ma maison',
                'Oui, pour passer au solaire', 'Oui, pour Isolation',
                "Non, pas besoin de faire des travaux"], target: "travaux_renovation_de_letat", singleSelect
            }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1.51 }} />
              <NextSimulationStepButton disabledCond={!logement.travaux_renovation_de_letat}
                onClick={() => {
                  switch (logement.travaux_renovation_de_letat) {
                    case "Oui":
                      setPage(1.6)
                      break;

                    default:
                      setPage(2)
                      break;
                  }
                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 1.6 && (
          <div className="loyer-wrap">
            <span>
              Je rembourse tous les mois
            </span>
            <div className="simulateur-age loyer">

              <div>
                <input
                  value={logement.creditImmoVal}
                  type="tel"
                  autoFocus maxLength="4" placeholder="0"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(
                      e.target.value
                    );
                    setLogement({ ...logement, creditImmoVal: e.target.value });
                  }}
                />
              </div>
              <span>€</span>
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!logement.creditImmoVal}
                onClick={() => {
                  setPage(1.7)
                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 1.7 && (
          <div className="loyer-wrap">
            <span>
              Total restant à rembourser
            </span>
            <div className="simulateur-age loyer bien">

              <div>
                <input
                  // ref={ageRef}
                  value={logement.creditImmoValRest}
                  type="tel"
                  autoFocus
                  maxLength="7"
                  placeholder="0"
                  onChange={(e) => {
                    e.target.value = normalizeAgeNumber(
                      e.target.value
                    );
                    setLogement({ ...logement, creditImmoValRest: e.target.value });
                  }}
                />
              </div>
              <span>€</span>
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!logement.creditImmoValRest}
                onClick={() => {
                  setPage(1.51)
                }}
              />
            </div>
          </div >
        )
      }

      {
        page === 2 && (
          <div className="loyer-wrap">
            <span>
              Mon code postal :
            </span>
            <div className="simulateur-age loyer bien postal">

              <div>
                <input
                  // ref={ageRef}
                  value={logement.codePostal}
                  type="tel"
                  autoFocus
                  maxLength="5"
                  placeholder="75001"
                  onChange={(e) => {
                    setCodePostalError(false)
                    e.target.value = normalizeAgeNumber(
                      e.target.value
                    );
                    setLogement({ ...logement, codePostal: e.target.value });
                  }}
                />
              </div>
              {codePostalError && <span>
                Code postal invalide
              </span>}
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!logement.codePostal}
                onClick={() => {
                  let test = false;
                  for (var i = 0; i < cities.length; i++) {
                    if (cities[i].zip_code === logement.codePostal) {
                      test = true;
                      break;
                    }
                  }
                  if (test) {
                    setCodePostalError(false);
                    setPage(3)
                  } else {
                    setCodePostalError(true);
                  }
                }}
              />
            </div>
          </div >
        )
      }


      {
        page === 3 && (
          <div className="simulateur-nationalite">
            <span>
              Je compte déménager dans les prochaines semaines
            </span>

            <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "demenagement", singleSelect }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 1 }} />
              <NextSimulationStepButton disabledCond={!logement.demenagement}
                onClick={() => {
                  switch (logement.demenagement) {
                    case "Oui":
                      setPage(3.1)
                      break;

                    default:
                      setPage(3.9)
                      break;
                  }
                }}
              />
            </div>
          </div >
        )
      }
      {
        page === 3.1 && (
          <>
            <div className="simulateur-date">
              <span>Date du déménagement
                <span style={{ color: "	#3c3c3c", fontSize: 13, display: "block" }}>
                  Une date approximative suffit
                </span>
              </span>
              <div>
                <input
                  value={logement.demenagementDate}
                  type="date"
                  autoFocus
                  // max={today}
                  onChange={(e) => {
                    // e.target.value = normalizeDateNumber(e.target.value);
                    setLogement({
                      ...logement,
                      demenagementDate: e.target.value,
                    });
                  }}
                />
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 3 }} />
              <NextSimulationStepButton disabledCond={!logement.demenagementDate}
                onClick={() => {
                  setPage(3.9)
                }}
              />
            </div>
          </>
        )
      }

      {
        page === 3.9 && (
          <div className="simulateur-nationalite">
            <span>
              Êtes-vous intéressé (e ) par une demande de logement social ?
            </span>
            <OneChoice props={{ choiceArray: ["Oui", "Non"], target: "demande_logement_social", singleSelect }} />
            <div className="button-simulateur">
              <PrevSimulationStep props={{ setPage, prevPage: 2 }} />
              <NextSimulationStepButton disabledCond={!logement.demande_logement_social}
                onClick={() => {
                  setSection("mes revenus");
                  setProgress(80);
                  setData((data) => {
                    return { ...data, ...logement };
                  });
                }}
              />
            </div>
          </div >
        )
      }
    </>
  )
}

export default Logement
