import { InputPasswordWithFormik } from "./password";
import { SimpleInputWithFormik, TextAreaWithFormik } from "./simple";
import { InputCheckBox2WithFormik, InputCheckBoxWithFormik, InputRadioWithFormik } from "./choices";
import { useField } from "formik";
import { DocFileInputWithFormik, DocFilesInputWithFormik } from "./others";


export function HideInputConditionaly({ conditionalFieldName, conditionalValues, children }) {
    const [field, meta, helpers] = useField(conditionalFieldName);
    const { value } = meta;
    if ((conditionalFieldName && conditionalValues) && !conditionalValues.includes(value)) {
        return '';
    }
    return children;
}
function FormikFieldController(props) {
    const simplesType = ['text', 'email', 'number', 'tel', 'date'];
    const { fieldType, conditionalFieldName, conditionalValues, ...rest } = props;
    return <HideInputConditionaly conditionalFieldName={conditionalFieldName}
        conditionalValues={conditionalValues}>
        {
            (() => {
                if (simplesType.includes(fieldType)) {
                    return <SimpleInputWithFormik {...rest} type={fieldType} />
                }
                if (fieldType == 'password')
                    return <InputPasswordWithFormik  {...rest} />

                if (fieldType === 'checkbox') {
                    return <InputCheckBoxWithFormik {...rest} />
                }
                if (fieldType === 'checkbox2') {
                    return <InputCheckBox2WithFormik {...rest} />
                }
                if (fieldType === 'radio') {
                    return <InputRadioWithFormik {...rest} />
                }
                if (fieldType === 'file') {
                    return <DocFileInputWithFormik {...rest} />
                }
                if (fieldType === 'files') {
                    return <DocFilesInputWithFormik {...rest} />
                }
                if (fieldType === 'textarea') {
                    return <TextAreaWithFormik {...rest} />
                }
            })()
        }
    </HideInputConditionaly>
}

export default FormikFieldController;