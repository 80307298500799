import React from 'react'
import { useState } from 'react'
import axios from 'axios';
import { withAuth } from '../hocs/withAuth';
import { useAuth } from '../contexts/auth';
import { FormButton } from '../uikits/buttons';
import { cancelPlanFields } from '../helper/form/fields';
import FormikFieldController from '../uikits/form';
import { Form, FormikProvider, useFormik } from 'formik';
import { CancelSubSchema } from '../helper/form/validations';

function CancelSubscription() {
    const formik = useFormik({
        initialValues: {
            cancelReason: ''
        },
        validateOnMount: true,
        validationSchema: CancelSubSchema,
        onSubmit: handleCancelSubmit
    });
    const { isValid } = formik;
    const [cancelIsSet, setCIS] = useState(false);

    const { user } = useAuth();

    function handleCancelSubmit(formvalues) {
        axios.post('/user/remove-pay', { user, cancelReason: formvalues.cancelReason })
            .then(res => setCIS(true))
            .catch(err => console.log(err))
    }
    return (
        <div className='cancelSubPart'>
            <section>
                {
                    cancelIsSet ? <h1>Votre demande de résiliation a bien été envoyé</h1>
                        : <>
                            <h1>Souhaitez vous réellement résilier votre abonnement France-Assist?</h1>
                            <br />
                            <p>
                                Dites-nous les raisons de votre demande de résiliation à notre service d'accompagnement.
                            </p>
                            <br />
                            <FormikProvider value={formik}>
                                <Form >
                                    {
                                        cancelPlanFields.map((item, i) => <FormikFieldController
                                            {...item}
                                            key={"cp field nb" + i}
                                        />)
                                    }
                                    <FormButton text='Résilier mon abonnement'
                                        isValid={isValid} />
                                </Form>
                            </FormikProvider>
                        </>
                }
            </section>
        </div>
    )
}

export default withAuth(CancelSubscription)