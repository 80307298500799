export function g_Asi() {
  return (
    <>
      <div className="caf-wrapper">

        <p>
          L’allocation supplémentaire d’invalidité est une prestation mensuelle versée aux assurés invalides ayant de faibles ressources, qui ne remplissent pas les conditions pour bénéficier de l’allocation de solidarité aux personnes âgées (ASPA).
        </p>
        <h1>Qui a droit à l'allocation supplémentaire d'invalidité ?</h1>
        <span>
          L'allocation supplémentaire d'invalidité peut être versée selon certaines conditions :
          <ul>
            <li>
              Il faut que la personne soit en situation d'invalidité et voit ses capacités de travail ou encore ses revenus réduits de 2/3
            </li>
            <li>
              Il faut pouvoir justifier d'une résidence stable et régulière en France. Si le demandeur est étranger, il lui faut aussi détenir un titre de séjour l'autorisant à travailler depuis au moins 10 ans (ou bien un justificatif de cotisations pour la retraite durant ces 10 ans) ou bien être réfugié, apatride, bénéficiaire de la protection subsidiaire ou encore avoir combattu pour la France. La personne étrangère faisant la demande d'ASI doit aussi être ressortissante d'un État membre de l'Espace économique européen (EEE) ou de la Confédération suisse
            </li>
            <li>
              Respecter les critères d’âge
            </li>
            <li>
              Percevoir des ressources inférieures à un certain plafond
            </li>
          </ul>
        </span>

      </div>
    </>
  )
}
