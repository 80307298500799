export function g_Aspa() {
  return (
    <>
      <div className="caf-wrapper">
        <p>
          L’Aspa, ou l’allocation de solidarité aux personnes âgées est une
          allocation pour les personnes de 65 ans ou plus dont les ressources
          ne permettent pas de vivre décemment. L’Aspa est soumise à des
          conditions de revenus, mais dépend également de votre situation
          familiale.
        </p>
        <h1>Qu’est-ce que l’Aspa ?</h1>
        <p>
          L’Aspa, autrefois appelé minimum vieillesse est une allocation pour les personnes âgées accessible à partir de 65 ans. Elle est destinée principalement aux personnes dont la pension de retraite perçue n’est pas suffisante pour avoir un niveau de vie décent. Dans certaines situations il est possible de percevoir le montant de l’ Aspa au moment de votre départ à la retraite.
        </p>
        <h1>Qui est éligible ?</h1>
        <span>
          Si vous souhaitez percevoir l’Aspa, vous devez respecter les conditions suivantes :
          <br />
          <ul>
            <li>
              Avoir 65 ans ou plus
            </li>
            <li>
              Tous les bénéficiaires doivent résider en France
            </li>
            <li>
              Vous pouvez bénéficier de l’Aspa si vous percevez déjà une retraite personnelle ou de réversion
            </li>
          </ul>
        </span>
      </div>
    </>
  );
}
