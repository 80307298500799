import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { FormikProvider, useFormik } from "formik";
import { errorToast, successToast } from "../../../helper/others";
import { FormButton } from "../../../uikits/buttons";
import { threeMonthsRessourceFields, yearRessourceFields } from "../../../helper/form/fields";
import FormikFieldController from "../../../uikits/form";
import { Form } from "formik";
import { AppSectionSpinner } from "../../../uikits/others.js"

const UpdateRessources = ({ props }) => {

    const formik = useFormik({
        initialValues: {
            salaire_annuelle: '', charge_deductible: '',

            trimestre_1: '', trimestre_2: '', trimestre_3: '',

            salaires_enfants: []
        },
        onSubmit: handleSubmit
    });
    const [currentRessources, setCR] = useState({});
    const [childRessources, setCHR] = useState([])
    const [formValues, setFormValues] = useState({
        salaire_annuelle: '',
        charge_deductible: '',

        trimestre_1: '',
        trimestre_2: '',
        trimestre_3: '',

        salaires_enfants: []
    })
    useEffect(() => {
        axios.get("/user/ressources")
            .then(res => {
                if (res.data != null) {
                    setCR(res.data)
                    setFormValues({
                        salaire_annuelle: res.data.salaires_annuelles,
                        charge_deductible: res.data.charges_deductibles_annuelles,

                        trimestre_1: res.data.salaires_trimestrielles.trimestre_1,
                        trimestre_2: res.data.salaires_trimestrielles.trimestre_2,
                        trimestre_3: res.data.salaires_trimestrielles.trimestre_3,

                        salaires_enfants: res.data.salaires_enfants
                    })
                    setCHR(res.data.salaires_enfants)
                }
            })
            .catch(err => console.log('d'))

    }, [])

    const { typeRessources } = props;

    const formFields = {
        "annuelles": <AnnuelleRessourceForm props={{ formValues, setFormValues }} />,
        "trimestrielles": <TrimestreRessourceForm props={{ formValues, setFormValues }} />
    }


    function handleSubmit(formValues) {
        let formData = null;
        let urlEnd = "";

        if (typeRessources == 'annuelles') {
            urlEnd = "/ressources-annuelles"
            formData = {
                salaires_annuelles: formValues.salaire_annuelle,
                charges_deductibles_annuelles: formValues.charge_deductible,
                salaires_annuelles_conjoint: 0,
                charges_deductibles_annuelles_conjoint: 0
            }
        } else {
            urlEnd = "/ressources-trimestrielles"
            formData = {
                salaires_trimestrielles: {
                    trimestre_1: formValues.trimestre_1,
                    trimestre_2: formValues.trimestre_2,
                    trimestre_3: formValues.trimestre_3,
                },
                salaires_trimestrielles_conjoint: {
                    trimestre_1: 0,
                    trimestre_2: 0,
                    trimestre_3: 0,
                },
                salaires_enfants: 0
            }
        }

        axios.put('/user' + urlEnd, formData)
            .then(res => {
                console.log(res);
                if (res.data._id) {
                    successToast('Ressources enregistré avec success')
                }
            })
            .catch(err => errorToast('Désolé ,une erreur est survenue'))
    }


    function handleChildSalaireChange(event) {
        let value = event.target.value;
        let name = event.target.name;

        let copy = formValues.salaires_enfants;
        if (formValues.salaires_enfants.length == 0) {
            copy = [{ id: name, salaires: value }]
        } else {
            copy.push({ id: name, salaires: value })
        }
        setFormValues({ ...formValues, salaires_enfants: copy })
    }


    return <section>
        <Link to="/Ressources"> <i className="mdi mdi-chevron-left"></i> Revenir à la liste des ressources</Link>
        <h2>Modifiez vos Ressources {typeRessources} </h2>
        <FormikProvider value={formik}>
            <Form >
                {
                    currentRessources._id ? formFields[typeRessources] : <AppSectionSpinner />
                }
                {
                    // typeRessources == 'trimestrielles' && childRessources.length > 0 ?
                    //     <>
                    //         <h2>Notifier le salaire de vos enfants(si ils en recoivent) </h2>
                    //         {
                    //             currentRessources.salaires_enfants.map((cse, index) => <div key={"child salair nb" + index}
                    //                 className="formSegment">
                    //                 <label>Salaire enfant n* "1" </label>
                    //                 <input type="number" value={cse.salaires}
                    //                     name={'dk76s766'}
                    //                     onChange={(event) => handleChildSalaireChange(event)} />
                    //             </div>)
                    //         }
                    //     </>
                    //     : ""
                }
                <FormButton text='Sauvegarder les ressources' isValid={true} />
            </Form>
        </FormikProvider>
    </section>
}

function AnnuelleRessourceForm({ props }) {
    return <>
        {
            threeMonthsRessourceFields.map((item, i) => <FormikFieldController
                {...item}
                key={'hehe 3m field nb' + i}
            />)
        }
    </>
}

function TrimestreRessourceForm({ props }) {
    return <>
        {
            yearRessourceFields.map((item, i) => <FormikFieldController
                {...item}
                key={'hehe year field nb' + i}
            />)
        }
    </>
}

export default UpdateRessources;