export function g_PrimeActivite() {
  return (
    <>
      <div className="caf-wrapper">

        <p>
          L’objectif principal de la prime d’activité est de de favoriser le maintien de l’emploi ou le retour sur le marché du travail. Son versement mensuel en fait une aide plus incitative. Elle s’ajoute ainsi à vos revenus mensuels, sans répéter l’erreur des deux aides dont elle est née.
        </p>
        <h1>Les conditions d’obtention de la prime d’activité</h1>
        <p>
          Pour toucher la prime d’activité, vous devez avant tout résider en France légalement, de manière stable et effective. Vous devez résider en France de manière permanente (comprenez que la durée de vos séjours à l’étranger ne doivent excéder 3 mois par année civile ou date à date). Vous devez également être âgé au minimum de 18 ans.
        </p>
        <p>
          La prime d’activité s’indexe avant tout sur vos revenus. Vous devez donc avoir une activité et être rémunéré modestement.
        </p>

      </div>
    </>
  )
}

