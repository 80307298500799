const Mention_légales = () => {
    return <div className="legality">
        <section className='ml'>
            <h1>
                Mentions Légales
            </h1>

            <h3>1. Présentation du site</h3>

            Il est précisé aux utilisateurs du site
            <a href="https://france-assist.fr"> www.france-assist.fr </a>
            l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi :

            <b>Editeur: </b>FRANCE CONSEIL

            <b>Siège social :</b> <strong>France-Assist est un site privé et indépendant</strong>
            <b>Directeur de publication :</b> Christian Mallet
            <b>Téléphone :</b> 04 65 84 08 48
            <b>Adresse email :</b> contact@france-assist.fr


            <h3>2. Conditions générales d’utilisation du site et des services proposés</h3>

            L’utilisation du site <a href="https://france-assist.fr">www.france-assist.fr</a> implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site <a href="https://france-assist.fr">www.france-assist.fr</a> sont donc invités à les consulter de manière régulière. Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par <a href="https://france-assist.fr">www.france-assist.fr</a>, qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l’intervention.

            Le site <a href="https://france-assist.fr">www.france-assist.fr</a> est mis à jour régulièrement par l’équipe France Assist De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.

            <h3>3. Description des services fournis</h3>

            Le site <a href="https://france-assist.fr">www.france-assist.fr</a> a pour objet de fournir une information concernant l’ensemble des activités de la société.
            FRANCE CONSEIL s’engage à fournir sur le site <a href="https://france-assist.fr">www.france-assist.fr</a> des informations aussi précises que possible.

            <h3>4. Propriété intellectuelle et contrefaçons</h3>

            FRANCE CONSEIL est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels.
            Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de : FRANCE CONSEIL.
            Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.

            <h3>5. Réclamations</h3>

            Pour toute réclamation, merci de nous contacter via l’adresse email contact@france-assist.fr ou bien par courrier adressé au Service Clients à l’adresse suivante :<strong>France-Assist est un site privé et indépendant</strong>. Il est important pour nous d’entretenir une relation de confiance avec nos utilisateurs.

        </section>
    </div>
}

export default Mention_légales;