import { Route, Routes, useLocation } from "react-router-dom";
import './Assets/css/default/index.css';
import './Assets/css/default/utilClass.css';
import './Assets/css/slick/slick.css';

import './Assets/css/errorAndMaintenance.css';
import './Assets/css/app.css';

import "./Assets/css/slick/slick.css";
import "./Assets/css/slick/slick-theme.css";

import './Assets/css/others.css'

import './Assets/css/index.scss'

import "moment/locale/fr";

import { useEffect } from "react";

import { Modal } from "./GlobalComponents/message/modal";
import { allRoutes } from "./Raw/routesArray";
import { AuthContextProvider } from "./contexts/auth";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes('/Faq')) {
      window.scrollTo(0, 0);
    }
  }, [location]);


  return (<>
    <AuthContextProvider>
      <Routes>
        {
          allRoutes.map((route, index) => <Route key={'need auth num route' + index}
            path={route.link}
            element={route.components} />)
        }
      </Routes>
      <Modal />
      {/* <AppFullScreenSpinner /> */}
      <ToastContainer />
    </AuthContextProvider>
  </>
  );
}

export default App;
