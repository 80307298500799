export function DI() {
  return (
    <>
      <div className="caf-wrapper">
        <p>
          La déclaration d'impôt demeure indispensable. Elle permet de calculer le montant total d'impôt sur le revenu dont le contribuable est redevable au titre de l'année d'imposition
        </p>
        <p>
          La période déclarative intervient généralement au printemps. Les foyers fiscaux reçoivent ensuite leur avis d'imposition à la fin du mois de juillet. La date de paiement, elle, intervient à la mi-septembre. Le service de correction de la déclaration d'impôts a été mis en ligne le 4 août dernier, et il reste disponible jusqu'au 15 décembre.
        </p>
        <p>
          Notre service permet aux clients de liquider leurs impôts, et taxes (déclarations mensuelles, dépôt des déclaration de l'impôt sur les sociétés, déclaration de l'avance due par les sociétés de personnes et assimilées, déclaration de l'impôt sur le revenu des personnes physiques, déclaration de l'acompte provisionnel) à partir de leur poste de travail en souscrivant à notre offre.
        </p>

      </div>
    </>
  )
}
