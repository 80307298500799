import { useParams } from 'react-router-dom';
import DocumentList from '../RoutesSubComponents/dashboard/documents/documentList';
import axios from 'axios';
import { useState, useEffect } from 'react';
import AddDocument from '../RoutesSubComponents/dashboard/documents/addDocument';
import SelectTypeDocument from '../RoutesSubComponents/dashboard/documents/selectDocument';
import { useAuth } from '../contexts/auth';
import { withAuth } from '../hocs/withAuth';
const Documents = () => {
    const { documentAction } = useParams();
    const { user } = useAuth();
    const [documentList, setDocumentList] = useState([]);
    const agent = user.agentData;
    const [typeDoc, setTypeDoc] = useState('ofUser');

    useEffect(() => {
        if (documentAction == "liste") {
            let apiUrlEnd = '';
            apiUrlEnd = typeDoc == 'ofUser' ? '/emails' : '/documents'
            axios.get('/user' + apiUrlEnd)
                .then(res => setDocumentList(res.data))
                .catch(err => console.log(err))
        }
    }, [typeDoc, documentAction])

    return <div className="fichier">
        <h1>Documents</h1>
        {
            (() => {
                if (documentAction == 'liste') {
                    return <>
                        <h2>Liste de mes documents</h2>

                        <SelectTypeDocument props={{ typeDoc, setTypeDoc }} />
                        {
                            documentList.length > 0 ?
                                <DocumentList props={{ documentList, typeDoc }} />
                                : <div className='sorryNotFound'>
                                    <h3>Desolé , aucun document à afficher .</h3>
                                </div>
                        }
                    </>
                } else if (documentAction == 'ajouter') {
                    return <AddDocument props={{ agent }} />
                }
            })()
        }

    </div>
}

export default withAuth(Documents);