import { Banner } from '../RoutesSubComponents/accueil/banner';
import HowItWord from '../RoutesSubComponents/accueil/howItWork';
import OurTaskOffer from '../RoutesSubComponents/accueil/ourTasks';
import OurResults from '../RoutesSubComponents/accueil/ourResult';
import HomeSomeFAQ from '../RoutesSubComponents/accueil/someFaq';
import HomeBestArticles from '../RoutesSubComponents/accueil/bestArticles';
import { SomeReviews } from '../GlobalComponents/Others';
import { withoutAuth } from '../hocs/withoutAuth';
import StealerAlert from '../GlobalComponents/StealerAlert';

const Accueil = () => {

    return <div className="home">
        <Banner />
        {/* <StealerAlert /> */}
        <HowItWord />
        <OurTaskOffer />
        <OurResults />
        {/* <UserComments /> */}
        <HomeBestArticles />
        <HomeSomeFAQ />
        <SomeReviews />
    </div>
}

export default withoutAuth(Accueil);