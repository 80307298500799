import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';
import axios from 'axios';
import { Provider } from 'react-redux';
import store from "./store";


const tagManagerArgs = {
  gtmId: 'GTM-5WXWQN9'
}

ReactGA.initialize('UA-209023794-1');
ReactGA.pageview(window.location.pathname + window.location.search);

axios.defaults.baseURL = process.env.NODE_ENV == 'production' ? 'https://france-assist.fr' : 'http://localhost:5100';
axios.defaults.headers = localStorage.getItem('fa-authToken') ? {
  "Authorization": 'Bearer ' + localStorage.getItem('fa-authToken')
} : null;


TagManager.initialize(tagManagerArgs)
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
