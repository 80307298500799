export function RL() {
  return (
    <>
      <div className="caf-wrapper">
        <p>
          Vous êtes à la recherche d’un logement ?, vous risquez de vous retrouver sans logement dans peu de temps?
        </p>
        <p>
          En fonction de votre situation et de vos revenus, l'equipe France Assist, vous accompagne dans toutes vos démarches en souscrivant à notre offre
        </p>
      </div>
    </>
  )
}
