import { Link, useParams } from "react-router-dom";
import { S_I_RSA } from "../Raw/Simulation-infos-page/RSA";
import { S_I_AAH } from "../Raw/Simulation-infos-page/AAH";
import { S_I_APA } from "../Raw/Simulation-infos-page/APA";
import { S_I_AF } from "../Raw/Simulation-infos-page/AF";
import { S_I_APL } from "../Raw/Simulation-infos-page/APL";
import { S_I_MVA } from "../Raw/Simulation-infos-page/MVA";
import { S_I_PAJE } from "../Raw/Simulation-infos-page/PAJE";
import { S_I_AEEH } from "../Raw/Simulation-infos-page/AEEH";
import { S_I_PPA } from "../Raw/Simulation-infos-page/PPA";
import { S_I_ASPA } from "../Raw/Simulation-infos-page/ASPA";
import { EndPageSimulationLink } from "../GlobalComponents/Btn";
import { simulateurLinks2 } from "../Raw/menuLinks";
import { PageBannerType1 } from "../GlobalComponents/OtherOnWebsite";
import { bothAuth } from "../hocs/bothAuth";

const Simulations = () => {

    const { typeSimulation } = useParams();

    const simulationInfosObj = {
        "RSA": S_I_RSA,
        "PPA": S_I_PPA,
        "AAH": S_I_AAH,

        "AEEH": S_I_AEEH,
        "MVA": S_I_MVA,
        "ASPA": S_I_ASPA,

        "PAJE": S_I_PAJE,
        "APL": S_I_APL,
        "AF": S_I_AF,

        "APA": S_I_APA,
    };
    return <div className="simulations-guides">
        <PageBannerType1 title={typeSimulation ? 'Simulation ' + typeSimulation : 'Nos simulations'} />
        {
            !typeSimulation ?
                <section className="sg-allSimulations">
                    <h1>Nos simulateurs diponibles</h1>
                    <p>Retrouvez ici la liste exhaustive de toutes nos simulateurs disponible</p>
                    <div>
                        {
                            simulateurLinks2.map((sl, index) => <div
                                key={"simulation nb" + index}>
                                <b>{sl.label}</b>
                                <Link to={sl.link}>Plus d'info</Link>
                            </div>)
                        }
                    </div>
                </section>
                : <section className="sg-simulationInfos">
                    <h1>Ce qu'il faut savoir avant de lancer votre simulation </h1>
                    {simulationInfosObj[typeSimulation]}
                </section>
        }
        <EndPageSimulationLink />
    </div>
}

export default bothAuth(Simulations);