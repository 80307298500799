export const S_I_AEEH =
  <>
    <div >
      <p>
        L’Allocation d’Education de l’Enfant Handicapé (AEEH) est une prestation familiale destinée à compenser les frais supplémentaires liés à l’éducation et aux soins apportés à un enfant handicapé.
      </p>
      <h3>
        AEEH : comment ça marche ?
      </h3>
      <span>
        Cette aide est destinée à aider les familles à affronter les frais engendrés par le handicap d’un enfant de moins de 20 ans. Depuis le 1er avril 2021, le montant de l’AEEH de base est fixé à 132,74 euros par mois. Peut s’y ajouter un complément financier en fonction des coûts supplémentaires liés à la nature et la gravité du handicap.
      </span>
      <h3>
        Montant de l'AEEH
      </h3>
      <span>
        Le montant du complément AEEH 2021, varie en fonction des 6 catégories déterminées par la commission des droits et de l’autonomie des personnes handicapées dont dépend le jeune handicapé :
        <ul>
          <li>
            1ère catégorie : 99,46 euros,
          </li>
          <li>
            2ème catégorie : 269,36 euros,
          </li>
          <li>
            3ème catégorie : 381,25 euros,
          </li>
          <li>
            4ème catégorie : 590,81 euros,
          </li>
          <li>
            5ème catégorie : 755,08 euros,
          </li>
          <li>
            6ème catégorie : 1.125,29 euros.
          </li>
        </ul>
      </span>
      <h3>
        AEEH : durée de versement
      </h3>
      <span>
        Depuis, le 1er janvier 2019, la durée de versement de l’AEEH a été allongée (pour les demandes effectuées à partir de cette date). Elle s’étale désormais entre 2 et 20 ans.
      </span>

    </div>
  </>
