import Maintenance from "../Routes/Maintenance";
import Accueil from "../Routes/Accueil";
import Souscription from "../Routes/Souscription";
import ResultatSouscription from "../Routes/ResultSouscription";
import Auth from "../Routes/Auth";
import Mot_de_passe from "../Routes/Mot_de_passe";

import Contact from "../Routes/Contact";
import Politique_de_confidentialité from "../Routes/Pdc";
import Mention_légales from "../Routes/Ml";
import CGUV from "../Routes/Cguv";
import Simulations from "../Routes/Simulations";
import Guides from "../Routes/Guides";
import Dashboard from "../Routes/Dashboard";
import Documents from "../Routes/Documents";

import Aide_Offres from "../Routes/Aide-Offre";

import Agenda from "../Routes/Agenda";
import Profil from "../Routes/Profil";
import SimulationGratuit from "../Routes/Simulation-gratuite";
import SimulationResultat from "../Routes/SimulationResultat";
import Ressources from "../Routes/Ressources";

import SimulationResultWithToken from "../Routes/SimulationResultat2";
import PrelevementForm from "../Routes/Prelevement";
import Blog from "../Routes/Blog";

import Faq from "../Routes/Faq";

import CancelSubscription from "../Routes/CancelSubscription";

import Page404 from "../Routes/404";
import { Navigate } from "react-router-dom";
import Referral from "../Routes/Referral";



export const allRoutes = [
    { link: '/Cancel/subscription', components: <CancelSubscription /> },
    { link: '/Dashboard', components: <Dashboard /> },
    { link: '/Documents/:documentAction', components: <Documents /> },
    { link: '/Agenda', components: <Agenda /> },
    { link: '/Ressources', components: <Ressources /> },
    { link: '/Ressources/:typeRessources', components: <Ressources /> },
    { link: '/Profil/:action', components: <Profil /> },
    { link: '/Cancel/subscription', components: <CancelSubscription />, },

    { link: '/resultat/token/:token', components: <SimulationResultWithToken /> },
    { link: '/Blog', components: <Blog /> },
    { link: '/Blog/article/:articleId', components: <Blog /> },
    { link: '/Maintenance', components: <Maintenance /> },
    { link: '*', components: <Navigate to="/Dashboard" /> },

    { link: '/Connexion', components: <Auth props={{ authType: 'connexion' }} /> },
    { link: '/Inscription', components: <Auth props={{ authType: 'inscription' }} /> },
    { link: '/password/mail', components: <Mot_de_passe />, },
    { link: '/password/:passwordId/:passwordToken', components: <Mot_de_passe />, },

    { link: '/Souscription', components: <Souscription />, },
    { link: '/Souscription/:typeCommande', components: <Souscription />, },
    { link: '/Resultat-souscription', components: <ResultatSouscription />, },

    {
        link: '/Simulation-gratuite', components: <SimulationGratuit />,
    },
    { link: '/Simulation-resultat', components: <SimulationResultat />, },
    { link: '/resultat/token/:token', components: <SimulationResultWithToken />, },


    { link: '/Contact', components: <Contact />, },
    { link: '/Faq', components: <Faq />, },
    { link: '/Faq/:qType/:qId', components: <Faq />, },
    { link: '/Politique-de-confidentialite', components: <Politique_de_confidentialité />, },
    { link: '/Mention-legales', components: <Mention_légales />, },
    { link: '/CGV', components: <CGUV />, },

    { link: '/Guides', components: <Guides /> },
    { link: '/Guides/:typeGuide', components: <Guides /> },

    { link: '/Simulations/:typeSimulation', components: <Simulations /> },
    { link: '/Nos-Aides/:typeAide', components: <Aide_Offres /> },

    { link: '/Programme-de-reference/description', components: <Referral view="desc" /> },
    { link: '/Programme-de-reference/formulaire', components: <Referral view="form" /> },


    { link: '/', components: <Accueil /> },
    { link: '/Maintenance', components: <Maintenance /> },
]