import * as Yup from 'yup';

// function checkNumberLength(number, length) {
//     return number.test('len', 'Minimum ' + length + ' car', val => !val || (val && val.toString().length === length))
// }


let nom = Yup.string().required("Champs requis");
let prenom = Yup.string().required("Champs requis");
let telephone = Yup.string().required("Champs requis");

let email = Yup.string().email('Invalid email').required("Champs requis");

let adresse = Yup.string().required("Champs requis");
let codepostal = Yup.string().required("Champs requis");

let cardNumber = Yup.string().max(16, 'Trop court').max(16, 'Trop long').required("Champs requis");
let code_derriere_carte = Yup.string().max(3, 'Trop court').max(3, 'Trop long').required("Champs requis");
let carte_expiration_mois = Yup.string().max(2, 'Trop court').max(2, 'Trop long').required("Champs requis");
let carte_expiration_année = Yup.string().max(4, 'Trop court').max(4, 'Trop long').required("Champs requis");



let phone = Yup.string().matches(/^(06|07)\d{8}$/, 'Numéro de téléphone non valide').required("Champs requis");
let pwd = Yup.string().min(8, 'Minimum 8 caractères : Mot de passe trop court!').max(150, 'Trop long');

let password_confirmation = Yup.string().oneOf([Yup.ref('password'), null], 'Les mots de passe doivent être conformes');
// .required("Champs requis")

export const LoginSchema = Yup.object().shape({
    email,
    password: pwd
});

export const SignupSchema = Yup.object().shape({
    email, nom, prenom, telephone, codepostal,
    number: cardNumber, code_derriere_carte, carte_expiration_mois, carte_expiration_année,
    password: pwd.required('Champs requis'), password_confirmation: password_confirmation.required('Champs requis')
});

export const UpdateGeneralInfoSchema = Yup.object().shape({
    adresse, password: pwd.notRequired(), password_confirmation: password_confirmation.notRequired()
});


export const NewDocSchema = Yup.object().shape({
    title: Yup.string().required("Champs requis"),
    docFiles: Yup.array().min(1, "Veuillez sélectionner au moins un fichier.")
        .required("Champs requis")
});


export const ContactSchema = Yup.object().shape({
    username: Yup.string().required("Champs requis"),
    telephone: Yup.string().required("Champs requis"),
    email: email,
    message: Yup.string().required("Champs requis")
});


export const ResetPasswordStep1 = Yup.object().shape({
    email: email
});

export const ResetPasswordStep2 = Yup.object().shape({
    password: pwd.required("Champs requis"), password_confirmation: password_confirmation.required("Champs requis")
});

export const CancelSubSchema = Yup.object().shape({
    cancelReason: Yup.string().required("Champs requis")
});

export const NewChildSchema = Yup.object().shape({
    nom_enfant: Yup.string().required("Champs requis"),
    prenom_enfant: Yup.string().required("Champs requis"),
    date_de_naissance_enfant: Yup.string().required("Champs requis")
});

export const CommentSchema = Yup.object().shape({
    message: Yup.string().required("Champs requis"),
    autorName: Yup.string().required("Champs requis"),
    autorEmail: email
});


export const ReferralSchema = Yup.object().shape({
    referrorName: Yup.string().required("Champs requis"),
    referrorEmail: email,
    referredEmail: email,
    referredName: Yup.string().required("Champs requis"),
    referredPhone: Yup.string().required("Champs requis"),
});



