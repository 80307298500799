import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios';
import { ArticleCommentsSection } from './comments.js'
import { Link } from 'react-router-dom';
import { AppSectionSpinner } from '../../uikits/others.js';


function createMarkup(rawHTML) {
    return { __html: rawHTML };
}
function BlogArticleDetail({ articleId }) {
    const [article, setArticle] = useState();
    const [aLoading, setAL] = useState(true);

    const apiUrl = process.env.NODE_ENV == 'production' ? 'https://dashboard.france-assist.fr'
        : 'http://localhost:5110';
    useEffect(() => {
        axios.get(apiUrl + '/admin/blog/article/' + articleId)
            .then(res => setArticle(res.data))
            .catch(err => console.log('blog err'))
            .finally(() => {
                setAL(false)
            })
    }, []);


    if (aLoading)
        return <AppSectionSpinner />;
    return (
        <>
            <div className="arcticleContent">


                <div className="ac-head">
                    <p>
                        <span>Posté par : <b>{article.autorId.nom + ' ' + article.autorId.prenom}</b> </span>
                        <br />
                        <strong>{article.views.length} vues</strong>
                    </p>
                    <Link to="">{article.categoryId.name}</Link>
                </div>
                <h1 className="articleContent-title">{article.title}</h1>
                <img src={article.banner} alt="" />
                <img src="" alt="" />
                <div className="acrticleContent-firstPart">
                    <p>
                        {article.description}
                    </p>
                </div>
                <div className="acrticleContent-secondPart">
                    <div className='bpv-content'
                        dangerouslySetInnerHTML={createMarkup(article.content)} />
                </div>

            </div>


            <ArticleCommentsSection apiUrl={apiUrl} articleId={articleId} />

        </>
    )
}




export default BlogArticleDetail