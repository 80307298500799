import { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { SimulationLink } from "../../GlobalComponents/Btn";
import { SimpleImage } from "../../GlobalComponents/img/global";
import { useAuth } from "../../contexts/auth";
export const Banner = () => {
    const { user } = useAuth();
    const [activeSlide, setActiveSlide] = useState(0)
    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };

    const homeBannerTexts = [
        {
            img: 'b1.webp',
            h1: <>J'ai des droits sociaux !</>,
            b: <>Faute d'informations ou en raison d'une complexité des démarches,<br />
                de nombreuses prestations sociales
                ne sont pas réclamées<br /> par les personnes qui y ont pourtant droit !</>
        },
        {
            img: 'b2.webp',
            h1: <>Je dois vivre pleinnement de mes droits sociaux!</>,
            b: <>Faute d'informations ou en raison d'une complexité des démarches,<br />
                de nombreuses prestations sociales
                ne sont pas réclamées<br /> par les personnes qui y ont pourtant droit !</>
        },
        {
            img: 'b3.webp',
            h1: <>J'ai le droit de savoir ce qui est et ce qui n'est pas mon droit social!</>,
            b: <>Faute d'informations ou en raison d'une complexité des démarches,<br />
                de nombreuses prestations sociales
                ne sont pas réclamées<br /> par les personnes qui y ont pourtant droit !</>
        },
        {
            img: 'referror.webp',
            h1: <>Programme de référencement des particuliers</>,
            b: <>Référencez un particulier et obtenez une prime de référencement lorsqu’il s’inscrit et souscris a un abonnement!</>
        },
    ]

    return <div className="hBanner">
        <section className='hb-textAndImage'>
            <Slider {...settings}
            // beforeChange={(_, next) => setActiveSlide(next)}
            // hidden={activeSlide !== 0 ? true : undefined}
            >
                {
                    homeBannerTexts.map((hbt, i) => <div className="hbt-item"
                        key={'banner img and text ' + i}>
                        <SimpleImage props={{
                            src: hbt.img, alt: 'banner image 1'
                        }} />
                        <div>
                            <h1>{hbt.h1} </h1>
                            <b>
                                Faute d'informations ou en raison d'une complexité des démarches,<br />
                                de nombreuses prestations sociales
                                ne sont pas réclamées<br /> par les personnes qui y ont pourtant droit !
                            </b>

                            <section className={i > 2 ? "hbt-refPg" : "hbt-authAndSimulateLinks"}>
                                {
                                    i > 2 ? <Link to="/Programme-de-reference/description">En savoir plus</Link> : <>
                                        <Link to="/Inscription">S'inscrire</Link>
                                        <SimulationLink />
                                    </>
                                }
                            </section>
                        </div>
                    </div>)
                }
            </Slider>
        </section>

    </div>
}
