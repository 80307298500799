import { Field, ErrorMessage } from "formik";

export function SimpleInputWithFormik(props) {
    const { name, ph, label, type, fieldStyle, ...rest } = props;
    return <div className={' formSegment '}>
        <label htmlFor="">{label}</label>
        <section className="">
            <Field type={type}
                name={name}
                placeholder={ph}
                {...rest} />
        </section>
        <ErrorMessage name={name} component="div" className="input-error-msg" />
    </div>;
}

export function TextAreaWithFormik(props) {
    const { name, ph, label, type, fieldStyle, ...rest } = props;
    return <div className={' formSegment '}>
        <label htmlFor="">{label}</label>
        <section className="">
            <Field as="textarea"
                name={name}
                placeholder={ph}
                {...rest}
                row={100} />
        </section>
        <ErrorMessage name={name} component="div" className="input-error-msg" />
    </div>;
}