import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/auth';
import DashHowItWork from '../RoutesSubComponents/dashboard/home/howItWork.js';
import DashHomeLastOperation from '../RoutesSubComponents/dashboard/home/lastOperation';
import DashWelcome from '../RoutesSubComponents/dashboard/home/welcome';
import DataNotFoundMessage from '../GlobalComponents/message/notFound';
import { withAuth } from '../hocs/withAuth';
import { AppSectionSpinner } from '../uikits/others';
const Dashboard = () => {
    const { user } = useAuth();
    const authedInfos = user;
    const authedRole = authedInfos.grade;
    const [lastOperation, setLastOperation] = useState([]);
    const [oLoading, setOloading] = useState(true);
    const userMentor = user.agentData;



    useEffect(() => {
        axios.get('/user/transaction')
            .then(res => setLastOperation(res.data))
            .catch(err => console.log(err))
            .finally(() => setOloading(false))
    }, [])

    if (oLoading)
        return <AppSectionSpinner />
    return <div className="dashboard">
        <h1>Mon espace client</h1>
        {
            lastOperation.length > 0 ?
                <DashHomeLastOperation props={{ lastOperation }} />
                : <DataNotFoundMessage text={'Desolé , aucune transaction effectuée.'} />
        }
        {
            <div className="d-welcomeAndHowItWork">
                <DashWelcome authedInfos={authedInfos}
                    userMentor={userMentor} />
                {
                    authedRole != 'Admin' && <>
                        <DashHowItWork props={{ userFullName: authedInfos.nom + ' ' + authedInfos.prenom, userMentor }} />
                    </>
                }
                <Link to="/Profil/modification">Mon profil</Link>
            </div>
        }
    </div>
}



export default withAuth(Dashboard);