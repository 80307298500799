export const S_I_AF = <>
  <div >
    <span>
      Vous recevez automatiquement les allocations familiales (AF) :
      <ul>
        <li>A partir de 2 enfants à charge (de moins de 20 ans),</li>
        <li>
          Et, à condition de résider en France au moins 6 mois dans
          l’année (consécutifs ou non).
        </li>
      </ul>
      Les allocations familiales sont cumulables avec toutes les autres
      prestations, vous les toucherez quels que soient votre nationalité,
      situation familiale et le montant de vos revenus.
    </span>
    <h3>Les montants des allocations familiales</h3>
    <span>
      Le montant mensuel des allocations familiales varie selon le nombre
      d’enfants à charge et selon vos ressources. Aussi, ce montant
      augmente avec l’âge des enfants, une majoration vous est versée pour
      un ou plusieurs enfants de 14 ans et plus.
    </span>
    <table>
      <tbody>
        <tr>
          <td colSpan="2">
            <p>
              <b>Votre situation</b>
            </p>
          </td>
          <td rowSpan="2">
            <p>
              <b>Montant de base</b>
            </p>
          </td>
          <td rowSpan="2">
            <p>
              <b>Majoration</b>
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              <b>Enfants à charge</b>
            </p>
          </td>
          <td>
            <p>
              <b>Ressources</b>
            </p>
          </td>
        </tr>
        <tr>
          <td rowSpan="3">
            <p>
              <b>2 enfants</b>
            </p>
          </td>
          <td>
            <p>Inférieures ou égales à 67 408 €</p>
          </td>
          <td>
            <p>129,86 €</p>
          </td>
          <td>
            <p>+ 64,93 € si le second enfant a plus de 14 ans</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              Supérieures à 67 408 € et inférieures ou égales à 89 847 €
            </p>
          </td>
          <td>
            <p>64,93 €</p>
          </td>
          <td>
            <p>+ 32,47 € si le second enfant a plus de 14 ans</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Supérieures à 89 847 €</p>
          </td>
          <td>
            <p>32,47 €</p>
          </td>
          <td>
            <p>+16,23 € si le second enfant a plus de 14 ans</p>
          </td>
        </tr>
        <tr>
          <td rowSpan="3">
            <p>
              <b>
                3 enfants
              </b>
            </p>
          </td>
          <td>
            <p>

              Inférieures ou égales à 73 025 €
            </p>
          </td>
          <td>
            <p>
              296,23 €
            </p>
          </td>
          <td>
            <p>
              + 64,93 € par enfant de + de 14 ans
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              Supérieures à 73 025 € et inférieures ou égales à 95 464 €
            </p>
          </td>
          <td>
            <p>
              148,12 €
            </p>
          </td>
          <td>
            <p>
              + 32,47 € par enfant de +de 14 ans
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              Supérieures à 95 464 €
            </p>
          </td>
          <td>
            <p>
              74,06 €
            </p>
          </td>
          <td>
            <p>
              +16,23 € par enfant de + de 14 ans
            </p>
          </td>
        </tr>
        <tr>
          <td rowSpan="3">
            <p>
              <b>
                4 enfants
              </b>
            </p>
          </td>
          <td>
            <p>
              Inférieures ou égales à 78 642 €
            </p>
          </td>
          <td>
            <p>
              462,61 €
            </p>
          </td>
          <td>
            <p>
              + 64,93 € par enfant de + de 14 ans
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              Supérieures à 78 642 € et inférieures ou égales à 101 081 €
            </p>
          </td>
          <td>
            <p>
              231,31 €
            </p>
          </td>
          <td>
            <p>
              + 32,47 € par enfant de +de 14 ans
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              Supérieures à 101 081 €
            </p>
          </td>
          <td>
            <p>
              115,65 €
            </p>
          </td>
          <td>
            <p>
              +16,23 € par enfant de + de 14 ans
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <h3>
      La durée des allocation familiales
    </h3>
    <p>
      Les allocations familiales sont dues à compter du mois qui suit la naissance ou l’accueil d’un 2e enfant, puis d’un 3e, etc.
    </p>
  </div>
</>
