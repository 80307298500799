export function g_Cf() {
  return (
    <>
      <div className="caf-wrapper">


        <p>
          Le complément familial est une aide financière versée par la Caisse d’allocations familiales (CAF) ou la Mutualité sociale agricole (MSA), sous conditions, aux personnes ayant au moins 3 enfants à charge.
        </p>
        <h1>Les conditions à remplir</h1>
        <span>
          L’allocataire qui bénéficie du complément familial remplit les conditions suivantes :
          <ul>
            <li>
              Être français.  Si vous êtes ressortissant de l’Union Européenne ou de l’Espace Économique Européen ou de la Suisse, vous devez remplir les conditions de droit au séjour. Si vous êtes étranger, vous devez fournir un titre de séjour en cours de validité qui justifie votre situation régulière en France
            </li>
            <li>
              Avoir au moins 3 enfants à charge de manière effective et permanente
            </li>
            <li>
              Vos enfants doivent être âgés d’au moins 3 ans et de moins de 21 ans
            </li>
            <li>
              Vos ressources ne doivent pas dépasser un certain montant
            </li>
          </ul>
          Les personnes bénéficiant de l’allocation adulte handicapé (AAH) ne peuvent pas bénéficier de l’ASS. Cependant, les assurés bénéficiant de l’AAH et remplissant les conditions d’obtention de l’ASS au 31 décembre 2016 peuvent continuer de bénéficier de l’ASS tant qu’ils remplissent les conditions d’obtention pendant une durée maximum de 10 ans.
        </span>

      </div>
    </>
  )
}
